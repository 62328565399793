import { Overlay } from '@angular/cdk/overlay';
import { HttpEventType } from '@angular/common/http';
import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { bindCallback, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { filter, finalize, first, map, mergeMap, tap } from 'rxjs/operators';

import { DialogService } from '../dialog/dialog.service';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { HelperService } from '../services/helper.service';
import { HttpRestService } from '../services/http-rest.service';

@Component({
  selector: 'app-card-canvas',
  templateUrl: './card-canvas.component.html',
  styleUrls: ['./card-canvas.component.scss'],
  exportAs: 'cardCanvas',
})
export class CardCanvasComponent implements OnInit, OnDestroy {
  /**
   * Custom template for card header.
   */
  @ContentChild('header', { read: TemplateRef })
  public header: TemplateRef<any>;

  /**
   * Fixed target width in pixel.
   */
  @Input()
  public width: number;

  /**
   * Fixed target height in pixel.
   */
  @Input()
  public height: number;

  /**
   * Title.
   */
  @Input()
  public title: string;

  /**
   * Filename without extension.
   */
  @Input()
  public filename: string;

  /**
   * GET URL.
   */
  @Input()
  public getURL: string;

  /**
   * PUT URL.
   */
  @Input()
  public putURL: string;

  /**
   * PUT form key.
   */
  @Input()
  public putKey: string;

  /**
   * Disabled flag.
   */
  @Input()
  public disabled: boolean;

  /**
   * Modification disabled flag.
   */
  @Input()
  public modificationDisabled: boolean;

  /**
   * Canvas id.
   */
  public canvasId: string;

  /**
   * Fabric.StaticCanvas.
   */
  public fabricCanvas: fabric.StaticCanvas;

  /**
   * Loading state of the canvas.
   */
  public canvasState: { loading: boolean; percentage: number; changed: boolean };

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  // public get ratio() {
  //   return (fabric as any).devicePixelRatio;
  // }

  // public get fabricWidth() {
  //   return this.fabricCanvas?.getWidth();
  // }

  // public get fabricHeight() {
  //   return this.fabricCanvas?.getHeight();
  // }

  // public get canvasWidth() {
  //   return this.fabricCanvas?.getElement().getAttribute('width');
  // }

  // public get canvasHeight() {
  //   return this.fabricCanvas?.getElement().getAttribute('height');
  // }

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService,
    private _overlay: Overlay
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this.canvasId = `canvas_${Date.now() + Math.floor(Math.random() * 1000)}`;
    this.canvasState = { loading: false, percentage: 0, changed: false };
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy() {
    this.dispose().subscribe();
    this._destroySignal.next();
  }

  /**
   * Check if add action is disabled.
   */
  public isAddDisabled(): boolean {
    return this.disabled || this.modificationDisabled || !this.fabricCanvas || this.canvasState.loading;
  }

  /**
   * Check if crop action is disabled.
   */
  public isCropDisabled(): boolean {
    return (
      this.disabled ||
      this.modificationDisabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if view action is disabled.
   */
  public isViewDisabled(): boolean {
    return (
      this.disabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if download action is disabled.
   */
  public isDownloadDisabled(): boolean {
    return (
      this.disabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if clear action is disabled.
   */
  public isClearDisabled(): boolean {
    return (
      this.disabled ||
      this.modificationDisabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if card is empty.
   */
  public isEmpty(): boolean {
    return this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined;
  }

  /**
   * Add new image into canvas.
   */
  public add(): Observable<fabric.Image> {
    if (this.isAddDisabled()) {
      return of(null);
    }

    return this._helperService.promptFileInput('image/jpeg,image/png').pipe(
      mergeMap((files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        return fromEvent(reader, 'load').pipe(
          first(),
          map(() => reader.result as string)
        );
      }),
      tap((imageData) => {
        if (!/^data:image\/(jpeg|png)/.test(imageData)) {
          this._helperService.toast('Support only JPEG/PNG image format.');
          throw 'Support only JPEG/PNG image format.';
        }
      }),
      mergeMap((imageData) => this.crop(imageData))
    );
  }

  /**
   * Crop image on the image editor.
   */
  public crop(imageData?: string): Observable<fabric.Image> {
    if (this.isCropDisabled() && this.isAddDisabled()) {
      return of(null);
    }

    return this._dialogService
      .openImmediateDialog(ImageEditorComponent, null, null, {
        data: {
          imageData: imageData ?? this.fabricCanvas.toDataURL(),
          alt: 'image',
          cropperConfig: {
            aspectRatio: this.width / this.height,
            autoCropArea: 1,
            viewMode: 2,
          },
        },
        scrollStrategy: this._overlay.scrollStrategies.block(),
      })
      .afterClosed()
      .pipe(
        mergeMap((imageData) => this._loadIntoCanvas(imageData)),
        tap(() => (this.canvasState.changed = true))
      );
  }

  /**
   * View image on the image viewer.
   */
  public view(): Observable<any> {
    if (this.isViewDisabled()) {
      return of(null);
    }

    return this._dialogService
      .openImmediateDialog(ImageViewerComponent, null, null, {
        data: {
          imageData: (this.fabricCanvas.getObjects()[0] as fabric.Image).toDataURL({ format: 'JPEG', quality: 0.92 }),
          alt: 'image',
        },
        scrollStrategy: this._overlay.scrollStrategies.block(),
      })
      .afterClosed();
  }

  /**
   * Save image to local disk.
   */
  public download(): Observable<Blob> {
    if (this.isDownloadDisabled()) {
      return of(null);
    }

    return from(
      fetch(this.fabricCanvas.getObjects()[0].toDataURL({ format: 'JPEG', quality: 0.92 })).then((res) => res.blob())
    ).pipe(tap((blob) => saveAs(blob, `${this.filename}.jpg`)));
  }

  /**
   * Clear the image from canvas.
   */
  public clear(): Observable<fabric.StaticCanvas> {
    if (this.isClearDisabled()) {
      return of(null);
    }

    this._clear();
    this.canvasState.changed = true;

    return of(this.fabricCanvas);
  }

  /**
   * Clear canvas only its image object.
   */
  private _clear() {
    const firstObject = this.fabricCanvas.getObjects()[0];
    if (firstObject && firstObject.type === 'image') {
      this.fabricCanvas.remove(firstObject);
    }
  }

  /**
   * GET the image then load into canvas.
   * @return Observable of response body.
   */
  public get(): Observable<any> {
    return of({}).pipe(
      tap(() => this._clear()),
      mergeMap(() => {
        return this._httpRestService
          .get(
            this.getURL,
            {
              responseType: 'blob',
              observe: 'events',
              reportProgress: true,
            },
            false,
            false,
            (event) => {
              if (event.type == HttpEventType.Sent) {
                this.canvasState.loading = true;
                this.canvasState.percentage = 0;
              } else if (event.type == HttpEventType.DownloadProgress) {
                if (event.total) {
                  this.canvasState.percentage = Math.round((100 * event.loaded) / event.total);
                }
              }
            }
          )
          .pipe(
            finalize(() => {
              this.canvasState.loading = false;
              this.canvasState.percentage = 0;
            })
          );
      }),
      mergeMap((res) => this._loadIntoCanvas(res.body).pipe(map(() => res.body))),
      tap(() => (this.canvasState.changed = false))
    );
  }

  /**
   * PUT the image.
   * @return Observable of response body.
   */
  public put(): Observable<any> {
    return of({}).pipe(
      filter(() => this.canvasState.changed),
      map(() => (this.isEmpty() ? null : this.fabricCanvas.toDataURL({ format: 'JPEG', quality: 0.92 }))),
      mergeMap((imageData) => {
        return imageData
          ? from(
              fetch(imageData)
                .then((res) => res.blob())
                .catch((err) => null)
            )
          : of(null);
      }),
      mergeMap((blob) => {
        const formData = new FormData();
        formData.append('_method', 'put');
        if (blob) {
          formData.append(this.putKey, blob, `${this.filename}.jpg`);
        }

        return this._httpRestService
          .post(this.putURL, formData, { observe: 'events', reportProgress: true }, true, false, (event) => {
            if (event.type == HttpEventType.Sent) {
              this.canvasState.loading = true;
              this.canvasState.percentage = 0;
            } else if (event.type == HttpEventType.UploadProgress) {
              if (event.total) {
                this.canvasState.percentage = Math.round((100 * event.loaded) / event.total);
              }
            }
          })
          .pipe(
            map((res) => res.body),
            finalize(() => {
              this.canvasState.loading = false;
              this.canvasState.percentage = 0;
            })
          );
      })
    );
  }

  /**
   * Initialize fabric.StaticCanvas.
   * @return Observable of fabric.StaticCanvas.
   */
  public initialize(): Observable<fabric.StaticCanvas> {
    return Observable.create((observer) => {
      if (this.fabricCanvas && this.fabricCanvas.getElement() === document.getElementById(this.canvasId)) {
        // If fabricCanvas was initialized and canvas element it currently handles is the canvas
        // element currently exists in the DOM, we will only clear the fabricCanvas instead of
        // reinitialized fabric.StaticCanvas.

        this.fabricCanvas.clear();
      } else {
        this.fabricCanvas = new fabric.StaticCanvas(this.canvasId, { enableRetinaScaling: false });

        this.fabricCanvas.setDimensions({
          width: this.width,
          height: this.height,
        });
      }

      observer.next(this.fabricCanvas);
      observer.complete();
    });
  }

  /**
   * Dispose fabric canvas.
   */
  public dispose(): Observable<fabric.StaticCanvas> {
    if (this.fabricCanvas) {
      this.fabricCanvas.dispose();
    }
    return of(this.fabricCanvas);
  }

  /**
   * Load image data into canvas.
   * @param imageData Image data as Base64 or Blob.
   * @return Observable of image as fabric.Image.
   */
  private _loadIntoCanvas(imageData: string | Blob): Observable<fabric.Image> {
    return of(imageData).pipe(
      tap((imageData) => {
        if (!imageData) {
          throw 'No source image.';
        }
      }),
      mergeMap((imageData) =>
        typeof imageData === 'string' ? fetch(imageData).then((res) => res.blob()) : of(imageData)
      ),
      tap(() => this.fabricCanvas.clear()),
      mergeMap((blob) => bindCallback(fabric.Image.fromURL)(URL.createObjectURL(blob))),
      map((fabricImage) => {
        fabricImage.setOptions({
          scaleX: this.width / fabricImage.getOriginalSize().width,
          scaleY: this.height / fabricImage.getOriginalSize().height,
          top: 0,
          left: 0,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          selectable: false,
        });
        this.fabricCanvas.add(fabricImage);
        this.fabricCanvas.sendToBack(fabricImage);
        return fabricImage;
      })
    );
  }
}
