import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Chart, ChartConfiguration, ChartData, ChartPoint } from 'chart.js';
import { clone, isNumber, unionBy } from 'lodash-es';
import * as moment from 'moment-mini';
import { Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, delay, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { HttpRestService } from '../services/http-rest.service';
import { SanitizerService } from '../services/sanitizer.service';

export enum TimelinePanDirection {
  Left = 'left',
  Right = 'right',
}

export enum TimelineMode {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  animations: [
    trigger('showBackdrop', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ opacity: 0 }))]),
    ]),
  ],
  exportAs: 'ChartComponent',
})
export class ChartComponent implements AfterViewInit, OnDestroy {
  /**
   * Canvas ElementRef used by Chart.js to render the chart.
   */
  @ViewChild('canvas')
  private _canvasRef: ElementRef;

  /**
   * Chart params' NgForm directive.
   */
  @ViewChild('paramForm', { read: NgForm })
  private _paramForm: NgForm;

  /**
   * Projected chart params' NgModel directives.
   */
  @ContentChildren(NgModel, { read: NgModel, descendants: true })
  private _paramProjectedModels: QueryList<NgModel>;

  /**
   * Id for data retrieval purpose.
   */
  @Input()
  public id: string;

  /**
   * Displayed title.
   */
  @Input()
  public title: string;

  /**
   * Resource URL for data retrieval from backend.
   */
  @Input()
  public resourceURL: string;

  /**
   * Chart.js configuration.
   */
  @Input()
  public configuration: ChartConfiguration;

  /**
   * Local data as opposed to data retrieved from backend.
   */
  @Input()
  public localData?: ChartData;

  /**
   * Interval in minutes of chart auto refresh.
   */
  @Input()
  public set refreshInterval(value: number) {
    this._refreshInterval = value;
    this.resetAutoRefresh();
  }
  public get refreshInterval(): number {
    return this._refreshInterval;
  }
  private _refreshInterval: number;

  /**
   * Timeline chart param mode.
   */
  @Input()
  public timelineMode?: TimelineMode;

  /**
   * Timeline chart param max span.
   */
  @Input()
  public timelineMaxSpan?: number;

  /**
   * TimelinePanDirection enums for template use.
   */
  public timelinePanDirection = TimelinePanDirection;

  /**
   * Timeline patch mode enable flag.
   */
  @Input()
  public timelinePatchModeEnable: boolean;

  /**
   * Timeline patch mode active flag.
   */
  public timelinePatchMode: boolean;

  /**
   * Flag indicating non-continuous dataset patch due to data refresh failed.
   * This flag is used to force user to click refresh to amend the gap
   * instead of panning left/right or changing date range or span.
   */
  public timelinePatchModeGapExists: boolean;

  /**
   * Keep track the left most start date range.
   */
  private _timelinePatchModeStart: moment.Moment;

  /**
   * Keep track the right most end date range.
   */
  private _timelinePatchModeEnd: moment.Moment;

  /**
   * Keep track the last performed pan direction.
   */
  private _timelineLastPanDirection: TimelinePanDirection;

  /**
   * Parameter for data retrieval from backend.
   */
  public params: any = {};

  /**
   * Flag indicating the chart is refreshing its data.
   */
  public refreshing: boolean;

  /**
   * Chart.js instance.
   */
  public chart: Chart;

  /**
   * Timeline chart datepicker param's filter.
   */
  public timelineDatepickerFilter = (d: moment.Moment | null): boolean => {
    switch (this.timelineMode) {
      case TimelineMode.Month:
        return d?.date() === 1;
        break;

      case TimelineMode.Week:
        return d?.day() === 1;
        break;

      default:
        return true;
        break;
    }
  };

  /**
   * Last refresh time.
   */
  private _lastRefresh: moment.Moment;

  /**
   * Auto refresh subscription.
   */
  private _refreshSub?: Subscription;

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(private _httpRestService: HttpRestService, private _sanitizerService: SanitizerService) {}

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit(): void {
    // Workaround to add content projected NgModel to the parent NgForm
    // in order for them to participate in params form validation.
    this._paramProjectedModels.forEach((ngModel) => {
      this._paramForm.addControl(ngModel);
    });
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy(): void {
    this.destroy();
  }

  /**
   * Instantiate new Chart object with provided configuration and register auto refresh.
   */
  public init(): void {
    this.params = this.configuration.options.plugins.params;

    // By default timeline patch mode is enabled when timeline mode is provided, unless specified otherwise by @Input().
    this.timelinePatchModeEnable =
      this.timelinePatchModeEnable === undefined ? !!this.timelineMode : this.timelinePatchModeEnable;

    if (this.timelinePatchModeEnable) {
      // Get the default active state of the timeline patch mode.
      this.timelinePatchMode = this.configuration.options.plugins.timelinePatchMode;
      // Define the most left or right date range.
      if (this.timelinePatchMode) {
        this._timelinePatchModeStart = this.params.start;
        this._timelinePatchModeEnd = this.params.end;
      }
    }

    // By default we would expand to the left when span is changed and pan buttons has never been clicked.
    this._timelineLastPanDirection = TimelinePanDirection.Left;

    this.chart = new Chart(this._canvasRef.nativeElement, clone(this.configuration));
    this._registerAutoRefresh();
  }

  /**
   * Destroy chart and unregister auto refresh.
   */
  public destroy(): void {
    if (this.chart) {
      this._destroySignal.next();
      this._unregisterAutoRefresh();
      this.chart.destroy();
    }
  }

  /**
   * Refresh chart with new chart data.
   */
  public refresh(): void {
    if (!this.chart || this.refreshing || this._paramForm.invalid) {
      return;
    }

    this.refreshing = true;

    this._getChartData()
      .pipe(
        takeUntil(this._destroySignal),
        tap((result) => {
          // Clear any previous zoom.
          if ((this.chart as any).resetZoom) {
            (this.chart as any).resetZoom();
          }

          // Keep previous datasets' visibility.
          const datasetsExist = this.chart.data.datasets.length > 0;
          const visibilities = (result.datasets as any[]).map((dataset, index) => {
            return datasetsExist ? this.chart.isDatasetVisible(index) : true;
          });

          // Set new datasets.
          if (this.chart.data.datasets.length > 0 && this.timelinePatchMode) {
            for (let i = 0; i < result.datasets.length; i++) {
              const existing = this.chart.data.datasets[i].data as ChartPoint[];
              const patch = result.datasets[i].data as ChartPoint[];

              this.chart.data.datasets[i].data = unionBy<ChartPoint>(
                this._timelineLastPanDirection === TimelinePanDirection.Left ? patch : existing,
                this._timelineLastPanDirection === TimelinePanDirection.Left ? existing : patch,
                (point) => point.x
              );
            }
          } else {
            this.chart.data = result;
          }

          // Set there's no dataset gap.
          this.timelinePatchModeGapExists = false;

          // Apply previous datasets' visibility.
          visibilities.forEach((visible, index) => {
            const meta = this.chart.getDatasetMeta(index);
            meta.hidden = visible ? null : true;
          });

          // Rerender chart.
          this.chart.update();

          // Capture last refresh time.
          this._lastRefresh = moment();
        }),
        // Delay switching off the refreshing flag to skip any subsequent calls to chart.refresh()
        // within 500ms avoiding "double refresh". This is because chart.init() and chart params'
        // ngControl (ngModelChange) both could call chart.refresh() during initialization.
        delay(500),
        catchError((error) => {
          // Set there's dataset gap.
          if (this.timelinePatchMode) {
            this.timelinePatchModeGapExists = true;
          }
          return throwError(error);
        }),
        finalize(() => (this.refreshing = false))
      )
      .subscribe();
  }

  /**
   * Reset chart with new chart configuraion options.
   */
  public reset(): void {
    // This reset function is also a workaround to forcely trigger gridLines.color change.
    this.chart.options = clone(this.configuration.options);
    this.chart.update();
  }

  /**
   * Get last refresh label.
   */
  public getLastRefresh(): string {
    return this.chart
      ? this.refreshing
        ? 'refreshing...'
        : this._lastRefresh instanceof moment
        ? this._lastRefresh.fromNow()
        : 'unknown'
      : 'unknown';
  }

  /**
   * Reset auto refresh schedule registration.
   */
  public resetAutoRefresh() {
    this._unregisterAutoRefresh();
    this._registerAutoRefresh();
  }

  /**
   * Handle timeline start or end date changed.
   */
  public onTimelineStartEndChange() {
    this.params.span = this.params.end.diff(this.params.start, this.timelineMode) + 1;
    this.refresh();
  }

  /**
   * Handle timeline span model changed.
   * @param amount New span value.
   */
  public onTimelineSpanModelChange(amount: number) {
    this._alterTimeline(this._timelineLastPanDirection, { mode: 'resize', spanSize: amount });
  }

  /**
   * Handle timeline pan to the left or right.
   * @param timelinePanDirection Timeline pan direction.
   */
  public onTimelinePan(timelinePanDirection: TimelinePanDirection) {
    this._alterTimeline(timelinePanDirection, { mode: 'pan', panSpeed: 0.15 });
  }

  /**
   * Alter timeline's start, end, or span and then refresh the chart.
   * @param timelinePanDirection Timeline pan direction.
   * @param options Timeline alteration options.
   */
  private _alterTimeline(
    timelinePanDirection: TimelinePanDirection,
    options: { mode: 'pan' | 'resize'; panSpeed?: number; spanSize?: number }
  ) {
    if (this.refreshing) {
      return;
    }

    // Avoid modifying param's start, end, and span on invalid form to avoid dataset gap especially on patch mode.
    if (this._paramForm.invalid) {
      return;
    }

    // Slide the date range to the most left or right based on current span.
    if (this.timelinePatchMode) {
      if (timelinePanDirection === TimelinePanDirection.Left) {
        this.params.start = this._timelinePatchModeStart.clone();
        this.params.end = this.params.start.clone().add(this.params.span - 1, this.timelineMode);
      } else {
        this.params.end = this._timelinePatchModeEnd.clone();
        this.params.start = this.params.end.clone().subtract(this.params.span - 1, this.timelineMode);
      }
    }

    // Update the span and date range based on alteration mode.

    if (options.mode === 'pan') {
      const method = timelinePanDirection === TimelinePanDirection.Left ? 'subtract' : 'add';
      const speed = this.timelinePatchMode ? 1 : options.panSpeed;
      this.params.start = this.params.start
        .clone()
        [method](Math.max(1, Math.floor(speed * this.params.span)), this.timelineMode);
      this.params.end = this.params.end
        .clone()
        [method](Math.max(1, Math.floor(speed * this.params.span)), this.timelineMode);
    }

    if (options.mode === 'resize') {
      this.params.span = options.spanSize;
      if (this.timelinePatchMode) {
        if (timelinePanDirection === TimelinePanDirection.Left) {
          this.params.end = this.params.start.clone().subtract(1, this.timelineMode);
          this.params.start = this.params.end.clone().subtract(this.params.span - 1, this.timelineMode);
        } else {
          this.params.start = this.params.end.clone().add(1, this.timelineMode);
          this.params.end = this.params.start.clone().add(this.params.span - 1, this.timelineMode);
        }
      } else {
        this.params.start = this.params.end.clone().subtract(this.params.span - 1, this.timelineMode);
      }
    }

    // Keep track left and right most date range.
    if (this.timelinePatchMode) {
      if (timelinePanDirection === TimelinePanDirection.Left) {
        this._timelinePatchModeStart = this.params.start.clone();
      } else {
        this._timelinePatchModeEnd = this.params.end.clone();
      }
    }

    // Keep track last pan direction.
    this._timelineLastPanDirection = timelinePanDirection;

    this.refresh();
  }

  /**
   * Handle patch mode checkbox's model change.
   * @param active Current patch mode active state.
   */
  public onTimelinePatchModeModelChange(active: boolean) {
    this.timelinePatchMode = active;

    if (this.timelinePatchMode) {
      this._timelinePatchModeStart = this.params.start;
      this._timelinePatchModeEnd = this.params.end;
    } else {
      // Slide the date range to the most right based on current span.
      this.params.end = this._timelinePatchModeEnd.clone();
      this.params.start = this.params.end.clone().subtract(this.params.span - 1, this.timelineMode);

      this._timelinePatchModeStart = undefined;
      this._timelinePatchModeEnd = undefined;
    }

    this.refresh();
  }

  /**
   * Handle show data label checkbox's model change.
   * @param show Current data label's show state.
   */
  public onShowDataLabelModelChange(show: boolean) {
    this.chart.options.plugins.showDataLabel = show;
    // We don't call reset() as it contains workaround which interfere ngModel binding.
    this.chart.update();
  }

  @HostListener('document:keydown', ['$event'])
  public onShiftKeyDown(event: KeyboardEvent) {
    if (event.key == 'Shift') {
      this._toggleZoomPan(true);
    }
  }

  @HostListener('document:keyup', ['$event'])
  public onShiftKeyUp(event: KeyboardEvent) {
    if (event.key == 'Shift') {
      this._toggleZoomPan(false);
    }
  }

  @HostListener('pointerdown', ['$event'])
  public onPointerDown(event: PointerEvent) {
    this._toggleZoomPan(true);
  }

  @HostListener('pointerup', ['$event'])
  public onPointerUp(event: PointerEvent) {
    this._toggleZoomPan(false);
  }

  /**
   * Enable or disable zoom and pan.
   * @param enable Enable flag.
   */
  private _toggleZoomPan(enable: boolean) {
    // Use settimeout to avoid Chart.update() to interfere with dataset hiding during user clicking dataset label.
    if (this.chart.options.plugins.zoom) {
      setTimeout(() => {
        if (this.configuration.options.plugins.zoom) {
          this.chart.options.plugins.zoom.zoom.enabled = enable;
          this.chart.options.plugins.zoom.pan.enabled = enable;
        } else {
          // Make zoom always disabled if zoom key is not preset in the configuration object.
          // This way we can selectively disable zoom on certain charts.
          this.chart.options.plugins.zoom.zoom.enabled = false;
          this.chart.options.plugins.zoom.pan.enabled = false;
        }
        this.chart.update();
      });
    }
  }

  /**
   * Register auto refresh.
   */
  private _registerAutoRefresh() {
    if (isNumber(this._refreshInterval)) {
      this._refreshSub = timer(0, this._refreshInterval * 60 * 1000)
        .pipe(tap(() => this.refresh()))
        .subscribe();
    } else {
      // Only refresh once if refresh interval is not defined.
      // Use setTimeout to allow param form validation to run first.
      setTimeout(() => this.refresh());
    }
  }

  /**
   * Unregister auto refresh.
   */
  private _unregisterAutoRefresh() {
    if (this._refreshSub) {
      this._refreshSub.unsubscribe();
    }
  }

  /**
   * Perform GET request to retrieve chart data.
   */
  private _getChartData(): Observable<any> {
    return this.localData
      ? of(this.localData)
      : this._httpRestService
          .get(this.resourceURL, {
            params: { chartId: this.id, chartParams: JSON.stringify(this._sanitizerService.sanitizeSent(this.params)) },
          })
          .pipe(map((result) => result.data));
  }
}
