import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash-es';

import { HelperService } from '../services/helper.service';
import { QAdv } from './q-adv.interface';
import { QueryPresetList } from './query-preset-list.interface';

/**
 * Query service.
 *
 * qAdv format:
 * {
 *     s : {b: 'and', g: [
 *             {f: 'field', type: 'select', comp: undefined, i: 1},
 *             ...
 *         ], type: 'select', comp: undefined, root: true, i: 0},
 *     w : {b: 'and', g: [
 *             {b: 'and', f: 'field', o:'=', v:'value', type: 'where', comp: undefined, i: 3},
 *             {b: 'or', g: [...], type: 'where', comp: undefined, root: false, i: 4},
 *             ...
 *         ], type: 'where', comp: undefined, root: true, i: 2},
 *     h : [
 *             {b: 'or', r: 'relationNameOne', resourceURL: '/relation-one', c: [{o: '>=', n: 1},...], a: false, g: [
 *                  ...
 *             ], type: 'where', comp: undefined, root: true, i: 5},
 *             {b: 'or', r: 'relationNameTwo', resourceURL: '/relation-two', c: [{o: '>=', n: 1},...], a: false, g: [
 *                 ...
 *             ], type: 'where', comp: undefined, root: true, i: 6},
 *             ...
 *         ],
 *     j : {b: 'and', g: [
 *             {t: 'table', 's': 'first', 'o': 'operator', 'd': 'second', 'y': 'type', type: 'join', comp: undefined, i: 8},
 *             ...
 *         ], type: 'join', comp: undefined, root: true, i: 7}
 *     o : {b: 'and', g: [
 *             {f: 'field', 'o': 'asc', type: 'order', comp: undefined, i: 10},
 *             ...
 *         ], type: 'order', comp: undefined, root: true, i: 9}
 * }
 *
 * Extracted qAdv format:
 * {
 *     s : [
 *             {f: 'field'},
 *             ...
 *         ],
 *     w : [
 *             {b: 'and', f: 'field', o:'=', v:'value'},
 *             {b: 'or', g: [...]},
 *             ...
 *         ],
 *     h : [
 *             {b: 'or', r: 'relationNameOne', c: [{o: '>=', n: 1},...], a: false, g: [...]},
 *             {b: 'or', r: 'relationNameTwo', c: [{o: '>=', n: 1},...], a: false, g: [...]},
 *             ...
 *         ],
 *     j : [
 *             {t: 'table', 's': 'first', 'o': 'operator', 'd': 'second', 'y': 'type'},
 *             ...
 *         ]
 *     o : [
 *             {f: 'field', 'o': 'asc'},
 *             ...
 *         ]
 * }
 *
 */
@Injectable({
  providedIn: 'root',
})
export class QueryService {
  /**
   * The name of default preset.
   */
  public readonly DEFAULT_QADV_PRESET_NAME: string = 'Default';

  /**
   * The name of last used qAdv preset.
   */
  public readonly LAST_USED_QADV_PRESET_NAME: string = 'Last Used';

  /**
   * The default name of custom qAdv preset.
   */
  public readonly DEFAULT_CUSTOM_QADV_PRESET_NAME: string = 'Custom Preset';

  /**
   * The local storage key used to store non-fixed qAdv presets.
   */
  private readonly _QADV_PRESET_STORAGE_KEY: string = 'qAdvPresets';

  /**
   * qAdv preset lists for all resources.
   */
  public queryPresetLists: QueryPresetList[] = [];

  /**
   * Constructor.
   */
  constructor(private _helperService: HelperService) {
    // // Temporary auto rename saved qAdv preset object keys.
    // const savedQueryPresets: QueryPreset[] = this._helperService.getLocalStorageItem(this._QADV_PRESET_STORAGE_KEY);
    // const presetRenamed = this._helperService.getLocalStorageItem('preset_renamed');
    // if (!presetRenamed && savedQueryPresets instanceof Array) {
    //   savedQueryPresets.forEach((queryPreset) => {
    //     queryPreset.presets.forEach((qAdvPreset) => {
    //       if (Object.keys(qAdvPreset).includes('display')) {
    //         qAdvPreset.name = (qAdvPreset as any).display;
    //         qAdvPreset.qAdv = (qAdvPreset as any).value;
    //         delete (qAdvPreset as any).display;
    //         delete (qAdvPreset as any).value;
    //       }
    //     });
    //   });
    //   this._helperService.setLocalStorageItem(this._QADV_PRESET_STORAGE_KEY, savedQueryPresets);
    //   this._helperService.setLocalStorageItem('preset_renamed', true);
    // }
  }

  /**
   * Get qAdv presets for specified resourceURL.
   * @param resourceURL Resource URL.
   * @return qAdv presets.
   */
  public getQAdvPresets(resourceURL: string): QAdv[] {
    const queryPresetList = this.queryPresetLists.find((item) => item.resourceURL === resourceURL);

    const fixedQAdvPresets = queryPresetList ? cloneDeep(queryPresetList.presets) : [];
    const savedQAdvPresets = this._getSavedQAdvPresets(resourceURL);

    const lastUsedIndex = savedQAdvPresets.findIndex((item) => item.name.startsWith(this.LAST_USED_QADV_PRESET_NAME));
    const lastUsedQAdvPreset = lastUsedIndex >= 0 ? savedQAdvPresets.splice(lastUsedIndex, 1) : [];

    const defaultIndex = fixedQAdvPresets.findIndex((item) => item.name === this.DEFAULT_QADV_PRESET_NAME);
    const defaultQAdvPreset = defaultIndex >= 0 ? fixedQAdvPresets.splice(defaultIndex, 1) : [];

    return lastUsedQAdvPreset.concat(defaultQAdvPreset).concat(fixedQAdvPresets).concat(savedQAdvPresets);
  }

  /**
   * Get default qAdv preset for specified resourceURL.
   * @param resourceURL Resource URL.
   * @return Default qAdv preset.
   */
  public getDefaultQAdvPreset(resourceURL: string): QAdv {
    return this.getQAdvPresets(resourceURL).find((preset) => preset.name === this.DEFAULT_QADV_PRESET_NAME);
  }

  /**
   * Save specified qAdv as qAdv preset to local storage.
   * @param resourceURL Resource URL.
   * @param name qAdv preset name.
   * @param qAdv qAdv to be saved.
   */
  public saveAsQAdvPreset(resourceURL: string, name: string, qAdv: QAdv): void {
    let savedQueryPresetLists: QueryPresetList[] = this._helperService.getLocalStorageItem(
      this._QADV_PRESET_STORAGE_KEY
    );
    if (!(savedQueryPresetLists instanceof Array)) {
      savedQueryPresetLists = [];
    }

    let savedQueryPresetList: QueryPresetList = savedQueryPresetLists.find((item) => item.resourceURL === resourceURL);
    if (!(savedQueryPresetList instanceof Object)) {
      savedQueryPresetList = { resourceURL: resourceURL, presets: [] };
      savedQueryPresetLists.push(savedQueryPresetList);
    }

    qAdv.name = name;

    const savedQAdvPresetIndex = savedQueryPresetList.presets.findIndex((item) =>
      name.startsWith(this.LAST_USED_QADV_PRESET_NAME)
        ? item.name.startsWith(this.LAST_USED_QADV_PRESET_NAME)
        : item.name === name
    );

    if (savedQAdvPresetIndex > -1) {
      savedQueryPresetList.presets.splice(savedQAdvPresetIndex, 1, qAdv);
    } else {
      savedQueryPresetList.presets.push(qAdv);
    }

    this._helperService.setLocalStorageItem(this._QADV_PRESET_STORAGE_KEY, savedQueryPresetLists);
  }

  /**
   * Delete specified qAdv preset name from local storage.
   * @param resourceURL Resource URL.
   * @param name qAdv preset name.
   */
  public deleteQAdvPreset(resourceURL: string, name: string): void {
    const savedQueryPresetLists: QueryPresetList[] = this._helperService.getLocalStorageItem(
      this._QADV_PRESET_STORAGE_KEY
    );

    const savedQueryPresetList =
      savedQueryPresetLists instanceof Array
        ? savedQueryPresetLists.find((item) => item.resourceURL === resourceURL)
        : undefined;

    if (savedQueryPresetList) {
      const deletedQAdvPresetIndex = savedQueryPresetList.presets.findIndex((preset) => preset.name === name);

      if (deletedQAdvPresetIndex > -1) {
        savedQueryPresetList.presets.splice(deletedQAdvPresetIndex, 1);
        this._helperService.setLocalStorageItem(this._QADV_PRESET_STORAGE_KEY, savedQueryPresetLists);
      }
    }
  }

  /**
   * Extract qAdv for transmitting over HTTP as JSON.
   * @param qAdv qAdv to be extracted.
   * @return Extracted QAdv.
   */
  public extract(qAdv: QAdv): object {
    // Clone and wiped unused qAdv properties.
    const wipedQAdv = cloneDeep(qAdv);
    this._wipeProperties(wipedQAdv, ['i', 'root', 'comp', 'searchable', 'type', 'resourceURL', 'temp']);

    // Extract the qAdv
    const extractedQAdv: any = {};
    extractedQAdv.name = wipedQAdv.name.replace(new RegExp(`${this.LAST_USED_QADV_PRESET_NAME} — `), '');
    extractedQAdv.mode = wipedQAdv.mode;
    extractedQAdv.report = wipedQAdv.report;
    extractedQAdv.s = wipedQAdv.s.g;
    extractedQAdv.w = wipedQAdv.w.g;
    extractedQAdv.h = wipedQAdv.h;
    extractedQAdv.j = wipedQAdv.j.g;
    extractedQAdv.o = wipedQAdv.o.g;

    return extractedQAdv;
  }

  /**
   * Export all saved qAdv presets from local storage.
   */
  public export(): void {
    saveAs(
      new Blob([localStorage.getItem(this._QADV_PRESET_STORAGE_KEY)], { type: 'application/json' }),
      'presets.json'
    );
  }

  /**
   * Import provided qAdv presets into local storage.
   */
  public import(afterImport: () => void): void {
    // Create input element.
    const input: any = document.createElement('input');
    input.setAttribute('type', 'file');

    // Register input change event.
    input.onchange = (changeEvent) => {
      // Read input file.
      const fr = new FileReader();
      fr.onload = (loadEvent) => {
        localStorage.setItem(this._QADV_PRESET_STORAGE_KEY, (<any>loadEvent.target).result);
        afterImport();
      };
      fr.readAsText(changeEvent.target.files[0]);

      // Remove input element.
      document.body.removeChild(input);
    };

    // Append and perform click on input element.
    document.body.appendChild(input);
    input.click();
  }

  /**
   * Wipe specified properties from an object recursively.
   * @param obj Object to be wiped.
   * @param wipedKeys Array of keys to be wiped.
   */
  private _wipeProperties(obj: Object, wipedKeys: string[]): void {
    for (const key in obj) {
      if (wipedKeys.find((wipedKey) => key === wipedKey)) {
        delete obj[key];
      } else if (obj[key] instanceof Object) {
        this._wipeProperties(obj[key], wipedKeys);
      }
    }
  }

  /**
   * Get saved qAdv preset for specified resourceURL from local storage.
   * @param resourceURL Resource URL.
   * @return Saved qAdv presets.
   */
  private _getSavedQAdvPresets(resourceURL: string): QAdv[] {
    const savedQueryPresetLists: QueryPresetList[] = this._helperService.getLocalStorageItem(
      this._QADV_PRESET_STORAGE_KEY
    );

    const savedQueryPresetList =
      savedQueryPresetLists instanceof Array
        ? savedQueryPresetLists.find((item) => item.resourceURL === resourceURL)
        : undefined;

    return savedQueryPresetList ? savedQueryPresetList.presets : [];
  }
}
