import { Pipe, PipeTransform } from '@angular/core';

/**
 * Add row number field `_no`into each element of an array of object.
 */
@Pipe({
  name: 'rowNumber',
})
export class RowNumberPipe implements PipeTransform {
  /**
   * Transform the value.
   * @param value Value to be transformed.
   * @param args Pipe arguments.
   */
  transform(value: Array<Object>, ...args: any[]): any {
    return value.map((item, index) => {
      item['_no'] = item['_no'] == undefined ? index + 1 : item['_no'];
      return item;
    });
  }
}
