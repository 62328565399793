<app-base-dialog #dialog dialogTitle="User Profile">
  <div top-toolbar></div>

  <div content>
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ name }}</mat-card-title>
        <mat-card-subtitle>{{ email }}</mat-card-subtitle>
        <mat-card-subtitle>{{ roles }} {{ specialRole }}</mat-card-subtitle>
      </mat-card-header>

      <mat-tab-group>
        <mat-tab *ngFor="let workgroup of workgroups; let appIndex = index" label="{{ workgroup.appCode }}">
          <div fxLayout="column" class="tab-wrapper">
            <mat-form-field>
              <mat-label>Active Workgroup</mat-label>
              <mat-select
                placeholder="Active Workgroup"
                [ngModel]="profilePreference.activeWorkgroupIndex[workgroup.appId]"
                (ngModelChange)="selectActiveWorkgroup(workgroup.appId, $event)"
              >
                <mat-option
                  *ngFor="let workgroupItem of workgroup.workgroups; let workgroupIndex = index"
                  [value]="workgroupIndex"
                  >{{ workgroupItem.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Active Storage</mat-label>
              <mat-select
                placeholder="Active Storage"
                [ngModel]="profilePreference.activeStorageIndex[workgroup.appId]"
                (ngModelChange)="selectActiveStorage(workgroup.appId, $event)"
              >
                <mat-option
                  *ngFor="
                    let storage of workgroup.workgroups[profilePreference.activeWorkgroupIndex[workgroup.appId]]
                      ?.storages;
                    let storageIndex = index
                  "
                  [value]="storageIndex"
                  >{{ storage.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div *ngIf="appIndex === 0" class="filter-workgroup" fxLayout="row wrap">
              <span *ngIf="appIndex === 0" class="title">Report Workgroup Filter</span>
              <mat-checkbox
                [disabled]="lastFilterWorkgroupChecked(filterIndex)"
                *ngFor="let workgroupItem of workgroup.workgroups; let filterIndex = index"
                [checked]="filterWorkgroupChecked(filterIndex)"
                (change)="toggleFilterWorkgroup(filterIndex)"
                [style.visibility]="workgroupExistInActiveStorage(workgroupItem) ? 'visible' : 'hidden'"
              >
                {{ workgroupItem.name }}
              </mat-checkbox>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
