import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, ResolveEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { MenuService } from '../menu/menu.service';
import { GlobalService } from '../services/global.service';
import { BREADCRUMBS_SEPARATOR } from './breadcrumbs-separator.token';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  /**
   * Leaf portion of breadcrumbs as Subject of string.
   */
  private _leaf: Subject<string> = new Subject();

  /**
   * Breadcrumbs HTML string.
   */
  public html: Observable<string> = null;

  /**
   * Constructor.
   */
  constructor(
    @Inject(BREADCRUMBS_SEPARATOR) private _separator: string,
    private _globalService: GlobalService,
    private _menuService: MenuService,
    private _router: Router,
    private _meta: Meta,
    private _title: Title
  ) {
    const navigationFinished$: Observable<
      NavigationEnd | NavigationCancel | NavigationError
    > = this._router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError
      ),
      map((event) => event as NavigationEnd | NavigationCancel | NavigationError)
    );

    // Pipe to startWith() bacause data has to be present on both
    // streams in order to combineLatest() to emit output.
    // (Happens during intial route navigation)
    const leaf$: Observable<string> = this._leaf.asObservable().pipe(startWith(null));

    this.html = combineLatest([navigationFinished$, leaf$]).pipe(
      map(([navigationFinished, leaf]) => {
        const portions = [
          navigationFinished instanceof NavigationEnd
            ? this._inferRoot(navigationFinished.urlAfterRedirects)
            : this._inferRoot(navigationFinished.url),
        ];
        if (leaf) {
          portions.push(leaf);
        }
        return portions.join(this._separator);
      }),
      tap((breadcrumbs) => this._setTitleAndDescription(breadcrumbs))
    );

    // Clear leaf value on ResolvedEnd because not all routes have
    // identifier leaf portion so we assume next resolved
    // route doesn't have identifier leaf portion.
    this._router.events.subscribe((event) => {
      if (event instanceof ResolveEnd) {
        this.setLeaf(null);
      }
    });
  }

  /**
   * Set leaf portion of the breadcrumbs.
   * @param value Breadcrumbs leaf portion.
   */
  public setLeaf(value: string): void {
    this._leaf.next(value);
  }

  /**
   * Infer root portion of breadcrumbs based on either menus or url.
   * @param url URL to infer breadcrumbs.
   * @return Breadcrumbs string root portion.
   */
  private _inferRoot(url: string): string {
    let root = this._menuService.getActiveMenuItemBreadcrumbs(url);

    if (!root) {
      // Infer based on url.
      let stripedUrl: string = url;

      // Remove query string parameters.
      stripedUrl = stripedUrl.split('?')[0];

      // Remove angular's optional route parameters.
      stripedUrl = stripedUrl.split(';')[0];

      // Remove leading slash.
      stripedUrl = stripedUrl.substring(1);

      // Create bread crumbs.
      root = stripedUrl.split('/').join(this._separator);
    }

    return root;
  }

  /**
   * Set document title (browser tab) and description based on breadcrumbs.
   * @param breadcrumbs Full breadcrumbs string.
   */
  private _setTitleAndDescription(breadcrumbs: string): void {
    const portions: string[] = breadcrumbs.split(this._separator);
    const currentPage = portions
      // Take last 2 portions only.
      .slice(-2)
      .join(' - ')
      // Capitalize each words.
      .replace(/(^|\s)[a-z]/g, (str) => str.toUpperCase())
      // Remove html tag characters.
      .replace(/(<([^>]+)>)/gi, '')
      // Remove multiple whitespaces.
      .replace(/[\s]{2,}/g, ' ');

    this._title.setTitle(`${this._globalService.appNameAcronym} ${this._globalService.appMode} | ${currentPage}`);

    this._meta.updateTag({
      name: 'description',
      content: `${currentPage}. The Container Management System (CMS) is made with ❤ and proudly presented to you by PT Jangkar Pacific.`,
    });
  }
}
