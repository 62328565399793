<div fxLayout="column" fxLayoutGap="8px">
  <ng-container *ngTemplateOutlet="header || defaultHeader"></ng-container>

  <ng-template #defaultHeader>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <h1 class="mat-h1">{{ title }}</h1>
      <div class="actions" fxLayout="row" fxLayoutGap="8px">
        <button
          mat-mini-fab
          color="primary"
          (click)="add().subscribe()"
          [disabled]="isAddDisabled()"
          matTooltip="Insert"
          matTooltipShowDelay="500"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          (click)="download().subscribe()"
          [disabled]="isDownloadDisabled()"
          matTooltip="Download"
          matTooltipShowDelay="500"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          (click)="clear().subscribe()"
          [disabled]="isClearDisabled()"
          matTooltip="Clear"
          matTooltipShowDelay="500"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <div [style]="'--aspect-ratio:' + (this.width + this.stampPanelWidth) + '/' + this.height + ';'">
    <mat-card>
      <!-- {{ ratio }} {{ fabricWidth }}x{{ fabricHeight }} {{ canvasWidth }}x{{ canvasHeight }} -->
      <canvas [id]="canvasId"></canvas>

      <mat-progress-bar
        [fxShow]="canvasState.loading"
        [value]="canvasState.percentage"
        [mode]="'determinate'"
        class="progress"
        [ngStyle]="{
          'width.%':
            ((canvasState.stampPercentages ? this.stampPanelWidth : this.width) /
              (this.width + this.stampPanelWidth) /
              2) *
            100,
          'left.%':
            (canvasState.stampPercentages
              ? this.width / (this.width + this.stampPanelWidth) +
                this.stampPanelWidth / (this.width + this.stampPanelWidth) / 2
              : this.width / (this.width + this.stampPanelWidth) / 2) * 100
        }"
      ></mat-progress-bar>

      <div
        #actionsContainer
        class="actions"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="8px"
        [ngStyle]="{
          'width.%': (this.width / (this.width + this.stampPanelWidth) / 2) * 100,
          'left.%': (this.width / (this.width + this.stampPanelWidth) / 2) * 100
        }"
      >
        <button
          mat-mini-fab
          color="primary"
          (click)="add().subscribe()"
          [disabled]="isAddDisabled()"
          matTooltip="Insert"
          matTooltipShowDelay="500"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          (click)="crop().subscribe()"
          [disabled]="isCropDisabled()"
          matTooltip="Crop"
          matTooltipShowDelay="500"
        >
          <mat-icon>crop</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          (click)="view().subscribe()"
          [disabled]="isViewDisabled()"
          matTooltip="Fullscreen"
          matTooltipShowDelay="500"
        >
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          (click)="download().subscribe()"
          [disabled]="isDownloadDisabled()"
          matTooltip="Download"
          matTooltipShowDelay="500"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          (click)="clear().subscribe()"
          [disabled]="isClearDisabled()"
          matTooltip="Clear"
          matTooltipShowDelay="500"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
