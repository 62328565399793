import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { tap } from 'rxjs/operators';

import { DialogService } from '../../dialog/dialog.service';
import { LookupCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom lookup editor component (single resource lookup).
 */
@Component({
  selector: 'app-lookup-cell-editor',
  templateUrl: './lookup-cell-editor.component.html',
  styleUrls: ['./lookup-cell-editor.component.scss'],
})
export class LookupCellEditorComponent implements AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: LookupCellEditorParams;

  /**
   * Selected resource.
   */
  private _selectedRows: { [field: string]: any }[];

  /**
   * Constructor.
   */
  constructor(private _dialogService: DialogService) {}

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: LookupCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Add timeout delay to allow immediate stop edit mode during Shift+Enter key pressing.
    setTimeout(() => {
      // Abort opening lookup dialog if there're no cells in edit mode anymore.
      // This is indicating that our user has pressed Shift+Enter
      // and the grid editing mode has been canceled.
      if (this.params.api.getEditingCells().length == 0) {
        return;
      }

      // Open lookup dialog which select single resource. There are three possible result values:
      // 1. undefined
      //    Indicate user canceling the dialog.
      //    isCancelAfterEnd() will return true and the result of the edit will be ignored.
      // 2. null
      //    Indicate user clearing previous value with null.
      //    getValue() will return null, which is set to the bound field.
      //    The resource object will be set to null.
      // 3. array of single resource object
      //    Indicate user selecting a single resource.
      //    getValue() will return selected resource field value based on valueKey, which is set to the bound field.
      //    The resource object will be set to the selected resource.
      this._dialogService
        .openLookupDialog(
          params.resourceURL,
          params.getColumnDefs(),
          null,
          (selectedRows) => (this.params.validate ? this.params.validate(selectedRows[0], this.params.node) : true),
          'single',
          undefined,
          this.params.preloadedQuery
        )
        .afterClosed()
        .pipe(
          tap((result) => {
            // Get selected row.
            this._selectedRows = result;

            // Setting the resource object of this field using key derived from resourceURL
            // (e.g. /resource-name -> resource_name). This object could be referenced
            // by other adjecent cells to display this field's resource details.
            if (this._selectedRows !== undefined) {
              let resObj: any;

              // Populate resource object.
              if (this._selectedRows === null) {
                resObj = null;
              } else {
                resObj = {};
                // Filter selected resource based on specified resourceFields.
                this.params.resourceFields.forEach((field) => {
                  resObj[field] = this._selectedRows[0][field];
                });
              }

              this.params.data[this.params.resourceURL.split('/').pop().split('-').join('_')] = resObj;
            }

            // Stop editing and trigger returning of the editing result.
            this.params.stopEditing();
          })
        )
        .subscribe();
    }, 100);
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    // Return the selected object's property based on valueKey.
    return this._selectedRows === null ? null : this._selectedRows[0][this.params.valueKey];
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    return this._selectedRows === undefined;
  }
}
