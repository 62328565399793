import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Params,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { GlobalService } from '../services/global.service';
import { UserProfileService } from '../user-profile/user-profile.service';
import { JwtService } from './jwt.service';

/**
 * JWT authentication guard.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanLoad {
  /**
   * Constructor.
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _globalService: GlobalService,
    private _jwtService: JwtService,
    private _router: Router,
    private _userProfileService: UserProfileService
  ) {
    // Set initialAuthenticationPassed when both token authenticated and profile loaded observables are completed.
    forkJoin([this._jwtService.authenticated, this._userProfileService.loaded]).subscribe(
      () => (this._globalService.initialAuthenticationPassed = true)
    );
  }

  /**
   * CanActivate interface implementation.
   * @param route ActivatedRouteSnapshot.
   * @param state RouterStateSnapshot.
   * @return True if can activate, otherwise false.
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return !this._globalService.initialAuthenticationPassed
      ? this._authenticateUser(route.queryParams).pipe(switchMap(() => this._authenticateMenu(route)))
      : this._authenticateMenu(route);
  }

  /**
   * CanLoad interface implementation.
   * @param route Route.
   * @return True if can load, otherwise false.
   */
  public canLoad(route: Route): Observable<boolean> {
    return this._authenticateUser(this._activatedRoute.snapshot.queryParams);
  }

  /**
   * Authenticate user's menu access permission.
   * @param route ActivatedRouteSnapshot
   */
  private _authenticateMenu(route: ActivatedRouteSnapshot): Observable<boolean> {
    const allowed =
      route.url[0].path === 'home'
        ? true
        : this._userProfileService.allowAccessMenu(
            route.pathFromRoot.map((path) => (path.routeConfig ? path.routeConfig.path : 'menu')).join('.')
          );

    if (!allowed) {
      this._router.navigate(['/home']);
    }

    return of(allowed);
  }

  /**
   * Authenticate user's JWT then load his profile.
   * @param params URL's query params.
   * @return True if autheticated and profile loaded, otherwise false.
   */
  private _authenticateUser(params: Params): Observable<boolean> {
    // Only authenticate once if the user hasn't been authenticated. We don't have to
    // authenticate every time a JWT-protected route is accessed, the token will be
    // expired by itself if it isn't refreshed due to idling for a long time.
    if (!this._globalService.initialAuthenticationPassed) {
      return this._jwtService.authenticate(params).pipe(
        switchMap((authenticated) => {
          return authenticated ? this._userProfileService.getUserProfile() : of(false);
        })
      );
    } else {
      return of(this._globalService.initialAuthenticationPassed);
    }
  }
}
