import { Overlay } from '@angular/cdk/overlay';
import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { bindCallback, concat, from, fromEvent, Observable, of, Subject, throwError } from 'rxjs';
import { bufferCount, catchError, delay, filter, finalize, first, map, mergeMap, tap } from 'rxjs/operators';

import { DialogService } from '../dialog/dialog.service';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { HelperService } from '../services/helper.service';
import { HttpRestService } from '../services/http-rest.service';

@Component({
  selector: 'app-card-stamping',
  templateUrl: './card-stamping.component.html',
  styleUrls: ['./card-stamping.component.scss'],
  exportAs: 'cardStamping',
})
export class CardStampingComponent implements OnInit, OnDestroy {
  /**
   * Custom template for card header.
   */
  @ContentChild('header', { read: TemplateRef })
  public header: TemplateRef<any>;

  /**
   * Action buttons container.
   */
  @ViewChild('actionsContainer', { read: ElementRef })
  public actionsContainer: ElementRef;

  /**
   * Fixed target width in pixel.
   */
  @Input()
  public width: number;

  /**
   * Fixed target height in pixel.
   */
  @Input()
  public height: number;

  /**
   * Fixed stamp panel width in pixel.
   */
  @Input()
  public stampPanelWidth: number;

  /**
   * Stamps array.
   */
  @Input()
  public stamps: string[];

  /**
   * Title.
   */
  @Input()
  public title: string;

  /**
   * Filename without extension.
   */
  @Input()
  public filename: string;

  /**
   * GET URL.
   */
  @Input()
  public getURL: string;

  /**
   * PUT URL.
   */
  @Input()
  public putURL: string;

  /**
   * PUT form key.
   */
  @Input()
  public putKey: string;

  /**
   * Disabled flag.
   */
  @Input()
  public disabled: boolean;

  /**
   * Modification disabled flag.
   */
  @Input()
  public modificationDisabled: boolean;

  /**
   * Canvas id.
   */
  public canvasId: string;

  /**
   * Fabric.Canvas.
   */
  public fabricCanvas: fabric.Canvas;

  /**
   * Loading state of the canvas.
   */
  public canvasState: {
    loading: boolean;
    percentage: number;
    stampPercentages?: { [stampName: string]: number };
    changed: boolean;
  };

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  // public get ratio() {
  //   return (fabric as any).devicePixelRatio;
  // }

  // public get fabricWidth() {
  //   return this.fabricCanvas?.getWidth();
  // }

  // public get fabricHeight() {
  //   return this.fabricCanvas?.getHeight();
  // }

  // public get canvasWidth() {
  //   return this.fabricCanvas?.getElement().getAttribute('width');
  // }

  // public get canvasHeight() {
  //   return this.fabricCanvas?.getElement().getAttribute('height');
  // }

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService,
    private _ngZone: NgZone,
    private _overlay: Overlay
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this.canvasId = `canvas_${Date.now() + Math.floor(Math.random() * 1000)}`;
    this.canvasState = { loading: false, percentage: 0, stampPercentages: null, changed: false };
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy() {
    this.dispose().subscribe();
    this._destroySignal.next();
  }

  /**
   * Check if add action is disabled.
   */
  public isAddDisabled(): boolean {
    return this.disabled || this.modificationDisabled || !this.fabricCanvas || this.canvasState.loading;
  }

  /**
   * Check if crop action is disabled.
   */
  public isCropDisabled(): boolean {
    return (
      this.disabled ||
      this.modificationDisabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if view action is disabled.
   */
  public isViewDisabled(): boolean {
    return (
      this.disabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if download action is disabled.
   */
  public isDownloadDisabled(): boolean {
    return (
      this.disabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if clear action is disabled.
   */
  public isClearDisabled(): boolean {
    return (
      this.disabled ||
      this.modificationDisabled ||
      !this.fabricCanvas ||
      this.canvasState.loading ||
      this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined
    );
  }

  /**
   * Check if card is empty.
   */
  public isEmpty(): boolean {
    return this.fabricCanvas.getObjects().find((object) => object.isType('image')) == undefined;
  }

  /**
   * Add new image into canvas.
   */
  public add(): Observable<fabric.Image> {
    if (this.isAddDisabled()) {
      return of(null);
    }

    return this._helperService.promptFileInput('image/jpeg,image/png').pipe(
      mergeMap((files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        return fromEvent(reader, 'load').pipe(
          first(),
          map(() => reader.result as string)
        );
      }),
      tap((imageData) => {
        if (!/^data:image\/(jpeg|png)/.test(imageData)) {
          this._helperService.toast('Support only JPEG/PNG image format.');
          throw 'Support only JPEG/PNG image format.';
        }
      }),
      mergeMap((imageData) => this.crop(imageData))
    );
  }

  /**
   * Crop image on the image editor.
   */
  public crop(imageData?: string): Observable<fabric.Image> {
    if (this.isCropDisabled() && this.isAddDisabled()) {
      return of(null);
    }

    return this._dialogService
      .openImmediateDialog(ImageEditorComponent, null, null, {
        data: {
          imageData: imageData ?? this.fabricCanvas.getObjects()[0].toDataURL({ format: 'JPEG', quality: 0.92 }),
          alt: 'image',
          cropperConfig: {
            aspectRatio: this.width / this.height,
            autoCropArea: 1,
            viewMode: 2,
          },
        },
        scrollStrategy: this._overlay.scrollStrategies.block(),
      })
      .afterClosed()
      .pipe(
        mergeMap((imageData) => this._loadIntoCanvas(imageData)),
        tap(() => (this.canvasState.changed = true))
      );
  }

  /**
   * View image on the image viewer.
   */
  public view(): Observable<any> {
    if (this.isViewDisabled()) {
      return of(null);
    }

    return this._dialogService
      .openImmediateDialog(ImageViewerComponent, null, null, {
        data: {
          imageData: this.fabricCanvas.toDataURL({ format: 'JPEG', quality: 0.92, width: this.width }),
          alt: 'image',
        },
        scrollStrategy: this._overlay.scrollStrategies.block(),
      })
      .afterClosed();
  }

  /**
   * Save image to local disk.
   */
  public download(): Observable<Blob> {
    if (this.isDownloadDisabled()) {
      return of(null);
    }

    return from(
      fetch(this.fabricCanvas.toDataURL({ format: 'JPEG', quality: 0.92, width: this.width })).then((res) => res.blob())
    ).pipe(tap((blob) => saveAs(blob, `${this.filename}.jpg`)));
  }

  /**
   * Clear the image from canvas.
   */
  public clear(): Observable<fabric.Canvas> {
    if (this.isClearDisabled()) {
      return of(null);
    }

    this._clear();
    this.canvasState.changed = true;

    return of(this.fabricCanvas);
  }

  /**
   * Clear canvas only its image object.
   */
  private _clear() {
    const firstObject = this.fabricCanvas.getObjects()[0];
    if (firstObject && firstObject.type === 'image') {
      this.fabricCanvas.remove(firstObject);
    }
  }

  /**
   * GET the image then load into canvas.
   * @return Observable of response body.
   */
  public get(): Observable<any> {
    return of({}).pipe(
      tap(() => this._clear()),
      mergeMap(() => {
        return this._httpRestService
          .get(
            this.getURL,
            {
              responseType: 'blob',
              observe: 'events',
              reportProgress: true,
            },
            false,
            false,
            (event) => {
              if (event.type == HttpEventType.Sent) {
                this.canvasState.loading = true;
                this.canvasState.percentage = 0;
                this.canvasState.stampPercentages = null;
              } else if (event.type == HttpEventType.DownloadProgress) {
                if (event.total) {
                  this.canvasState.percentage = Math.round((100 * event.loaded) / event.total);
                }
              }
            }
          )
          .pipe(
            finalize(() => {
              this.canvasState.loading = false;
              this.canvasState.percentage = 0;
              this.canvasState.stampPercentages = null;
            })
          );
      }),
      mergeMap((res) => this._loadIntoCanvas(res.body).pipe(map(() => res.body))),
      tap(() => (this.canvasState.changed = false))
    );
  }

  /**
   * PUT the image.
   * @return Observable of response body.
   */
  public put(): Observable<any> {
    return of({}).pipe(
      filter(() => this.canvasState.changed),
      map(() =>
        this.isEmpty() ? null : this.fabricCanvas.toDataURL({ format: 'JPEG', quality: 0.92, width: this.width })
      ),
      mergeMap((imageData) => {
        return imageData
          ? from(
              fetch(imageData)
                .then((res) => res.blob())
                .catch((err) => null)
            )
          : of(null);
      }),
      mergeMap((blob) => {
        const formData = new FormData();
        formData.append('_method', 'put');
        if (blob) {
          formData.append(this.putKey, blob, `${this.filename}.jpg`);
        }

        return this._httpRestService
          .post(
            this.putURL,
            formData,
            {
              responseType: 'blob',
              observe: 'events',
              reportProgress: true,
            },
            true,
            false,
            (event) => {
              if (event.type == HttpEventType.Sent) {
                this.canvasState.loading = true;
                this.canvasState.percentage = 0;
              } else if (event.type == HttpEventType.UploadProgress) {
                if (event.total) {
                  this.canvasState.percentage = Math.round((100 * event.loaded) / event.total);
                }
              }
            }
          )
          .pipe(
            map((res) => res.body),
            finalize(() => {
              this.canvasState.loading = false;
              this.canvasState.percentage = 0;
            })
          );
      })
    );
  }

  /**
   * Initialize fabric canvas and stamps.
   * @return Observable of fabric.Canvas.
   */
  public initialize(): Observable<fabric.Canvas> {
    return this._ngZone.runOutsideAngular(() => {
      return concat(
        Observable.create((observer) => {
          if (this.fabricCanvas && this.fabricCanvas.getElement() === document.getElementById(this.canvasId)) {
            // If fabricCanvas was initialized and canvas element it currently handles is the canvas
            // element currently exists in the DOM, we will only clear the fabricCanvas instead of
            // reinitialized fabric.Canvas leading to duplicated selection (upper) canvas.

            this.fabricCanvas.clear();
          } else {
            this.fabricCanvas = new fabric.Canvas(this.canvasId, { enableRetinaScaling: false });

            this.fabricCanvas.setDimensions({
              width: this.width + this.stampPanelWidth,
              height: this.height,
            });

            fabric.Object.prototype.borderColor = 'red';
            fabric.Object.prototype.borderScaleFactor = 4;
            fabric.Object.prototype.cornerColor = '#39FF14';
            fabric.Object.prototype.cornerSize = 30;
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.rotatingPointOffset = 100;
            fabric.Object.prototype.padding = 15;
            fabric.Object.prototype.lockUniScaling = true;

            this.fabricCanvas.on('mouse:over', (event) => {
              if (event.target && event.target.type == 'image') {
                this.actionsContainer.nativeElement.classList.add('mouse-over');
              }
            });

            this.fabricCanvas.on('mouse:out', (event) => {
              if (event.target && event.target.type == 'image') {
                const mousePoint = this.fabricCanvas.getPointer(event.e);
                const letterTopLeftPoint = event.target.getCoords()[0];
                const letterBottomRightPoint = event.target.getCoords()[2];

                if (
                  mousePoint.x < letterTopLeftPoint.x ||
                  mousePoint.y < letterTopLeftPoint.y ||
                  mousePoint.x > letterBottomRightPoint.x ||
                  mousePoint.y > letterBottomRightPoint.y
                ) {
                  this.actionsContainer.nativeElement.classList.remove('mouse-over');
                }
              }
            });

            this.fabricCanvas.on('mouse:down', (event) => {
              if (event.target && event.target.type == 'image') {
                if (this.actionsContainer.nativeElement.classList.contains('mouse-over')) {
                  this.actionsContainer.nativeElement.classList.remove('mouse-over');
                } else {
                  this.actionsContainer.nativeElement.classList.add('mouse-over');
                }
              }
            });
          }

          observer.next(this.fabricCanvas);
          observer.complete();
        }),
        this._getStamps()
      ).pipe(map(() => this.fabricCanvas));
    });
  }

  /**
   * Dispose fabric canvas.
   */
  public dispose(): Observable<fabric.Canvas> {
    if (this.fabricCanvas) {
      this.fabricCanvas.dispose();
    }
    return of(this.fabricCanvas);
  }

  /**
   * Get and load stamps into canvas.
   * @return Observable of stamps as fabric.Object.
   */
  private _getStamps(): Observable<{ objects: fabric.Object[]; options: any; order: number }[]> {
    return of(...this.stamps).pipe(
      mergeMap((stampName, index) => {
        return this._httpRestService
          .get(
            `/stamp/${stampName}`,
            {
              responseType: 'blob',
              observe: 'events',
              reportProgress: true,
            },
            true,
            false,
            (event) => {
              if (event.type == HttpEventType.Sent) {
                if (this.canvasState.stampPercentages == null) {
                  this.canvasState.stampPercentages = {};
                }
                this.canvasState.loading = true;
                this.canvasState.stampPercentages[stampName] = 0;
                this.canvasState.percentage =
                  Object.values(this.canvasState.stampPercentages).reduce((cumm, curr) => cumm + curr) /
                  Object.keys(this.canvasState.stampPercentages).length;
              } else if (event.type == HttpEventType.DownloadProgress) {
                if (event.total) {
                  this.canvasState.stampPercentages[stampName] = Math.round((100 * event.loaded) / event.total);
                  this.canvasState.percentage =
                    Object.values(this.canvasState.stampPercentages).reduce((cumm, curr) => cumm + curr) /
                    Object.keys(this.canvasState.stampPercentages).length;
                }
              }
            }
          )
          .pipe(
            map((response) => ({ response, order: index })),
            catchError((error) => {
              this.canvasState.loading = false;
              this.canvasState.percentage = 0;
              this.canvasState.stampPercentages = null;
              return throwError(error);
            }),
            finalize(() => {
              if (this.canvasState.percentage === 100) {
                this.canvasState.loading = false;
                this.canvasState.percentage = 0;
                this.canvasState.stampPercentages = null;
              }
            })
          );
      }),
      mergeMap((stamp) => {
        return bindCallback(fabric.loadSVGFromURL)(URL.createObjectURL(stamp.response.body)).pipe(
          map(([objects, options]) => ({ objects, options, order: stamp.order }))
        );
      }),
      bufferCount(this.stamps.length),
      tap((buffered) => {
        buffered.sort((a, b) => a.order - b.order);
        buffered.forEach(({ objects, options, order }) => {
          const margin = 50;
          const ratioX = 0.3;

          const stamp = fabric.util.groupSVGElements(objects, options);
          const scaleX = Math.round(((this.width * ratioX) / stamp.width) * 100 + Number.EPSILON) / 100;
          const scaleY =
            Math.round(((stamp.width * scaleX * stamp.height) / stamp.width / stamp.height) * 100 + Number.EPSILON) /
            100;

          stamp.setOptions({
            scaleX: scaleX,
            scaleY: scaleY,
            top:
              order > 0
                ? margin +
                  this.fabricCanvas
                    .getObjects()
                    .filter((object) => !object.isType('image'))
                    .map((object) => object.getScaledHeight() + margin)
                    .reduce((cumm, curr) => cumm + curr)
                : margin,
            left: this.width + (this.stampPanelWidth - this.width * ratioX) / 2,
          });
          stamp.setCoords();

          this.fabricCanvas.add(stamp);
        });
      }),
      // Add delay to wait for the progress bar to go back to zero.
      delay(0)
    );
  }

  /**
   * Load image data into canvas.
   * @param imageData Image data as Base64 or Blob.
   * @return Observable of image as fabric.Image.
   */
  private _loadIntoCanvas(imageData: string | Blob): Observable<fabric.Image> {
    return of(imageData).pipe(
      tap((imageData) => {
        if (!imageData) {
          throw 'No source image.';
        }
      }),
      mergeMap((imageData) =>
        typeof imageData === 'string' ? fetch(imageData).then((res) => res.blob()) : of(imageData)
      ),
      tap(() => this._clear()),
      mergeMap((blob) => bindCallback(fabric.Image.fromURL)(URL.createObjectURL(blob))),
      map((fabricImage) => {
        fabricImage.setOptions({
          scaleX: this.width / fabricImage.getOriginalSize().width,
          scaleY: this.height / fabricImage.getOriginalSize().height,
          top: 0,
          left: 0,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          selectable: false,
        });
        this.fabricCanvas.add(fabricImage);
        this.fabricCanvas.sendToBack(fabricImage);
        return fabricImage;
      })
    );
  }
}
