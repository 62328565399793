import { EventEmitter, Injectable } from '@angular/core';
import { find, forEach } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { DialogService } from '../../shared/dialog/dialog.service';
import { HelperService } from '../../shared/services/helper.service';
import { HttpRestService } from '../../shared/services/http-rest.service';
import { GlobalService } from '../services/global.service';

/**
 * User profile service.
 */
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /**
   * Get Profile API URI.
   */
  public readonly GET_PROFILE_API_URI: string = '/get-profile';

  /**
   * Loaded event emitter.
   */
  public loaded: EventEmitter<boolean> = new EventEmitter();

  /**
   * Flag indicating user is administrator.
   */
  public get administrator(): boolean {
    if (this._globalService.userProfile !== undefined) {
      return find(this._globalService.userProfile.roles, { administrator: true }) === undefined ? false : true;
    } else {
      return false;
    }
  }

  /**
   * Flag indicating user is guest.
   */
  public get guest(): boolean {
    if (this._globalService.userProfile !== undefined) {
      return find(this._globalService.userProfile.roles, { guest: true }) === undefined ? false : true;
    } else {
      return false;
    }
  }

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService
  ) {
    this._httpRestService.profilePreferenceReinitRequired.subscribe(() => {
      this._clearProfilePreference();
      this._initializeProfilePreference();
    });
  }

  /**
   * Get user profile.
   */
  public getUserProfile(): Observable<boolean> {
    const loading = this._dialogService.openProcessingDialog('Getting user profile');

    return this._httpRestService.get(this.GET_PROFILE_API_URI, {}, true, true).pipe(
      tap((payload) => {
        this._globalService.userProfile = payload[GlobalService.USER_KEY];
        this._globalService.apiProfiles = payload['APIPartners'];
        this._initializeProfilePreference();
        this._emitLoaded();
      }),
      map(() => true),
      catchError((error) => {
        this._helperService.toast('Failed to get user profile.');
        return of(false);
      }),
      finalize(() => this._dialogService.closeProcessingDialog(loading))
    );
  }

  /**
   * Check whether a user is allowed to access a menu (Menu | SubMenu | MenuItem).
   * @param menuPermission Being checked menu's permission.
   * @returns Boolean whether allowed or not.
   */
  public allowAccessMenu(menuPermission: string): boolean {
    let permission;

    if (!this._globalService.userProfile) {
      return false;
    }

    forEach(this._globalService.userProfile.roles, (role) => {
      permission = role.permissions.find((permission) => permission.name == menuPermission);
      if (permission) {
        return false; // Exit forEach on first match.
      }
    });

    return permission ? true : false;
  }

  /**
   * Initialize profile preference.
   */
  private _initializeProfilePreference() {
    this._initializeActiveWorkgroup();
    this._initializeActiveStorage();
    this._initializeFilterWorkgroups();
  }

  /**
   * Clear profile preference.
   */
  private _clearProfilePreference() {
    this._helperService.setLocalStorageItem('activeWorkgroupIndex', null);
    this._helperService.setLocalStorageItem('activeStorageIndex', null);
    this._helperService.setLocalStorageItem('filterWorkgroupIndexes', null);
  }

  /**
   * Initialize active workgroup.
   */
  private _initializeActiveWorkgroup(): void {
    // Get stored data.
    const storedActiveWorkgroupIndex = this._helperService.getLocalStorageItem('activeWorkgroupIndex');

    // Main app.
    this._globalService.profilePreference.activeWorkgroupIndex[
      this._globalService.userProfile.applications[0].id
    ] = storedActiveWorkgroupIndex ? storedActiveWorkgroupIndex[this._globalService.userProfile.applications[0].id] : 0;
    // Partners apps.
    Object.keys(this._globalService.apiProfiles).forEach((appCode) => {
      this._globalService.profilePreference.activeWorkgroupIndex[
        this._globalService.apiProfiles[appCode].applications[0].id
      ] = storedActiveWorkgroupIndex
        ? storedActiveWorkgroupIndex[this._globalService.apiProfiles[appCode].applications[0].id]
        : 0;
    });

    // Save data to storage.
    this._helperService.setLocalStorageItem(
      'activeWorkgroupIndex',
      this._globalService.profilePreference['activeWorkgroupIndex']
    );
  }

  /**
   * Initialize active storage.
   */
  private _initializeActiveStorage(): void {
    // Get stored data.
    const storedActiveStorageIndex = this._helperService.getLocalStorageItem('activeStorageIndex');

    // Main app.
    this._globalService.profilePreference.activeStorageIndex[
      this._globalService.userProfile.applications[0].id
    ] = storedActiveStorageIndex ? storedActiveStorageIndex[this._globalService.userProfile.applications[0].id] : 0;
    // Partners apps.
    Object.keys(this._globalService.apiProfiles).forEach((appCode) => {
      this._globalService.profilePreference.activeStorageIndex[
        this._globalService.apiProfiles[appCode].applications[0].id
      ] = storedActiveStorageIndex
        ? storedActiveStorageIndex[this._globalService.apiProfiles[appCode].applications[0].id]
        : 0;
    });

    // Save data to storage.
    this._helperService.setLocalStorageItem(
      'activeStorageIndex',
      this._globalService.profilePreference['activeStorageIndex']
    );
  }

  /**
   * Initialize filter workgroups.
   */
  private _initializeFilterWorkgroups(): void {
    // Get stored data.
    const storedFilterWorkgroupIndexes = this._helperService.getLocalStorageItem('filterWorkgroupIndexes');

    // Main app.
    this._globalService.profilePreference.filterWorkgroupIndexes = storedFilterWorkgroupIndexes
      ? storedFilterWorkgroupIndexes
      : [0];

    // Save data to storage.
    this._helperService.setLocalStorageItem(
      'filterWorkgroupIndexes',
      this._globalService.profilePreference['filterWorkgroupIndexes']
    );
  }

  /**
   * Emit user profile loaded.
   */
  private _emitLoaded(): void {
    this.loaded.emit(true);
    // Immediately complete this event to avoid leak, because it only emits once on loaded.
    this.loaded.complete();
  }
}
