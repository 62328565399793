import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';

/**
 * Root module.
 */
@NgModule({
  imports: [
    /**
     * Angular modules.
     */
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwaEnabled }),
    /**
     * Shared module
     */
    SharedModule.forRoot(),
    /**
     * App modules.
     */
    CoreModule,
    /**
     * App routing module.
     */
    AppRoutingModule,
  ],
  declarations: [
    /**
     * Components.
     */
    AppComponent,
    HomeComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
