import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { zipWith } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { BaseDialogComponent } from '../../dialog/base-dialog/base-dialog.component';
import { DialogService } from '../../dialog/dialog.service';
import { Option } from '../../interfaces/option.interface';
import { HelperService } from '../../services/helper.service';
import { HttpRestService } from '../../services/http-rest.service';
import { DateRangeComponent } from '../date-range/date-range.component';
import { Field } from '../field.interface';
import { WhereFieldNode } from '../where-field-node.interface';

/**
 * Dialog component for setting the node.
 */
@Component({
  selector: 'app-node-setting',
  templateUrl: './node-setting.component.html',
  styleUrls: ['./node-setting.component.scss'],
})
export class NodeSettingComponent implements OnInit, AfterViewInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Field's MatSelect.
   */
  @ViewChild('fieldSelect', { read: MatSelect })
  private _fieldSelect: MatSelect;

  /**
   * Value's ElementRef.
   */
  @ViewChild('valueInput')
  private _valueInputRef: ElementRef;

  /**
   * Being set node, either where or order node.
   */
  public node: any;

  /**
   * Resource URL.
   */
  public resourceURL: string;

  /**
   * Relation name.
   */
  public relationName: string;

  /**
   * Fields.
   */
  public fields: Field[];

  /**
   * Sugestions used to give value suggestion to user so the user doesn't have to manually type.
   */
  public suggestions: { fieldName: string; options: Option[]; guidedValue: boolean }[] = [];

  /**
   * Value's autocomplete items.
   */
  public valueItems$: Observable<any[]>;

  /**
   * Has relation's occurrence settings.
   */
  public hasOccurences: any[];

  /**
   * Operators.
   */
  public operators: Option[] = [
    { display: '=', value: '=' },
    { display: '!=', value: '!=' },
    { display: '<', value: '<' },
    { display: '<=', value: '<=' },
    { display: '>', value: '>' },
    { display: '>=', value: '>=' },
    { display: 'CONTAINS', value: 'contains' },
    { display: 'NOT CONTAINS', value: 'not contains' },
    { display: 'LIKE', value: 'like' },
    { display: 'NOT LIKE', value: 'not like' },
    { display: 'IN', value: 'in' },
    { display: 'NOT IN', value: 'not in' },
    { display: 'BETWEEN', value: 'between' },
    { display: 'NOT BETWEEN', value: 'not between' },
    { display: 'IS NULL', value: 'is null' },
    { display: 'IS NOT NULL', value: 'is not null' },
  ];

  /**
   * Has operators.
   */
  public hasOperators: Option[] = [
    { display: '=', value: '=' },
    { display: '<', value: '<' },
    { display: '<=', value: '<=' },
    { display: '>', value: '>' },
    { display: '>=', value: '>=' },
  ];

  /**
   * Keep track the previous value which is then used to construct the
   * piped values during guided value option selected. We're doing like
   * this because ngModelChange will be called before optionSelected.
   */
  private _previousValue: string;

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<NodeSettingComponent>,
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService
  ) {
    this.node = data.node;
    this.resourceURL = data.resourceURL;
    this.relationName = data.relationName;
    this.fields = data.fields ? data.fields : [];
    this.hasOccurences = this.node.r
      ? zipWith(this._helperService.renderRelationName(this.node.r).split(' > '), this.node.c, (value1, value2) => ({
          r: value1,
          c: value2,
        }))
      : [];
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    // Set fields' suggestions by mapping from resource fields. We use field's display
    // as our suggestion which will be mapped back to its value by backend API
    // for querying. This makes the query more readable to user.
    this.suggestions = this.fields.map((field) => {
      return {
        fieldName: field.name,
        options: field.options.map((option) => {
          return { display: option.display, value: option.display };
        }),
        guidedValue: field.guidedValue ? field.guidedValue : false,
      };
    });
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit(): void {
    // Trigger field selection change to subscribe to the autocomplete list without having to change field selection.
    this.onFieldChange();
  }

  /**
   * Available suggestion options based on current node's selected field.
   * @return An array of suggestion options.
   */
  public get suggestionOptions(): Option[] {
    const suggestions = this.suggestions.find((item) => item.fieldName === (<WhereFieldNode>this.node).f);
    return suggestions ? suggestions.options : [];
  }

  /**
   * Flag indicating whether current node's selected field has available suggestion options.
   * @return True if suggestion options are available.
   */
  public get suggestionOptionsAvailable(): boolean {
    return this.suggestionOptions.length > 0;
  }

  /**
   * Flag indicating whether current node's selected field uses guided value.
   * @return True if this field uses guided value.
   */
  public get useGuidedValue(): boolean {
    return this.suggestions.find((item) => item.fieldName === (<WhereFieldNode>this.node).f).guidedValue;
  }

  /**
   * Flag indicating whether current node's selected field is datetime field.
   * @return True if field is datetime.
   */
  public get isDateTimeField() {
    return this.fields.find((field) => field.name === (<WhereFieldNode>this.node).f).isDateTime;
  }

  /**
   * Flag indicating NULL operator is selected.
   */
  public get isNULLOperator(): boolean {
    return (<WhereFieldNode>this.node).o.includes('null');
  }

  /**
   * Flag indicating whether current node's operator is IN.
   */
  public get isINOperator() {
    return (<WhereFieldNode>this.node).o.includes('in');
  }

  /**
   * Flag indicating if this node is where type node for @ syntax query.
   */
  public get isAtWhereNode(): boolean {
    return this.node.type === '@where';
  }

  /**
   * Get the rendered relation name.
   */
  public getRelationName() {
    if (this.relationName) {
      // Return the provided relation name string.
      return this.relationName;
    } else {
      // Get parent node.
      let parentNode = this.node.comp.parentNode;

      // Traverse up to root parent node.
      while (!parentNode.root) {
        parentNode = parentNode.comp.parentNode;
      }

      // Render relation name string.
      return this._helperService.renderRelationName(parentNode.r || (parentNode.root && !parentNode.r ? 'this' : ''));
    }
  }

  /**
   * Get the model object of the "IN select" field.
   */
  public getINSelectValue() {
    if ((<WhereFieldNode>this.node).o.includes('in') && !this.node.temp) {
      this.node.temp = (<WhereFieldNode>this.node).v ? (<WhereFieldNode>this.node).v.split(' | ') : [];
    }
    return this.node.temp;
  }

  /**
   * The "IN select" field's ngModelChange handler.
   * @param event The new array of selected values.
   */
  public onINSelectModelChange(event: any) {
    this.node.temp = event;
    (<WhereFieldNode>this.node).v = event.join(' | ');
  }

  /**
   * Show date time prompt dialog.
   */
  public showDateTimePrompt() {
    this._dialogService.openImmediateDialog(
      DateRangeComponent,
      null,
      (result) => {
        // Skip if unchanged.
        if (!result) {
          return;
        }
        // Set operator based on user selected datetime.
        if (result.includes('|')) {
          (<WhereFieldNode>this.node).o = 'between';
        } else {
          if ((<WhereFieldNode>this.node).o === 'between') {
            (<WhereFieldNode>this.node).o = '=';
          }
        }
        // Set node value.
        (<WhereFieldNode>this.node).v = result;
      },
      { data: { current: (<WhereFieldNode>this.node).v } }
    );
  }

  /**
   * Close the dialog.
   */
  public close(): void {
    this._dialogRef.close(this.node);
  }

  /**
   * On selected field change handler.
   */
  public onFieldChange(): void {
    // Subscribe to autocomplete list observable.
    // Use settimeout to wait for the ViewChild.
    setTimeout(() => {
      if (!this.useGuidedValue) {
        this.valueItems$ = null;
      } else {
        this.valueItems$ = this._helperService
          .getAutocompleteItems(this._valueInputRef.nativeElement, (keyword) => this._getValueItems(keyword), 1)
          .pipe(takeUntil(this._fieldSelect.selectionChange.asObservable()));
      }
    });
  }

  /**
   * On selected operator change handler.
   */
  public onOperatorChange(): void {
    if (this.isNULLOperator) {
      (<WhereFieldNode>this.node).v = '';
    }
  }

  /**
   * On Grab any checkbox change handler.
   * @param event MatCheckboxChange
   */
  public onGrabAnyChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.node.g = [];
    }
  }

  /**
   * Handle ngModelChange event for input with guided options.
   * @param value New ngModel value.
   * @param node The field node object.
   */
  public onGuidedValueModelChange(value: string, node: any) {
    this._previousValue = node.v;
    node.v = value;
  }

  /**
   * Handle if user select an option from the guided values list.
   * We will append the selected value from the dropdown list
   * to the end of the string using "|" as the separator.
   * @param event MatAutocompleteSelectedEvent.
   * @param node The field node object.
   * @param input Input element.
   */
  public onGuidedValueOptionSelected(event: MatAutocompleteSelectedEvent, node: any, input: HTMLInputElement) {
    const split = this._previousValue.split('|').map((item) => item.trim());
    split.pop();
    split.push(event.option.value);
    node.v = split.join(' | ');
    // Here we manualy assign the input element value to overcome "caching"
    // effect due to selecting the same option from the dropdown list.
    input.value = split.join(' | ');
  }

  /**
   * Render field name.
   * @param fieldName The field name to be beautified.
   * @return Beautified field name.
   */
  public renderFieldName(FieldName: string): string {
    return this._helperService.renderFieldName(FieldName);
  }

  /**
   * Value's autocomplete display renderer.
   * @param item Field to be rendered.
   * @return Rendered field.
   */
  public valueDisplayWith(item: any): string {
    return item ? item : '';
  }

  /**
   * Get value's autocomplete items.
   * @return Observable of field items.
   */
  private _getValueItems(keyword: string): Observable<any[]> {
    const value = '%' + keyword.split('|').pop().trim() + '%';
    const field = (<WhereFieldNode>this.node).f;
    const qAdv = {
      s: [{ f: field }],
      w: [{ b: 'and', f: field, o: 'like', v: value }],
      o: [{ f: field, o: 'asc' }],
    };

    return this._httpRestService
      .get(this.resourceURL || this.node.comp.resourceURL, {
        params: { q: JSON.stringify({ quick: null, adv: qAdv }), i: '1', skip: '0', take: '50' },
      })
      .pipe(
        map((result) => <any>{ data: result.data.map((item) => item[field]) }),
        catchError((error) => of([]))
      );
  }
}
