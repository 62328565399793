<app-base-dialog #dialog dialogTitle="About">
  <div top-toolbar></div>

  <div content>
    <mat-card>
      <span class="mat-display-1">Container Management System</span>
      <span class="mat-headline">Version {{ version }}</span> <br />
      <span class="mat-body">Copyright © 2023 PT Jangkar Pacific</span>
      <span class="mat-body">All rights reserved</span>
    </mat-card>

    <mat-card *ngIf="!guest">
      <h1 class="mat-headline">Changelog</h1>
      <markdown [src]="'/app/changelog.md'" class="mat-typography"></markdown>
    </mat-card>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
