import { Directive, ElementRef, Inject, Input, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import * as moment from 'moment-mini';
import { fromEvent, merge } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { createTextMaskInputElement } from 'text-mask-core';

@Directive({
  selector: `app-txt-mask, [app-txt-mask], [appTxtMask]`,
  exportAs: 'appTxtMask',
})
export class TxtMaskDirective implements OnInit {
  /**
   * Text mask config.
   */
  @Input('appTxtMask') txtMaskConfig = {};

  /**
   * The mask ref.
   */
  public maskRef;

  /**
   * Constructor.
   */
  constructor(
    public elementRef: ElementRef,
    public ngControl: NgControl,
    @Optional() @Inject(MAT_INPUT_VALUE_ACCESSOR) public matInput
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    const textMaskConfig = {
      inputElement: this.elementRef.nativeElement,
      ...this.txtMaskConfig,
    };

    // Initialize text-mask.
    this.maskRef = createTextMaskInputElement(textMaskConfig);

    // Trigger update (masking and syncing back the after-mask value into ngControl) on control value changes.
    merge(
      this.ngControl.valueChanges,
      // Somehow we also trigger update on input event to get the mask's guide to be shown on MatDatePicker.
      fromEvent<any>(this.elementRef.nativeElement, 'input').pipe(map((event) => event.target.value))
    )
      .pipe(
        distinctUntilChanged(),
        tap((value) => this.update(value))
      )
      .subscribe();
  }

  /**
   * Update the input with new value.
   */
  public update(newValue: any) {
    // If the new value passed is `undefined`, `null`, or empty string, we do not perform masking because calling
    // `maskRef.update()` on `undefined` and `null` will cause `maskRef.state.previousConformedValue` to be an
    // empty string which is distinct from `undefined` and `null` passing through distinctUntilChanged().
    //
    // This could potentially cause infinite change between `undefined` or `null`
    // and empty string because we emit all events during ngControl.setValue().
    if ([undefined, null, ''].includes(newValue)) {
      return;
    }

    // If the new value passed is a Moment object, it must already
    // have a correct mask (e.g. when user choose a date using
    // datepicker), so we do not perform masking anymore.
    if (this._isDatepicker() && moment.isMoment(newValue)) {
      return;
    }

    // Perform masking and immediately update the input element's value.
    this.maskRef.update(newValue);

    // From now on, the input has succesfully been masked, while the ngControl's bound data has not.
    // But if we are masking matDatepickerInput, it's unnecessary to perform "correction" below
    // because unmasked input value still could be parsed as Moment object by the datepicker.
    //
    // Furthermore, correcting it ourself here will interfere datepicker's logic, potentially
    // causing user's already typed digits to be cleared by the datepicker when Moment date
    // parsing of still incomplete user typed digits results in invalid date Moment.
    if (this._isDatepicker()) {
      return;
    }

    // We need to "correct" the ngControl's bound data with the "after mask" value using text-mask's state variable.
    // This is because the Control Value Accessor provided by Angular (e.g. DefaultValueAccessor for input element)
    // or Angular Material (e.g. MatDatepickerInput) also listen to input element's input event.
    //
    // This leads to async update of the input's value by Angular's CVA and TxtMaskDirective, resulting in condition
    // where the input will have the correctly masked one but ngControl still has outdated incorrectly masked value.
    // e.g input's value is 10,000 but ngControl's value is 1,0000 after typing the last `0`.
    //
    // So as a workaround we reset ngControl's value with the correct value
    // immediately after calling text-mask's update() method above.
    this.ngControl.control.setValue(this.maskRef.state.previousConformedValue, {
      emitEvent: true,
      emitModelToViewChange: true,
      emitViewToModelChange: true,
    });
  }

  /**
   * Check if we are masking a datepicker's input.
   * @returns boolean
   */
  private _isDatepicker(): boolean {
    return this.matInput && this.matInput instanceof MatDatepickerInput;
  }
}
