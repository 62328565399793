import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../../shared/dialog/base-dialog/base-dialog.component';
import { HelperService } from '../../shared/services/helper.service';
import { GlobalService } from '../services/global.service';
import { UserProfileService } from './user-profile.service';

/**
 * Display current logged in user profile and manage his selected workgroup and storage configuration.
 */
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * User profile data.
   */
  public name: string;
  public email: string;
  public roles: string;
  public specialRole: string;
  public workgroups: {
    appId: number;
    appCode: string;
    workgroups: any[];
  }[] = [];

  /**
   * User selected workgroup and storage preferences.
   */
  public profilePreference: {
    activeWorkgroupIndex: { [appId: string]: number };
    activeStorageIndex: { [appId: string]: number };
    filterWorkgroupIndexes: number[];
  };

  /**
   * Profile changed flag.
   */
  private _profileChanged: boolean;

  /**
   * Constructor.
   */
  constructor(
    private _dialogRef: MatDialogRef<UserProfileComponent>,
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _userProfileService: UserProfileService
  ) {
    this.profilePreference = this._globalService.profilePreference;
  }

  /**
   * Angular life cycle hook.
   */
  public ngOnInit() {
    const userProfile = this._globalService.userProfile;
    const apiProfiles = this._globalService.apiProfiles;

    /**
     * Set user and his roles.
     */
    this.name = userProfile.name;
    this.email = userProfile.email;
    this.roles = userProfile.roles.map((role) => role.name).join(', ');
    this.specialRole = this._userProfileService.administrator
      ? '(Administrator)'
      : this._userProfileService.guest
      ? '(Guest)'
      : '';

    /**
     * Set user workgroups and their storages.
     */
    // Main app workgroups.
    this.workgroups.push({
      appId: userProfile.applications[0].id,
      appCode: userProfile.applications[0].code,
      workgroups: userProfile.workgroups,
    });
    // Partner apps' workgroups.
    for (const appCode of Object.keys(apiProfiles)) {
      this.workgroups.push({
        appId: apiProfiles[appCode].applications[0].id,
        appCode: apiProfiles[appCode].applications[0].code,
        workgroups: apiProfiles[appCode].workgroups,
      });
    }
  }

  /**
   * Angular life cycle hook.
   */
  public ngOnDestroy() {
    // Emit profile changed event if active workgroup, active storage, or filter workgroup has changed.
    if (this._profileChanged) {
      this._globalService.profileChanged.emit();
    }
  }

  /**
   * On active workgroup changed event handler.
   * @param appId App id of the app we want to select its active workgroup.
   * @param value Selected active workgroup index.
   */
  public selectActiveWorkgroup(appId: number, value: number) {
    // Update bindable member.
    this.profilePreference.activeWorkgroupIndex[appId] = value;

    // Update local storage.
    this._helperService.setLocalStorageItem('activeWorkgroupIndex', this.profilePreference['activeWorkgroupIndex']);

    // Because we store the index (not value), two different storages could have the same index (because they
    // are separated by workgroup), so we need to force active storage's ngModel to respond to changes
    // by presetting with -1. By doing so, active storage is updated even the index is same.
    this.profilePreference.activeStorageIndex[appId] = -1;

    // Set active storage to the first option.
    this.selectActiveStorage(appId, 0);
  }

  /**
   * On active storage changed event handler.
   * @param appId App id of the app we want to select its active storage.
   * @param value Selected active storage index.
   */
  public selectActiveStorage(appId: number, value: number) {
    // Update bindable member.
    this.profilePreference.activeStorageIndex[appId] = value;

    // Update local storage.
    this._helperService.setLocalStorageItem('activeStorageIndex', this.profilePreference['activeStorageIndex']);

    // Preset selected filter workgroups to be the current active workgroup (main app only).
    if (appId === this.workgroups[0].appId) {
      this.profilePreference.filterWorkgroupIndexes = [this.profilePreference.activeWorkgroupIndex[appId]];
      this._helperService.setLocalStorageItem(
        'filterWorkgroupIndexes',
        this.profilePreference['filterWorkgroupIndexes']
      );
    }

    // Will trigger event emitter on dialog close.
    this._profileChanged = true;
  }

  /**
   * Toggle filter workgroup.
   * @param value Toggled filter workgroup.
   */
  public toggleFilterWorkgroup(value: number) {
    // Ignore toggle if user is guest.
    if (this._userProfileService.guest) {
      return;
    }

    // Find the selected filter workgroup if already checked.
    const index = this.profilePreference.filterWorkgroupIndexes.indexOf(value);

    if (index >= 0) {
      // Has already been checked, so we toggle it off.
      this.profilePreference.filterWorkgroupIndexes.splice(index, 1);
    } else {
      // Has not already been checked, so we toggle it on.
      this.profilePreference.filterWorkgroupIndexes.push(value);
      // Sort selected filter workgroup ids in ascending order.
      this.profilePreference.filterWorkgroupIndexes.sort();
    }

    // Update local storage.
    this._helperService.setLocalStorageItem('filterWorkgroupIndexes', this.profilePreference['filterWorkgroupIndexes']);

    // Will trigger event emitter on dialog close.
    this._profileChanged = true;
  }

  /**
   * Check if specified filter workgroup is selected.
   * @param value Index of filter workgroup to be checked .
   * @return Boolean indicating whether filter workgroup is checked.
   */
  public filterWorkgroupChecked(value: number): boolean {
    return this.profilePreference.filterWorkgroupIndexes.indexOf(value) > -1;
  }

  /**
   * Check if workgroup (for report filter) exists in active storage.
   * @param value Filter workgroup to be checked .
   * @return Boolean indicating whether filter workgroup exists in active storage.
   */
  public workgroupExistInActiveStorage(workgroup: any): boolean {
    if (workgroup.storages.length > 0) {
      const userProfile = this._globalService.userProfile;
      const mainAppId = userProfile.applications[0].id;

      // Get the active storage.
      const activeStorage =
        userProfile.workgroups[this.profilePreference.activeWorkgroupIndex[mainAppId]].storages[
          this.profilePreference.activeStorageIndex[mainAppId]
        ];

      // If there is an active storage in this
      // workgroup, then this workgroup exists
      // in active storage, so we return true.
      return (
        workgroup.storages.filter((storage) => {
          // Check if current storage is an active storage.
          return activeStorage ? storage.id === activeStorage.id : false;
        }).length > 0
      );
    } else {
      // Workgroup doesn't have storage at all, so we return false.
      return false;
    }
  }

  /**
   * Check if there's only one filter workgroup checked.
   * @param value Index of filter workgroup to be checked .
   * @return Boolean indicating whether there's only one filter workgroup checked.
   */
  public lastFilterWorkgroupChecked(value: number): boolean {
    return this.profilePreference.filterWorkgroupIndexes.length === 1 &&
      this.profilePreference.filterWorkgroupIndexes.find((item) => item === value) !== undefined
      ? true
      : false;
  }

  /**
   * Close the dialog.
   */
  public close() {
    this._dialogRef.close();
  }
}
