import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HelperService } from '../../shared/services/helper.service';

/**
 * Service for displaying app's help documentation.
 */
@Injectable({
  providedIn: 'root',
})
export class HelpService {
  /**
   * Help for each app routes.
   */
  private _helps = [
    {
      routes: [
        '/master/activity',
        '/master/carrier',
        '/master/point',
        '/master/port',
        '/master/spec',
        '/master/stakeholder',
        '/master/transport',
      ],
      helpUrl: 'https://drive.google.com/open?id=148rzG0SeInKRUBn-CGuRdQQYOQC1YTdiy0imfx71YZo',
    },
    {
      routes: ['/master/authority'],
      helpUrl: 'https://docs.google.com/document/d/1oJViYFUXYNqCjvDz9ZflkGRwxJDxx6unbOoZwwF1wHo',
    },
    {
      routes: [
        '/master/contractor',
        '/master/surveyor',
        '/master/component-code',
        '/master/damage-location-code',
        '/master/damage-code',
        '/master/repair-code',
        '/master/damage-category-code',
        '/master/repair-tariff',
      ],
      helpUrl: 'https://docs.google.com/document/d/1xYJzMcTGgdGPZfTdv4zQw0HH1CJboGj4wq-5WEWau6o',
    },
    {
      routes: ['/agency/agency-dashboard'],
      helpUrl: 'https://docs.google.com/document/d/1lZnj20Dcl07U5hj6EHME8_iYKFRPAXBSvexEEYE3YMg',
    },
    {
      routes: ['/agency/delivery-order'],
      helpUrl: 'https://docs.google.com/document/d/1IrxVelc0m-IB4-iVJPxFjh3AmK1sSSR0pnvJhFkYjmA',
    },
    {
      routes: ['/depot/depot-dashboard'],
      helpUrl: 'https://docs.google.com/document/d/1g-atrwx-iIJ_PogQfJEgY1JLwlycGnLfRB8ur-gL-vs',
    },
    {
      routes: ['/depot/booking-in'],
      helpUrl: 'https://docs.google.com/document/d/1pQryra94-LHxZ5s4lNzO2rBSlbvS-e-7URHY_Ec5nA0',
    },
    {
      routes: ['/depot/booking-out'],
      helpUrl: 'https://docs.google.com/document/d/1Xfhr5oJxIze_ziKdAebYDdtxtF2yd8-yZEGJo4cMzAo',
    },
    {
      routes: ['/depot/depot-container'],
      helpUrl: 'https://drive.google.com/open?id=1DgIjvNc-YiQyZOGk8eYavVWZjK4yxAsj6pCAcDca3J4',
    },
    {
      routes: ['/depot/edifact-job'],
      helpUrl: 'https://docs.google.com/document/d/1uwBiKlvg6zQ5P8ZytLwec--xQIiCSQth_o2toxNU9ns',
    },
    {
      routes: ['/depot/edifact'],
      helpUrl: 'https://docs.google.com/document/d/158JCTouL5q1ECNsr2OdmWKFzxw6gP6me56DHUO7bZT0',
    },
    {
      routes: ['/depot/gate-in'],
      helpUrl: 'https://docs.google.com/document/d/1p_WGIrwIhibJ2P8mwf22xmSobGNd8Q_ImHrJQq8ZjjY',
    },
    {
      routes: ['/depot/gate-out'],
      helpUrl: 'https://docs.google.com/document/d/1iqaCpo7kBOlqZEOAh5Fw7UE-mgUBYnpRm7hxXrT0TQU',
    },
    {
      routes: ['/depot/warning'],
      helpUrl: 'https://docs.google.com/document/d/1-ugHyskHN5YdAx6_bBXWgn58KV9MJSLH-edxiGPpe1Y',
    },
    {
      routes: ['/depot/estimate'],
      helpUrl: 'https://docs.google.com/document/d/1chKdrI5CvROCkkqffCGQtS1cPBoFQ9Bg_uvOFg774RU',
    },
    {
      routes: ['/depot/eor'],
      helpUrl: 'https://docs.google.com/document/d/14fSSJGt2hzc_y5gz3xiU48KjrR9xR1SFdArmX6tsHiE',
    },
    {
      routes: ['/public-access/vgm-vermas'],
      helpUrl: 'https://docs.google.com/document/d/1Rf8hiWg5Ua-o2O_NiZjBW-iwNedzDgytViiJubIDGT8',
    },
  ];

  /**
   * Constructor.
   */
  constructor(private _helperService: HelperService, private _router: Router) {}

  /**
   * Open the corresponding help based on router's current url.
   */
  public open() {
    const found = this._helps.find(
      (help) => help.routes.find((route) => this._router.url.startsWith(route)) !== undefined
    );
    if (found) {
      window.open(found.helpUrl, '_blank');
    } else {
      this._helperService.toast('Help for current screen is not available', null, false);
    }
  }
}
