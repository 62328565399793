import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

import { QAdv } from '../advanced-search/q-adv.interface';
import { ColDefExt } from '../data-list/column-def/col-def-ext.interface';
import { Option } from '../interfaces/option.interface';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogJob } from './dialog-job.interface';
import { LookupDialogComponent } from './lookup-dialog/lookup-dialog.component';
import { ProcessingDialogComponent } from './processing-dialog/processing-dialog.component';
import { PromptDialogType } from './prompt-dialog/prompt-dialog-type.enum';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

/**
 * Service providing dialog display.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /**
   * Processing dialog queue.
   */
  private _processingDialogQueue: DialogJob[] = [];
  private _processingDialogQueue$: Subject<DialogJob[]> = new Subject();
  public get processingDialogQueue(): DialogJob[] {
    return this._processingDialogQueue;
  }
  public set processingDialogQueue(v: DialogJob[]) {
    this._processingDialogQueue = v;
    this._processingDialogQueue$.next(v);
  }

  /**
   * Interactive (alert, confirm, prompt, lookup and custom interactive) dialog queue.
   */
  private _interactiveDialogQueue: DialogJob[] = [];
  private _interactiveDialogQueue$: Subject<DialogJob[]> = new Subject();
  public get interactiveDialogQueue(): DialogJob[] {
    return this._interactiveDialogQueue;
  }
  public set interactiveDialogQueue(v: DialogJob[]) {
    this._interactiveDialogQueue = v;
    this._interactiveDialogQueue$.next(v);
  }

  /**
   * Constructor.
   */
  constructor(private _matDialog: MatDialog, private _mediaObserver: MediaObserver, private _overlay: Overlay) {
    this._processingDialogQueue$.subscribe((queue) => this._execProcessingDialogQueue());
    this._interactiveDialogQueue$.subscribe((queue) => this._execInteractiveDialogQueue());
  }

  /**
   * Open immediate custom dialog without using dialog queue allowing dialog to be opened on top of previously opened dialog.
   * @param component Dialog component to be opened.
   * @param beforeOpened Function gets called before the dialog is shown, passing dialog reference as parameter.
   * @param afterClosed Function gets called after the dialog is shown, passing result as parameter.
   * @param config MatDialog config.
   * @returns Reference to newly opened dialog (MatDialoRef<T>).
   */
  public openImmediateDialog<T, D = any, R = any>(
    component: ComponentType<T>,
    beforeOpened?: (dialogRef: MatDialogRef<T, R>) => void,
    afterClosed?: (result: R) => any,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    const dialogRef = this._open<T, D, R>(component, config, true) as MatDialogRef<T>;
    if (beforeOpened) {
      beforeOpened(dialogRef);
    }
    if (afterClosed) {
      dialogRef.afterClosed().subscribe((result) => afterClosed(result));
    }
    return dialogRef;
  }

  /**
   * Open custom dialog using interactive queue. Dialog will be opened after all previously queued dialog have been closed.
   * @param component Dialog component to be opened.
   * @param beforeOpened Function gets called before the dialog is shown, passing dialog reference as parameter.
   * @param afterClosed Function gets called after the dialog is shown, passing result as parameter.
   * @param config MatDialog config.
   */
  public openInteractiveDialog<T, D = any, R = any>(
    component: ComponentType<T>,
    beforeOpened?: (dialogRef: MatDialogRef<T, R>) => void,
    afterClosed?: (result: R) => any,
    config?: MatDialogConfig<D>
  ): void {
    // console.log(`Queuing custom dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () => {
        const dialogRef$ = this._open<T, D, R>(component, config) as Observable<MatDialogRef<T>>;
        dialogRef$.pipe(
          tap((dialogRef) => {
            if (beforeOpened) {
              beforeOpened(dialogRef);
            }
          })
        );
        return dialogRef$;
      },
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };
    this._interactiveDialogQueue.push(job);
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`Custom dialog job with id "${job.id}" queued.`);
  }

  /**
   * Open alert dialog using interactive queue. Default is open immediately.
   * @param title Title text.
   * @param contents Array of content paragraphs.
   * @param afterClosed Function gets called after the dialog is shown, passing true as parameter.
   * @param immediate If true, the dialog will not use queue and will return afterClosed observable. Default is true.
   * @param okLabel OK button label.
   */
  public openAlertDialog(
    title: string,
    contents: string[],
    afterClosed?: (result: boolean) => any,
    immediate: boolean = true,
    okLabel?: string
  ): MatDialogRef<AlertDialogComponent, boolean> {
    const component = AlertDialogComponent;
    const config: MatDialogConfig = {
      data: {
        title: title,
        contents: contents,
        okLabel: okLabel,
      },
      disableClose: true,
    };

    if (immediate) {
      return this.openImmediateDialog(component, null, afterClosed, config);
    }

    // console.log(`Queuing "${title}" alert dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () => this._open(component, config) as Observable<MatDialogRef<AlertDialogComponent, boolean>>,
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };
    this._interactiveDialogQueue.push(job);
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`"${title}" alert dialog job with id "${job.id}" queued.`);
  }

  /**
   * Open confirm dialog using interactive queue. Default is open immediately.
   * @param title Title text.
   * @param contents Array of content paragraphs.
   * @param afterClosed Function gets called after the dialog is shown, passing result as parameter, true if confirmed, otherwise false.
   * @param immediate If true, the dialog will not use queue and will return afterClosed observable. Default is true.
   * @param okLabel OK button label.
   * @param cancelLabel Cancel button label.
   */
  public openConfirmDialog(
    title: string,
    contents: string[],
    afterClosed?: (result: boolean) => any,
    immediate: boolean = true,
    okLabel?: string,
    cancelLabel?: string
  ): MatDialogRef<ConfirmDialogComponent, boolean> {
    const component = ConfirmDialogComponent;
    const config: MatDialogConfig = {
      data: {
        title: title,
        contents: contents,
        okLabel: okLabel,
        cancelLabel: cancelLabel,
      },
      disableClose: true,
    };

    if (immediate) {
      return this.openImmediateDialog(component, null, afterClosed, config);
    }

    // console.log(`Queuing "${title}" confirm dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () => this._open(component, config) as Observable<MatDialogRef<ConfirmDialogComponent, boolean>>,
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };
    this._interactiveDialogQueue.push(job);
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`"${title}" confirm dialog job with id "${job.id}" queued.`);
  }

  /**
   * Open prompt dialog using interactive queue. Default is open immediately.
   * @param title Title text.
   * @param contents Array of content paragraphs.
   * @param afterClosed Function gets called after the dialog is shown, passing result as parameter, true if confirmed, otherwise false.
   * @param options Prompt select's options.
   * @param inputValue Prompt input's value.
   * @param promptDialogType Prompt dialog's type.
   * @param sanitize User input sanitizer function.
   * @param validate User input validation function.
   * @param immediate If true, the dialog will not use queue and will return afterClosed observable. Default is true.
   * @param okLabel OK button label.
   * @param cancelLabel Cancel button label.
   */
  public openPromptDialog(
    title: string,
    contents: string[],
    afterClosed?: (result: any | undefined) => any,
    options?: Option[],
    inputValue?: any,
    promptDialogType?: PromptDialogType,
    sanitize?: (input: any) => any,
    validate?: (input: any) => boolean,
    immediate: boolean = true,
    okLabel?: string,
    cancelLabel?: string
  ): MatDialogRef<PromptDialogComponent, any | undefined> {
    const component = PromptDialogComponent;
    const config: MatDialogConfig = {
      data: {
        title: title,
        contents: contents,
        options: options,
        inputValue: inputValue,
        promptDialogType: promptDialogType
          ? promptDialogType
          : options
          ? PromptDialogType.Select
          : PromptDialogType.Text,
        sanitize: sanitize,
        validate: validate,
        okLabel: okLabel,
        cancelLabel: cancelLabel,
      },
      width:
        promptDialogType === PromptDialogType.Array
          ? this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm')
            ? '90vw'
            : '80vw'
          : null,
      disableClose: true,
    };

    if (immediate) {
      return this.openImmediateDialog(component, null, afterClosed, config);
    }

    // console.log(`Queuing "${title}" prompt dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () => this._open(component, config) as Observable<MatDialogRef<PromptDialogComponent, any | undefined>>,
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };
    this._interactiveDialogQueue.push(job);
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`"${title}" prompt dialog job with id "${job.id}" queued.`);
  }

  /**
   * Open lookup dialog using interactive queue. Default is open immediately.
   * @param resourceURL Resource URL to be lookup.
   * @param columnDefs Column definition of the resource to lookup.
   * @param afterClosed
   * Function gets called after the dialog is shown, passing result as parameter,
   * undefined if canceled, null if cleared, and resource object if OK button is pressed.
   * @param validate Selection validation function.
   * @param rowSelection Row selection mode, either 'single' or 'multiple'.
   * @param selectedFields Selected fields to pass as lookup result.
   * @param preloadedQuery Data list's preloaded query.
   * @param immediate If true, the dialog will not use queue and will return afterClosed observable. Default is true.
   * @param autoSelect Auto select the first or all of the search result if any.
   */
  public openLookupDialog(
    resourceURL: string,
    columnDefs: ColDefExt[],
    afterClosed?: (result: any[] | null | undefined) => any,
    validate?: (selectedRows: any[]) => boolean,
    rowSelection?: 'single' | 'multiple',
    selectedFields?: string[],
    preloadedQuery?: string | QAdv,
    immediate: boolean = true,
    autoSelect: 'first' | 'all' = null
  ): MatDialogRef<LookupDialogComponent, any[] | null | undefined> {
    const component = LookupDialogComponent;
    const config: MatDialogConfig = {
      data: {
        resourceURL: resourceURL,
        columnDefs: columnDefs,
        validate: validate,
        rowSelection: rowSelection,
        selectedFields: selectedFields,
        preloadedQuery: preloadedQuery,
        autoSelect: autoSelect,
      },
      width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '90vw' : '80vw',
    };

    if (immediate) {
      return this.openImmediateDialog(component, null, afterClosed, config);
    }

    // console.log(`Queuing "${resourceURL}" lookup dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () =>
        this._open(component, config) as Observable<MatDialogRef<LookupDialogComponent, any[] | null | undefined>>,
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };
    this._interactiveDialogQueue.push(job);
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`"${resourceURL}" lookup dialog job with id "${job.id}" queued.`);
  }

  /**
   * Open processing dialog using processing queue.
   * @param content Processing dialog content.
   * @param openNow Open dialog right now.
   * @param hasBackdrop Whether the processing dialog has a backdrop.
   * @param afterClosed Function gets called after the dialog is shown, passing null as parameter.
   */
  public openProcessingDialog(
    content?: string,
    openNow: boolean = false,
    hasBackdrop: boolean = true,
    afterClosed?: () => any
  ): DialogJob {
    const component = ProcessingDialogComponent;
    const config: MatDialogConfig = {
      data: {
        content: content,
      },
      disableClose: true,
      restoreFocus: false,
      position: { top: '8px' },
      hasBackdrop: hasBackdrop,
    };

    // console.log(`Queuing "${content}" processing dialog job...`);
    const job = {
      id: this._getJobId(),
      getRef: () => this._open(component, config) as Observable<MatDialogRef<ProcessingDialogComponent, void>>,
      ref: undefined,
      afterClosed: afterClosed,
      active: false,
      closed: false,
    };

    const currentJob = this._processingDialogQueue[0];
    if (openNow && currentJob) {
      // console.log(`Force close current processing dialog job with id "${currentJob.id}".`);
      this._processingDialogQueue.splice(1, 0, job);
      this.closeProcessingDialog(currentJob);
    } else {
      this._processingDialogQueue.push(job);
      this.processingDialogQueue = this._processingDialogQueue;
    }

    // console.log(`"${content}" processing dialog job with id "${job.id}" queued.`);
    return job;
  }

  /**
   * Close processing dialog from processing queue.
   * @param job DialogJob to be closed.
   */
  public closeProcessingDialog(job: DialogJob, retry = 0): void {
    // console.log(`Clearing processing dialog job with id "${job.id}"...`);
    if (job.closed) {
      // console.log(`Processing dialog job with id "${job.id}" already closed.`);
      return;
    }

    if (job.active && job.ref) {
      // console.log(`Closing "${(<any>job.ref.componentInstance).content}" processing dialog...`);
      job.ref.close();
      job.active = false;
      job.closed = true;
      // console.log(`"${(<any>job.ref.componentInstance).content}" processing dialog closed.`);

      this._processingDialogQueue.splice(
        this._processingDialogQueue.findIndex((queuedJob) => queuedJob.id === job.id),
        1
      );
      this.processingDialogQueue = this._processingDialogQueue;
      // console.log(`Processing dialog job with id "${job.id}" cleared.`);
    } else {
      if (retry < 30) {
        // We use settimeout to wait for the unopened processing dialog ref and then retry.
        retry++;
        setTimeout(() => this.closeProcessingDialog(job, retry), 100);
      } else {
        job.active = false;
        job.closed = true;

        this._processingDialogQueue.splice(
          this._processingDialogQueue.findIndex((queuedJob) => queuedJob.id === job.id),
          1
        );
        this.processingDialogQueue = this._processingDialogQueue;
        // console.log(`Unopened processing dialog job with id "${job.id}" cleared.`);
      }
    }
  }

  /**
   * Close all of the currently-open dialogs.
   */
  public closeAll() {
    this._matDialog.closeAll();
  }

  /**
   * Executing the opening of processing dialog job in processing queue.
   */
  private _execProcessingDialogQueue(): void {
    if (this.processingDialogQueue.length > 0) {
      // console.log(`Processing dialog queue is not empty.`);

      const jobToDo: DialogJob = this.processingDialogQueue[0];

      if (!jobToDo.active && jobToDo.ref === undefined) {
        // console.log(`Opening "${jobToDo.id}" processing dialog...`);
        jobToDo.active = true;
        jobToDo.getRef().subscribe((dialogRef) => {
          // console.log(`"${dialogRef.componentInstance.content}" processing dialog opened.`);
          jobToDo.ref = dialogRef;
          if (jobToDo.afterClosed) {
            jobToDo.ref.afterClosed().subscribe((result) => jobToDo.afterClosed(null));
          }
        });
      } else {
        // console.log(`"${jobToDo.id}" processing dialog is currently shown.`);
      }
    } else {
      // console.log(`Processing dialog queue is empty.`);
    }
  }

  /**
   * Executing the opening of interactive dialog job in interactive queue.
   */
  private _execInteractiveDialogQueue(): void {
    if (this.interactiveDialogQueue.length > 0) {
      // console.log(`Interactive dialog queue is not empty.`);

      const jobToDo: DialogJob = this.interactiveDialogQueue[0];

      if (!jobToDo.active && jobToDo.ref === undefined) {
        // console.log(`Opening "${jobToDo.id}" interactive dialog...`);
        jobToDo.active = true;
        jobToDo.getRef().subscribe((dialogRef) => {
          // console.log(`"${dialogRef.componentInstance['title']}" interactive dialog with id "${jobToDo.id}" opened.`);
          jobToDo.ref = dialogRef;
          if (jobToDo.afterClosed) {
            jobToDo.ref.afterClosed().subscribe((result) => jobToDo.afterClosed(result));
          }
          jobToDo.ref.afterClosed().subscribe((result) => this._deleteInteractiveDialogJob(jobToDo));
        });
      } else {
        // console.log(`"${jobToDo.ref.componentInstance['title']}" interactive dialog is currently shown.`);
      }
    } else {
      // console.log(`Interactive dialog queue is empty.`);
    }
  }

  /**
   * Delete interactive dialog job from interactive queue.
   * @param job interactive DialogJob to ve deleted.
   */
  private _deleteInteractiveDialogJob(job: DialogJob): void {
    // console.log(`Clearing "${job.ref.componentInstance['title']}" interactive dialog job with id "${job.id}"...`);
    job.active = false;
    job.closed = true;

    this._interactiveDialogQueue.splice(
      this._interactiveDialogQueue.findIndex((queuedJob) => queuedJob.id === job.id),
      1
    );
    this.interactiveDialogQueue = this._interactiveDialogQueue;
    // console.log(`Interactive dialog job with id "${job.id}" cleared.`);
  }

  /**
   * Open a dialog.
   * @param component Dialog component to be opened.
   * @param config MatDialog config.
   * @param immediate Show dialog immediately regardless the afterAllClosed stream.
   * @return Observable<MatDialogRef<T>> or if immediate MatDialoRef<T>.
   */
  private _open<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>,
    immediate: boolean = false
  ): Observable<MatDialogRef<T, R>> | MatDialogRef<T, R> {
    return immediate
      ? this._openMaterialDialog(component, config)
      : of({}).pipe(
          delay(100),
          map(() => {
            return this._openMaterialDialog<T, D, R>(component, config);
          })
        );
  }

  /**
   * Open material dialog.
   * @param component Dialog component to be opened.
   * @param config MatDialog config.
   * @returns Reference to newly opened dialog (MatDialoRef<T>).
   */
  private _openMaterialDialog<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T> {
    // Set default config object.
    config = config || {};

    // Set default max width and height to 100% of the viewport.
    config.maxWidth = '95vw';
    config.maxHeight = '90vh';

    // Set default scroll strategy to Noop if not provided.
    config.scrollStrategy = config.scrollStrategy ? config.scrollStrategy : this._overlay.scrollStrategies.noop();

    // Open the dialog.
    const dialogRef = this._matDialog.open<T, D, R>(component, config);

    // Set the DialogComponent's wrapper width, height, max width, and max height.
    (dialogRef.componentInstance as any).dialog.width = config && config.width ? config.width : undefined;
    (dialogRef.componentInstance as any).dialog.height = config && config.height ? config.height : undefined;
    (dialogRef.componentInstance as any).dialog.maxWidth = config.maxWidth;
    (dialogRef.componentInstance as any).dialog.maxHeight = config.maxHeight;

    return dialogRef;
  }

  /**
   * Get random job id.
   * @return Random job id .
   */
  private _getJobId(): number {
    return Date.now() + Math.floor(Math.random() * 1000);
  }
}
