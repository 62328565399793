import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ResizableModule } from 'angular-resizable-element';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { DateRangeComponent } from './advanced-search/date-range/date-range.component';
import { FavNodeComponent } from './advanced-search/fav-node/fav-node.component';
import { NodeSettingComponent } from './advanced-search/node-setting/node-setting.component';
import { NodeComponent } from './advanced-search/node/node.component';
import { CardCanvasComponent } from './card-canvas/card-canvas.component';
import { CardPdfComponent } from './card-pdf/card-pdf.component';
import { CardStampingComponent } from './card-stamping/card-stamping.component';
import { ChartComponent } from './chart/chart.component';
import { CrudComponent } from './crud/crud.component';
import { PopupDataListComponent } from './crud/popup-data-list/popup-data-list.component';
import { StateLogsReaderComponent } from './crud/state-logs-reader/state-logs-reader.component';
import { CheckboxCellRendererComponent } from './data-list/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DataListComponent } from './data-list/data-list.component';
import { DateCellEditorComponent } from './data-list/date-cell-editor/date-cell-editor.component';
import { DatetimeCellEditorComponent } from './data-list/datetime-cell-editor/datetime-cell-editor.component';
import { LookupCellEditorComponent } from './data-list/lookup-cell-editor/lookup-cell-editor.component';
import { MultiSelectCellEditorComponent } from './data-list/multi-select-cell-editor/multi-select-cell-editor.component';
import { NumberCellEditorComponent } from './data-list/number-cell-editor/number-cell-editor.component';
import { PopupCellEditorComponent } from './data-list/popup-cell-editor/popup-cell-editor.component';
import { PromptCellEditorComponent } from './data-list/prompt-cell-editor/prompt-cell-editor.component';
import { SelectCellEditorComponent } from './data-list/select-cell-editor/select-cell-editor.component';
import { DatetimepickerComponent } from './datetimepicker/datetimepicker.component';
import { AlertDialogComponent } from './dialog/alert-dialog/alert-dialog.component';
import { BaseDialogComponent } from './dialog/base-dialog/base-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { LookupDialogComponent } from './dialog/lookup-dialog/lookup-dialog.component';
import { ProcessingDialogComponent } from './dialog/processing-dialog/processing-dialog.component';
import { PromptDialogComponent } from './dialog/prompt-dialog/prompt-dialog.component';
import { DraggableDirective } from './directives/draggable.directive';
import { HighlightErrorDirective } from './directives/highlight-error.directive';
import { ModelChangeDebouncedDirective } from './directives/model-change-debounced.directive';
import { TxtMaskDirective } from './directives/txt-mask.directive';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ActorPathPipe } from './pipes/actor-path.pipe';
import { ArrayPipe } from './pipes/array.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { RowNumberPipe } from './pipes/row-number.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { MultiResourceReportComponent } from './report/multi-resource-report/multi-resource-report.component';
import { SingleResourceReportComponent } from './report/single-resource-report/single-resource-report.component';
import { MessageSnackbarComponent } from './utils/message-snackbar/message-snackbar.component';

/**
 * Angular modules.
 */
const ANGULAR_MODULES: any[] = [CommonModule, FormsModule, HttpClientModule, RouterModule];

/**
 * Angular Material modules.
 */
const ANGULAR_MATERIAL_MODULES: any[] = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
];

/**
 * Other third party modules.
 */
const MISC_MODULES: any[] = [PdfJsViewerModule, ResizableModule];

/**
 * Shared Components.
 */
const COMPONENTS: any[] = [
  AdvancedSearchComponent,
  AlertDialogComponent,
  BaseDialogComponent,
  CardCanvasComponent,
  CardPdfComponent,
  CardStampingComponent,
  ChartComponent,
  CheckboxCellRendererComponent,
  ConfirmDialogComponent,
  CrudComponent,
  DataListComponent,
  DateCellEditorComponent,
  DateRangeComponent,
  DatetimeCellEditorComponent,
  DatetimepickerComponent,
  FavNodeComponent,
  ImageEditorComponent,
  ImageViewerComponent,
  LookupCellEditorComponent,
  LookupDialogComponent,
  MessageSnackbarComponent,
  MultiResourceReportComponent,
  MultiSelectCellEditorComponent,
  NodeComponent,
  NodeSettingComponent,
  NumberCellEditorComponent,
  PopupCellEditorComponent,
  PopupDataListComponent,
  ProcessingDialogComponent,
  PromptCellEditorComponent,
  PromptDialogComponent,
  SelectCellEditorComponent,
  SingleResourceReportComponent,
  StateLogsReaderComponent,
];

/**
 * Shared direcitves.
 */
const DIRECTIVES: any[] = [
  DraggableDirective,
  HighlightErrorDirective,
  ModelChangeDebouncedDirective,
  TxtMaskDirective,
];

/**
 * Shared pipes.
 */
const PIPES: any[] = [ActorPathPipe, ArrayPipe, OrderByPipe, RowNumberPipe, SafePipe];

/**
 * Custom Date format.
 */
const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

/**
 * Shared module.
 */
@NgModule({
  imports: [
    ANGULAR_MODULES,
    ANGULAR_MATERIAL_MODULES,
    FlexLayoutModule.withConfig({ useColumnBasisZero: false }),
    MISC_MODULES,
    AgGridModule.withComponents([
      DateCellEditorComponent,
      DatetimeCellEditorComponent,
      LookupCellEditorComponent,
      MultiSelectCellEditorComponent,
      NumberCellEditorComponent,
      PopupCellEditorComponent,
      SelectCellEditorComponent,
    ]),
  ],
  declarations: [COMPONENTS, DIRECTIVES, PIPES],
  exports: [
    COMPONENTS,
    DIRECTIVES,
    PIPES,
    // Re-exported modules.
    ANGULAR_MODULES,
    ANGULAR_MATERIAL_MODULES,
    FlexLayoutModule,
    MISC_MODULES,
    AgGridModule,
  ],
})
export class SharedModule {
  /**
   * forRoot() implementation used to register providers to the root injector.
   */
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
        /**
         * Providing material date adapter for moment.js and our custom date format
         * instead of importing predefined ones using MatNativeDateModule
         * in order to allow us to define custom date format.
         */
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
      ],
    };
  }
}
