<app-base-dialog #dialog [showHeader]="false" [showActionBar]="false">
  <div #wrapper content>
    <img #img [src]="imageData" [alt]="alt" />

    <div class="toolbar" fxLayout="row" fxLayoutGap="8px">
      <button mat-mini-fab color="primary" (click)="reset()" matTooltip="Reset" matTooltipShowDelay="500">
        <mat-icon>replay</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="rotateLeft()" matTooltip="Rotate Left" matTooltipShowDelay="500">
        <mat-icon>rotate_left</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="rotateRight()" matTooltip="Rotate Right" matTooltipShowDelay="500">
        <mat-icon>rotate_right</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="rotateCrop()" matTooltip="Rotate Crop" matTooltipShowDelay="500">
        <mat-icon>screen_rotation</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="confirm()" matTooltip="Confirm" matTooltipShowDelay="500">
        <mat-icon>check</mat-icon>
      </button>
    </div>

    <button
      mat-icon-button
      color="primary"
      *ngIf="!forced"
      (click)="close()"
      matTooltip="Close"
      matTooltipShowDelay="500"
      class="close"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</app-base-dialog>
