import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

@Directive({
  selector: '[appModelChangeDebounced]',
  exportAs: 'appModelChangeDebounced',
})
export class ModelChangeDebouncedDirective implements OnInit, OnDestroy {
  /**
   * Event emitted when new distict value changes after being debonced.
   */
  @Output('appModelChangeDebounced')
  modelChangeDebounced = new EventEmitter<any>();

  /**
   * Debounce time in miliseconds.
   */
  @Input()
  modelChangeDebounceTime = 500;

  /**
   * NgModel value change subscription.
   */
  private _subscription: Subscription;

  /**
   * Constructor.
   */
  constructor(private ngModel: NgModel) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this._subscription = this.ngModel.control.valueChanges
      .pipe(
        skip(1), // Skip initial value as it's not considered a change.
        debounceTime(this.modelChangeDebounceTime),
        distinctUntilChanged()
      )
      .subscribe((value) => this.modelChangeDebounced.emit(value));
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
