import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { Field } from '../../shared/advanced-search/field.interface';
import { ResourceFieldPreset } from '../../shared/resource-field/resource-field-preset.interface';
import { GlobalService } from '../services/global.service';

/**
 * Resource field preset.
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceFieldPresetService {
  /**
   * Common fields.
   */
  private _common: Field[] = [
    { name: 'id', searchable: true, options: [] },
    { name: 'created_by', searchable: true, options: [] },
    { name: 'updated_by', searchable: true, options: [] },
    { name: 'created_at', searchable: true, options: [], isDateTime: true },
    { name: 'updated_at', searchable: true, options: [], isDateTime: true },
  ];

  /**
   * Constructor.
   */
  constructor(private _globalService: GlobalService) {}

  /**
   * Get the preset values.
   * @return Array of resource fields of all resources.
   */
  public get(): ResourceFieldPreset[] {
    return [
      // #region Agency Module
      {
        resourceURL: '/agency/delivery-order',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'shipper', searchable: true, options: [], guidedValue: true },
            { name: 'shipping_bc', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'shipping_instruction', searchable: true, options: [], guidedValue: true },
            { name: 'kwitansi', searchable: true, options: [], guidedValue: true },
            { name: 'ttd', searchable: true, options: [], guidedValue: true },
            { name: 'tts', searchable: true, options: [], guidedValue: true },
            { name: 'expiry_at', searchable: true, options: [], isDateTime: true },
            {
              name: 'status',
              searchable: true,
              options: [
                { display: 'Not Release', value: 'DNR' },
                { display: 'New', value: 'DNW' },
                { display: 'In Progress', value: 'DIP' },
                { display: 'Partial Complete', value: 'DPC' },
                { display: 'Full Complete', value: 'DFC' },
                { display: 'Canceled', value: 'DCN' },
              ],
              fav: true,
            },
            { name: 'commodity', searchable: true, options: [], guidedValue: true },
            { name: 'amount_20', searchable: true, options: [] },
            { name: 'amount_40', searchable: true, options: [] },
            { name: 'amount_45', searchable: true, options: [] },
            { name: 'amount_all', searchable: true, options: [] },
            {
              name: 'renewed',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
            },
            { name: 'remark', searchable: true, options: [], guidedValue: true },
            { name: 'seal_numbers', searchable: true, options: [], guidedValue: true },
            { name: 'token', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/agency/delivery-order-item',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'quantity', searchable: true, options: [] },
            { name: 'outstanding', searchable: true, options: [] },
            {
              name: 'dg',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'dg_imo_class', searchable: true, options: [], guidedValue: true },
            { name: 'dg_un_number', searchable: true, options: [], guidedValue: true },
            {
              name: 'rf',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'rf_temperature', searchable: true, options: [] },
            {
              name: 'rf_temperature_unit',
              searchable: true,
              options: [
                { display: 'CEL', value: 'CEL' },
                { display: 'FAH', value: 'FAH' },
              ],
            },
            { name: 'rf_ventilation', searchable: true, options: [] },
            {
              name: 'rf_ventilation_unit',
              searchable: true,
              options: [
                { display: 'MTQ', value: 'MTQ' },
                { display: 'FTQ', value: 'FTQ' },
              ],
            },
            { name: 'rf_humidity', searchable: true, options: [] },
            {
              name: 'rf_humidity_unit',
              searchable: true,
              options: [{ display: 'PCT', value: 'PCT' }],
            },
            {
              name: 'oog',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'oog_top', searchable: true, options: [] },
            { name: 'oog_front', searchable: true, options: [] },
            { name: 'oog_rear', searchable: true, options: [] },
            { name: 'oog_left', searchable: true, options: [] },
            { name: 'oog_right', searchable: true, options: [] },
            {
              name: 'oog_unit',
              searchable: true,
              options: [
                { display: 'CMT', value: 'CMT' },
                { display: 'INH', value: 'INH' },
              ],
            },
            { name: 'specific_containers', searchable: true, options: [] },
            { name: 'remark', searchable: true, options: [], guidedValue: true },
            { name: 'gate_out_containers', searchable: true, options: [] }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/agency/delivery-order-status-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'Not Release', value: 'DNR' },
                { display: 'New', value: 'DNW' },
                { display: 'In Progress', value: 'DIP' },
                { display: 'Partial Complete', value: 'DPC' },
                { display: 'Full Complete', value: 'DFC' },
                { display: 'Canceled', value: 'DCN' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'Not Release', value: 'DNR' },
                { display: 'New', value: 'DNW' },
                { display: 'In Progress', value: 'DIP' },
                { display: 'Partial Complete', value: 'DPC' },
                { display: 'Full Complete', value: 'DFC' },
                { display: 'Canceled', value: 'DCN' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/agency/stack',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            {
              name: 'status',
              searchable: true,
              options: [
                { display: 'New', value: 'SNW' },
                { display: 'Approved', value: 'SAP' },
                { display: 'Canceled', value: 'SCN' },
              ],
              fav: true,
            },
            { name: 'shipper', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'shipping_bl', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'total_gross', searchable: true, options: [], fav: true },
            {
              name: 'dg',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            {
              name: 'rf',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            {
              name: 'oog',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            {
              name: 'misc',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'customer_email', searchable: true, options: [], guidedValue: true },
            { name: 'email_sent_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'email_sent_to', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/agency/stack-status-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'New', value: 'SNW' },
                { display: 'Approved', value: 'SAP' },
                { display: 'Canceled', value: 'SCN' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'New', value: 'SNW' },
                { display: 'Approved', value: 'SAP' },
                { display: 'Canceled', value: 'SCN' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      // #endregion

      // #region Depot Module
      {
        resourceURL: '/depot/booking-in',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'reference_document', searchable: true, options: [], guidedValue: true },
            {
              name: 'status',
              searchable: true,
              options: [
                { display: 'New', value: 'INW' },
                { display: 'In Progress', value: 'IIP' },
                { display: 'Partial Complete', value: 'IPC' },
                { display: 'Full Complete', value: 'IFC' },
                { display: 'Canceled', value: 'ICN' },
              ],
              fav: true,
            },
            { name: 'consignee', searchable: true, options: [], guidedValue: true },
            { name: 'shipping_bl', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'dem_ft', searchable: true, options: [] },
            { name: 'dem_ft_end_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'tts_jc', searchable: true, options: [], guidedValue: true },
            { name: 'fiat_day', searchable: true, options: [] },
            { name: 'fiat_day_end_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'tts_dm', searchable: true, options: [], guidedValue: true },
            { name: 'lms_dem_ft', searchable: true, options: [] },
            { name: 'lms_dem_apvl', searchable: true, options: [], guidedValue: true },
            { name: 'commodity', searchable: true, options: [], guidedValue: true },
            { name: 'amount_20', searchable: true, options: [] },
            { name: 'amount_40', searchable: true, options: [] },
            { name: 'amount_45', searchable: true, options: [] },
            { name: 'amount_all', searchable: true, options: [] },
            { name: 'remark', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/booking-in-status-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'New', value: 'INW' },
                { display: 'In Progress', value: 'IIP' },
                { display: 'Partial Complete', value: 'IPC' },
                { display: 'Full Complete', value: 'IFC' },
                { display: 'Canceled', value: 'ICN' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'New', value: 'INW' },
                { display: 'In Progress', value: 'IIP' },
                { display: 'Partial Complete', value: 'IPC' },
                { display: 'Full Complete', value: 'IFC' },
                { display: 'Canceled', value: 'ICN' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/booking-out',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'reference_document', searchable: true, options: [], guidedValue: true },
            { name: 'shipper', searchable: true, options: [], guidedValue: true },
            { name: 'shipping_bc', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'shipping_instruction', searchable: true, options: [], guidedValue: true },
            { name: 'fiat_day_end_at', searchable: true, options: [], isDateTime: true },
            { name: 'ttd', searchable: true, options: [], guidedValue: true },
            { name: 'tts', searchable: true, options: [], guidedValue: true },
            {
              name: 'status',
              searchable: true,
              options: [
                { display: 'New', value: 'ONW' },
                { display: 'In Progress', value: 'OIP' },
                { display: 'Partial Complete', value: 'OPC' },
                { display: 'Full Complete', value: 'OFC' },
                { display: 'Canceled', value: 'OCN' },
              ],
              fav: true,
            },
            { name: 'commodity', searchable: true, options: [], guidedValue: true },
            { name: 'amount_20', searchable: true, options: [] },
            { name: 'amount_40', searchable: true, options: [] },
            { name: 'amount_45', searchable: true, options: [] },
            { name: 'amount_all', searchable: true, options: [] },
            { name: 'remark', searchable: true, options: [], guidedValue: true },
            { name: 'customer_email', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/booking-out-item',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'quantity', searchable: true, options: [] },
            { name: 'outstanding', searchable: true, options: [] },
            { name: 'undecided', searchable: true, options: [] },
            {
              name: 'dg',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'dg_imo_class', searchable: true, options: [], guidedValue: true },
            { name: 'dg_un_number', searchable: true, options: [], guidedValue: true },
            {
              name: 'rf',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'rf_temperature', searchable: true, options: [] },
            {
              name: 'rf_temperature_unit',
              searchable: true,
              options: [
                { display: 'CEL', value: 'CEL' },
                { display: 'FAH', value: 'FAH' },
              ],
            },
            { name: 'rf_ventilation', searchable: true, options: [] },
            {
              name: 'rf_ventilation_unit',
              searchable: true,
              options: [
                { display: 'MTQ', value: 'MTQ' },
                { display: 'FTQ', value: 'FTQ' },
              ],
            },
            { name: 'rf_humidity', searchable: true, options: [] },
            {
              name: 'rf_humidity_unit',
              searchable: true,
              options: [{ display: 'PCT', value: 'PCT' }],
            },
            {
              name: 'oog',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            { name: 'oog_top', searchable: true, options: [] },
            { name: 'oog_front', searchable: true, options: [] },
            { name: 'oog_rear', searchable: true, options: [] },
            { name: 'oog_left', searchable: true, options: [] },
            { name: 'oog_right', searchable: true, options: [] },
            {
              name: 'oog_unit',
              searchable: true,
              options: [
                { display: 'CMT', value: 'CMT' },
                { display: 'INH', value: 'INH' },
              ],
            },
            { name: 'specific_containers', searchable: true, options: [] },
            { name: 'remark', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/booking-out-status-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'New', value: 'ONW' },
                { display: 'In Progress', value: 'OIP' },
                { display: 'Partial Complete', value: 'OPC' },
                { display: 'Full Complete', value: 'OFC' },
                { display: 'Canceled', value: 'OCN' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'New', value: 'ONW' },
                { display: 'In Progress', value: 'OIP' },
                { display: 'Partial Complete', value: 'OPC' },
                { display: 'Full Complete', value: 'OFC' },
                { display: 'Canceled', value: 'OCN' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/depot-container',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'spec_id', searchable: true, options: [] },
            { name: 'tare', searchable: true, options: [] },
            { name: 'max_gross', searchable: true, options: [] },
            { name: 'net', searchable: true, options: [] },
            { name: 'cu_cap', searchable: true, options: [] },
            { name: 'vent', searchable: true, options: [] },
            { name: 'mnf_date', searchable: true, options: [], isDateTime: true },
            {
              name: 'grade',
              searchable: true,
              options: [
                { display: 'Lower', value: 'L' },
                { display: 'Normal', value: 'N' },
                { display: 'Special', value: 'S' },
                { display: 'Food', value: 'F' },
              ],
              fav: true,
            },
            {
              name: 'acep',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'availability',
              searchable: true,
              options: [
                { display: 'Book In', value: 'BI' },
                { display: 'Omitted', value: 'OM' },
                { display: 'Gate In', value: 'GI' },
                { display: 'Book Out', value: 'BO' },
                { display: 'Gate Out', value: 'GO' },
                { display: 'Retracted', value: 'RT' },
              ],
              fav: true,
            },
            {
              name: 'condition',
              searchable: true,
              options: [
                { display: 'Damage', value: 'DM' },
                { display: 'Under Repair', value: 'UR' },
                { display: 'Available', value: 'AV' },
              ],
              fav: true,
            },
            {
              name: 'cgi',
              searchable: true,
              options: [
                { display: 'Damage', value: 'DM' },
                { display: 'Under Repair', value: 'UR' },
                { display: 'Available', value: 'AV' },
              ],
              fav: true,
            },
            {
              name: 'cleaning',
              searchable: true,
              options: [
                { display: 'WPB', value: 'WPB' },
                { display: 'WWB', value: 'WWB' },
                { display: 'WWI', value: 'WWI' },
                { display: 'CCB', value: 'CCB' },
                { display: 'CCI', value: 'CCI' },
                { display: 'DDB', value: 'DDB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'DDI', value: 'DDI' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCB', value: 'SCB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCI', value: 'SCI' }, // not applicable anymore to eportal, but leave it there as reference.
              ],
              fav: true,
            },
            {
              name: 'pti_result',
              searchable: true,
              options: [
                { display: 'Fail', value: 'FA' },
                { display: 'OK', value: 'OK' },
              ],
              fav: true,
            },
            {
              name: 'ex_repair',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            { name: 'location', searchable: true, options: [] },
            {
              name: 'load_in',
              searchable: true,
              options: [
                { display: 'Full', value: 'FL' },
                { display: 'Empty', value: 'MT' },
              ],
            },
            {
              name: 'load_out',
              searchable: true,
              options: [
                { display: 'Full', value: 'FL' },
                { display: 'Empty', value: 'MT' },
              ],
            },
            { name: 'gate_in_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'gate_out_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'truck_provider_in', searchable: true, options: [], guidedValue: true },
            { name: 'truck_provider_out', searchable: true, options: [], guidedValue: true },
            { name: 'truck_number_in', searchable: true, options: [], guidedValue: true },
            { name: 'truck_number_out', searchable: true, options: [], guidedValue: true },
            { name: 'simple_pre_survey_in', searchable: true, options: [], guidedValue: true },
            { name: 'simple_pre_survey_out', searchable: true, options: [], guidedValue: true },
            { name: 'pre_survey_in', searchable: true, options: [], guidedValue: true },
            { name: 'pre_survey_out', searchable: true, options: [], guidedValue: true },
            { name: 'pic_in', searchable: true, options: [], guidedValue: true },
            { name: 'pic_out', searchable: true, options: [], guidedValue: true },
            { name: 'recipient_in', searchable: true, options: [], guidedValue: true },
            { name: 'received_date_in', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'vgm_gross', searchable: true, options: [] },
            {
              name: 'vgm_method',
              searchable: true,
              options: [
                { display: 'SM1', value: 'SM1' },
                { display: 'SM2', value: 'SM2' },
              ],
            },
            { name: 'vgm_responsible_party', searchable: true, options: [], guidedValue: true },
            { name: 'vgm_authorized_official', searchable: true, options: [], guidedValue: true },
            { name: 'seal_number', searchable: true, options: [], guidedValue: true },
            { name: 'remark', searchable: true, options: [], guidedValue: true },

            // /depot/depot-container custom fields

            { name: 'period', searchable: true, options: [], isDateTime: true, fav: true, custom: true },
            { name: 'in_hand_at', searchable: true, options: [], isDateTime: true, fav: true, custom: true },
            {
              name: 'availability',
              searchable: true,
              options: [
                { display: 'Book In', value: 'BI' },
                { display: 'Omitted', value: 'OM' },
                { display: 'Gate In', value: 'GI' },
                { display: 'Book Out', value: 'BO' },
                { display: 'Gate Out', value: 'GO' },
                { display: 'Retracted', value: 'RT' },
              ],
              fav: true,
              custom: true,
            },
            {
              name: 'ca_code',
              searchable: true,
              options: [],
              fav: true,
              custom: true,
              guidedValue: true,
              guidedValueResourceURL: '/master/stakeholder',
              guidedValueFieldName: 'code',
            },
            {
              name: 'activity_out_code',
              searchable: true,
              options: [],
              fav: true,
              custom: true,
              guidedValue: true,
              guidedValueResourceURL: '/master/activity',
              guidedValueFieldName: 'code',
            },
            {
              name: 'activity_in_code',
              searchable: true,
              options: [],
              fav: true,
              custom: true,
              guidedValue: true,
              guidedValueResourceURL: '/master/activity',
              guidedValueFieldName: 'code',
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/depot-container-availability-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'Book In', value: 'BI' },
                { display: 'Omitted', value: 'OM' },
                { display: 'Gate In', value: 'GI' },
                { display: 'Book Out', value: 'BO' },
                { display: 'Gate Out', value: 'GO' },
                { display: 'Retracted', value: 'RT' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'Book In', value: 'BI' },
                { display: 'Omitted', value: 'OM' },
                { display: 'Gate In', value: 'GI' },
                { display: 'Book Out', value: 'BO' },
                { display: 'Gate Out', value: 'GO' },
                { display: 'Retracted', value: 'RT' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/depot-container-condition-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'Damage', value: 'DM' },
                { display: 'Under Repair', value: 'UR' },
                { display: 'Available', value: 'AV' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'Damage', value: 'DM' },
                { display: 'Under Repair', value: 'UR' },
                { display: 'Available', value: 'AV' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            {
              name: 'tag',
              searchable: true,
              options: [{ display: 'CGI', value: 'CGI' }],
              fav: true,
            },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/depot-container-pti-result-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'Fail', value: 'FA' },
                { display: 'OK', value: 'OK' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'Fail', value: 'FA' },
                { display: 'OK', value: 'OK' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: true },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/edifact',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'eqd_count', searchable: true, options: [] },
            { name: 'filepath', searchable: true, options: [], guidedValue: true },
            { name: 'generated_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'sent_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'success',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'scheduled',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'resending',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            { name: 'next_attempt_id', searchable: true, options: [], fav: true },
            { name: 'error_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'error_message', searchable: true, options: [] },
            { name: 'data', searchable: false, options: [] }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/edifact-job',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'GICODECO', value: 'GICODECO' },
                { display: 'GOCODECO', value: 'GOCODECO' },
                { display: 'GOCOPARN', value: 'GOCOPARN' },
                { display: 'VGMVERMAS', value: 'VGMVERMAS' },
                { display: 'GINLECONNECT', value: 'GINLECONNECT' },
                { display: 'GONLECONNECT', value: 'GONLECONNECT' },
              ],
              fav: true,
            },
            {
              name: 'subject',
              searchable: true,
              options: [
                { display: 'Gate In', value: 'GI' },
                { display: 'Gate Out', value: 'GO' },
              ],
              fav: true,
            },
            {
              name: 'format',
              searchable: true,
              options: [
                { display: 'CODECO', value: 'CODECO' },
                { display: 'COPARN', value: 'COPARN' },
                { display: 'VERMAS', value: 'VERMAS' },
                { display: 'NLECONNECT', value: 'NLECONNECT' },
              ],
            },
            { name: 'sender', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'recipient', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'filepath_prefix', searchable: true, options: [], guidedValue: true, fav: true },
            {
              name: 'target_protocol',
              searchable: true,
              options: [
                { display: 'FTP', value: 'FTP' },
                { display: 'SFTP', value: 'SFTP' },
              ],
            },
            {
              name: 'target_ftp_ssl',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            { name: 'target_port', searchable: true, options: [], guidedValue: true },
            { name: 'target_host', searchable: true, options: [], guidedValue: true },
            { name: 'target_username', searchable: false, options: [] },
            { name: 'target_password', searchable: false, options: [] },
            { name: 'schedule', searchable: true, options: [] },
            { name: 'effective_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/eor',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'document_number', searchable: true, options: [], guidedValue: true, fav: true },
            {
              name: 'initial',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'service_time',
              searchable: true,
              options: [
                { display: 'Pre Gate', value: 'PRG' },
                { display: 'In Gate', value: 'ING' },
                { display: 'Post Gate', value: 'POG' },
              ],
              fav: true,
            },
            {
              name: 'status',
              searchable: true,
              options: [
                { display: 'Original', value: 'ORI' },
                { display: 'Surveyed', value: 'SVY' },
                { display: 'Survey Only', value: 'SVO' },
                { display: 'Approved', value: 'APP' },
                { display: 'Completed', value: 'CMP' },
                { display: 'WOD', value: 'WOD' },
                { display: 'Canceled', value: 'CAN' },
              ],
              fav: true,
            },
            {
              name: 'currency',
              searchable: true,
              options: [
                { display: 'USD', value: 'USD' },
                { display: 'IDR', value: 'IDR' },
                // { display: 'MIX', value: 'MIX' },
              ],
              fav: true,
            },
            { name: 'exchange_rate_idr', searchable: true, options: [], fav: true },
            { name: 'exchange_date_idr', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'exchange_rounded_idr',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            { name: 'original_material_cost', searchable: true, options: [], fav: true },
            { name: 'original_material_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_labor_cost', searchable: true, options: [], fav: true },
            { name: 'original_labor_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_owner_cost', searchable: true, options: [], fav: true },
            { name: 'original_owner_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_user_cost', searchable: true, options: [], fav: true },
            { name: 'original_user_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_cleaning_cost', searchable: true, options: [], fav: true },
            { name: 'original_cleaning_cost_idr', searchable: true, options: [], fav: true },
            {
              name: 'original_cleaning_code',
              searchable: true,
              options: [
                { display: 'WPB', value: 'WPB' },
                { display: 'WWB', value: 'WWB' },
                { display: 'WWI', value: 'WWI' },
                { display: 'CCB', value: 'CCB' },
                { display: 'CCI', value: 'CCI' },
                { display: 'DDB', value: 'DDB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'DDI', value: 'DDI' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCB', value: 'SCB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCI', value: 'SCI' }, // not applicable anymore to eportal, but leave it there as reference.
              ],
              fav: true,
            },
            { name: 'original_pti_cost', searchable: true, options: [], fav: true },
            { name: 'original_pti_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_total_cost', searchable: true, options: [], fav: true },
            { name: 'original_total_cost_idr', searchable: true, options: [], fav: true },
            { name: 'original_tpc_cost', searchable: true, options: [], fav: true },
            { name: 'original_tpc_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_material_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_material_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_labor_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_labor_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_owner_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_owner_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_user_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_user_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_cleaning_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_cleaning_cost_idr', searchable: true, options: [], fav: true },
            {
              name: 'surveyed_cleaning_code',
              searchable: true,
              options: [
                { display: 'WPB', value: 'WPB' },
                { display: 'WWB', value: 'WWB' },
                { display: 'WWI', value: 'WWI' },
                { display: 'CCB', value: 'CCB' },
                { display: 'CCI', value: 'CCI' },
                { display: 'DDB', value: 'DDB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'DDI', value: 'DDI' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCB', value: 'SCB' }, // not applicable anymore to eportal, but leave it there as reference.
                { display: 'SCI', value: 'SCI' }, // not applicable anymore to eportal, but leave it there as reference.
              ],
              fav: true,
            },
            { name: 'surveyed_pti_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_pti_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_total_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_total_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_tpc_cost', searchable: true, options: [], fav: true },
            { name: 'surveyed_tpc_cost_idr', searchable: true, options: [], fav: true },
            // { name: 'waiver_reference', searchable: true, options: [], fav: true },
            // { name: 'waiver_value', searchable: true, options: [], fav: true },
            // { name: 'surveyed_tpc_nett', searchable: true, options: [], fav: true },
            { name: 'original_total_tpc_cost_idr', searchable: true, options: [], fav: true },
            { name: 'surveyed_total_tpc_cost_idr', searchable: true, options: [], fav: true },
            // { name: 'waiver_value_idr', searchable: true, options: [], fav: true },
            // { name: 'surveyed_tpc_nett_idr', searchable: true, options: [], fav: true },
            { name: 'remark', searchable: true, options: [], guidedValue: true },
            // EOR's special fields only for IDSRG
            { name: 'bl_no', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'act', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'cnee', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'comm', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'ves', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'voy', searchable: true, options: [], guidedValue: true, fav: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/eor-item',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'damage_type',
              searchable: true,
              options: [
                { display: 'Structural', value: 'S' },
                { display: 'Machinery', value: 'M' },
              ],
            },
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'Repair', value: 'R' },
                { display: 'PTI', value: 'P' },
                { display: 'Cleaning', value: 'C' },
              ],
              fav: true,
            },
            {
              name: 'volume_type',
              searchable: true,
              options: [
                { display: 'QTY', value: 'QTY' },
                { display: 'SZE', value: 'SZE' },
                { display: 'SQR', value: 'SQR' },
              ],
            },
            { name: 'volume_amount', searchable: true, options: [] },
            {
              name: 'material_rate_in_idr',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            { name: 'material_rate', searchable: true, options: [] },
            { name: 'material_cost', searchable: true, options: [] },
            { name: 'material_cost_idr', searchable: true, options: [] },
            { name: 'man_hour', searchable: true, options: [] },
            { name: 'total_man_hour', searchable: true, options: [] },
            {
              name: 'labor_rate_in_idr',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            { name: 'labor_rate', searchable: true, options: [] },
            { name: 'labor_cost', searchable: true, options: [] },
            { name: 'labor_cost_idr', searchable: true, options: [] },
            { name: 'total_cost', searchable: true, options: [] },
            { name: 'total_cost_idr', searchable: true, options: [] },
            {
              name: 'claim',
              searchable: true,
              options: [
                { display: 'Owner', value: 'O' },
                { display: 'User', value: 'U' },
                { display: 'Cleaning', value: 'C' },
                { display: 'PTI', value: 'P' },
              ],
            },
            {
              name: 'tpc',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'override',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'nat',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            { name: 'remark', searchable: true, options: [], guidedValue: true },
            {
              name: 'surveyed',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/eor-status-log',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'from',
              searchable: true,
              options: [
                { display: 'Original', value: 'ORI' },
                { display: 'Surveyed', value: 'SVY' },
                { display: 'Survey Only', value: 'SVO' },
                { display: 'Approved', value: 'APP' },
                { display: 'Completed', value: 'CMP' },
                { display: 'WOD', value: 'WOD' },
                { display: 'Canceled', value: 'CAN' },
              ],
            },
            {
              name: 'to',
              searchable: true,
              options: [
                { display: 'Original', value: 'ORI' },
                { display: 'Surveyed', value: 'SVY' },
                { display: 'Survey Only', value: 'SVO' },
                { display: 'Approved', value: 'APP' },
                { display: 'Completed', value: 'CMP' },
                { display: 'WOD', value: 'WOD' },
                { display: 'Canceled', value: 'CAN' },
              ],
              fav: true,
            },
            { name: 'remark', searchable: true, options: [] },
            { name: 'tag', searchable: false, options: [], fav: false },
            { name: 'event_at', searchable: true, options: [], isDateTime: true, fav: true },
            {
              name: 'valid',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            },
            {
              name: 'current',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/depot/warning',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'subject',
              searchable: true,
              options: [
                { display: 'Sale Unit', value: 'SLO' },
                { display: 'Repo Urgent', value: 'RPO' },
                { display: 'Lease Out', value: 'LSO' },
                { display: 'Special Use', value: 'SPU' },
              ],
              fav: true,
            },
            { name: 'reference', searchable: true, options: [], guidedValue: true },
            { name: 'reference_date', searchable: true, options: [], isDateTime: true },
            { name: 'number_start', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'number_end', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'range_size', searchable: true, options: [] },
            { name: 'affected', searchable: true, options: [] },
            { name: 'expired_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'completed_at', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'remark', searchable: true, options: [], guidedValue: true, fav: true }
          );
          return fields;
        })(),
      },
      // #endregion

      // #region Master Module
      // Main
      {
        resourceURL: '/master/activity',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            {
              name: 'direction',
              searchable: true,
              options: [
                { display: 'Inbound', value: 'I' },
                { display: 'Outbound', value: 'O' },
              ],
            },
            { name: 'name', searchable: true, options: [], guidedValue: true },
            {
              name: 'required_transport',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'required_container_availability',
              searchable: true,
              options: [
                { display: 'Book In', value: 'BI' },
                { display: 'Omitted', value: 'OM' },
                { display: 'Gate In', value: 'GI' },
                { display: 'Book Out', value: 'BO' },
                { display: 'Gate Out', value: 'GO' },
                { display: 'Retracted', value: 'RT' },
              ],
            },
            {
              name: 'required_container_condition',
              searchable: true,
              options: [
                { display: 'Damage', value: 'DM' },
                { display: 'Under Repair', value: 'UR' },
                { display: 'Available', value: 'AV' },
              ],
            },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/carrier',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'name', searchable: true, options: [], guidedValue: true }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/point',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'Depot', value: 'D' },
                { display: 'Terminal', value: 'T' },
              ],
            },
            { name: 'number', searchable: true, options: [], guidedValue: true },
            { name: 'name', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'address', searchable: true, options: [], guidedValue: true },
            {
              name: 'ediable',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/port',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'country', searchable: true, options: [], guidedValue: true },
            { name: 'name', searchable: true, options: [], guidedValue: true },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/spec',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'D2', value: 'D2' },
                { display: 'D3', value: 'D3' },
                { display: 'D4', value: 'D4' },
                { display: 'D5', value: 'D5' },
                { display: 'D7', value: 'D7' },
                { display: 'F2', value: 'F2' },
                { display: 'F4', value: 'F4' },
                { display: 'F5', value: 'F5' },
                { display: 'O2', value: 'O2' },
                { display: 'O3', value: 'O3' },
                { display: 'O4', value: 'O4' },
                { display: 'O5', value: 'O5' },
                { display: 'P2', value: 'P2' },
                { display: 'P4', value: 'P4' },
                { display: 'R2', value: 'R2' },
                { display: 'R3', value: 'R3' },
                { display: 'R4', value: 'R4' },
                { display: 'R5', value: 'R5' },
                { display: 'R7', value: 'R7' },
                { display: 'T2', value: 'T2' },
                { display: 'T4', value: 'T4' },
                { display: 'V2', value: 'V2' },
              ],
              fav: true,
            },
            {
              name: 'size',
              searchable: true,
              options: [
                { display: '20', value: '20' },
                { display: '40', value: '40' },
                { display: '45', value: '45' },
              ],
            },
            { name: 'iso_code', searchable: true, options: [], guidedValue: true },
            { name: 'iso_length', searchable: true, options: [], guidedValue: true },
            { name: 'iso_height', searchable: true, options: [], guidedValue: true },
            { name: 'iso_width', searchable: true, options: [], guidedValue: true },
            { name: 'iso_type_description', searchable: true, options: [], guidedValue: true },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/stakeholder',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'name', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'address', searchable: true, options: [], guidedValue: true },
            { name: 'contacts', searchable: true, options: [], guidedValue: true },
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'Shipping Line', value: 'SL' },
                { display: 'Leasing Company', value: 'LC' },
                { display: 'Shipping Agency', value: 'SA' },
                { display: 'Freight Forwarder', value: 'FF' },
                { display: 'Container Depot', value: 'CD' },
                { display: 'Sublease Company', value: 'SC' },
                { display: 'Container Factory', value: 'CF' },
                { display: 'Port Authority', value: 'PA' },
                { display: 'Other Stakeholder', value: 'OS' },
              ],
            },
            {
              name: 'container_authority',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'container_principal',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/transport',
        fields: (() => {
          const fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'mode',
              searchable: true,
              options: [
                { display: 'Sea', value: 'S' },
                { display: 'Land', value: 'L' },
                { display: 'Railway', value: 'R' },
              ],
            },
            { name: 'number', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'number_edi', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'etd', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'eta', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'atd', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'ata', searchable: true, options: [], isDateTime: true, fav: true },
            { name: 'closing_cargo', searchable: true, options: [], isDateTime: true },
            {
              name: 'direction',
              searchable: true,
              options: [
                { display: 'Inbound', value: 'I' },
                { display: 'Outbound', value: 'O' },
              ],
            },
            {
              name: 'completed',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/authority',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'name', searchable: true, options: [], guidedValue: true },
            { name: 'address', searchable: true, options: [], guidedValue: true },
            {
              name: 'type',
              searchable: true,
              options: [
                { display: 'Agency', value: 'AG' },
                { display: 'Depot', value: 'DP' },
              ],
            }
          );
          return fields;
        })(),
      },
      // EOR
      {
        resourceURL: '/master/component-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/contractor',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'name', searchable: true, options: [], guidedValue: true },
            { name: 'address', searchable: true, options: [] },
            { name: 'contacts', searchable: true, options: [] }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/damage-category-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/damage-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/damage-location-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/division-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/repair-code',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'description', searchable: true, options: [], guidedValue: true },
            {
              name: 'custom',
              searchable: true,
              options: [
                { display: 'TRUE', value: true },
                { display: 'FALSE', value: false },
              ],
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/repair-tariff',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            {
              name: 'standard',
              searchable: true,
              options: [
                { display: 'CW', value: 'CW' },
                { display: 'CL', value: 'CL' },
              ],
              fav: true,
            },
            {
              name: 'group',
              searchable: true,
              options: [
                { display: 'DB', value: 'DB' },
                { display: 'RB', value: 'RB' },
                { display: 'RU', value: 'RU' },
                { display: 'SB', value: 'SB' },
              ],
              fav: true,
            },
            { name: 'description', searchable: true, options: [], fav: true, guidedValue: true },
            {
              name: 'material_rate_in_idr',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            },
            {
              name: 'volume_type',
              searchable: true,
              options: [
                { display: 'QTY', value: 'QTY' },
                { display: 'SZE', value: 'SZE' },
                { display: 'SQR', value: 'SQR' },
              ],
            },
            { name: 'volume_min', searchable: true, options: [] },
            { name: 'volume_max', searchable: true, options: [] },
            { name: 'volume_range', searchable: true, options: [] },
            { name: 'material_rate', searchable: true, options: [] },
            { name: 'man_hour', searchable: true, options: [] },
            { name: 'volume_range_additional', searchable: true, options: [] },
            { name: 'material_rate_additional', searchable: true, options: [] },
            { name: 'man_hour_additional', searchable: true, options: [] },
            {
              name: 'active',
              searchable: true,
              options: [
                { display: 'FALSE', value: false },
                { display: 'TRUE', value: true },
              ],
              fav: true,
            }
          );
          return fields;
        })(),
      },
      {
        resourceURL: '/master/surveyor',
        fields: (() => {
          let fields = cloneDeep(this._common);
          fields.splice(
            1,
            0,
            { name: 'code', searchable: true, options: [], guidedValue: true, fav: true },
            { name: 'name', searchable: true, options: [], guidedValue: true },
            { name: 'address', searchable: true, options: [] },
            { name: 'contacts', searchable: true, options: [] }
          );
          return fields;
        })(),
      },
      // #endregion
    ];
  }

  /* eslint-disable */
  /**
   * Date time field options.
   */
  // private _getDateTimeOptions(dateFormat: string): Option[] {
  //   return [
  //     { display: 'Start Of Today', value: moment().startOf('day').format(dateFormat) },
  //     { display: 'End Of Today', value: moment().endOf('day').format(dateFormat) },
  //     { display: 'This Day', value: moment().startOf('day').format(dateFormat) + ' | ' + moment().endOf('day').format(dateFormat) },
  //     { display: 'This Week', value: moment().startOf('week').format(dateFormat) + ' | ' + moment().endOf('week').format(dateFormat) },
  //     { display: 'This Month', value: moment().startOf('month').format(dateFormat) + ' | ' + moment().endOf('month').format(dateFormat) },
  //     { display: 'This Year', value: moment().startOf('year').format(dateFormat) + ' | ' + moment().endOf('year').format(dateFormat) },
  //     { display: 'Start Of Yesterday', value: moment().subtract(1, 'days').startOf('day').format(dateFormat) },
  //     { display: 'End Of Yesterday', value: moment().subtract(1, 'days').endOf('day').format(dateFormat) },
  //     { display: 'Last Day', value: moment().subtract(1, 'days').startOf('day').format(dateFormat) + ' | ' + moment().subtract(1, 'days').endOf('day').format(dateFormat) },
  //     { display: 'Last Week', value: moment().subtract(1, 'weeks').startOf('week').format(dateFormat) + ' | ' + moment().subtract(1, 'weeks').endOf('week').format(dateFormat) },
  //     { display: 'Last Month', value: moment().subtract(1, 'month').startOf('month').format(dateFormat) + ' | ' + moment().subtract(1, 'month').endOf('month').format(dateFormat) },
  //     { display: 'Last Year', value: moment().subtract(1, 'year').startOf('year').format(dateFormat) + ' | ' + moment().subtract(1, 'year').endOf('year').format(dateFormat) },
  //     { display: 'Start of Tomorrow', value: moment().add(1, 'days').startOf('day').format(dateFormat) },
  //     { display: 'End of Tomorrow', value: moment().add(1, 'days').endOf('day').format(dateFormat) },
  //     { display: 'Next Day', value: moment().add(1, 'days').startOf('day').format(dateFormat) + ' | ' + moment().add(1, 'days').endOf('day').format(dateFormat) },
  //     { display: 'Next Week', value: moment().add(1, 'weeks').startOf('week').format(dateFormat) + ' | ' + moment().add(1, 'weeks').endOf('week').format(dateFormat) },
  //     { display: 'Next Month', value: moment().add(1, 'month').startOf('month').format(dateFormat) + ' | ' + moment().add(1, 'month').endOf('month').format(dateFormat) },
  //     { display: 'Next Year', value: moment().add(1, 'year').startOf('year').format(dateFormat) + ' | ' + moment().add(1, 'year').endOf('year').format(dateFormat) }
  //   ]
  // }
  /* eslint-enable */
}
