import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResizeEvent } from 'angular-resizable-element';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

/**
 * Basic dialog component.
 */
@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { read: ElementRef })
  private _header: ElementRef;

  /**
   * Dialog title.
   */
  @Input() public dialogTitle = '';

  /**
   * Flag indicating the dialog header is shown.
   */
  @Input() public showHeader = true;

  /**
   * Flag indicating the dialog action bar is shown.
   */
  @Input() public showActionBar = true;

  /**
   * Wrapper width.
   */
  public width: string;

  /**
   * Wrapper height.
   */
  public height: string;

  /**
   * Wrapper max width.
   */
  public maxWidth: string;

  /**
   * Wrapper max height.
   */
  public maxHeight: string;

  /**
   * Function gets called before closing dialog. Return true to cancel closing.
   */
  public cancelClosing: () => Observable<boolean> = () => of(false);

  /**
   * Component destroying signal to unsubscribe.
   */
  public destroySignal = new Subject<any>();

  /**
   * Flag indicating height is defined.
   */
  public get heightDefined(): boolean {
    return this.height !== '';
  }

  /**
   * Wrapper max width in pixel.
   */
  private _maxWidthPixel: number;

  /**
   * Wrapper max height in pixel.
   */
  private _maxHeightPixel: number;

  /**
   * Current dragged offset position.
   */
  private _dragOffset: { x: number; y: number } = { x: 0, y: 0 };

  /**
   * Left position when the width is oversize.
   */
  private _leftOnWidthOverflow: number = null;

  /**
   * Top position when the height is oversize.
   */
  private _topOnHeightOverflow: number = null;

  /**
   * Contructor.
   */
  constructor(private _dialogRef: MatDialogRef<BaseDialogComponent>, private _ngZone: NgZone) {}

  /**
   * Angular life cycle hook.
   */
  public ngOnInit(): void {
    this._maxWidthPixel = (window.innerWidth * +this.maxWidth.substring(0, this.maxWidth.length - 2)) / 100;
    this._maxHeightPixel = (window.innerHeight * +this.maxHeight.substring(0, this.maxHeight.length - 2)) / 100;
  }

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit(): void {
    this._subscribeMouseWheelToChangeOpacity();
  }

  /**
   * Subscribe to mouse wheel event to change dialog overlay panel's opacity.
   */
  private _subscribeMouseWheelToChangeOpacity() {
    if (!this._header) {
      return;
    }

    let opacityValue = 6;
    let opacityClass = `opacity-${opacityValue}`;
    this._dialogRef.addPanelClass(opacityClass);

    this._ngZone.runOutsideAngular(() => {
      fromEvent(this._header.nativeElement, 'wheel')
        .pipe(throttleTime(5), takeUntil(this.destroySignal))
        .subscribe((event: WheelEvent) => {
          // Prevent scrolling main content.
          event.preventDefault();

          // Change opacity by switching opacity level class.
          this._dialogRef.removePanelClass(opacityClass);
          opacityValue += event.deltaY < 0 ? 1 : -1;
          opacityValue = Math.max(1, Math.min(6, opacityValue));
          opacityClass = `opacity-${opacityValue}`;
          this._dialogRef.addPanelClass(opacityClass);
        });
    });
  }

  /**
   * Close the dialog.
   */
  public close(): void {
    this.cancelClosing().subscribe(
      (canceled) => {
        if (!canceled) {
          this.destroySignal.next();
          this._dialogRef.close();
        }
      },
      () => {}
    );
  }

  /**
   * Handle dialog resizing event.
   * @param event Resize event
   */
  public onResizing(event: ResizeEvent) {
    const widthOverflow = this._maxWidthPixel < event.rectangle.width;
    const heightOverflow = this._maxHeightPixel < event.rectangle.height;

    const cappedWidth = (widthOverflow ? this._maxWidthPixel : event.rectangle.width) + 'px';
    const cappedHeight = (heightOverflow ? this._maxHeightPixel : event.rectangle.height) + 'px';

    // Update content and material dialog (overlay) size.
    this.height = cappedHeight;
    this.width = cappedWidth;
    this._dialogRef.updateSize(cappedWidth, cappedHeight);

    // Capture left and top position on size overflow.
    this._leftOnWidthOverflow = widthOverflow
      ? !this._leftOnWidthOverflow
        ? event.rectangle.left
        : this._leftOnWidthOverflow
      : null;
    this._topOnHeightOverflow = heightOverflow
      ? !this._topOnHeightOverflow
        ? event.rectangle.top
        : this._topOnHeightOverflow
      : null;

    // We also need to update the material dialog (overlay) position so that its position isn't centered on resized.
    // There are chances when the size is overflow but the edge is undefined so we coalescing with zero.
    this._dialogRef.updatePosition({
      left: (widthOverflow ? this._leftOnWidthOverflow : event.rectangle.left) + this._dragOffset.x * -1 + 'px',
      top: (heightOverflow ? this._topOnHeightOverflow : event.rectangle.top) + this._dragOffset.y * -1 + 'px',
    });
  }

  /**
   * Handle dialog resize end event.
   * @param event Resize event
   */
  public onResizeEnd() {
    // Reset overflow position so that it will capture new position on next overflow.
    this._leftOnWidthOverflow = null;
    this._topOnHeightOverflow = null;
  }

  /**
   * Keep drag offset position on dialog drag change.
   * @param event Current offset position.
   */
  public onDragChange(event: { x: number; y: number }) {
    this._dragOffset = event;
  }
}
