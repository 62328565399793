<div fxLayout="column" fxLayoutGap="8px">
  <ng-container *ngTemplateOutlet="header || defaultHeader"></ng-container>

  <ng-template #defaultHeader>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <h1 class="mat-h1">{{ title }}</h1>
      <div class="actions" fxLayout="row" fxLayoutGap="8px">
        <button
          mat-mini-fab
          color="primary"
          (click)="add().subscribe()"
          [disabled]="isAddDisabled()"
          matTooltip="Insert"
          matTooltipShowDelay="500"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          (click)="download().subscribe()"
          [disabled]="isDownloadDisabled()"
          matTooltip="Download"
          matTooltipShowDelay="500"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          (click)="clear().subscribe()"
          [disabled]="isClearDisabled()"
          matTooltip="Clear"
          matTooltipShowDelay="500"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <div [style]="'--aspect-ratio:' + width + '/' + height + ';'">
    <mat-card>
      <!-- {{ ratio }} {{ fabricWidth }}x{{ fabricHeight }} {{ canvasWidth }}x{{ canvasHeight }} -->
      <canvas [id]="canvasId"></canvas>

      <figcaption (dblclick)="view()" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-bar
          [fxShow]="canvasState.loading"
          [value]="canvasState.percentage"
          [mode]="'determinate'"
          class="progress"
        ></mat-progress-bar>

        <span fxFlex></span>

        <div class="actions" fxLayout="row" fxLayoutGap="8px">
          <button
            mat-mini-fab
            color="primary"
            (click)="add().subscribe()"
            [disabled]="isAddDisabled()"
            matTooltip="Insert"
            matTooltipShowDelay="500"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="crop().subscribe()"
            [disabled]="isCropDisabled()"
            matTooltip="Crop"
            matTooltipShowDelay="500"
          >
            <mat-icon>crop</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="view().subscribe()"
            [disabled]="isViewDisabled()"
            matTooltip="Fullscreen"
            matTooltipShowDelay="500"
          >
            <mat-icon>fullscreen</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="download().subscribe()"
            [disabled]="isDownloadDisabled()"
            matTooltip="Download"
            matTooltipShowDelay="500"
          >
            <mat-icon>arrow_downward</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="warn"
            (click)="clear().subscribe()"
            [disabled]="isClearDisabled()"
            matTooltip="Clear"
            matTooltipShowDelay="500"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </figcaption>
    </mat-card>
  </div>
</div>
