import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { Field } from '../advanced-search/field.interface';
import { ResourceFieldPreset } from './resource-field-preset.interface';

/**
 * Resource field service.
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceFieldService {
  /**
   * Resource fields for all resources.
   */
  public presets: ResourceFieldPreset[] = [];

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Get resource fields of specified resource URL.
   * @param resourceURL Resource URL to get resource fields.
   * @return Resource fields.
   */
  public get(resourceURL: string): Field[] {
    const obj = cloneDeep(this.presets.find((item) => item.resourceURL === resourceURL));
    if (obj) {
      // Return sorted fields.
      return obj.fields.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }
}
