import { Injectable } from '@angular/core';
import * as moment from 'moment-mini';
import { QueryPresetList } from 'src/app/shared/advanced-search/query-preset-list.interface';

import { Mode } from '../../shared/advanced-search/mode.enum';
import { NodeComponent } from '../../shared/advanced-search/node/node.component';
import { QueryService } from '../../shared/advanced-search/query.service';
import { WhereFieldNode } from '../../shared/advanced-search/where-field-node.interface';
import { WhereHasGroupNode } from '../../shared/advanced-search/where-has-group-node.interface';
import { GlobalService } from '../services/global.service';

/**
 * QAdv preset.
 */
@Injectable({
  providedIn: 'root',
})
export class QueryPresetService {
  /**
   * Where hases list.
   */
  private _whereHases: { resourceURL: string; has: { resourceURL: string; relation: string }[] }[] = [
    // #region Agency Module
    {
      resourceURL: '/agency/delivery-order',
      has: [
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
        { resourceURL: '/master/stakeholder', relation: 'gateOutTo' },
        { resourceURL: '/master/activity', relation: 'activity' },
        { resourceURL: '/master/transport', relation: 'transport' },
        { resourceURL: '/master/point', relation: 'transport.terminal' },
        { resourceURL: '/master/carrier', relation: 'transport.carrier' },
        { resourceURL: '/master/port', relation: 'pol' },
        { resourceURL: '/master/port', relation: 'pod' },
        { resourceURL: '/master/port', relation: 'fd' },
        { resourceURL: '/master/spec', relation: 'deliveryOrderItems.spec' },
        { resourceURL: '/agency/delivery-order-item', relation: 'deliveryOrderItems' },
        { resourceURL: '/agency/delivery-order-status-log', relation: 'statusLogs' },
        { resourceURL: '/agency/delivery-order-status-log', relation: 'currentStatusLog' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOut' },
        { resourceURL: '/depot/booking-out-item', relation: 'deliveryOrderItems.bookingOutItem' },
        { resourceURL: '/depot/depot-container', relation: 'deliveryOrderItems.bookingOutItem.depotContainers' },
      ],
    },
    {
      resourceURL: '/agency/stack',
      has: [
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/stakeholder', relation: 'gateOutTo' },
        { resourceURL: '/master/transport', relation: 'transport' },
        { resourceURL: '/master/point', relation: 'transport.terminal' },
        { resourceURL: '/master/carrier', relation: 'transport.carrier' },
        { resourceURL: '/agency/stack-status-log', relation: 'statusLogs' },
        { resourceURL: '/agency/stack-status-log', relation: 'currentStatusLog' },
      ],
    },
    // #endregion

    // #region Depot Module
    {
      resourceURL: '/depot/booking-in',
      has: [
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
        { resourceURL: '/master/stakeholder', relation: 'gateInFrom' },
        { resourceURL: '/master/activity', relation: 'activity' },
        { resourceURL: '/master/transport', relation: 'transport' },
        { resourceURL: '/master/point', relation: 'transport.terminal' },
        { resourceURL: '/master/carrier', relation: 'transport.carrier' },
        { resourceURL: '/master/port', relation: 'poo' },
        { resourceURL: '/master/port', relation: 'poe' },
        { resourceURL: '/master/spec', relation: 'depotContainers.spec' },
        { resourceURL: '/depot/booking-in-status-log', relation: 'statusLogs' },
        { resourceURL: '/depot/booking-in-status-log', relation: 'currentStatusLog' },
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/depot/eor', relation: 'depotContainers.eors' },
      ],
    },
    {
      resourceURL: '/depot/booking-out',
      has: [
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
        { resourceURL: '/master/stakeholder', relation: 'gateOutTo' },
        { resourceURL: '/master/activity', relation: 'activity' },
        { resourceURL: '/master/transport', relation: 'transport' },
        { resourceURL: '/master/transport', relation: 'transportConfirm' },
        { resourceURL: '/master/point', relation: 'transport.terminal' },
        { resourceURL: '/master/carrier', relation: 'transport.carrier' },
        { resourceURL: '/master/port', relation: 'pol' },
        { resourceURL: '/master/port', relation: 'pod' },
        { resourceURL: '/master/port', relation: 'fd' },
        { resourceURL: '/master/spec', relation: 'bookingOutItems.spec' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrder' },
        { resourceURL: '/depot/booking-out-status-log', relation: 'statusLogs' },
        { resourceURL: '/depot/booking-out-status-log', relation: 'currentStatusLog' },
        { resourceURL: '/depot/booking-out-item', relation: 'bookingOutItems' },
        { resourceURL: '/depot/depot-container', relation: 'bookingOutItems.depotContainers' },
      ],
    },
    {
      resourceURL: '/depot/depot-container',
      has: [
        { resourceURL: '/master/activity', relation: 'bookingIn.activity' },
        { resourceURL: '/master/activity', relation: 'bookingOutItem.bookingOut.activity' },
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/port', relation: 'bookingIn.poo' },
        { resourceURL: '/master/port', relation: 'bookingOutItem.bookingOut.pol' },
        { resourceURL: '/master/port', relation: 'bookingOutItem.bookingOut.pod' },
        { resourceURL: '/master/port', relation: 'bookingOutItem.bookingOut.fd' },
        // { resourceURL: '/principal-container', relation: 'principalContainer' },
        { resourceURL: '/master/spec', relation: 'spec' },
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
        { resourceURL: '/master/transport', relation: 'bookingIn.transport' },
        { resourceURL: '/master/point', relation: 'bookingIn.transport.terminal' },
        { resourceURL: '/master/transport', relation: 'bookingOutItem.bookingOut.transport' },
        { resourceURL: '/master/transport', relation: 'bookingOutItem.bookingOut.transportConfirm' },
        { resourceURL: '/master/point', relation: 'bookingOutItem.bookingOut.transport.terminal' },
        { resourceURL: '/master/surveyor', relation: 'eors.surveyor' },
        { resourceURL: '/master/contractor', relation: 'eors.eorItems.contractor' },
        { resourceURL: '/depot/booking-in', relation: 'bookingIn' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutItem.bookingOut' },
        { resourceURL: '/depot/booking-out-item', relation: 'bookingOutItem' },
        { resourceURL: '/depot/depot-container-availability-log', relation: 'availabilityLogs' },
        { resourceURL: '/depot/depot-container-condition-log', relation: 'conditionLogs' },
        { resourceURL: '/depot/depot-container-pti-result-log', relation: 'ptiResultLogs' },
        { resourceURL: '/depot/depot-container-availability-log', relation: 'currentAvailabilityLog' },
        { resourceURL: '/depot/depot-container-condition-log', relation: 'currentConditionLog' },
        { resourceURL: '/depot/depot-container-pti-result-log', relation: 'currentPtiResultLog' },
        { resourceURL: '/depot/edifact', relation: 'edifacts' },
        { resourceURL: '/depot/edifact-job', relation: 'edifacts.edifactJob' },
        { resourceURL: '/depot/eor', relation: 'eors' },
        { resourceURL: '/depot/eor-item', relation: 'eors.eorItems' },
        { resourceURL: '/depot/warning', relation: 'warning' },
      ],
    },
    {
      resourceURL: '/depot/edifact',
      has: [
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/depot/booking-in', relation: 'depotContainers.bookingIn' },
        { resourceURL: '/depot/booking-out', relation: 'depotContainers.bookingOutItem.bookingOut' },
        { resourceURL: '/depot/booking-out-item', relation: 'depotContainers.bookingOutItem' },
        { resourceURL: '/depot/edifact-job', relation: 'edifactJob' },
        { resourceURL: '/master/stakeholder', relation: 'edifactJob.containerAuthority' },
        { resourceURL: '/master/port', relation: 'edifactJob.depot.port' },
        { resourceURL: '/master/point', relation: 'edifactJob.depot' },
        { resourceURL: '/master/activity', relation: 'edifactJob.activity' },
        { resourceURL: '/master/point', relation: 'edifactJob.terminal' },
        { resourceURL: '/master/transport', relation: 'depotContainers.bookingIn.transport' },
        { resourceURL: '/master/transport', relation: 'depotContainers.bookingOutItem.bookingOut.transport' },
      ],
    },
    {
      resourceURL: '/depot/edifact-job',
      has: [
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/port', relation: 'depot.port' },
        { resourceURL: '/master/activity', relation: 'activity' },
        { resourceURL: '/master/point', relation: 'terminal' },
        { resourceURL: '/depot/edifact', relation: 'edifacts' },
      ],
    },
    {
      resourceURL: '/depot/eor',
      has: [
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/master/point', relation: 'depot' },
        { resourceURL: '/master/spec', relation: 'depotContainer.spec' },
        { resourceURL: '/master/stakeholder', relation: 'depotContainer.containerAuthority' },
        { resourceURL: '/master/activity', relation: 'depotContainer.bookingIn.activity' },
        { resourceURL: '/master/transport', relation: 'depotContainer.bookingIn.transport' },
        { resourceURL: '/master/surveyor', relation: 'surveyor' },
        { resourceURL: '/master/contractor', relation: 'eorItems.contractor' },
        { resourceURL: '/master/component-code', relation: 'eorItems.componentCode' },
        { resourceURL: '/master/damage-location-code', relation: 'eorItems.damageLocationCode' },
        { resourceURL: '/master/damage-code', relation: 'eorItems.damageCode' },
        { resourceURL: '/master/repair-code', relation: 'eorItems.repairCode' },
        { resourceURL: '/master/damage-category-code', relation: 'eorItems.damageCategoryCode' },
        { resourceURL: '/master/division-code', relation: 'eorItems.divisionCode' },
        { resourceURL: '/master/repair-tariff', relation: 'eorItems.repairTariff' },
        { resourceURL: '/depot/booking-in', relation: 'depotContainer.bookingIn' },
        { resourceURL: '/depot/depot-container', relation: 'depotContainer' },
        { resourceURL: '/depot/warning', relation: 'depotContainer.warning' },
        { resourceURL: '/depot/eor-item', relation: 'eorItems' },
        { resourceURL: '/depot/eor-status-log', relation: 'statusLogs' },
        { resourceURL: '/depot/eor-status-log', relation: 'currentStatusLog' },
        // { resourceURL: '/depot/tpc-payment', relation: 'tpcPayment' }
      ],
    },
    {
      resourceURL: '/depot/warning',
      has: [
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/master/spec', relation: 'spec' },
      ],
    },
    // #endregion

    // #region Master Module
    // Main
    {
      resourceURL: '/master/activity',
      has: [
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrders' },
        { resourceURL: '/depot/booking-in', relation: 'bookingIns' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOuts' },
      ],
    },
    {
      resourceURL: '/master/carrier',
      has: [{ resourceURL: '/master/transport', relation: 'transports' }],
    },
    {
      resourceURL: '/master/point',
      has: [
        { resourceURL: '/master/port', relation: 'port' },
        { resourceURL: '/master/transport', relation: 'transportsAsDeparturePoint' },
        { resourceURL: '/master/transport', relation: 'transportsAsArrivalPoint' },
        { resourceURL: '/master/authority', relation: 'authority' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrders' },
        { resourceURL: '/depot/booking-in', relation: 'bookingIns' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOuts' },
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/depot/eor', relation: 'eors' },
      ],
    },
    {
      resourceURL: '/master/port',
      has: [
        { resourceURL: '/master/point', relation: 'points' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrdersAsPOL' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrdersAsPOD' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrdersAsFD' },
        { resourceURL: '/depot/booking-in', relation: 'bookingInsAsPOO' },
        { resourceURL: '/depot/booking-in', relation: 'bookingInsAsPOE' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutsAsPOL' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutsAsPOD' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutsAsFD' },
      ],
    },
    {
      resourceURL: '/master/spec',
      has: [
        { resourceURL: '/agency/delivery-order-item', relation: 'deliveryOrderItems' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrderItems.deliveryOrders' },
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/depot/booking-in', relation: 'depotContainers.bookingIn' },
        { resourceURL: '/depot/booking-out-item', relation: 'bookingOutItems' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutItems.bookingOuts' },
        { resourceURL: '/depot/warning', relation: 'warnings' },
      ],
    },
    {
      resourceURL: '/master/stakeholder',
      has: [
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrdersAsContainerAuthority' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrdersAsGateOutTo' },
        { resourceURL: '/depot/booking-in', relation: 'bookingInsAsContainerAuthority' },
        { resourceURL: '/depot/booking-in', relation: 'bookingInsAsGateInFrom' },
        { resourceURL: '/depot/depot-container', relation: 'depotContainers' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutsAsContainerAuthority' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOutsAsGateOutTo' },
      ],
    },
    {
      resourceURL: '/master/transport',
      has: [
        { resourceURL: '/master/carrier', relation: 'carrier' },
        { resourceURL: '/master/point', relation: 'departurePoint' },
        { resourceURL: '/master/point', relation: 'arrivalPoint' },
        { resourceURL: '/master/point', relation: 'terminal' },
        { resourceURL: '/agency/delivery-order', relation: 'deliveryOrders' },
        { resourceURL: '/depot/booking-in', relation: 'bookingIns' },
        { resourceURL: '/depot/booking-out', relation: 'bookingOuts' },
      ],
    },
    {
      resourceURL: '/master/authority',
      has: [
        { resourceURL: '/master/port', relation: 'port' },
        { resourceURL: '/master/point', relation: 'depots' },
      ],
    },
    // EOR
    {
      resourceURL: '/master/component-code',
      has: [{ resourceURL: '/master/repair-tariff', relation: 'repairTariffs' }],
    },
    {
      resourceURL: '/master/contractor',
      has: [
        { resourceURL: '/master/repair-tariff', relation: 'repairTariffs' },
        { resourceURL: '/depot/eor', relation: 'eors' },
      ],
    },
    {
      resourceURL: '/master/damage-category-code',
      has: [],
    },
    {
      resourceURL: '/master/damage-code',
      has: [],
    },
    {
      resourceURL: '/master/damage-location-code',
      has: [],
    },
    {
      resourceURL: '/master/repair-code',
      has: [{ resourceURL: '/master/repair-tariff', relation: 'repairTariffs' }],
    },
    {
      resourceURL: '/master/division-code',
      has: [{ resourceURL: '/master/repair-tariff', relation: 'repairTariffs' }],
    },
    {
      resourceURL: '/master/repair-tariff',
      has: [
        { resourceURL: '/master/component-code', relation: 'componentCode' },
        { resourceURL: '/master/contractor', relation: 'contractor' },
        { resourceURL: '/master/activity', relation: 'activity' },
        { resourceURL: '/master/repair-code', relation: 'repairCode' },
        { resourceURL: '/master/division-code', relation: 'divisionCode' },
        { resourceURL: '/master/stakeholder', relation: 'containerAuthority' },
      ],
    },
    {
      resourceURL: '/master/surveyor',
      has: [{ resourceURL: '/depot/eor', relation: 'eors' }],
    },
    // #endregion
  ];

  /**
   * Constructor.
   */
  constructor(private _queryService: QueryService) {}

  /**
   * Get the array of query preset list of all resources.
   * @return Array of query preset list of all resources.
   */
  public get(): QueryPresetList[] {
    return [
      // #region Agency Module
      {
        resourceURL: '/agency/delivery-order',
        presets: [
          (() => ({
            name: this._queryService.DEFAULT_QADV_PRESET_NAME,
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: this.getWhereHasNodes('/agency/delivery-order'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          }))(),
          (() => {
            const qAdv = {
              name: 'Status Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/agency/delivery-order'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Status Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/agency/delivery-order'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentStatusLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Token List',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/agency/delivery-order'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', 'New'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    'between',
                    moment().subtract(1, 'days').hours(15).minutes(0).seconds(1).format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().hours(15).minutes(0).seconds(0).format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
        ],
      },
      {
        resourceURL: '/agency/stack',
        presets: [
          (() => ({
            name: this._queryService.DEFAULT_QADV_PRESET_NAME,
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: this.getWhereHasNodes('/agency/stack'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          }))(),
          (() => {
            const qAdv = {
              name: 'Status Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/agency/stack'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Status Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/agency/stack'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentStatusLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
        ],
      },
      // #endregion

      // #region Depot Module
      {
        resourceURL: '/depot/booking-in',
        presets: [
          (() => ({
            name: this._queryService.DEFAULT_QADV_PRESET_NAME,
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: this.getWhereHasNodes('/depot/booking-in'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          }))(),
          (() => {
            const qAdv = {
              name: 'Status Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Status Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentStatusLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'DEM Flat & TPC',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Full Complete | Partial Complete'),
                  NodeComponent.createWhereFieldNode('and', 'shipping_bl', '=', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode(
                'order',
                'and',
                [NodeComponent.createOrderFieldNode('shipping_bl', 'asc')],
                true
              ),
            };
            qAdv.h
              .find((h) => h.r === 'activity')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MID')]);
            qAdv.h
              .find((h) => h.r === 'transport')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'number', '=', '')]);
            qAdv.h
              .find((h) => h.r === 'depotContainers')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'received_date_in', '=', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'DEM Flat LMS & TPC',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Full Complete | Partial Complete'),
                  NodeComponent.createWhereFieldNode('and', 'shipping_bl', '=', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode(
                'order',
                'and',
                [NodeComponent.createOrderFieldNode('shipping_bl', 'asc')],
                true
              ),
            };
            qAdv.h
              .find((h) => h.r === 'activity')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MID')]);
            qAdv.h
              .find((h) => h.r === 'transport')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'number', '=', '')]);
            qAdv.h
              .find((h) => h.r === 'depotContainers')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'received_date_in', '=', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'DEM Progressive & TPC',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Full Complete | Partial Complete'),
                  NodeComponent.createWhereFieldNode('and', 'shipping_bl', '=', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode(
                'order',
                'and',
                [NodeComponent.createOrderFieldNode('shipping_bl', 'asc')],
                true
              ),
            };
            qAdv.h
              .find((h) => h.r === 'activity')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MID')]);
            qAdv.h
              .find((h) => h.r === 'transport')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'number', '=', '')]);
            qAdv.h
              .find((h) => h.r === 'depotContainers')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'received_date_in', '=', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'DEM Progressive LMS & TPC',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Full Complete | Partial Complete'),
                  NodeComponent.createWhereFieldNode('and', 'shipping_bl', '=', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/booking-in'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode(
                'order',
                'and',
                [NodeComponent.createOrderFieldNode('shipping_bl', 'asc')],
                true
              ),
            };
            qAdv.h
              .find((h) => h.r === 'activity')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MID')]);
            qAdv.h
              .find((h) => h.r === 'transport')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'number', '=', '')]);
            qAdv.h
              .find((h) => h.r === 'depotContainers')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'received_date_in', '=', '')]);
            return qAdv;
          })(),
        ],
      },
      {
        resourceURL: '/depot/booking-out',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/booking-out'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'Status Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/booking-out'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Status Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/booking-out'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentStatusLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
        ],
      },
      {
        resourceURL: '/depot/depot-container',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'Availability Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'availabilityLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Condition Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'conditionLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Availability Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentAvailabilityLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Condition Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentConditionLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'In Hand with Warning (BI | GI | BO)',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'availability', 'in', 'Book In | Gate In | Book Out')],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'warning')
              .g.push(NodeComponent.createWhereFieldNode('and', 'completed_at', 'is null', null));
            return qAdv;
          })(),
          (() => {
            return {
              name: 'In Hand Back Date',
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'in_hand_at',
                    '=',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'ca_code', 'in', 'MEL'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            return {
              name: 'In Yard At (GI | BO)',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    '<=',
                    moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createGroupNode('where', 'and', [
                    NodeComponent.createWhereFieldNode(
                      'and',
                      'gate_out_at',
                      '>',
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                    ),
                    NodeComponent.createWhereFieldNode('or', 'gate_out_at', 'is null'),
                  ]),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'In Yard At (GI Only)',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    '<=',
                    moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createGroupNode('where', 'and', [
                    NodeComponent.createWhereFieldNode(
                      'and',
                      'gate_out_at',
                      '>',
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                    ),
                    NodeComponent.createWhereFieldNode('or', 'gate_out_at', 'is null'),
                  ]),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h.find((h) => h.r === 'availabilityLogs').c = [{ o: '=', n: 0 }];
            qAdv.h
              .find((h) => h.r === 'availabilityLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', 'Book Out'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<=',
                    moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'In Yard At (BO Only)',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    '<=',
                    moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createGroupNode('where', 'and', [
                    NodeComponent.createWhereFieldNode(
                      'and',
                      'gate_out_at',
                      '>',
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                    ),
                    NodeComponent.createWhereFieldNode('or', 'gate_out_at', 'is null'),
                  ]),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'availabilityLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', 'Book Out'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<=',
                    moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Awaiting EOR AV (GI | BO | GO)',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'availability', 'in', 'Gate In | Book Out | Gate Out'),
                  NodeComponent.createWhereFieldNode('and', 'cgi', '=', 'Available'),
                  NodeComponent.createWhereFieldNode('and', 'cleaning', 'is not null'),
                  NodeComponent.createWhereFieldNode('and', 'gate_in_at', '>=', '2019-07-15 00:00:00'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'containerAuthority')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'PIL')]);
            const eors = qAdv.h.find((h) => h.r === 'eors');
            eors.c = [{ o: '=', n: 0 }];
            eors.a = true;
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Awaiting EOR DM (GI | BO)',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'availability', 'in', 'Gate In | Book Out'),
                  NodeComponent.createWhereFieldNode('and', 'cgi', '=', 'Damage'),
                  NodeComponent.createWhereFieldNode('and', 'gate_in_at', '>=', '2019-07-15 00:00:00'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'containerAuthority')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'PIL')]);
            const eors = qAdv.h.find((h) => h.r === 'eors');
            eors.c = [{ o: '=', n: 0 }];
            eors.a = true;
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Missing GI EDIFACT',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    'between',
                    moment().startOf('day').subtract(6, 'days').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            const containerAuthority = qAdv.h.find((h) => h.r === 'containerAuthority');
            const activity = qAdv.h.find((h) => h.r === 'bookingIn.activity');
            const edifacts = qAdv.h.find((h) => h.r === 'edifacts');
            const edifactJob = qAdv.h.find((h) => h.r === 'edifacts.edifactJob');
            containerAuthority.g.push(NodeComponent.createWhereFieldNode('and', 'code', 'IN', 'PIL | MEL'));
            activity.g.push(NodeComponent.createWhereFieldNode('and', 'code', 'in', 'MID | TVI | TNI | MOR'));
            edifacts.c[0].o = '=';
            edifacts.c[0].n = 0;
            edifacts.g.push(NodeComponent.createWhereFieldNode('and', 'success', '=', 'TRUE'));
            edifactJob.c[0].o = '=';
            edifactJob.c[0].n = 0;
            edifactJob.g.push(NodeComponent.createWhereFieldNode('and', 'type', 'in', 'GICODECO'));
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Missing GO EDIFACT',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_out_at',
                    'between',
                    moment().startOf('day').subtract(6, 'days').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            const containerAuthority = qAdv.h.find((h) => h.r === 'containerAuthority');
            const activity = qAdv.h.find((h) => h.r === 'bookingOutItem.bookingOut.activity');
            const edifacts = qAdv.h.find((h) => h.r === 'edifacts');
            const edifactJob = qAdv.h.find((h) => h.r === 'edifacts.edifactJob');
            containerAuthority.g.push(NodeComponent.createWhereFieldNode('and', 'code', 'IN', 'PIL | MEL'));
            activity.g.push(NodeComponent.createWhereFieldNode('and', 'code', 'in', 'MOP | TVO | TNO'));
            edifacts.c[0].o = '=';
            edifacts.c[0].n = 0;
            edifacts.g.push(NodeComponent.createWhereFieldNode('and', 'success', '=', 'TRUE'));
            edifactJob.c[0].o = '=';
            edifactJob.c[0].n = 0;
            edifactJob.g.push(NodeComponent.createWhereFieldNode('and', 'type', 'in', 'GOCODECO'));
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Cleaning List',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'cgi', 'in', 'Available'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Booking In EA',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Book In')],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'In Gate',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Out Gate',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_out_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Out Gate Order By DO',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_out_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            return {
              name: 'LOLO Storage',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'MEL'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'period',
                    'between',
                    moment().subtract(1, 'month').startOf('month').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().subtract(1, 'month').endOf('month').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createGroupNode('where', 'and', [
                    NodeComponent.createWhereFieldNode('or', 'activity_in_code', 'in', 'TVI | TNI'),
                    NodeComponent.createWhereFieldNode('or', 'activity_out_code', 'in', 'TVO | TNO'),
                  ]),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'Stock List',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Gate In')],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            return {
              name: 'Stock Summary CC',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Gate In'),
                  NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'PIL'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            return {
              name: 'Stock Summary EA',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'PIL')],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'Condition',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Gate In')],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            return {
              name: 'Movement',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'period',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'PIL'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            return {
              name: 'Monthly Lifting',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'period',
                    'between',
                    moment().startOf('year').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'PIL'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            return {
              name: 'Otoritas Pelabuhan',
              report: true,
              mode: Mode.Custom,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'period',
                    'between',
                    moment().startOf('year').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'ca_code', '=', 'PIL'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'TMFD SRFD',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Gate In'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'EOR Waiting List',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'availability', '=', 'Gate In'),
                  NodeComponent.createWhereFieldNode('and', 'condition', '=', 'Damage'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/depot-container'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            return qAdv;
          })(),
        ],
      },
      {
        resourceURL: '/depot/edifact',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/edifact'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/depot/edifact-job',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/edifact-job'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/depot/eor',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            const qAdv = {
              name: 'Status Log',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Status Aging',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'currentStatusLog')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    '<',
                    moment().subtract(6, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Cost Threshold',
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'surveyed_total_cost', '<', 5)],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'cgi', 'in', 'Damage | Available'),
                  NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', ''),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'List',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Daily Publish',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Original | Surveyed | Approved'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'cgi', 'in', 'Damage')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Daily Publish Depo',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR')],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'gate_in_at',
                    '<=',
                    moment().subtract(2, 'days').endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                  NodeComponent.createWhereFieldNode('and', 'cgi', 'in', 'Damage'),
                ]
              );
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', 'in', 'Original | Surveyed | Approved | Completed'),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    'between',
                    moment().subtract(1, 'days').startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().subtract(1, 'days').endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'TPC',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_tpc_cost_idr', '>', 0),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Approved | Completed | WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'TPC IDR',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_tpc_cost_idr', '>', 0),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Approved | Completed | WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'TPC with Waiver',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_tpc_cost', '>', 0),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Approved | Completed | WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'TPC with Waiver IDR',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_tpc_cost', '>', 0),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'Approved | Completed | WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Surveyor',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'surveyor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'USI')]);
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Repair Bill',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'WOD'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_total_cost_idr', '>', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            qAdv.h
              .find((h) => h.r === 'eorItems')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'type', '=', 'Repair')]);
            qAdv.h
              .find((h) => h.r === 'eorItems.contractor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MCP')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Repair Bill IDR',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'WOD'),
                  NodeComponent.createWhereFieldNode('and', 'surveyed_total_cost_idr', '>', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            qAdv.h
              .find((h) => h.r === 'eorItems')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'type', '=', 'Repair')]);
            qAdv.h
              .find((h) => h.r === 'eorItems.contractor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MCP')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'PTI Bill',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            qAdv.h
              .find((h) => h.r === 'eorItems')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'type', '=', 'PTI')]);
            qAdv.h
              .find((h) => h.r === 'eorItems.contractor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'PPC')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Cleaning Bill',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', 'WOD'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            qAdv.h
              .find((h) => h.r === 'eorItems')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'type', '=', 'Cleaning')]);
            qAdv.h
              .find((h) => h.r === 'eorItems.contractor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'JPC')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'Costs',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', 'in', ''),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'depotContainer')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'gate_in_at', 'between', '')]);
            return qAdv;
          })(),
          (() => {
            const qAdv = {
              name: 'On Site Repair',
              report: true,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'currency', '=', 'IDR'),
                  NodeComponent.createWhereFieldNode('and', 'status', '=', 'Completed'),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/eor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
            qAdv.h
              .find((h) => h.r === 'statusLogs')
              .g.push(
                ...[
                  NodeComponent.createWhereFieldNode('and', 'to', '=', 'Completed'),
                  NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
                  NodeComponent.createWhereFieldNode(
                    'and',
                    'event_at',
                    'between',
                    moment().startOf('day').format(GlobalService.DATETIME_FORMAT) +
                      ' | ' +
                      moment().endOf('day').format(GlobalService.DATETIME_FORMAT)
                  ),
                ]
              );
            qAdv.h
              .find((h) => h.r === 'eorItems')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'type', '=', 'Repair')]);
            qAdv.h
              .find((h) => h.r === 'eorItems.contractor')
              .g.push(...[NodeComponent.createWhereFieldNode('and', 'code', '=', 'MCP')]);
            return qAdv;
          })(),
        ],
      },
      {
        resourceURL: '/depot/warning',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/depot/warning'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
          (() => {
            return {
              name: 'Active',
              mode: Mode.Builder,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode(
                'where',
                'and',
                [
                  NodeComponent.createWhereFieldNode('and', 'completed_at', 'is null'),
                  NodeComponent.createGroupNode('where', 'and', [
                    NodeComponent.createWhereFieldNode(
                      'or',
                      'expired_at',
                      '>',
                      moment().startOf('day').format(GlobalService.DATETIME_FORMAT)
                    ),
                    NodeComponent.createWhereFieldNode('or', 'affected', '>', 0),
                  ]),
                ],
                true
              ),
              h: this.getWhereHasNodes('/depot/warning'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      // #endregion

      // #region Master Module
      // Main
      {
        resourceURL: '/master/activity',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/activity'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/carrier',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/carrier'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/point',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/point'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/port',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/port'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/spec',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/spec'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/stakeholder',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/stakeholder'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/transport',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/transport'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/authority',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/authority'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      // EOR
      {
        resourceURL: '/master/component-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/component-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/contractor',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/contractor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/damage-category-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/damage-category-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/damage-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/damage-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/damage-location-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/damage-location-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/division-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/division-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/repair-code',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/repair-code'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/repair-tariff',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/repair-tariff'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      {
        resourceURL: '/master/surveyor',
        presets: [
          (() => {
            return {
              name: this._queryService.DEFAULT_QADV_PRESET_NAME,
              s: NodeComponent.createGroupNode('select', 'and', [], true),
              w: NodeComponent.createGroupNode('where', 'and', [], true),
              h: this.getWhereHasNodes('/master/surveyor'),
              j: NodeComponent.createGroupNode('join', 'and', [], true),
              o: NodeComponent.createGroupNode('order', 'and', [], true),
            };
          })(),
        ],
      },
      // #endregion
    ];
  }

  /**
   * Get all where hases for specified resourceURL.
   * @param resourceURL Resource URL to get where hases.
   * @return Where hases for specified resource URL.
   */
  public getWhereHases(resourceURL: string): { resourceURL: string; relation: string }[] {
    const whereHas = this._whereHases.find((item) => item.resourceURL === resourceURL);
    return whereHas
      ? whereHas.has.sort((a, b) => {
          if (a.relation < b.relation) {
            return -1;
          }
          if (a.relation > b.relation) {
            return 1;
          }
          return 0;
        })
      : [];
  }

  /**
   * Get all where has nodes for specified resourceURL.
   * @param resourceURL Resource URL to get where has nodes.
   * @return Where has nodes for specified resource URL.
   */
  public getWhereHasNodes(resourceURL: string): WhereHasGroupNode<WhereFieldNode>[] {
    return this.getWhereHases(resourceURL).map((item) => {
      return NodeComponent.createWhereHasGroupNode(item.relation, item.resourceURL);
    });
  }
}
