import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { clone } from 'lodash-es';
import {
  animationFrameScheduler,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import {
  concatMap,
  delay,
  filter,
  finalize,
  map,
  scan,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Directive({
  selector: '[appDraggable]',
  exportAs: 'appDraggable',
})
export class DraggableDirective implements AfterViewInit, OnDestroy {
  /**
   * Enable or disable the drag.
   * Programmatic drag() and dragTo() won't be affected by this flag.
   */
  @Input() dragEnabled: boolean = true;

  /**
   * The element to be dragged's selector.
   */
  @Input() dragTarget: string;

  /**
   * Target has been dragged event, emmiting current offset position.
   */
  @Output() dragChange: EventEmitter<{ x: number; y: number }> = new EventEmitter();

  /**
   * The offset coordinate between the original and the after drag.
   */
  public get offset(): { x: number; y: number } {
    return this._offset;
  }

  /**
   * The draggable is being translated.
   */
  public get translating(): boolean {
    return this._translating;
  }

  /**
   * The drag target element.
   */
  private _target: HTMLElement;

  /**
   * The delta coordinate between mousedown event and mouseup event.
   */
  private _delta = { x: 0, y: 0 };

  /**
   * The offset coordinate between the original and the after drag.
   */
  private _offset = { x: 0, y: 0 };

  /**
   * Translate subscription.
   */
  private _translationSub: Subscription;

  /**
   * Still tranlating flag.
   */
  private _translating: boolean;

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(private _elementRef: ElementRef, private _ngZone: NgZone) {}

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit(): void {
    const targets = document.querySelectorAll(this.dragTarget);
    this._target = (targets.item(targets.length - 1) as HTMLElement) || this._elementRef.nativeElement;

    // Setup events.
    this._ngZone.runOutsideAngular(() => {
      this._subscribeMouseDown();
      this._subscribeMouseUp();

      this._subscribeTouchStart();
      this._subscribeTouchEnd();
    });
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy(): void {
    this._destroySignal.next();
    this.dragChange.complete();
  }

  /**
   * Drag to a target point programmaticaly.
   * @param targetOffsetX Target offset X point.
   * @param targetOffsetY Target offset Y point.
   * @param handleX Drag handle X.
   * @param handleY Drag handle Y.
   * @param rate Drag rate.
   */
  public dragTo(
    targetOffsetX: number,
    targetOffsetY: number,
    handleX: number = null,
    handleY: number = null,
    rate: number = 1
  ): Observable<{ x: number; y: number }> {
    return this._translate(
      { x: handleX || this._offset.x, y: handleY || this._offset.y },
      rate === 1
        ? of({ x: targetOffsetX, y: targetOffsetY })
        : of({ x: this._offset.x, y: this._offset.y }, { x: targetOffsetX, y: targetOffsetY }).pipe(
            concatMap((offset) => of(offset).pipe(delay(50)))
          ),
      rate
    ).pipe(
      // Anticipating bugs that will make the translate observable never complete.
      take(50),
      takeWhile(
        (currentOffset) => {
          const unreached =
            Math.abs(Math.round(currentOffset.x)) !== Math.abs(Math.round(targetOffsetX)) ||
            Math.abs(Math.round(currentOffset.y)) !== Math.abs(Math.round(targetOffsetY));

          // console.log(`take while current => x: ${currentOffset.x} y: ${currentOffset.y}`);
          // console.log(`take while target => x: ${targetOffsetX} y: ${targetOffsetY}`);

          if (!unreached) {
            // We are setting translating flag early here instead of waiting the translate's finalize.
            // This allow us to perform another translate operation on the next observable chain.
            // Otherwise the translate will throw error because right now it's still translating.
            this._translating = false;
          }

          return unreached;
        },
        // We use inclusive takeWhile that allow "reached" current offset to
        // be emitted instead of completing the observable right away.
        true
      ),
      finalize(() => {
        // console.log('drag to complete', this._offset);
      })
    );
  }

  /**
   * Drag from current offset at delta distance programmaticaly.
   * @param deltaX Delta X.
   * @param deltaY Delta Y.
   * @param handleX Drag handle X.
   * @param handleY Drag handle Y.
   * @param rate Drag rate.
   */
  public drag(
    deltaX: number,
    deltaY: number,
    handleX: number = null,
    handleY: number = null,
    rate: number = 1
  ): Observable<{ x: number; y: number }> {
    return this.dragTo(this._offset.x + deltaX, this._offset.y + deltaY, handleX, handleY, rate);
  }

  /**
   * Translate the target element.
   * @param handle The translate handle point.
   * @param targets Observable of translate target offsets.
   * @param rate LERP rate.
   * @return Observable of LERP translated offsets.
   */
  private _translate(
    handle: { x: number; y: number },
    targets: Observable<{ x: number; y: number }>,
    rate: number = null
  ): Observable<{ x: number; y: number }> {
    // Prevent another subscription when disabled.
    if (!this.dragEnabled) {
      return throwError('Draggable is disabled.');
    }

    // Prevent another subscription when still translating.
    if (this._translating) {
      return throwError('Draggable is still translating');
    }

    // Translating at the speed of animation frame.
    return interval(0, animationFrameScheduler).pipe(
      withLatestFrom(targets, (tick, target) => target),
      // When disabled, stop translating momentarily instead of completing
      // or throwing error, so we're still translating when disabled.
      filter((target) => this.dragEnabled),
      tap((target) => (this._translating = true)),
      scan((current, next) => this._lerp(current, next, rate)),
      map((lerpStep) => {
        this._delta = { x: lerpStep.x - handle.x, y: lerpStep.y - handle.y };
        return this._delta;
      }),
      tap((delta) => {
        this._target.style.transform = `
          translate(${this._offset.x + this._delta.x}px, ${this._offset.y + this._delta.y}px)
          `;
      }),
      map((delta) => {
        return { x: this._offset.x + this._delta.x, y: this._offset.y + this._delta.y };
      }),
      finalize(() => {
        this._offset.x += this._delta.x;
        this._offset.y += this._delta.y;
        this._delta = { x: 0, y: 0 };
        this._translating = false;
        this.dragChange.next(clone(this._offset));
        // console.log('translate complete', this._offset);
      })
    );
  }

  /**
   * Linear interpolation function to smoothen motion.
   * @param start Start point.
   * @param end End point.
   * @param rate LERP rate.
   */
  private _lerp(start: { x: number; y: number }, end: { x: number; y: number }, rate: number = null) {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    rate = rate || 0.4;

    return {
      x: start.x + dx * rate,
      y: start.y + dy * rate,
    };
  }

  /**
   * Subscribe to mouse down event.
   */
  private _subscribeMouseDown() {
    fromEvent(this._elementRef.nativeElement, 'mousedown')
      .pipe(
        takeUntil(this._destroySignal),
        tap((event: MouseEvent) => this._onDown(event, event.clientX, event.clientY))
      )
      .subscribe();
  }

  /**
   * Subscribe to mouse up event.
   */
  private _subscribeMouseUp() {
    fromEvent(this._elementRef.nativeElement, 'mouseup')
      .pipe(
        takeUntil(this._destroySignal),
        tap(() => this._onUp())
      )
      .subscribe();
  }

  /**
   * Subscribe to touch start event.
   */
  private _subscribeTouchStart() {
    fromEvent(this._elementRef.nativeElement, 'touchstart')
      .pipe(
        takeUntil(this._destroySignal),
        tap((event: TouchEvent) => this._onDown(event, event.targetTouches[0].clientX, event.targetTouches[0].clientY))
      )
      .subscribe();
  }

  /**
   * Subscribe to touch end event.
   */
  private _subscribeTouchEnd() {
    fromEvent(this._elementRef.nativeElement, 'touchend')
      .pipe(
        takeUntil(this._destroySignal),
        tap(() => this._onUp())
      )
      .subscribe();
  }

  /**
   * Handle down event.
   * @param event The mouse or touch event.
   * @param clientX The X point on down event.
   * @param clientY The Y point on down event.
   */
  private _onDown(event: MouseEvent | TouchEvent, clientX: number, clientY: number): void {
    // Prevent Firefox's default IMG "copy to pc" dragging behavior and also prevent browser native
    // touch action of "reload page" on touch device except for BUTTON or element with data-drag-role
    // BUTTON so that any buttons nested inside the draggable will not prevented and clickable.
    const target: HTMLElement = <HTMLElement>event.target;
    if (
      target.tagName == 'IMG' ||
      ('ontouchstart' in window &&
        event instanceof TouchEvent &&
        target.tagName != 'BUTTON' &&
        target.dataset.dragRole != 'BUTTON')
    ) {
      event.preventDefault();
    }

    // Subsribe to tranlate observable only for one touch.
    if (
      // Check if TouchEvent exists because it's undefined in Firefox and will cause error.
      'ontouchstart' in window &&
      event instanceof TouchEvent &&
      event.targetTouches.length > 1
    ) {
      this._stop();
    } else {
      const move = merge(
        fromEvent(window, 'mousemove').pipe(map((event: MouseEvent) => ({ x: event.clientX, y: event.clientY }))),
        fromEvent(window, 'touchmove').pipe(
          map((event: TouchEvent) => {
            event.preventDefault();
            return { x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY };
          })
        )
      );

      this._translationSub = this._translate({ x: clientX, y: clientY }, move, 1).subscribe();
    }
  }

  /**
   * Handle up event.
   */
  private _onUp(): void {
    this._stop();
  }

  /**
   * Stop dragging by unsubscribing.
   */
  private _stop() {
    if (this._translationSub && !this._translationSub.closed) {
      this._translationSub.unsubscribe();
      // console.log('unsubscribing', this._offset);
    }
  }
}
