import { Component, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Moment } from 'moment-mini';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

import { HelperService } from '../services/helper.service';
import { FormControlBase } from '../utils/form-control/form-control-base';

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatetimepickerComponent,
      multi: true,
    },
  ],
})
export class DatetimepickerComponent extends FormControlBase<Moment> {
  @Input()
  public label: string;

  @Input()
  public disabled: boolean;

  @Input()
  public validationMessages: string[];

  @HostBinding('class.app-datetimepicker')
  public addBaseCssClass = true;

  public dateMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];
  public datePipe = createAutoCorrectedDatePipe('yyyy-mm-dd');
  public timePipe = createAutoCorrectedDatePipe('HH:MM:SS');

  /**
   * Constructor.
   */
  constructor(private _helperService: HelperService) {
    // Datetimepicker doesn't support validation, so we pass null to parent constructor.
    super(null, null);
  }

  /**
   * On model change handler.
   * @param newValue New value.
   */
  public onModelChange(newValue: any) {
    this.value = this._helperService.changeDateTime(this.value, newValue);
  }
}
