<app-base-dialog #dialog [showHeader]="false">
  <div top-toolbar></div>

  <div content>
    <h2>Choose Date Range</h2>

    <div fxLayout="column">
      <app-datetimepicker [(ngModel)]="from" label="From" fxFlex></app-datetimepicker>
      <app-datetimepicker
        [(ngModel)]="until"
        (ngModelChange)="onUntilModelChange($event)"
        label="Until"
        fxFlex
      ></app-datetimepicker>
    </div>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
