import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatChip, MatChipEvent, MatChipInput, MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-mini';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { Option } from '../../interfaces/option.interface';
import { HelperService } from '../../services/helper.service';
import { HttpRestService } from '../../services/http-rest.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { PromptDialogType } from './prompt-dialog-type.enum';

/**
 * Prompt dialog component.
 */
@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent implements DoCheck, AfterViewInit, AfterViewChecked {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Chip list.
   */
  @ViewChild('chipList', { read: MatChipList })
  private _chipList: MatChipList;

  /**
   * Chip list input.
   */
  @ViewChild('chipListInput', { read: MatChipInput })
  private _chipListInput: MatChipInput;

  /**
   * Chip editor's element ref.
   */
  @ViewChild('chipEditor', { read: ElementRef })
  private _chipEditor: ElementRef;

  /**
   * Autocomplete input' element ref.
   */
  @ViewChild('autocompleteInput')
  private _autocompleteInput: ElementRef;

  /**
   * Prompt title.
   */
  public title: string;

  /**
   * Prompt content.
   */
  public contents: string[];

  /**
   * OK button label.
   */
  public okLabel: string;

  /**
   * Cancel button label.
   */
  public cancelLabel: string;

  /**
   * Prompt input's value.
   */
  public inputValue: any;

  /**
   * PromptDialogType exposed to the template.
   */
  public readonly PromptDialogType = PromptDialogType;

  /**
   * Prompt dialog type.
   */
  public promptDialogType: PromptDialogType;

  /**
   * Prompt select's options.
   */
  public options: Option[];

  /**
   * Chip list's separator key codes to commit a chip.
   */
  public chipSeparatorKeyCodes: number[] = [ENTER, COMMA];

  /**
   * Selected chip index.
   */
  public chipSelectedIndex: number;

  /**
   * Flag indicating that underlying input array has changed and we need to resubscribe on next AfterViewChecked.
   */
  public inputArrayChanged: boolean;

  /**
   * Autocomplete's value items.
   */
  public autocompleteValueItems: Observable<Option[]>;

  /**
   * User input sanitizer function.
   */
  public sanitize: (inputValue: any) => any;

  /**
   * User input validation function.
   */
  public validate: (inputValue: any) => boolean;

  /**
   * Datetime mask.
   */
  public dateMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];
  public datePipe = createAutoCorrectedDatePipe('yyyy-mm-dd');
  public timePipe = createAutoCorrectedDatePipe('HH:MM:SS');

  /**
   * MatChipList's chipFocusChanges subscription.
   */
  private _chipFocusChangesSubscription: Subscription;

  /**
   * Autocomplete's params.
   */
  private _autocompleteParams: string[];

  /**
   * Autocomplete's resourceURL param.
   */
  private _autocompleteResourceURL: string;

  /**
   * Autocomplete's search field param.
   */
  private _autoCompleteSearchField: string;

  /**
   * Autocomplete's all selectable field param including the search field.
   */
  private _autocompleteSelectFields: string[];

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<PromptDialogComponent>,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService
  ) {
    this.title = data.title;
    this.contents = data.contents;
    this.options = data.options ? data.options : [];
    this.inputValue = data.inputValue ? data.inputValue : '';
    this.promptDialogType = data.promptDialogType ? data.promptDialogType : PromptDialogType.Text;
    this.sanitize = data.sanitize;
    this.validate = data.validate;
    this.okLabel = data.okLabel ? data.okLabel : 'OK';
    this.cancelLabel = data.cancelLabel ? data.cancelLabel : 'Cancel';

    if (this.promptDialogType === PromptDialogType.Array) {
      // Init the input array with empty array if it's null initially.
      if (!this.inputValue) {
        this.inputValue = [];
      }

      // Convert the source array to null if it's empty on dialog closed.
      this._dialogRef
        .afterClosed()
        .pipe(
          first(),
          tap(() => {
            if (this.inputValue.length === 0) {
              this.inputValue = null;
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngDoCheck() {
    if (this.promptDialogType === PromptDialogType.Array) {
      // Deselect all chips on input focus.
      if (this._chipListInput && this._chipListInput.focused) {
        this._chipDeselectAll();
      }
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit(): void {
    if (this.promptDialogType === PromptDialogType.Array) {
      this._inputArrayMarkChanged();
    }

    if (this.promptDialogType.startsWith(PromptDialogType.Autocomplete)) {
      // Parse autocomplete parameters.
      this._autocompleteParams = this.promptDialogType.split(':');
      this._autocompleteResourceURL = this._autocompleteParams[1];
      this._autoCompleteSearchField = this._autocompleteParams[2];
      this._autocompleteSelectFields = [
        this._autoCompleteSearchField,
        ...(this._autocompleteParams[3] ? this._autocompleteParams[3].split(',') : []),
      ];

      // Initiate autocomplete's value items observable.
      this.autocompleteValueItems = this._helperService.getAutocompleteItems(
        this._autocompleteInput.nativeElement,
        (keyword) => this._autocompleteGetValueItems(keyword),
        1
      );
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewChecked() {
    if (this.promptDialogType === PromptDialogType.Array) {
      // Focus on chip editor as soon as it is available.
      if (this._chipEditor && document.activeElement !== this._chipEditor.nativeElement) {
        this._chipEditor.nativeElement.focus();
        this._chipEditor.nativeElement.select();
      }

      // Perform resubscription of various combined stream of all of the child chips' events.
      if (this.inputArrayChanged) {
        this._chipSubscribeChipFocusChanges();
        this.inputArrayChanged = false;
      }
    }
  }

  /**
   * On typeDateTime's input model change handler.
   * @param newValue New value.
   */
  public onTypeDateTimeInputModelChange(newValue: moment.Moment | string) {
    this.inputValue = this._helperService.changeDateTime(this.inputValue, newValue);
  }

  /**
   * Keydown event handlers for chip editor.
   * @param chip Edited MatChip.
   * @param index Edited MatChip's index.
   * @param event KeyboardEvent of MatChip's input text editor.
   */
  public onChipEditorKeydown(chip: MatChip, index: number, event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.chipEndEdit(chip, index);
        break;

      case 'Escape':
        this.chipEndEdit(chip, -1);
        break;

      default:
        break;
    }

    event.stopPropagation();
  }

  /**
   * Start editing the provided MatChip.
   * @param chip Edited MatChip.
   * @param index Edited MatChip's index.
   */
  public chipStartEdit(chip: MatChip, index: number) {
    (chip as any).edit = true;
  }

  /**
   * End editing the provided MatChip.
   * @param chip Edited MatChip.
   * @param index Edited MatChip's index.
   */
  public chipEndEdit(chip: MatChip, index: number) {
    if (index >= 0) {
      const newValue = ((this._chipEditor && this._chipEditor.nativeElement.value) || '').trim();
      const oldValue = this.inputValue[index];

      if (newValue !== oldValue) {
        this.inputValue[index] = newValue;
        this._inputArrayMarkChanged();
      }
    }

    (chip as any).edit = false;

    if (index >= 0) {
      // Focus to newly added chip at this editing index.
      this._chipFocusAt(index);
    } else {
      // Edit has been canceled, focus to currently selected chip.
      chip.focus();
    }
  }

  /**
   * Add new chip on token end triggered by separator key code.
   * @param event MatChipInputEvent
   */
  public chipAdd(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.inputValue.push(value.trim());
      this._inputArrayMarkChanged();
    }

    input.value = '';
  }

  /**
   * Remove selected chip.
   * @param event MatChipEvent
   */
  public chipRemove(event: MatChipEvent) {
    const index = this._chipList.chips.toArray().indexOf(event.chip);
    this.inputValue.splice(index, 1);

    // No need to call this._inputArrayMarkChanged() because no new MatChip will be added during item removal.
    // this._inputArrayMarkChanged();

    // We need to call this._focusChipAt(index) because MatChipList will try to
    // re-focus based on value, so if there're duplicate values, the focus will
    // jump to the next chip with same value instead of to the current index.
    this._chipFocusAt(index);
  }

  /**
   * Autocomplete display renderer.
   * @param item Item to be rendered.
   * @return Rendered item.
   */
  public autocompleteValueDisplayWith(item: any): string {
    return item ? this._autocompleteSelectFields.map((field) => item[field]).join(' - ') : '';
  }

  /**
   * OK action.
   */
  public ok(): void {
    if (this.sanitize) {
      this.inputValue = this.sanitize(this.inputValue);

      if (this.promptDialogType === PromptDialogType.Array) {
        this._inputArrayMarkChanged();
      }
    }

    if (this.validate ? this.validate(this.inputValue) !== false : true) {
      this._dialogRef.close(this.inputValue);
    }
  }

  /**
   * Cancel action.
   */
  public cancel(): void {
    this._dialogRef.close();
  }

  /**
   * Mark that underlying array has been changed and new chip has been added
   * to the chip list so we need to resubscribe on next AfterViewChecked.
   *
   * This is because under the hood MatChipList's observables are combined
   * stream of all "existing" child chips' event using merge() operator.
   *
   * So if an item is changed or added to the underlying array, new MatChip
   * will be created by ngFor and added to MatChipList but this new MatChip
   * event stream will not get merge() unless we perform resubscription.
   */
  private _inputArrayMarkChanged() {
    this.inputArrayChanged = true;
  }

  /**
   * Subscribe MatChipList.chipFocusChanges so that focused chip will also get selected.
   */
  private _chipSubscribeChipFocusChanges() {
    // Unsubscribe from "previous set of chips" observable.
    if (this._chipFocusChangesSubscription) {
      this._chipFocusChangesSubscription.unsubscribe();
    }

    this._chipFocusChangesSubscription = this._chipList.chipFocusChanges.subscribe((event: MatChipEvent) => {
      // Use setTimeout to avoid changing this.selectedIndex during chip removal,
      // otherwise ExpressionChangedAfterItHasBeenCheckedError will be thrown.
      // It's because MatChipList will automaticaly change focus on removal.
      setTimeout(() => {
        event.chip.selected = true;
        this.chipSelectedIndex = this._chipList.chips.toArray().indexOf(event.chip);
      });
    });
  }

  /**
   * Focus chip at provided index. Focus will subsequently select the chip on chipFocusChanges.
   * @param index Chip index to focus on.
   */
  private _chipFocusAt(index: number) {
    // Use setTimeout to wait for MatChipList's chips property to get refreshed on next AfterViewChecked.
    setTimeout(() => {
      const chip = this._chipList.chips.toArray()[Math.max(index, 0)];
      if (chip) {
        chip.focus();
      }
    });
  }

  /**
   * Deselect all chips.
   */
  private _chipDeselectAll() {
    this._chipList.chips.forEach((chip) => (chip.selected = false));
    this.chipSelectedIndex = null;
  }

  /**
   * Get autocomplete's items.
   * @return Observable of items.
   */
  private _autocompleteGetValueItems(keyword: string): Observable<any[]> {
    const value = '%' + keyword.split('|').pop().trim() + '%';
    const qAdv = {
      s: this._autocompleteSelectFields.map((field) => ({ f: field })),
      w: [{ b: 'and', f: this._autoCompleteSearchField, o: 'like', v: value }],
      o: [{ f: this._autoCompleteSearchField, o: 'asc' }],
    };

    return this._httpRestService
      .get(this._autocompleteResourceURL, {
        params: { q: JSON.stringify({ quick: null, adv: qAdv }), i: '1', skip: '0', take: '50' },
      })
      .pipe(
        map((result) => <any>{ data: result.data.map((item) => item) }),
        catchError((error) => of([]))
      );
  }
}
