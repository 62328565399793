import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

/**
 * Processing dialog component.
 */
@Component({
  selector: 'app-processing-dialog',
  templateUrl: './processing-dialog.component.html',
  styleUrls: ['./processing-dialog.component.scss'],
})
export class ProcessingDialogComponent {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Processing content text.
   */
  public content: string;

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.content = data.content ? data.content : 'Processing';
  }
}
