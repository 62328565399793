import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community/main';
import { Observable } from 'rxjs';

import { DataListComponent } from '../../data-list/data-list.component';
import { BaseDialogComponent } from '../../dialog/base-dialog/base-dialog.component';

/**
 * Pop up data list dialog component.
 */
@Component({
  selector: 'app-popup-data-list',
  templateUrl: './popup-data-list.component.html',
  styleUrls: ['./popup-data-list.component.scss'],
})
export class PopupDataListComponent implements OnInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Data list Component.
   */
  @ViewChild('dataList')
  public dataList: DataListComponent;

  /**
   * Resource URL to load the data. Prefix it with '/'.
   */
  public resourceURL = '';

  /**
   * Data list's gridOptions.
   */
  public gridOptions: GridOptions;

  /**
   * Parent CRUD's destroy signal observable.
   */
  private _crudDestroySignal: Observable<any>;

  /**
   * Data List height adapting with dialog's height resizing.
   * wrapper - header - (divider) * 2 - actionBar
   */
  public get dataListHeight() {
    return this.dialog.height ? `${+this.dialog.height.split('px')[0] - 64 - 1 * 2 - 52}px` : '480px';
  }

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<PopupDataListComponent>) {
    this.resourceURL = data.resourceURL;
    this.gridOptions = data.gridOptions;
    this._crudDestroySignal = data.destroySignal;
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this._crudDestroySignal.subscribe(() => this.close());
    this._dialogRef.afterOpened().subscribe(() => this.dataList.refresh().subscribe());
  }

  /**
   * Close this dialog.
   */
  public close(): void {
    this._dialogRef.close(this.gridOptions.api.getSelectedRows());
  }
}
