import { AbstractControl, AsyncValidatorFn, Validator, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * An object containing a property and a message (or a boolean, for Angular-supplied validators) for each validator that is failing.
 */
export type ValidationResult = { [validator: string]: string | boolean };

/**
 * Validator array.
 */
export type ValidatorArray = Array<Validator | ValidatorFn>;

/**
 * Async validator array.
 */
export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;

/**
 * Normalize validator into validatorFn.
 * @param validator Validator or ValidatorFn to be normalized.
 * @return Normalized validator (as validator function).
 */
const normalizeValidator = (validator: Validator | ValidatorFn): any => {
  const func = (validator as Validator).validate.bind(validator);
  if (typeof func === 'function') {
    return (c: AbstractControl) => func(c);
  } else {
    return <ValidatorFn | AsyncValidatorFn>validator;
  }
};

/**
 * Composed array of validators into single validatorFn.
 * @param validators Array of validators to be composed.
 * @return If validators is empty returns null, otherwise returns composed validators.
 */
const composeValidators = (validators: ValidatorArray): ValidatorFn => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.compose(validators.map(normalizeValidator));
};

/**
 * Composed array of async validators into single async validatorFn.
 * @param validators Array of validators to be composed.
 * @return If validators is empty returns null, otherwise returns composed validators.
 */
const composeAsnycValidators = (validators: AsyncValidatorArray): AsyncValidatorFn => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.composeAsync(validators.map(normalizeValidator));
};

/**
 * Validate underlying model value using either sync or async validators and return result asynchronously.
 * @param control AbstractControl of this form control.
 * @param validators Validator array.
 * @param asyncValidators AsyncValidator array.
 * @return Observable of ValidationResult.
 */
export const validate = (
  control: AbstractControl,
  validators: ValidatorArray,
  asyncValidators: AsyncValidatorArray
): Observable<ValidationResult> => {
  // Composing validators.
  const validator = composeValidators(validators);
  const asyncValidator = composeAsnycValidators(asyncValidators);

  // Async validators available.
  if (asyncValidator) {
    return (<Observable<any>>asyncValidator(control)).pipe(
      map((asyncResult) => {
        const syncResult = validator ? validator(control) : {};

        if (syncResult || asyncResult) {
          // compose async and sync validator results.
          return Object.assign({}, syncResult, asyncResult);
        }
      })
    );
  }

  // Async validators not available, return sync validators result asynchronously.
  if (validator) {
    return of(validator(control));
  }

  // Validators not available, return null asynchronously.
  return of(null);
};

/**
 * Get validation error message.
 * @param validator Validation result.
 * @param key Validation key.
 * @return String of error message.
 */
export const getErrorMessage = (validationResult: ValidationResult, key: string): string => {
  // Angular's built in validators.
  switch (key) {
    case 'required':
      return 'Please enter a value';
    case 'pattern':
      return 'Value does not match required pattern';
    case 'minlength':
      return 'Value must be N characters';
    case 'maxlength':
      return 'Value must be a maximum of N characters';
  }

  switch (typeof validationResult[key]) {
    case 'string':
      // If custom validator's failure message is available.
      return <string>validationResult[key];
    default:
      return `Validation failed: ${key}`;
  }
};
