import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actorPath',
})
export class ActorPathPipe implements PipeTransform {
  private _actorBasePath = {
    delivery_orders: '/agency/delivery-order',
    stacks: '/agency/stack',
    booking_ins: '/depot/booking-in',
    booking_outs: '/depot/booking-out',
    depot_containers: '/depot/depot-container',
    eors: '/depot/eor',
  };

  transform(value: any, ...args: unknown[]): string {
    return value.actor_type && value.actor_id ? `${this._actorBasePath[value.actor_type]}/${value.actor_id}` : '';
  }
}
