import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JwtService } from '../core/auth/jwt.service';
import { GlobalService } from '../core/services/global.service';
import { UserProfileService } from '../core/user-profile/user-profile.service';
import { HttpRestService } from '../shared/services/http-rest.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  /**
   * Quote
   */
  public quote: { quote: string; source: string };

  /**
   * Greeting.
   */
  public greeting: string;

  /**
   * Currently logged in user is a guest.
   */
  public guest: boolean;

  /**
   * Constructor.
   */
  constructor(
    private _globalService: GlobalService,
    private _httpRestService: HttpRestService,
    private _jwtService: JwtService,
    private _router: Router,
    private _userProfileService: UserProfileService
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    if (this._jwtService.isAuthenticated) {
      // Already authenticated, initialize right away.
      this._initialize();
    } else {
      // Not yet authenticated, subscribe to authenticated event emitter.
      this._jwtService.authenticated.subscribe(() => this._initialize());
    }
  }

  /**
   * Check if card should be shown based on menu permission.
   */
  public allowAccessMenu(permission: string) {
    return this._userProfileService.allowAccessMenu(permission);
  }

  /**
   * Open module's dashboard.
   * @param module Module name.
   */
  public openDashboard(module: string) {
    switch (module) {
      case 'agency':
        this._router.navigate(['/agency/agency-dashboard']);
        break;

      case 'depot':
        this._router.navigate(['/depot/depot-dashboard']);
        break;

      default:
        break;
    }
  }

  /**
   * Open vgm-vermas component.
   */
  public openVgmVermas() {
    this._router.navigate(['/public-access/vgm-vermas']);
  }

  /**
   * Open stack counter component.
   */
  public openStackCounter() {
    this._router.navigate(['/public-access/stack-counter']);
  }

  /**
   * Initialize local variables.
   */
  private _initialize(): void {
    // Set guest flag.
    this.guest = this._userProfileService.guest;

    // Initialize user name.
    this.greeting = `Hello, ${this._globalService.userProfile.name}!`;

    // Initialize quote.
    this._httpRestService.get('/quote', {}, false, true).subscribe((quote) => (this.quote = quote));
  }
}
