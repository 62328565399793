import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationRef, Component, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import * as Chart from 'chart.js';
import { from, fromEvent, merge, Observable, of, timer } from 'rxjs';
import { catchError, delay, exhaustMap, first, map, mergeMap, tap, timeout } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AboutComponent } from './core/about/about.component';
import { JwtService } from './core/auth/jwt.service';
import { BREADCRUMBS_HTML } from './core/breadcrumbs/breadcrumbs-html.token';
import { Menu1 } from './core/menu/menu.interface';
import { MenuService } from './core/menu/menu.service';
import { ColumnDefPresetService } from './core/presets/column-def-preset.service';
import { MenuPresetService } from './core/presets/menu-preset.service';
import { QueryPresetService } from './core/presets/query-preset.service';
import { ReportPresetService } from './core/presets/report-preset.service';
import { ResourceFieldPresetService } from './core/presets/resource-field-preset.service';
import { GlobalService } from './core/services/global.service';
import { HelpService } from './core/services/help.service';
import { UserProfileComponent } from './core/user-profile/user-profile.component';
import { UserProfileService } from './core/user-profile/user-profile.service';
import { QueryService } from './shared/advanced-search/query.service';
import { ColumnDefService } from './shared/data-list/column-def/column-def.service';
import { DialogService } from './shared/dialog/dialog.service';
import { ReportService } from './shared/report/report.service';
import { ResourceFieldService } from './shared/resource-field/resource-field.service';
import { HelperService } from './shared/services/helper.service';
import { HttpRestService } from './shared/services/http-rest.service';

/**
 * App root component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * The breadcrumbs bar.
   */
  @ViewChild('breadcrumbs', { read: ElementRef, static: true })
  private _breadcrumbs: ElementRef;

  /**
   * The sidenav panel.
   */
  @ViewChild('sidenav', { static: true })
  private _sidenav: MatSidenav;

  /**
   * The theme toggle text.
   */
  public themeToggle: string;

  /**
   * Global service.
   */
  public global: GlobalService;

  /**
   * The application menus.
   */
  public menus: Menu1[];

  /**
   * The router is navigating.
   */
  public navigating: boolean;

  /**
   * Get user roles.
   */
  public get roles() {
    return this.global.userProfile ? this.global.userProfile.roles.map((role) => role.name).join(', ') : '';
  }

  /**
   * Get user profile label.
   */
  public get profile() {
    try {
      const userProfile = this._globalService.userProfile;
      const profilePreference = this._globalService.profilePreference;
      const activeWorkgroup =
        userProfile.workgroups[profilePreference.activeWorkgroupIndex[this._globalService.appId]].name;
      const reportWorkgroupFilters = userProfile.workgroups
        .reduce((prev, curr, index) => {
          if (
            profilePreference.filterWorkgroupIndexes.find((filterWorkgroupIndex) => filterWorkgroupIndex === index) !==
            undefined
          ) {
            prev.push(curr.name);
          }
          return prev;
        }, [])
        .join(', ');
      let isDashboardRoute = false;
      try {
        isDashboardRoute = this._activatedRoute.snapshot.children[0].children[0].url[0].path.includes('dashboard');
      } catch (error) {}
      return `${userProfile.name} | ${activeWorkgroup}` + (isDashboardRoute ? ` | ${reportWorkgroupFilters}` : '');
    } catch (error) {
      return '';
    }
  }

  /**
   * Constructor.
   */
  constructor(
    @Inject(BREADCRUMBS_HTML) public breadcrumbsHtml: Observable<string>,
    private _activatedRoute: ActivatedRoute,
    private _applicationRef: ApplicationRef,
    private _columnDefPresetService: ColumnDefPresetService,
    private _columnDefService: ColumnDefService,
    private _dialogService: DialogService,
    private _globalService: GlobalService,
    private _helpService: HelpService,
    private _helperService: HelperService,
    private _httpClient: HttpClient,
    private _httpRestService: HttpRestService,
    private _jwtService: JwtService,
    private _menuPresetService: MenuPresetService,
    private _menuService: MenuService,
    private _mediaObserver: MediaObserver,
    private _ngZone: NgZone,
    private _queryPresetService: QueryPresetService,
    private _queryService: QueryService,
    private _renderer: Renderer2,
    private _reportPresetService: ReportPresetService,
    private _reportService: ReportService,
    private _resourceFieldPresetService: ResourceFieldPresetService,
    private _resourceFieldService: ResourceFieldService,
    private _router: Router,
    private _swUpdate: SwUpdate,
    private _userProfileService: UserProfileService
  ) {}

  /**
   * Angular life cycle hook.
   */
  public ngOnInit(): void {
    // Load various presets.
    this._queryService.queryPresetLists = this._queryPresetService.get();
    this._resourceFieldService.presets = this._resourceFieldPresetService.get();
    this._columnDefService.presets = this._columnDefPresetService.get();
    this._reportService.presets = this._reportPresetService.get();

    // Initialize app-wide global configurations.
    this._globalService.appName = 'Container Management System';
    this._globalService.appId = '2';
    this._globalService.baseUrl = environment.baseUrl;
    this._globalService.authUrl = environment.authUrl;
    this._globalService.loginUrl = environment.loginUrl;

    // Post user profile loaded initialization.
    this._userProfileService.loaded.subscribe(() => {
      // Check about new version.
      this._httpClient
        .get('/app/version', {
          headers: new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate' }),
          responseType: 'text',
        })
        .pipe(
          tap((latestVersion) => {
            const clientVersion = this._helperService.getLocalStorageItem('version');

            // New version available.
            if (clientVersion !== latestVersion) {
              this._dialogService.openConfirmDialog(
                `You are updated to version ${latestVersion}!`,
                ['Do you want to read the changelog now?'],
                (readNow) => (readNow ? this.openAbout() : null),
                false,
                'Read Now',
                'Later'
              );

              // Save latest version to local storage.
              this._helperService.setLocalStorageItem('version', latestVersion);
            }

            // Set latest version to global.
            this._globalService.appVersion = latestVersion;
          })
        )
        .subscribe();

      // Load menus preset.
      this._menuService.presets = this._menuPresetService.get();
      // Create menus.
      this.menus = this._globalService.menus = this._menuService.createMenu();
    });

    // New version update handlers.
    this._applicationRef.isStable.pipe(first((stable) => stable)).subscribe((stable) => {
      // Subscribe to check for update based on signal sent from either http-rest or timer for every 10 min.
      const checkInterval = (environment.production ? 10 : 1) * 60 * 1000;
      const checkSignal = timer(checkInterval, checkInterval);
      merge(
        checkSignal.pipe(map(() => 'schedule')),
        this._httpRestService.newVersionAvailable.pipe(map(() => 'http-rest'))
      )
        .pipe(
          exhaustMap((source) => {
            if (this._swUpdate.isEnabled && source == 'http-rest') {
              const loading = this._dialogService.openProcessingDialog('Installing new version', true);
              return from(this._swUpdate.checkForUpdate()).pipe(
                tap(() => this._dialogService.closeProcessingDialog(loading)),
                timeout(30 * 1000),
                catchError(() => {
                  // Fallback to page reload if error occurs during checkForUpdate();
                  this._dialogService.openProcessingDialog('Reloading to perform update', true);
                  return timer(1000).pipe(tap(() => document.location.reload()));
                })
              );
            }

            if (this._swUpdate.isEnabled && source == 'schedule') {
              return from(this._swUpdate.checkForUpdate()).pipe(
                timeout(30 * 1000),
                catchError(() => of({}))
              );
            }

            if (!this._swUpdate.isEnabled && source == 'http-rest') {
              this._dialogService.openProcessingDialog('Reloading to perform update', true);
              return timer(1000).pipe(tap(() => document.location.reload()));
            }
          })
        )
        .subscribe();

      // Subscribe to activate the update and reload page.
      if (this._swUpdate.isEnabled) {
        this._swUpdate.available.subscribe(() => {
          this._dialogService.openProcessingDialog('Reloading to apply new version', true);
          from(this._swUpdate.activateUpdate())
            .pipe(delay(1000))
            .subscribe(() => document.location.reload());
        });
      }
    });

    // Authenticate required handler.
    this._httpRestService.authenticateRequired
      .pipe(
        first(),
        mergeMap((error) => {
          return this._dialogService
            .openAlertDialog('Authentication Failed', [error.message], null, true, 'Authenticate now')
            .afterClosed();
        }),
        tap(() => this._jwtService.redirectToLogin())
      )
      .subscribe();

    // Service unavailable handler.
    this._httpRestService.serviceUnavailable
      .pipe(
        exhaustMap((error) => {
          return this._dialogService
            .openAlertDialog('Service Unavailable', [error.message], null, true, 'OK')
            .afterClosed();
        })
      )
      .subscribe();

    // Initialize dark theme toggle.
    this.themeToggle = this._getThemeToggleLabel();
    this._globalService.darkTheme$.subscribe((darkTheme) => {
      const styleClass = 'dark-theme';
      if (darkTheme) {
        document.querySelector('body').classList.add(styleClass);
      } else {
        document.querySelector('body').classList.remove(styleClass);
      }

      // Adjust chart defaults against selected dark or light theme.
      this._setChartDefaults(darkTheme);
    });

    // Subscribe to window scroll to hide breadcrumbs.
    this._hideBreadcrumbsOnScroll();

    // Subscribe to router navigation end event to conform breadcrumbs.
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigating = true;
      } else if (event instanceof NavigationEnd) {
        this.navigating = false;
        this.closeSidenav();
      } else if (event instanceof NavigationCancel) {
        this.navigating = false;
      }
    });

    // Get global service reference.
    this.global = this._globalService;
  }

  /**
   * Scroll page to top.
   */
  public backToTop(): void {
    try {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Open help doc link.
   */
  public openHelp(): void {
    this.closeSidenav();

    this._helpService.open();
  }

  /**
   * Open about dialog.
   */
  public openAbout(): void {
    this.closeSidenav();

    this._dialogService.openImmediateDialog(AboutComponent, null, null, { autoFocus: false });
  }

  /**
   * Open profile dialog.
   */
  public openProfile(): void {
    this.closeSidenav();

    this._dialogService.openImmediateDialog(UserProfileComponent, null, null, {
      width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? undefined : '50vw',
    });
  }

  /**
   * Logout the user.
   */
  public logout(): void {
    this._jwtService.logout().subscribe();
  }

  /**
   * Toggle between dark and light theme.
   */
  public toggleDarkTheme(): void {
    this._globalService.darkTheme = !this._globalService.darkTheme;
    // Wait for menu to close before changing the label.
    setTimeout(() => this._getThemeToggleLabel(), 500);
  }

  /**
   * Close sidenav.
   */
  public closeSidenav() {
    this._sidenav.close();
  }

  /**
   * Get theme toggle label.
   * @return Theme toggle label.
   */
  private _getThemeToggleLabel(): string {
    return (this.themeToggle = this._globalService.darkTheme ? 'Light' : 'Dark');
  }

  /**
   * Listen to window scroll event to show/hide breadcrumbs.
   */
  private _hideBreadcrumbsOnScroll() {
    this._ngZone.runOutsideAngular(() => {
      let prevScrollPos = window.pageYOffset;
      let prevState = 'shown';

      fromEvent(window, 'scroll')
        .pipe(
          tap(() => {
            const currentScrollPos = window.pageYOffset;
            const scrolledDown = prevScrollPos < currentScrollPos;
            const scrolledUp = !scrolledDown;

            if (prevState == 'shown' && scrolledDown) {
              this._renderer.addClass(this._breadcrumbs.nativeElement, 'hidden');
              prevState = 'hidden';
            } else if (prevState == 'hidden' && scrolledUp) {
              this._renderer.removeClass(this._breadcrumbs.nativeElement, 'hidden');
              prevState = 'shown';
            }

            prevScrollPos = currentScrollPos;
          })
        )
        .subscribe();
    });
  }

  /**
   * Set chart default options and behaviors.
   */
  private _setChartDefaults(darkTheme: boolean) {
    Chart.defaults.global.defaultFontColor = '#666';
    Chart.defaults.global.maintainAspectRatio = false;
    Chart.defaults.bar.scales.yAxes[0].ticks = { beginAtZero: true };
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.mode = 'index';
    Chart.defaults.global.tooltips.position = 'nearest';

    Chart.plugins.register({
      beforeDraw: (chart: any) => {
        chart.config.data.datasets.forEach((dataset, i) => {
          if (dataset?.legendBackgroundColor) {
            chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
            chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor
              ? dataset.legendBorderColor
              : dataset.legendBackgroundColor;
          }
        });
      },
    });

    if (darkTheme) {
      Chart.defaults.global.defaultFontColor = '#fff';
      Chart.defaults.scale.gridLines.color = 'rgba(255, 255, 255, 0.1)';
      Chart.defaults.scale.gridLines.zeroLineColor = 'rgba(255, 255, 255, 0.1)';
    } else {
      Chart.defaults.global.defaultFontColor = '#666';
      Chart.defaults.scale.gridLines.color = 'rgba(0, 0, 0, 0.1)';
      Chart.defaults.scale.gridLines.zeroLineColor = 'rgba(0, 0, 0, 0.1)';
    }
  }
}
