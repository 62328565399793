<span fxLayout="row" fxLayoutAlign="start baseline">
  <!-- trigger and inputs -->
  <ng-container
    [ngTemplateOutlet]="
      searchable.field.options.length === 0
        ? searchable.field.isDateTime
          ? dateRange
          : searchable.field.guidedValue
          ? guided
          : basic
        : isINOperator(searchable)
        ? multiSelect
        : select
    "
  >
  </ng-container>

  <!-- operator selection menu -->
  <ng-container *ngIf="searchable.node.i">
    <button mat-icon-button [matMenuTriggerFor]="operator" [disabled]="!searchable.node.o" class="operator">
      <strong> {{ getOperatorInitial(searchable.node.o) }} </strong>
    </button>
    <mat-menu #operator="matMenu">
      <span *ngFor="let operator of operators; index as i">
        <button mat-menu-item (click)="searchable.node.o = operator.value">
          <mat-icon *ngIf="searchable.node.o === operator.value">radio_button_checked</mat-icon>
          <mat-icon *ngIf="searchable.node.o !== operator.value">radio_button_unchecked</mat-icon>
          <span> {{ operator.value }} </span>
        </button>
      </span>
      <span>
        <button mat-menu-item [matMenuTriggerFor]="moreOperator">
          <mat-icon></mat-icon>
          <span>More</span>
        </button>
      </span>
    </mat-menu>
    <mat-menu #moreOperator="matMenu">
      <span *ngFor="let operator of moreOperators; index as i">
        <button mat-menu-item (click)="searchable.node.o = operator.value">
          <mat-icon *ngIf="searchable.node.o === operator.value">radio_button_checked</mat-icon>
          <mat-icon *ngIf="searchable.node.o !== operator.value">radio_button_unchecked</mat-icon>
          <span> {{ operator.value.toUpperCase() }} </span>
        </button>
      </span>
    </mat-menu>
  </ng-container>
</span>

<!-- basic input -->
<ng-template #basic>
  <mat-checkbox
    #trigger
    [checked]="searchable.node.i !== undefined"
    [disabled]="disabled"
    (change)="onTriggerCheckboxChange($event)"
    class="trigger"
  ></mat-checkbox>

  <span class="control">
    <mat-form-field [matTooltip]="searchable.display" matTooltipShowDelay="500">
      <mat-label> {{ searchable.display }} </mat-label>
      <input
        #control
        matInput
        [(ngModel)]="searchable.node.v"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        placeholder="Type search value"
      />
    </mat-form-field>
  </span>
</ng-template>

<!-- guided input with autocomplete -->
<ng-template #guided>
  <mat-checkbox
    #trigger
    [checked]="searchable.node.i !== undefined"
    [disabled]="disabled"
    (change)="onTriggerCheckboxChange($event)"
    class="trigger"
  ></mat-checkbox>

  <span class="control">
    <mat-form-field [matTooltip]="searchable.display" matTooltipShowDelay="500">
      <mat-label> {{ searchable.display + ' (guided)' }} </mat-label>
      <input
        #control
        matInput
        [matAutocomplete]="guidedValueAutocomplete"
        [ngModel]="searchable.node.v"
        (ngModelChange)="onGuidedValueModelChange($event, searchable.node)"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        placeholder="Type search keyword to get options"
      />
      <mat-autocomplete
        #guidedValueAutocomplete="matAutocomplete"
        (optionSelected)="onGuidedValueOptionSelected($event, searchable.node, control)"
        [displayWith]="valueDisplayWith"
      >
        <mat-option *ngFor="let item of getValueItems(control, searchable) | async" [value]="item">
          {{ valueDisplayWith(item) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </span>
</ng-template>

<!-- select -->
<ng-template #select>
  <mat-checkbox
    #trigger
    [checked]="searchable.node.i !== undefined"
    [disabled]="disabled"
    (change)="onTriggerCheckboxChange($event)"
    class="trigger"
  ></mat-checkbox>

  <span class="control">
    <mat-form-field [matTooltip]="searchable.display" matTooltipShowDelay="500">
      <mat-label> {{ searchable.display }} </mat-label>
      <mat-select
        #control
        [(ngModel)]="searchable.node.v"
        (ngModelChange)="searchable.selectedValues = undefined"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        placeholder="Select an option"
      >
        <mat-option *ngFor="let option of searchable.field.options" [value]="option.display">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
</ng-template>

<!-- multi select -->
<ng-template #multiSelect>
  <mat-checkbox
    #trigger
    [checked]="searchable.node.i !== undefined"
    [disabled]="disabled"
    (change)="onTriggerCheckboxChange($event)"
    class="trigger"
  ></mat-checkbox>

  <span class="control">
    <mat-form-field [matTooltip]="searchable.display" matTooltipShowDelay="500">
      <mat-label> {{ searchable.display }} </mat-label>
      <mat-select
        #control
        [ngModel]="getINSelectValue(searchable)"
        (ngModelChange)="onINSelectModelChange($event, searchable)"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        placeholder="Select an option"
        multiple
      >
        <mat-option *ngFor="let option of searchable.field.options" [value]="option.display">
          {{ option.display }}
        </mat-option>
        <mat-select-trigger>
          <span> {{ searchable.node.v }} </span>
        </mat-select-trigger>
      </mat-select>
    </mat-form-field>
  </span>
</ng-template>

<!-- input with date range dialog -->
<ng-template #dateRange>
  <mat-checkbox
    #trigger
    [checked]="searchable.node.i !== undefined"
    [disabled]="disabled"
    (change)="onTriggerCheckboxChange($event)"
    class="trigger"
  ></mat-checkbox>

  <span class="control">
    <mat-form-field [matTooltip]="searchable.display" matTooltipShowDelay="500" class="date">
      <mat-label> {{ searchable.display }} </mat-label>
      <input
        #control
        matInput
        [(ngModel)]="searchable.node.v"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        placeholder="Select a date or date range"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="showDateTimePrompt(searchable)"
        [disabled]="!trigger.checked || isNULLOperator(searchable)"
        color="primary"
      >
        <mat-icon>today</mat-icon>
      </button>
    </mat-form-field>
  </span>
</ng-template>
