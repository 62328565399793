export enum PromptDialogType {
  Color = 'color',
  Email = 'email',
  Month = 'month',
  Number = 'number',
  Password = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  Url = 'url',
  Week = 'week',

  Select = 'typeSelect',
  MultiSelect = 'typeMultiSelect',
  Date = 'typeDate',
  Datetime = 'typeDatetime',
  Array = 'typeArray',
  Autocomplete = 'typeAutocomplete',
}
