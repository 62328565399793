import { Inject, Injectable } from '@angular/core';

import { BREADCRUMBS_SEPARATOR } from '../breadcrumbs/breadcrumbs-separator.token';
import { Menu1 } from './menu.interface';

/**
 * Application menu service.
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  /**
   * All menus.
   */
  public presets: Menu1[] = [];

  /**
   * Constructor.
   */
  constructor(@Inject(BREADCRUMBS_SEPARATOR) private _breadcrumbsSeparator: string) {}

  /**
   * Create application menu.
   * @return App all menus.
   */
  public createMenu(): Menu1[] {
    return this.presets;
  }

  /**
   * Get active menu breadcrumbs.
   * @param path Menu item's path.
   * @return Active menu breadcrumbs.
   */
  public getActiveMenuItemBreadcrumbs(path: string): string {
    let breadcrumbs = '';

    try {
      this.presets.forEach((menu1) => {
        if (path.includes(menu1.path)) {
          breadcrumbs = `${menu1.title}`;
          throw Error('Found!');
        } else {
          menu1.subMenus.forEach((menu2) => {
            if (path.includes(menu2.path)) {
              breadcrumbs = `${menu1.title} ${this._breadcrumbsSeparator} ${menu2.title}`;
              throw Error('Found!');
            } else {
              menu2.subMenus.forEach((menu3) => {
                if (path.includes(menu3.path)) {
                  breadcrumbs = `${menu1.title} ${this._breadcrumbsSeparator} ${menu2.title} ${this._breadcrumbsSeparator} ${menu3.title}`;
                  throw Error('Found!');
                } else {
                  menu3.subMenus.forEach((menu4) => {
                    if (path.includes(menu4.path)) {
                      breadcrumbs = `${menu1.title} ${this._breadcrumbsSeparator} ${menu2.title} ${this._breadcrumbsSeparator} ${menu3.title} ${this._breadcrumbsSeparator} ${menu4.title}`;
                      throw Error('Found!');
                    }
                  });
                }
              });
            }
          });
        }
      });
    } catch (error) {}

    return breadcrumbs;
  }
}
