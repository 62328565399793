import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { clone } from 'lodash-es';

import { DialogService } from '../../dialog/dialog.service';
import { PromptCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom prompt editor component.
 */
@Component({
  selector: 'app-prompt-cell-editor',
  templateUrl: './prompt-cell-editor.component.html',
  styleUrls: ['./prompt-cell-editor.component.scss'],
})
export class PromptCellEditorComponent implements AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: PromptCellEditorParams;

  /**
   * Prompt result.
   */
  private _result: any;

  /**
   * Constructor.
   */
  constructor(private _dialogService: DialogService) {}

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: PromptCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Open prompt dialog.
    this._dialogService.openPromptDialog(
      params.title,
      params.contents,
      (result) => {
        // Get prompt result.
        this._result = result;

        // Stop editing and trigger returning of the editing result.
        this.params.stopEditing();
      },
      params.options,
      clone(params.value),
      params.promptDialogType,
      (inputValue) => (this.params.sanitize ? this.params.sanitize(inputValue, this.params.node) : inputValue),
      (inputValue) => (this.params.validate ? this.params.validate(inputValue, this.params.node) : true)
    );
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    // Return the prompt result.
    return this._result;
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    return this._result === undefined;
  }
}
