import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ColDef, Column, GridOptions } from 'ag-grid-community/main';
import { cloneDeep, escapeRegExp, flatten, isEmpty, isFunction } from 'lodash-es';
import * as moment from 'moment-mini';
import { fromEvent, iif, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { QueryPresetService } from '../../core/presets/query-preset.service';
import { GlobalService } from '../../core/services/global.service';
import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';
import { DateRangeComponent } from '../advanced-search/date-range/date-range.component';
import { GroupNode } from '../advanced-search/group-node.interface';
import { NodeSettingComponent } from '../advanced-search/node-setting/node-setting.component';
import { NodeComponent } from '../advanced-search/node/node.component';
import { QAdv } from '../advanced-search/q-adv.interface';
import { QueryService } from '../advanced-search/query.service';
import { Searchable } from '../advanced-search/searchable.interface';
import { WhereFieldNode } from '../advanced-search/where-field-node.interface';
import { DialogService } from '../dialog/dialog.service';
import { PromptDialogType } from '../dialog/prompt-dialog/prompt-dialog-type.enum';
import { HTMLElementEvent } from '../interfaces/html-element-event.interface';
import { ResourceFieldService } from '../resource-field/resource-field.service';
import { HelperService } from '../services/helper.service';
import { HttpRestService } from '../services/http-rest.service';
import { FormControlBase } from '../utils/form-control/form-control-base';
import { CheckboxCellRendererComponent } from './checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DataListActionDef } from './data-list-action-def.interface';

/**
 * Data list component.
 */
@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DataListComponent,
      multi: true,
    },
  ],
})
export class DataListComponent extends FormControlBase<any[]> implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Quick search MatInput.
   */
  @ViewChild('quickSearchInput', { read: MatInput })
  private _quickSearchInput: MatInput;

  /**
   * Quick search element ref.
   */
  @ViewChild('quickSearchInput', { read: ElementRef })
  private _quickSearchEl: ElementRef;

  /**
   * Page element ref.
   */
  @ViewChild('pageInput', { read: ElementRef })
  private _pageEl: ElementRef;

  /**
   * Take element ref.
   */
  @ViewChild('takeInput', { read: ElementRef })
  private _takeEl: ElementRef;

  /**
   * AG Grid's grid options.
   */
  @Input() public gridOptions: GridOptions;

  /**
   * Resource URL to load the data. Prefix it with '/'.
   */
  @Input() public resourceURL: string;

  /**
   * Row selection mode, 'single' or 'multiple'.
   */
  @Input() public rowSelection: string = 'multiple';

  /**
   * Flag indicating whether row addition is disabled.
   */
  @Input() public addDisabled: boolean = false;

  /**
   * Flag indicating whether row duplication is disabled.
   */
  @Input() public duplicateDisabled: boolean = false;

  /**
   * Flag indicating whether row removal is disabled.
   */
  @Input() public removeDisabled: boolean = false;

  /**
   * Before new data is added callback. Return true to cancel.
   */
  @Input() public beforeAdd: () => boolean;

  /**
   * Before duplicates is added callback. Return true to cancel.
   */
  @Input() public beforeDuplicate: (duplicates: any[]) => boolean;

  /**
   * Before data is removed callback. Return true to cancel.
   */
  @Input() public beforeRemove: (selected: any[]) => boolean;

  /**
   * New row getter.
   */
  @Input() public getNewRow: () => any;

  /**
   * Search mode flag.
   */
  @Input() public searchMode: boolean = false;

  /**
   * Hide top nav flag.
   */
  @Input() public hideTopNav: boolean = false;

  /**
   * Additional top nav's actions.
   */
  @Input() public actions: DataListActionDef[] = [];

  /**
   * Data list width.
   */
  @Input() public width: string = '100%';

  /**
   * Data list height.
   */
  @Input() public height: string = '480px';

  /**
   * Add new row enabled flag.
   */
  @Input() public keyDownAddNewEnabled: boolean = false;

  /**
   * Default take size.
   */
  @Input() public defaultTake: number = 10;

  /**
   * Preloaded query can be either @ syntax, preset name, or QAdv.
   * If last used query preset name is used, the data
   * list will automatically be refreshed on init.
   */
  @Input() public preloadedQuery: string | QAdv;

  /**
   * Focus on grid cell after data load.
   * true             = select and focus
   * false            = select only
   * null | undefined = no select and no focus
   */
  @Input() public focusAfterLoad: boolean = true;

  /**
   * Focus on first editable grid cell of newly added row.
   * Newly added row will always be selected.
   */
  @Input() public focusFirstEditableCellAfterAdd: boolean = true;

  /**
   * Data list load end event emitter.
   */
  @Output() public dataLoadEnd: EventEmitter<any> = new EventEmitter();

  /**
   * Maximum number of rows per page.
   */
  public readonly MAX_PER_PAGE: number = 1000;

  /**
   * Dark theme flag.
   */
  public darkTheme: boolean;

  /**
   * Advanced search dialog is opened flag.
   */
  public advancedSearchOpened: boolean;

  /**
   * All available searchables.
   */
  public searchables: Searchable[];

  /**
   * Observable of filtered searchables for the autocomplete.
   */
  public filteredSearchables: Observable<Searchable[]>;

  /**
   * Selected searchable.
   */
  public selectedSearchable: Searchable;

  /**
   * Scheduled refresh subscription.
   */
  public scheduledRefreshSubscription: Subscription;

  /**
   * Scheduled refresh interval in minutes.
   */
  public scheduledRefreshInterval: number;

  /**
   * Indicating that the grid is currently loading data.
   */
  private _busy = false;
  public busy$: Subject<boolean> = new Subject<boolean>();
  public get busy(): boolean {
    return this._busy;
  }
  public set busy(v: boolean) {
    this._busy = v;
    this.busy$.next(v);
  }

  /**
   * Quick search keyword.
   */
  private _q = '';
  public get q(): string {
    return this._q;
  }
  public set q(v: string) {
    this._q = v;
  }

  /**
   * Advanced search query.
   */
  private _qAdv: QAdv;
  public get qAdv(): QAdv {
    return this._qAdv;
  }
  public set qAdv(v: QAdv) {
    this._qAdv = v;
  }

  /**
   * Current active page.
   */
  private _page = 0;
  public get page(): number {
    return this._page;
  }
  public set page(v: number) {
    this._page = v;
  }

  /**
   * Amount of rows load per page.
   */
  private _take = 10;
  public get take(): number {
    return this._take;
  }
  public set take(v: number) {
    this._take = v;
  }

  /**
   * Total amount of pages of current query.
   */
  private _totalPages = 0;
  public get totalPages(): number {
    return this._totalPages;
  }
  public set totalPages(v: number) {
    this._totalPages = v;
  }

  /**
   * Total amount of rows of current query.
   */
  private _totalRows = 0;
  public get totalRows(): number {
    return this._totalRows;
  }
  public set totalRows(v: number) {
    this._totalRows = v;
  }

  /**
   * Disable flag.
   */
  private _disabled: boolean;
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(v: boolean) {
    this._disabled = v;
    // Disable grid cell click edit on data list disabled.
    if (this.gridOptions) {
      this.gridOptions.suppressClickEdit = this._disabled;
    }
  }

  /**
   * Rows selected flags.
   */
  public get rowsSelected(): boolean {
    return this.gridOptions && this.gridOptions.api ? this.gridOptions.api.getSelectedRows().length > 0 : false;
  }

  /**
   * AG Grid calculated height.
   * dataList - topNav - paginationNav - extra-borders-to-avoid-flicker.
   */
  public get gridHeight(): SafeStyle {
    return this._domSanitizer.bypassSecurityTrustStyle(
      this._mediaObserver.isActive('lt-sm')
        ? `calc(${this.height} - 109px - 97px - 2px)`
        : `calc(${this.height} - 69px - 57px - 2px)`
    );
  }

  /**
   * Data list is using local data mode.
   */
  public get localData(): boolean {
    return this.value ? true : false;
  }

  /**
   * Get a proxied searchable option rendering function allowing 'this' to reference DataListComponent.
   */
  public get searchableDisplayFn(): (option: Searchable | string) => string {
    return (option) => (typeof option === 'string' ? option : option?.display);
  }

  /**
   * Flag indicating scheduled refresh is active.
   */
  public get refreshScheduled(): boolean {
    return this.scheduledRefreshSubscription && !this.scheduledRefreshSubscription.closed;
  }

  /**
   * @ syntax regex.
   */
  private readonly AT_SYNTAX_FULL: RegExp =
    /^@\(\s(.+)\s\)\s((?:=|!=|<|>|<=|>=|CONTAINS|NOT CONTAINS|LIKE|NOT LIKE|IN|NOT IN|BETWEEN|NOT BETWEEN)(?=\s(?:.+))|(?:IS NULL|IS NOT NULL)(?=$))\s?(.*)$/i;
  private readonly AT_SYNTAX_FIELD_AND_OPERATOR: RegExp =
    /^@\(\s(.+)\s\)\s(=|!=|<|>|<=|>=|CONTAINS|NOT CONTAINS|LIKE|NOT LIKE|IN|NOT IN|BETWEEN|NOT BETWEEN|IS NULL|IS NOT NULL)\s?(.*)$/i;
  private readonly AT_SYNTAX_FIELD_ONLY: RegExp = /^(@\(\s(.+)\s\))(.*)$/;

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal: Subject<any> = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _domSanitizer: DomSanitizer,
    private _elementRef: ElementRef,
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService,
    private _matDialog: MatDialog,
    private _mediaObserver: MediaObserver,
    private _queryPresetService: QueryPresetService,
    private _queryService: QueryService,
    private _resourceFieldService: ResourceFieldService
  ) {
    // Data list doesn't support validation, so we pass null to parent constructor.
    super(null, null);
  }

  /**
   * AG Grid element's keyup event handler.
   * We use keyup to trigger "arrow down key add new row"
   * when Shift+Enter is pressed and then released.
   * @param event KeyboardEvent
   */
  public onKeyUp(event: KeyboardEvent) {
    if (event.shiftKey && event.key == 'Enter') {
      setTimeout(() => {
        // Abort add new row if the grid is in edit mode, e.g.
        // when we put it immediately to edit mode on value
        // changed after failing some validation check.
        if (this.gridOptions.api.getEditingCells().length > 0) {
          return;
        }

        const keyboardEvent = new KeyboardEvent('keydown', { key: 'ArrowDown', keyCode: 40 } as KeyboardEventInit);
        const focusedCell = this.gridOptions.api.getFocusedCell();
        const params = {
          key: keyboardEvent.keyCode,
          previousCellPosition: focusedCell,
          nextCellPosition: null,
          event: keyboardEvent,
        };
        this.gridOptions.navigateToNextCell(params);
      });
    }
  }

  /**
   * AG Grid element's keydown event handler.
   * We also need to handle keydown event to be able to properly
   * trigger "arrow down key add new row" using Shift+Enter,
   * otherwise AG Grid will instead enter edit mode.
   * @param event KeyboardEvent
   */
  public onKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && event.key == 'Enter') {
      this.gridOptions.api.stopEditing(true);
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    // Subscribe to dark theme change.
    this._globalService.darkTheme$.pipe(takeUntil(this._destroySignal)).subscribe((darkTheme) => {
      this.darkTheme = darkTheme;
    });

    // Subscribe to profileChanged event and refresh data list when the event is emitted.
    this._globalService.profileChanged.pipe(takeUntil(this._destroySignal)).subscribe(() => {
      // We need to check if the data list is visible before performing refresh.
      // E.g. even though CRUD brings us back to embedded list mode, the closed
      // popup list still gets to receive the event and will try to refresh.
      this.refreshIfVisible().subscribe();
    });

    // Subsribe to busy observable to open blocking processing dialog when busy.
    this.busy$.subscribe((busy) => {
      if (busy) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        if (this._totalRows > 0) {
          this.gridOptions.api.hideOverlay();
        } else {
          this.gridOptions.api.showNoRowsOverlay();
        }
      }
    });

    // Set GridOptions with fixed value.
    this.gridOptions.suppressPropertyNamesCheck = true;
    this.gridOptions.overlayLoadingTemplate = `<span>Loading, please wait...</span>`;
    this.gridOptions.overlayNoRowsTemplate = `<span>There's no data.</span>`;
    this.gridOptions.rowHeight = 28;
    this.gridOptions.headerHeight = 28;
    this.gridOptions.rowData = [];
    this.gridOptions.defaultExcelExportParams = this.gridOptions.defaultCsvExportParams = <any>{
      sheetName: this.resourceURL ? this.resourceURL.split('/').pop().split('?')[0] : 'data-list',
      processCellCallback: (params) => {
        const datetimeFormat = (params.column.getUserProvidedColDef() as any).datetimeFormat;
        return datetimeFormat && params.value ? moment(params.value).format(datetimeFormat) : params.value;
      },
      columnKeys: this.gridOptions.columnDefs
        .filter((colDef) => !(<ColDef>colDef).checkboxSelection)
        .map((colDef) => (<ColDef>colDef).field),
    };

    // Keep ongoing move subscription as a lock to avoid double move when user holding the arrow down/up.
    let moveSub: Subscription;

    // Set GridOptions with fixed handler.
    this.gridOptions.navigateToNextCell = (params) => {
      const previousCell = params.previousCellPosition;
      const nextCell = params.nextCellPosition;

      // Disable navigate to next cell behavior when Alt key is
      // pressed. It'll be handled at onCellKeyDown instead
      // which will perform multiple rows selection.
      if (params.event.altKey) {
        return nextCell;
      }

      switch (params.event.key) {
        case 'ArrowDown':
          const nextRowIndex = previousCell.rowIndex + 1;
          const renderedRowCount = this.gridOptions.api.getModel().getRowCount();

          // If add new row feature enabled and local value is set and current position is at last row on last page.
          if (
            this.keyDownAddNewEnabled &&
            this.localData &&
            nextRowIndex >= renderedRowCount &&
            this.page === this.totalPages
          ) {
            // Add new row if not disabled.
            if (!this.disabled) {
              this.add();
            }

            // Don't navigate, returning null.
            return null;
          }

          if (nextCell?.rowIndex >= 0) {
            this.gridOptions.api.getDisplayedRowAtIndex(nextCell.rowIndex).setSelected(true, true);
          } else {
            // We are at the bottom most row, so load next page then select first row by moving down 1 row.
            if (moveSub == null || moveSub.closed) {
              moveSub = this.move(1).subscribe();
            }
          }
          return nextCell;
        case 'ArrowUp':
          if (nextCell?.rowIndex >= 0) {
            this.gridOptions.api.getDisplayedRowAtIndex(nextCell.rowIndex).setSelected(true, true);
          } else {
            // We are at the top most row, so load previous page then select last row by moving up 1 row.
            if (moveSub == null || moveSub.closed) {
              moveSub = this.move(-1).subscribe();
            }
          }
          return nextCell;
        case 'ArrowLeft':
        case 'ArrowRight':
          return nextCell;
        default:
          throw Error('Navigation is always on of the 4 arrow keys');
      }
    };

    this.gridOptions.processCellForClipboard = (params) => {
      const datetimeFormat = (params.column.getUserProvidedColDef() as any).datetimeFormat;
      return datetimeFormat && params.value
        ? moment(params.value).format(datetimeFormat)
        : params.value && params.value.replace
        ? // Remove any line breaks so that it can be properly pasted to excel.
          params.value.replace(/(\r\n|\n|\r)/gm, ' ')
        : params.value;
    };

    this.gridOptions.onCellKeyDown = (rowEvent) => {
      const keyboardEvent = <KeyboardEvent>rowEvent.event;

      if (!keyboardEvent) return;

      if (keyboardEvent.ctrlKey) {
        switch (keyboardEvent.key) {
          case 'ArrowDown':
          case 'ArrowUp':
            this.gridOptions.api
              .getDisplayedRowAtIndex(this.gridOptions.api.getFocusedCell().rowIndex)
              .setSelected(true, true);
            break;

          default:
            break;
        }
      } else if (keyboardEvent.altKey) {
        switch (keyboardEvent.key) {
          case 'ArrowDown':
          case 'ArrowUp':
            const upDown = keyboardEvent.key == 'ArrowDown' ? 1 : -1;
            const targetRowNode = rowEvent.api.getDisplayedRowAtIndex(rowEvent.rowIndex + upDown);
            targetRowNode
              ? targetRowNode.isSelected()
                ? this._deselectRowById(rowEvent.api.getDisplayedRowAtIndex(targetRowNode.rowIndex + upDown * -1).id)
                : this._selectRowById(targetRowNode.id, false)
              : null;
            break;

          default:
            break;
        }
      } else if (keyboardEvent.shiftKey) {
        switch (keyboardEvent.key) {
          case 'Home':
            this.first().subscribe();
            break;

          case 'PageUp':
            this.previous().subscribe();
            break;

          case 'PageDown':
            this.next().subscribe();
            break;

          case 'End':
            this.last().subscribe();
            break;

          default:
            break;
        }
      } else {
        switch (keyboardEvent.key) {
          case 'PageDown':
          case 'PageUp':
          case 'Home':
          case 'End':
            this.gridOptions.api
              .getDisplayedRowAtIndex(this.gridOptions.api.getFocusedCell().rowIndex)
              .setSelected(true, true);
            break;

          default:
            break;
        }
      }
    };

    this.gridOptions.onCellClicked = (event) => {
      event.node.isExpandable() ? this._selectRowById(event.node.id, true) : null;
    };

    // Set GridOptions with fixed value if it's not defined.
    this.gridOptions.defaultColDef =
      this.gridOptions.defaultColDef === undefined
        ? {
            sortable: true,
            filter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            // If we are to override defaultColDef, don't forget to implement below
            // code so that data list navigation can be performed correctly.
            suppressKeyboardEvent: (params) => {
              // Suppress page up, page down, home, and end cell navigation
              // to perform previous, next, first, and last data list navigation.
              if (params.event.shiftKey) {
                if (['PageUp', 'PageDown', 'Home', 'End'].includes(params.event.key)) {
                  return true;
                }
              }

              return false;
            },
          }
        : this.gridOptions.defaultColDef;
    this.gridOptions.enterMovesDownAfterEdit =
      this.gridOptions.enterMovesDownAfterEdit === undefined ? true : this.gridOptions.enterMovesDownAfterEdit;
    this.gridOptions.sideBar = this.gridOptions.sideBar === undefined ? undefined : this.gridOptions.sideBar;
    this.gridOptions.groupSelectsChildren =
      this.gridOptions.groupSelectsChildren === undefined ? true : this.gridOptions.groupSelectsChildren;
    this.gridOptions.groupSelectsFiltered =
      this.gridOptions.groupSelectsFiltered === undefined ? true : this.gridOptions.groupSelectsFiltered;
    this.gridOptions.enableRangeSelection =
      this.gridOptions.enableRangeSelection === undefined ? true : this.gridOptions.enableRangeSelection;
    this.gridOptions.suppressCopyRowsToClipboard =
      this.gridOptions.suppressCopyRowsToClipboard === undefined ? true : this.gridOptions.suppressCopyRowsToClipboard;
    this.gridOptions.autoGroupColumnDef =
      this.gridOptions.autoGroupColumnDef === undefined
        ? {
            comparator: (valueA, valueB) => {
              const dateA = moment(valueA ? valueA : '1970-01-01');
              const dateB = moment(valueB ? valueB : '1970-01-01');
              valueA = dateA.isValid() ? dateA.format() : valueA;
              valueB = dateB.isValid() ? dateB.format() : valueB;
              if (valueA < valueB) {
                return -1;
              } else if (valueA > valueB) {
                return 1;
              } else {
                return 0;
              }
            },
          }
        : this.gridOptions.autoGroupColumnDef;
    this.gridOptions.frameworkComponents === undefined
      ? {
          checkboxRenderer: CheckboxCellRendererComponent,
        }
      : {
          checkboxRenderer: CheckboxCellRendererComponent,
          ...this.gridOptions.frameworkComponents,
        };

    // Set GridOptions with specified value.
    this.gridOptions.rowSelection = this.rowSelection;
    if (this.gridOptions.rowSelection == 'single') {
      this.gridOptions.groupSelectsChildren = false;
    }

    // Set Default take.
    this._take = this.defaultTake;

    // Other initializations based on whether it is search mode or not.
    if (this.searchMode) {
      // Build all available searchables.
      this.searchables = flatten(
        [{ resourceURL: this.resourceURL.split('?')[0], relation: 'this' }]
          .concat(this._queryPresetService.getWhereHases(this.resourceURL.split('?')[0]))
          .map((whereHas) => {
            return this._resourceFieldService
              .get(whereHas.resourceURL)
              .filter((item) => item.searchable)
              .map((field) => {
                return {
                  relation: whereHas.relation,
                  resourceURL: whereHas.resourceURL,
                  field: field,
                  display: null,
                  node: {} as WhereFieldNode,
                  parentNode: {} as GroupNode<WhereFieldNode>,
                };
              })
              .map((searchable) => {
                searchable.display = this.renderRelationAndFieldName(searchable).toLowerCase();
                return searchable;
              });
          })
      );

      // Preload q or qAdv if provided.
      if (this.preloadedQuery) {
        if (typeof this.preloadedQuery === 'string') {
          const match = this.preloadedQuery.match(this.AT_SYNTAX_FIELD_AND_OPERATOR);
          if (match) {
            // Preload q with @ syntax.
            const found = this.searchables.filter((searchable) => {
              return match[1] === searchable.display;
            });

            if (found) {
              this.selectedSearchable = found[0];
              this._q = this.preloadedQuery;
            }
          } else if (this.preloadedQuery === this._queryService.LAST_USED_QADV_PRESET_NAME) {
            // Preload qAdv with last used QAdv object.
            this._qAdv = this._queryService
              .getQAdvPresets(this.resourceURL.split('?')[0])
              .find((item) => item.name === this._queryService.LAST_USED_QADV_PRESET_NAME);

            // Refresh if last used query preset name is used. Use settimeout because we are still in ngOnInit lifecycle.
            setTimeout(() => this.refresh().subscribe());
          } else {
            // Preload qAdv with preset QAdv object based on provided preset name.
            this._qAdv = this._queryService
              .getQAdvPresets(this.resourceURL.split('?')[0])
              .find((item) => item.name === this.preloadedQuery);
          }
        } else {
          this._qAdv = this.preloadedQuery;
        }
      }
    } else {
      // Register to CVA's implementation to get notified to reload the grid when underlying value is changed.
      this.registerOnChange(() => this._load().subscribe());
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit() {
    // Build observable of filtered searchables.
    if (this._quickSearchInput) {
      this.filteredSearchables = this._quickSearchInput.ngControl.valueChanges.pipe(
        debounceTime(100),
        map((val) => {
          if (typeof val !== 'string' || !val.startsWith('@')) {
            return { val: val, filtered: [] };
          }

          const keyword = val.substring(1).toLowerCase(); // offset the @ symbol.
          const re = new RegExp(escapeRegExp('¦' + keyword.split('').join('¦') + '¦').replace(/¦/g, '.*'));

          return {
            val: val,
            filtered: this.searchables.filter((searchable) => {
              try {
                return re.test(searchable.display) && !searchable.field.custom;
              } catch (error) {
                return false;
              }
            }),
          };
        }),
        tap((obj) => {
          if ((<any>obj).val === '@' || !(<any>obj).val.startsWith('@')) {
            // Skip clearing the _q variable to allow user to immediately use the already typed _q with the selectedFieldItem cleared.
            // This happens when user select all the quick search text and directly typing '@' or any string that doesn't start with '@'.
            this.selectedSearchable = undefined;
          } else {
            // Clear the quick search text when it doesn't follow the @ sytax anymore.
            this._q = !this.AT_SYNTAX_FIELD_ONLY.test((<any>obj).val) && this.selectedSearchable ? '' : this._q;

            // Clear the selected fieldItem when it doesn't follow the @ sytax anymore
            // or the filtered fieldItem exists, otherwise leave it as it.
            this.selectedSearchable =
              !this.AT_SYNTAX_FIELD_ONLY.test((<any>obj).val) || (<any>obj).filtered.length > 0
                ? undefined
                : this.selectedSearchable;
          }
        }),
        map((obj) => obj.filtered)
      );
    }

    // Subscribe to page change, take change, and
    // quick search keyup observables to populate
    // data list when their value changed.
    fromEvent<HTMLElementEvent<HTMLInputElement>>(this._pageEl.nativeElement, 'change')
      .pipe(
        map((e) => +e.target.value),
        map((page) => (page = this._page = Math.ceil(page))),
        filter((page) => page >= 1),
        switchMap(() => this._load()),
        takeUntil(this._destroySignal)
      )
      .subscribe();

    fromEvent<HTMLElementEvent<HTMLInputElement>>(this._takeEl.nativeElement, 'change')
      .pipe(
        map((e) => +e.target.value),
        map((take) => (take = this._take = Math.ceil(take))),
        filter((take) => take >= 1 && take <= this.MAX_PER_PAGE),
        switchMap(() => this._load()),
        takeUntil(this._destroySignal)
      )
      .subscribe();

    if (this._quickSearchEl) {
      fromEvent<KeyboardEvent>(this._quickSearchEl.nativeElement, 'keyup')
        .pipe(
          filter((e) => e.key === 'Enter' && (!this._q.startsWith('@') || this.AT_SYNTAX_FULL.test(this._q))),
          tap(() => (this._qAdv = undefined)),
          switchMap(() => this._load()),
          takeUntil(this._destroySignal)
        )
        .subscribe();
    }
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy(): void {
    // Emit destroying signal.
    this._destroySignal.next();
    // Complete this component's subjects.
    this.busy$.complete();
    // Complete this component's event emitters.
    this.dataLoadEnd.complete();
  }

  /**
   * Open @ node setting dialog.
   */
  public openAtNodeSetting(): Observable<any> {
    return of({}).pipe(
      switchMap(() => {
        const match =
          this._q.match(this.AT_SYNTAX_FULL) ||
          this._q.match(this.AT_SYNTAX_FIELD_AND_OPERATOR) ||
          this._q.match(this.AT_SYNTAX_FIELD_ONLY);
        return this._dialogService
          .openImmediateDialog(NodeSettingComponent, null, null, {
            data: {
              node: NodeComponent.createWhereFieldNode(
                'and',
                this.selectedSearchable.field.name,
                match[2].toLowerCase(),
                match[3],
                true
              ),
              resourceURL: this.selectedSearchable.resourceURL,
              relationName: match[1].split(' - ')[0],
              fields: [this.selectedSearchable.field],
            },
            width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '80vw' : '25vw',
          })
          .afterClosed();
      }),
      // Add delay to let the opened dialog to be properly closed and the node's value is properly set.
      delay(250),
      switchMap((node) => {
        if (node) {
          this._q = this._q.replace(
            this.AT_SYNTAX_FIELD_ONLY,
            '$1' + (node.v ? ` ${node.o.toUpperCase()} ${node.v}` : ` ${node.o.toUpperCase()}`)
          );
          return this._load();
        } else {
          return of(false);
        }
      })
    );
  }

  /**
   * Handle shortcut keydown event using document as the event target.
   * @param event Keyboard event.
   */
  @HostListener('document:keydown', ['$event'])
  public onShortcutKeydown(event: KeyboardEvent) {
    // We ignore shortcut if the component element is not visible.
    const rect: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();
    const visible = rect.width > 0 && rect.height > 0;
    if (!visible) {
      return;
    }

    if (this.searchMode) {
      if (event.shiftKey) {
        switch (event.key) {
          case 'F2':
            this._quickSearchInput.focus();
            return;

          default:
            break;
        }
      }

      if (event.ctrlKey) {
        switch (event.key) {
          case 'Enter':
            this._matDialog.openDialogs
              .filter(
                (dialogRef) =>
                  dialogRef.componentInstance instanceof NodeSettingComponent ||
                  dialogRef.componentInstance instanceof DateRangeComponent
              )
              .map((dialogRef) => dialogRef.componentInstance)
              .forEach((component) => component.close());
            return;

          default:
            break;
        }
      }

      switch (event.key) {
        case 'F2':
          // Do not open the dialog as F2 is also used to focus on AdvancedSearchComponent's search-field autocomplete.
          if (
            this._matDialog.openDialogs.find(
              (dialogRef) => dialogRef.componentInstance instanceof AdvancedSearchComponent
            )
          ) {
            return;
          }

          this._quickSearchInput.ngControl.control.setValue('@');
          this._quickSearchInput.focus();
          return;

        case '?':
          if (this.selectedSearchable) {
            this.openAtNodeSetting().subscribe();
          }
          return;

        default:
          break;
      }
    }
  }

  /**
   * Go to first page.
   * @return Observable of boolean indicating navigation is success or not.
   */
  public first(): Observable<boolean> {
    if (this._page > 1) {
      this._page = 1;
      return this._load();
    } else {
      this._helperService.toast('You have reached the first page.');
      return of(false);
    }
  }

  /**
   * Go to previous page.
   * @return Observable of boolean indicating navigation is success or not.
   */
  public previous(): Observable<boolean> {
    if (this._page - 1 >= 1) {
      this._page--;
      return this._load();
    } else {
      this._helperService.toast('You have reached the first page.');
      return of(false);
    }
  }

  /**
   * Go to next page.
   * @return Observable of boolean indicating navigation is success or not.
   */
  public next(): Observable<boolean> {
    if (this._page + 1 <= this._totalPages) {
      this._page++;
      return this._load();
    } else {
      this._helperService.toast('You have reached the last page.');
      return of(false);
    }
  }

  /**
   * Go to last page.
   * @return Observable of boolean indicating navigation is success or not.
   */
  public last(): Observable<boolean> {
    if (this._page < this._totalPages) {
      this._page = this._totalPages;
      return this._load();
    } else {
      this._helperService.toast('You have reached the last page.');
      return of(false);
    }
  }

  /**
   * Move currently selected row to the next or previous one. If the
   * to be selected row index is out of the range of a page, then
   * make a call to data list's next() or previous().
   * @param increment Step to move to another row.
   * @return Observable of boolean indicating whether move operation is success or not.
   */
  public move(increment: number): Observable<boolean> {
    const currentSelectedNodes = this.gridOptions.api.getSelectedNodes();
    const currentSelectedIndex = currentSelectedNodes.length > 0 ? currentSelectedNodes[0].rowIndex : -1;
    const currentFocusedCell = this.gridOptions.api.getFocusedCell();
    let futureIndex = currentSelectedIndex + increment;
    let result: Observable<boolean>;

    if (futureIndex < 0) {
      // Future index is negative, get previous page and select last index.
      let savedFocusAfterLoad: boolean;
      result = of(false).pipe(
        tap(() => {
          savedFocusAfterLoad = this.focusAfterLoad;
          this.focusAfterLoad = null;
        }),
        switchMap(() => this.previous()),
        tap((loaded) => {
          if (loaded) {
            futureIndex = this.take - 1;
            this.focusToCell(futureIndex, currentFocusedCell?.column);
            this.gridOptions.api.getDisplayedRowAtIndex(futureIndex).setSelected(true, true);
          }
        }),
        tap(() => (this.focusAfterLoad = savedFocusAfterLoad))
      );
    } else if (
      futureIndex >=
      (this.page === this.totalPages && this.totalRows % this.take !== 0 ? this.totalRows % this.take : this.take)
    ) {
      // Future index is larger than page size, get next page and select first index.
      result = this.next();
    } else {
      // Future index is within page range, select it immediately.
      this.focusToCell(futureIndex, currentFocusedCell?.column);
      this.gridOptions.api.getDisplayedRowAtIndex(futureIndex).setSelected(true, true);
      result = of(true);
    }

    // Add delay to wait for grid row to be properly selected.
    return result.pipe(delay(100));
  }

  /**
   * Select row by data row's id.
   * @param id The id of underlying data row. Set id to 0 and fallbackToFirstRow to true to select first row.
   * @param fallbackToFirstRow If row not found, select first row.
   * @param currentPageOnly Only search and select from current page.
   * @return Observable of boolean indicating selection is success or not.
   */
  public selectById(
    id: number | string,
    fallbackToFirstRow: boolean = true,
    currentPageOnly: boolean = true
  ): Observable<boolean> {
    const selectFromCurrentPage = () => {
      // Find and select row.
      let selected = false;
      this.gridOptions.api.forEachNode((node) => {
        if (!selected && node.data.id === id) {
          node.setSelected(true, true);
          this.gridOptions.api.clearFocusedCell();
          this.gridOptions.api.ensureIndexVisible(node.rowIndex, 'middle');
          selected = true;
        }
      });

      if (selected) {
        return true;
      } else {
        if (!fallbackToFirstRow) {
          return false;
        }

        // If no row is selected, select first row.
        const rowNode = this.gridOptions.api.getRenderedNodes()[0];
        if (rowNode) {
          rowNode.setSelected(true);
          return true;
        } else {
          return false;
        }
      }
    };

    return of({}).pipe(
      mergeMap(() => {
        if (currentPageOnly) {
          return of(selectFromCurrentPage());
        } else {
          if (!this.localData) {
            throw new Error('Select by id across multiple pages only works for local data.');
          }
          const targetIndex = this.value.findIndex((row) => row.id === id);
          const prevFocusAfterLoad = this.focusAfterLoad;
          this.focusAfterLoad = null;
          this._page = targetIndex ? Math.floor(targetIndex / this._take) + 1 : 0;
          return this._load().pipe(
            // Delay to wait for the grid rows to completely rendered before performing selection.
            delay(100),
            map(() => selectFromCurrentPage()),
            finalize(() => (this.focusAfterLoad = prevFocusAfterLoad))
          );
        }
      }),
      // Make sure row selection has finished.
      delay(500)
    );
  }

  /**
   * Search field renderer.
   * @param searchable Searchable to be rendered or @ syntax string as preloadedQuery.
   * @return Relation name and field name rendered as single string separated with dash.
   */
  public renderRelationAndFieldName(searchable: Searchable | string): string {
    return searchable && typeof searchable !== 'string'
      ? this._helperService.renderRelationName(searchable.relation) +
          ' - ' +
          this._helperService.renderFieldName(searchable.field.name)
      : (searchable as string);
  }

  /**
   * On quick search autocomplete option selected handler.
   * @param event MatAutocompleteSelectedEvent args.
   */
  public onFieldOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedSearchable = event.option.value;
    this._q = '@( ' + this.selectedSearchable.display + ' ) = ';
    this.openAtNodeSetting().subscribe();
  }

  /**
   * Open advanced search.
   * @return Observable of boolean indicating advanced search opening is successfuly resulting QAdv or not.
   */
  public openAdvancedSearch(): Observable<boolean> {
    return iif(
      () => this.advancedSearchOpened,
      of(false),
      of(false).pipe(
        tap(() => (this.advancedSearchOpened = !this.advancedSearchOpened)),
        switchMap(() => {
          return this._dialogService
            .openImmediateDialog(AdvancedSearchComponent, null, null, {
              data: {
                resourceURL: this.resourceURL.split('?')[0],
                // Set component's qAdv variable using stored one.
                qAdv: this.qAdv,
              },
              width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '90vw' : '80vw',
              height: '90vh',
            })
            .afterClosed()
            .pipe(
              map((result) => {
                if (result) {
                  // Clear quick search keyword.
                  this._q = '';
                  // Store resulting qAdv and load data.
                  this._qAdv = result;
                  // Load data into the grid.
                  this._load().subscribe();
                }
                return !!result;
              }),
              tap(() => (this.advancedSearchOpened = !this.advancedSearchOpened))
            );
        })
      )
    );
  }

  /**
   * Focus and set range selection to a grid cell.
   * @param rowIndex Row index.
   * @param colKey Grid column.
   */
  public focusToCell(rowIndex?: number, colKey?: string | Column) {
    // Infer current row index and column to focus on.
    if (this.gridOptions.api.getSelectedNodes().length > 0) {
      rowIndex ??= this.gridOptions.api.getSelectedNodes()[0].rowIndex;
      colKey ??=
        this.gridOptions.api.getCellRanges()[0]?.columns[0] ||
        this.gridOptions.columnApi.getAllColumns().find((column) => !column.isPinned());
    } else {
      rowIndex ??= 0;
      colKey ??= this.gridOptions.columnApi.getAllColumns().find((column) => !column.isPinned());
    }

    this.gridOptions.api.clearRangeSelection();
    this.gridOptions.api.addCellRange({
      rowStartIndex: rowIndex,
      rowEndIndex: rowIndex,
      columnStart: colKey,
      columnEnd: colKey,
    });
    this.gridOptions.api.ensureColumnVisible(colKey);
    this.gridOptions.api.ensureIndexVisible(rowIndex);
    this.gridOptions.api.setFocusedCell(rowIndex, colKey);
  }

  /**
   * Refresh current page.
   * @return Observable of boolean indicating refresh is success or not.
   */
  public refresh(): Observable<boolean> {
    return this._load();
  }

  /**
   * Refresh current page only if the data list is visible in the DOM.
   * It is done to avoid hidden inactive data list to perform data load.
   * @return Observable of boolean indicating refresh is success or not.
   */
  public refreshIfVisible(): Observable<boolean> {
    // Give some delay to wait for this data list's DOM element to be fully rendered and visible.
    return timer(100).pipe(
      // Visible data list will get its offsetParent set to a parent element.
      mergeMap(() => (this._elementRef.nativeElement.offsetParent ? this._load() : of(false)))
    );
  }

  /**
   * Schedule a refresh every specified interval only if the data list is visible in the DOM.
   */
  public scheduleRefresh() {
    if (this.refreshScheduled) {
      this.scheduledRefreshSubscription.unsubscribe();
      this.scheduledRefreshInterval = null;
    } else {
      this.scheduledRefreshSubscription = of({})
        .pipe(
          mergeMap(() => {
            if (!this.scheduledRefreshInterval) {
              return this._dialogService
                .openPromptDialog(
                  'Schedule refresh every minutes',
                  ['Data list will be refreshed every minutes according to your choice.'],
                  null,
                  [
                    { display: '1 minute', value: 1 },
                    { display: '3 minutes', value: 3 },
                    { display: '5 minutes', value: 5 },
                    { display: '10 minutes', value: 10 },
                    { display: '15 minutes', value: 15 },
                    { display: '30 minutes', value: 30 },
                    { display: '60 minutes', value: 60 },
                  ],
                  5,
                  PromptDialogType.Select
                )
                .afterClosed();
            } else {
              return of(this.scheduledRefreshInterval);
            }
          }),
          mergeMap((minutes) => {
            if (minutes) {
              this.scheduledRefreshInterval = minutes;
              return timer(0, minutes * 60 * 1000).pipe(
                takeUntil(this._destroySignal),
                // We do not perform scheduled refresh if data list is hidden e.g. when user is on CRUD's editor/reader.
                switchMap(() => this.refreshIfVisible())
              );
            } else {
              return of({});
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * Add row into data list.
   */
  public add(): void {
    if (this.addDisabled) {
      return;
    }

    // Call beforeAdd callback, cancel add if it returns true.
    if (this.beforeAdd && this.beforeAdd()) {
      return;
    }

    if (this.getNewRow) {
      // Clear all sorting.
      this.gridOptions.columnApi.applyColumnState({ defaultState: { sort: null } });

      const newRow = this.getNewRow();
      (newRow instanceof Observable ? newRow : of(newRow ? [newRow] : null))
        .pipe(
          filter((rows) => rows && rows.length > 0),
          tap((rows) => {
            this.dataLoadEnd
              .pipe(
                first(),
                // Manualy set the busy in order to not having to wait for finalize to be executed during completion.
                tap(() => (this.busy = false)),
                switchMap((data) => (this.page < this.totalPages ? this.last().pipe(map(() => data)) : of(data))),
                tap(() => {
                  // Select new row.
                  this.gridOptions.api
                    .getRenderedNodes()
                    [this.gridOptions.api.getRenderedNodes().length - 1].setSelected(true, true);

                  // Clear range selection.
                  this.gridOptions.api.clearRangeSelection();

                  // Focus on first editable cell.
                  if (this.focusFirstEditableCellAfterAdd) {
                    const focusedColDef: ColDef = <any>this.gridOptions.columnDefs.find((colDef) => {
                      return (<any>colDef).editable === true || isFunction((<any>colDef).editable);
                    });
                    if (focusedColDef) {
                      this.gridOptions.api.ensureColumnVisible(focusedColDef.field);
                      // Focus to cell.
                      this.gridOptions.api.setFocusedCell(
                        this.gridOptions.api.getLastDisplayedRow(),
                        focusedColDef.field
                      );
                      // Range-select the focused cell.
                      this.gridOptions.api.addCellRange({
                        rowStartIndex: this.gridOptions.api.getLastDisplayedRow(),
                        rowEndIndex: this.gridOptions.api.getLastDisplayedRow(),
                        columnStart: focusedColDef.field,
                        columnEnd: focusedColDef.field,
                      });
                    }
                  }
                })
              )
              .subscribe();

            this.value = [...this.value, ...rows];
          })
        )
        .subscribe();
    }
  }

  /**
   * Duplicate selected rows into data list.
   */
  public duplicate(): void {
    if (this.duplicateDisabled) {
      return;
    }

    // Get being duplicated rows.
    const selectedRowNodes = this.gridOptions.api.getSelectedNodes();
    const selectedRowsIndex = selectedRowNodes.map((row) => row.rowIndex);
    const duplicates = selectedRowNodes.map((rowNode) => cloneDeep(rowNode.data));

    // Call beforeDuplicate callback, cancel duplication if it returns true.
    if (this.beforeDuplicate && this.beforeDuplicate(duplicates)) {
      return;
    }

    // Temporarily disable focus after load.
    const focusAfterLoad = this.focusAfterLoad;
    this.focusAfterLoad = null;

    this.dataLoadEnd
      .pipe(
        first(),
        tap(() => {
          // Renable focus after load.
          this.focusAfterLoad = focusAfterLoad;

          // First clear current selection before selecting duplicated rows.
          // We call deselectAll() instead of using clearSelection param
          // because it's possible to duplicate multiple rows.
          this.gridOptions.api.deselectAll();

          // Select previously selected (duplicated) rows.
          this.gridOptions.api.getRenderedNodes().forEach((row) => {
            selectedRowsIndex.includes(row.rowIndex) ? row.setSelected(true) : null;
          });
        })
      )
      .subscribe();

    // Add duplicated rows.
    this.value = [...this.value, ...duplicates];
  }

  /**
   * Remove selected rows from data list.
   */
  public remove(): void {
    if (this.removeDisabled) {
      return;
    }

    // Get being removed rows.
    const selectedRowNodes = this.gridOptions.api.getSelectedNodes();
    const selectedRowsIndex = selectedRowNodes.map((row) => row.rowIndex);
    const selectedRows = this.gridOptions.api.getSelectedRows();

    // Call beforeRemove callback, cancel remove if it returns true.
    if (this.beforeRemove && this.beforeRemove(selectedRows)) {
      return;
    }

    this._dialogService.openConfirmDialog(
      'Are you sure want to remove selected items?',
      [`${selectedRows.length} item(s) will be removed. Please make sure you have selected properly.`],
      (confirmed) => {
        if (confirmed) {
          // Remove rows.
          selectedRows.forEach((selected) => {
            this.value.splice(
              this.value.findIndex((item) => item === selected),
              1
            );
          });

          this.dataLoadEnd
            .pipe(
              first(),
              tap(() => {
                // Select previously selected (removed) rows minus one row.
                this.gridOptions.api
                  .getDisplayedRowAtIndex(Math.max(0, selectedRowsIndex[0] - 1))
                  ?.setSelected(true, true);
              })
            )
            .subscribe();

          this.value = [...this.value];
        }
      }
    );
  }

  /**
   * Clear quick and advanced search query without triggering data load.
   */
  public clearQuery() {
    this._q = '';
    this._qAdv = undefined;
  }

  /**
   * Load current page.
   * @return Observable of boolean indicating data loading is success or not.
   */
  private _load(): Observable<boolean> {
    return !this.localData ? (this.resourceURL ? this._get(false) : of(false)) : this._get(true);
  }

  /**
   * Perform a GET request to the backend server or use provided local data to populate this data list.
   * @param local Flag indicating the data whether provided locally or remotely.
   * @return Observable of boolean indicating http request to get the data is success or not.
   */
  private _get(local: boolean): Observable<boolean> {
    // Only perform search if the data list is not busy.
    if (!this._busy) {
      try {
        // If local data is empty array, null or undefined.
        if (local && isEmpty(this.value)) {
          this._clearGrid();
          this.dataLoadEnd.emit(null);
          return of(false);
        }

        // Keep previous focused cell for cell focusing later after data load.
        const previousFocusedCell = this.gridOptions.api.getFocusedCell();

        // Subscribe to search sequence.
        return of({}).pipe(
          tap(() => (this.busy = true)),
          mergeMap(() => this._getTotalRowsCount(local)),
          mergeMap(() => this._getPaginatedRows(local)),
          tap((data) => {
            this.gridOptions.api.clearRangeSelection();

            // true             = select and focus
            // false            = select only
            // null | undefined = no select and no focus
            if (this.focusAfterLoad != null) {
              const row = this.gridOptions.api.getRenderedNodes()[0];
              const column = previousFocusedCell
                ? previousFocusedCell.column
                : this.gridOptions.columnApi.getAllColumns().find((column) => !column.isPinned());

              row.setSelected(true);

              if (this.focusAfterLoad === true) {
                this.focusToCell(0, column);
              }
            }
          }),
          tap((data) => this.dataLoadEnd.emit(data)),
          map((data) => true),
          catchError((error) => {
            // If there's retrieval error, clear the grid.
            this._clearGrid();
            this.dataLoadEnd.emit(null);
            return of(false);
          }),
          finalize(() => (this.busy = false))
        );
      } catch (error) {
        console.error('Data list failed to get data due to the following error:', error);
        this.busy = false;
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  /**
   * Get the total rows count of the specified query.
   * @param local Flag indicating the data whether provided locally or remotely.
   */
  private _getTotalRowsCount(local: boolean): Observable<any> {
    // Rows count observable.
    let rowsCount$: Observable<any>;

    if (local) {
      // Get local data rows count.
      rowsCount$ = of(this.value.length);
    } else {
      // Get remote data rows count.
      rowsCount$ = this._httpRestService
        .get(this.resourceURL, {
          params: {
            q: this._stringifyQ(),
            c: '1',
          },
        })
        .pipe(map((result) => result.data.count));
    }

    // Return as observable.
    return rowsCount$.pipe(
      tap((count) => {
        // Get total rows.
        this._totalRows = count;

        // Get total pages.
        this._totalPages = Math.ceil(this._totalRows / this._take);

        if (this._totalPages === 0) {
          // No data found, set page to 0.
          this._page = 0;
        } else if ((this._page === 0 && this._totalPages > 0) || this._page > this._totalPages) {
          // Data found and user has not set the selected page or
          // new total pages is greater than previous selected
          // page, so we set first page as the selected page.
          this._page = 1;
        }
      })
    );
  }

  /**
   * Get the actual paginated data rows of the specified query.
   * @param local Flag indicating the data whether provided locally or remotely.
   */
  private _getPaginatedRows(local: boolean): Observable<any> {
    // If previous total rows count retrieval is zero, we simply just skip the actual data retrieval by returning error observable.
    if (this._totalRows === 0) {
      return throwError('');
    }

    // Paginated rows observable.
    let paginatedRows$: Observable<any>;

    if (local) {
      // Get local paginated rows.
      paginatedRows$ = of(
        this.value.filter((value, index) => {
          const start: number = (this._page - 1) * this._take;
          const end: number = start + this._take;
          return index >= start && index < end;
        })
      );
    } else {
      // Get remote paginated rows.
      paginatedRows$ = this._httpRestService
        .get(this.resourceURL, {
          params: {
            q: this._stringifyQ(),
            d: '0',
            skip: ((this._page - 1) * this._take).toString(),
            take: this._take.toString(),
          },
        })
        .pipe(map((result) => result.data));
    }

    // Return as observable.
    return paginatedRows$.pipe(
      tap((data) => {
        this.gridOptions.api.setRowData(data);
        this.gridOptions.api.deselectAll();
        this.gridOptions.api.clearFocusedCell();
      })
    );
  }

  /**
   * Clear grid data.
   */
  private _clearGrid() {
    this._totalRows = 0;
    this._totalPages = 0;
    this._page = 0;
    this.gridOptions.api.deselectAll();
    this.gridOptions.api.setRowData([]);
  }

  /**
   * Create stringified JSON of the query (quick and adv).
   */
  private _stringifyQ() {
    // Set adv and quick query.
    let adv = this._qAdv ? this._queryService.extract(this._qAdv) : null;
    let quick = this._q ? this._q : null;

    // Build adv out of quick search "@" keyword.
    const match = this._q.match(this.AT_SYNTAX_FULL);
    if (this.selectedSearchable && match && this.selectedSearchable.display === match[1] && match[2]) {
      // Get default qAdv.
      const qAdv: QAdv = this._queryService
        .getQAdvPresets(this.resourceURL.split('?')[0])
        .find((item) => item.name === this._queryService.DEFAULT_QADV_PRESET_NAME);
      // Build where node.
      const whereFieldNode = NodeComponent.createWhereFieldNode(
        'and',
        this.selectedSearchable.field.name,
        match[2].toLowerCase(),
        match[3]
      );

      // Push the created where node.
      if (this.selectedSearchable.relation === 'this') {
        qAdv.w.g.push(whereFieldNode);
      } else {
        qAdv.h.find((h) => h.r === this.selectedSearchable.relation).g.push(whereFieldNode);
      }

      // Replace adv with the new one.
      adv = this._queryService.extract(qAdv);
      quick = null;
    } else {
      if (this._q.startsWith('@')) {
        this._helperService.toast('Invalid @ syntax.');
        throw Error('Invalid @ syntax.');
      }
    }

    // Return stringify query.
    return JSON.stringify({ quick, adv });
  }

  /**
   * Select row by row id.
   * @param rowId Next row id to be selected.
   * @param deselectAll Deselect currently selected rows before selecting new row.
   */
  private _selectRowById(rowId: string, deselectAll: boolean) {
    if (rowId == null) {
      return;
    }

    if (deselectAll) {
      this.gridOptions.api.deselectAll();
    }

    this.gridOptions.api.forEachNode((node) => {
      if (node.level === 0) {
        if (node.id === rowId) {
          node.setSelected(true);
        }
      } else {
        if (node.parent.expanded) {
          if (node.id === rowId) {
            node.setSelected(true);
          }
        }
      }
    });
  }

  /**
   * Deselect row by row id.
   * @param rowId Next row id to be selected.
   */
  private _deselectRowById(rowId: string) {
    if (rowId == null) {
      return;
    }

    this.gridOptions.api.forEachNode((node) => {
      if (node.level === 0) {
        if (node.id === rowId) {
          node.setSelected(false);
        }
      } else {
        if (node.parent.expanded) {
          if (node.id === rowId) {
            node.setSelected(false);
          }
        }
      }
    });
  }
}
