import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';

import { MultiSelectCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom multi select editor component.
 */
@Component({
  selector: 'app-multi-select-cell-editor',
  templateUrl: './multi-select-cell-editor.component.html',
  styleUrls: ['./multi-select-cell-editor.component.scss'],
})
export class MultiSelectCellEditorComponent implements AfterViewInit, AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: MultiSelectCellEditorParams;

  /**
   * Selected options as array.
   */
  public selectedOptions: { [key: string]: any }[] = [];

  /**
   * Select form control's ElementRef.
   */
  @ViewChild('select', { read: ElementRef, static: true })
  private _selectElement: ElementRef;

  /**
   * Constructor.
   */
  public constructor() {}

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit(): void {
    setTimeout(() => this._selectElement.nativeElement.focus());
  }

  /**
   * Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: MultiSelectCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Set the select's width and height.
    this._selectElement.nativeElement.style['min-width'] = params.eGridCell.offsetWidth + 'px';
    this._selectElement.nativeElement.style['height'] = '144px';

    // Set initial selected value.
    const initialSelected = this.params.options.filter((option) => {
      // We grab original value using colDef.field because params.value is derived from colDef.valueGetter() which could modify the value.
      return (
        this.params.data[this.params.colDef.field]?.find((value: any) => value === option[this.params.valueKey]) !==
        undefined
      );
    });

    // Initilize selected options.
    this.selectedOptions = initialSelected;
  }

  /**
   * Return the final value to the grid, the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    return this.selectedOptions ? this.selectedOptions.map((option) => option[this.params.valueKey]) : [];
  }

  /**
   * Gets called once after initialised. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    // Need to be popped up otherwise dropdown will be clipped inside grid cell.
    return true;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    return this.params.validate ? !this.params.validate(this.selectedOptions, this.params.node) : false;
  }
}
