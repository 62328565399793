import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { cloneDeep, extend } from 'lodash-es';

import { DialogService } from '../../dialog/dialog.service';
import { PopupCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom popup editor component.
 * Changes will be applied if the popup dialog is closed with truthy result.
 */
@Component({
  selector: 'app-popup-cell-editor',
  templateUrl: './popup-cell-editor.component.html',
  styleUrls: ['./popup-cell-editor.component.scss'],
})
export class PopupCellEditorComponent<T> implements AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: PopupCellEditorParams<T>;

  /**
   * Cloned data so that original data will not affected if the user cancel the editor.
   */
  private _data: any;

  /**
   * Flag indicating the popped up dialog is closed with truthy result.
   */
  private _dialogResultIsTruthy: boolean;

  /**
   * Constructor.
   */
  constructor(private _dialogService: DialogService) {}

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: PopupCellEditorParams<T>): void {
    this.params = params;

    this._dialogService.openImmediateDialog(
      this.params.component,
      null,
      (result) => {
        // Changes will be applied if the popup dialog is closed with truthy result.
        this._dialogResultIsTruthy = result ? true : false;
        this.params.stopEditing();
      },
      extend(
        {
          data: {
            data: this._data = cloneDeep(this.params.data),
            triggerField: this.params.triggerField,
            sanitize: (value: any) => (this.params.sanitize ? this.params.sanitize(value, this.params.node) : value),
            validate: (value: any) => (this.params.validate ? this.params.validate(value, this.params.node) : true),
          },
        },
        this.params.dialogConfig ? this.params.dialogConfig : {}
      )
    );
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    // Apply the additonal fields' changes.
    if (this.params.additionalFields) {
      this.params.additionalFields.forEach((field) => (this.params.data[field] = this._data[field]));
    }
    // Return the trigger field column's value as editing result.
    return this._data[this.params.triggerField];
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    return this._dialogResultIsTruthy === false;
  }
}
