import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import * as moment from 'moment-mini';

import { DatetimeCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom datetime editor component.
 */
@Component({
  selector: 'app-datetime-cell-editor',
  templateUrl: './datetime-cell-editor.component.html',
  styleUrls: ['./datetime-cell-editor.component.scss'],
})
export class DatetimeCellEditorComponent implements AfterViewInit, AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: DatetimeCellEditorParams;

  /**
   * Minimum value allowed.
   */
  public min: moment.Moment;

  /**
   * Maximum value allowed.
   */
  public max: moment.Moment;

  /**
   * Cancel editing before start flag.
   */
  private _cancelBeforeStart = false;

  /**
   * Select form control's ElementRef.
   */
  @ViewChild('datetime', { read: ElementRef, static: true })
  private _datetimeElement: ElementRef;

  /**
   * Constructor.
   */
  public constructor() {}

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit(): void {
    setTimeout(() => this._datetimeElement.nativeElement.focus());
  }

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: DatetimeCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Set the number's width and height to fit it's parent cell.
    this._datetimeElement.nativeElement.style.width = params.eGridCell.offsetWidth + 'px';
    this._datetimeElement.nativeElement.style.height = params.eGridCell.offsetHeight + 'px';

    // Set min. max, step properties.
    this.min = this.params.min;
    this.max = this.params.max;

    // Set initial value.
    const currentValue = moment(params.value);
    this._datetimeElement.nativeElement.value = currentValue.isValid()
      ? currentValue.format('YYYY-MM-DDTHH:mm')
      : moment().format('YYYY-MM-DDTHH:mm');
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    return moment(this._datetimeElement.nativeElement.value);
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will not shown.
   * @return True if editor is canceled before start.
   */
  public isCancelBeforeStart(): boolean {
    return this._cancelBeforeStart;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    const value = moment(this._datetimeElement.nativeElement.value);
    const outOfBound = (this.min ? value < this.min : false) || (this.max ? value > this.max : false);
    const invalid = this.params.validate && !this.params.validate(value, this.params.node) ? true : false;
    return outOfBound || invalid ? true : false;
  }

  /**
   * Prevent grid to stop editing on left, up, right, and down key down so that the user can move the cursor inside the input element.
   * @param event Browser's onKeyDown event args.
   */
  public onKeyDown(event: KeyboardEvent): void {
    if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(event.key)) {
      event.stopPropagation();
    }
  }
}
