import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community/main';
import { map, pick } from 'lodash-es';
import { of } from 'rxjs';
import { delay, mergeMap, tap } from 'rxjs/operators';

import { QAdv } from '../../advanced-search/q-adv.interface';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

/**
 * Lookup dialog component.
 */
@Component({
  selector: 'app-lookup-dialog',
  templateUrl: './lookup-dialog.component.html',
  styleUrls: ['./lookup-dialog.component.scss'],
})
export class LookupDialogComponent implements OnInit, AfterViewInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Datalist component.
   */
  @ViewChild('datalist')
  public datalist: any; // Use 'any' to avoid ts warning of cicular dependency.

  /**
   * Resource URL.
   */
  public resourceURL: string;

  /**
   * AG Grid column definitions.
   */
  public columnDefs: {}[];

  /**
   * Selection validation function.
   */
  public validate: (selectedRows: any[]) => boolean;

  /**
   * AG Grid row selection mode, 'single' or 'multiple'.
   */
  public rowSelection: 'single' | 'multiple';

  /**
   * Selected fields to pass as lookup result.
   */
  public selectedFields: string[];

  /**
   * Data list's preloaded query.
   */
  public preloadedQuery: string | QAdv;

  /**
   * Auto select the first or all of the search result if any
   */
  public autoSelect: 'first' | 'all';

  /**
   * AG Grid's options.
   */
  public gridOptions: GridOptions;

  /**
   * Data List height adapting with dialog's height resizing.
   * wrapper - header - (content padding + mat-card padding + divider) * 2 - actionBar
   */
  public get dataListHeight() {
    return this.dialog.height ? `${+this.dialog.height.split('px')[0] - 64 - (24 + 16 + 1) * 2 - 52}px` : '480px';
  }

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<LookupDialogComponent>) {
    this.resourceURL = data.resourceURL;
    this.columnDefs = data.columnDefs;
    this.validate = data.validate;
    this.rowSelection = data.rowSelection ? data.rowSelection : 'single';
    this.selectedFields = data.selectedFields ? data.selectedFields : undefined;
    this.preloadedQuery = data.preloadedQuery;
    this.autoSelect = data.autoSelect;
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'left',
          },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' },
        ],
      },
      onCellDoubleClicked: () => this.select(),
    };
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit() {
    // Immediately run datalist refresh and then select first or all of the search result.
    if (this.autoSelect) {
      of({})
        .pipe(
          delay(100),
          mergeMap(() => this.datalist.refresh()),
          delay(100),
          tap(() => {
            if (this.datalist.totalRows > 0) {
              if (this.autoSelect === 'all') {
                this.datalist.gridOptions.api.selectAll();
              } else {
                this.datalist.gridOptions.api.getRenderedNodes()[0].setSelected(true);
              }
              this.select();
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * On Keydown handler.
   * @param event Keypress event args.
   */
  public onKeydown(event: KeyboardEvent): void {
    // Select item on Enter key pressed and focus is on ag-grid cell.
    if (document.activeElement.classList.contains('ag-cell') && event.key === 'Enter') {
      this.select();
    }
    if (event.key === 'Escape') {
      this.close();
    }
    if (event.key === 'Delete' && event.ctrlKey) {
      this.clear();
    }
  }

  /**
   * Close the dialog and return undefined as result.
   */
  public close(): void {
    this._dialogRef.close();
  }

  /**
   * Close the dialog and return the selected items as result.
   */
  public select(): void {
    const selectedRows = this._dialogRef.componentInstance.gridOptions.api.getSelectedRows();
    // If rows are selected and valid.
    if (selectedRows.length > 0 && (this.validate ? this.validate(selectedRows) !== false : true)) {
      this._dialogRef.close(
        this.selectedFields ? map(selectedRows, (selectedRow) => pick(selectedRow, this.selectedFields)) : selectedRows
      );
    }
  }

  /**
   * Close the dialog and return null as result.
   */
  public clear(): void {
    this._dialogRef.close(null);
  }
}
