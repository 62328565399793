import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { saveAs } from 'file-saver';
import { iif, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';
import { Mode } from '../advanced-search/mode.enum';
import { QAdv } from '../advanced-search/q-adv.interface';
import { QueryService } from '../advanced-search/query.service';
import { DialogService } from '../dialog/dialog.service';
import { HttpRestService } from '../services/http-rest.service';
import { ReportPreset } from './report-preset.interface';
import { Report } from './report.interface';

/**
 * Report generator service.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  /**
   * Reports for all resources.
   */
  public presets: ReportPreset[] = [];

  /**
   * Last used qAdvs.
   */
  private _qAdvs: {} = {};

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _httpRestService: HttpRestService,
    private _mediaObserver: MediaObserver,
    private _queryService: QueryService
  ) {}

  /**
   * Get filtered reports.
   * @param predicate Filter predicate.
   */
  public get(predicate: (item: ReportPreset) => boolean): ReportPreset[] {
    return this.presets.filter(predicate);
  }

  /**
   * Build report.
   * @param resourceURL Resource URL of the report being generated.
   * @param report Selected report object.
   * @param format Selected report format. User will be prompted to choose if not provided.
   * @param showQueryPage Show or hide query page flag. User will be prompted to choose if not provided.
   * @returns Observable of boolean indicating if the report is successfully generated or not.
   */
  public build(
    resourceURL: string,
    report: Report,
    format: string = null,
    showQueryPage: boolean = null
  ): Observable<boolean> {
    return format != null && showQueryPage != null
      ? this._openAdvancedSearch(resourceURL, report.name, report.params, format, showQueryPage, report.custom)
      : of({}).pipe(
          switchMap((prev) =>
            iif(
              () => format == null,
              this._dialogService
                .openPromptDialog(
                  'Report Format',
                  ['Please choose report format you want to generate.'],
                  null,
                  report.formats.map((item) => ({ display: item, value: item })),
                  'PDF',
                  null,
                  null,
                  null,
                  true
                )
                .afterClosed()
                .pipe(
                  map((result) => {
                    if (result == undefined) {
                      throw new Error('Report format dialog canceled.');
                    }
                    return { ...prev, format: result };
                  })
                ),
              of({ ...prev, format })
            )
          ),
          switchMap((prev) =>
            iif(
              () => showQueryPage == null,
              this._dialogService
                .openConfirmDialog(
                  'Show Query Page',
                  ['Do you want to show the query page?'],
                  null,
                  true,
                  'SHOW',
                  'HIDE'
                )
                .afterClosed()
                .pipe(map((result) => ({ ...prev, showQueryPage: result }))),
              of({ ...prev, showQueryPage })
            )
          ),
          switchMap((result) =>
            result
              ? this._openAdvancedSearch(
                  resourceURL,
                  report.name,
                  report.params,
                  result.format,
                  result.showQueryPage,
                  report.custom
                )
              : of(false)
          ),
          catchError(() => of(false))
        );
  }

  /**
   * Show advanced search to build report query.
   * @param resourceURL Resource URL of the report being generated.
   * @param reportName Selected report name.
   * @param reportParams Callback which return observable of report params.
   * @param reportFormat Selected report format.
   * @param reportShowQueryPage Show or hide query page flag.
   * @param reportCustom Selected report custom flag.
   * @returns Observable of boolean indicating if the report is successfully generated or not.
   */
  private _openAdvancedSearch(
    resourceURL: string,
    reportName: string,
    reportParams: () => Observable<any>,
    reportFormat: string,
    reportShowQueryPage: boolean,
    reportCustom: boolean
  ): Observable<boolean> {
    // If available get last used qAdv, otherwise we set to undefined and it'll use report id as default preset name.
    const qAdv: QAdv =
      this._qAdvs[resourceURL] && this._qAdvs[resourceURL][reportName]
        ? this._qAdvs[resourceURL][reportName]
        : undefined;

    return this._dialogService
      .openImmediateDialog(AdvancedSearchComponent, null, null, {
        data: {
          resourceURL: resourceURL,
          qAdv: qAdv,
          defaultQAdvPresetName: reportName,
        },
        width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '90vw' : '80vw',
        height: '90vh',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result) {
            // Store resulting qAdv.
            this._qAdvs[resourceURL] = this._qAdvs[resourceURL] || {};
            this._qAdvs[resourceURL][reportName] = result;
            // Generate report.
            return this._generate(
              resourceURL,
              reportName,
              reportParams,
              reportFormat,
              reportShowQueryPage,
              reportCustom,
              result
            );
          } else {
            return of(false);
          }
        })
      );
  }

  /**
   * Get default qAdv preset for selected report name.
   * @param resourceURL Resource URL of the report being generated.
   * @param id Selected report id
   * @returns Default qAdv.
   */
  private _getDefaultQAdvPreset(resourceURL: string, id: string): QAdv {
    const qAdvPreset = this._queryService.getQAdvPresets(resourceURL).find((item) => item.name === id);

    // Set default mode if it is not defined (the preset is applicable for Favourite and Builder mode).
    if (qAdvPreset) {
      qAdvPreset.mode = qAdvPreset.mode || Mode.Favourite;
    }

    return qAdvPreset ? qAdvPreset : undefined;
  }

  /**
   * Generate report.
   * @param resourceURL Resource URL of the report being generated.
   * @param reportName Selected report name.
   * @param reportParams Callback which return observable of report params.
   * @param reportFormat Selected report format.
   * @param reportShowQueryPage Show or hide query page flag.
   * @param reportCustom Selected report custom flag.
   * @param qAdv Built qAdv.
   * @returns Observable of boolean indicating if the report is successfully generated or not.
   */
  private _generate(
    resourceURL: string,
    reportName: string,
    reportParams: () => Observable<any>,
    reportFormat: string,
    reportShowQueryPage: boolean,
    reportCustom: boolean,
    qAdv: QAdv
  ): Observable<boolean> {
    let dialogRef;

    // Send GET request to generate report.
    return ((reportParams && reportParams()) || of({})).pipe(
      tap(() => {
        dialogRef = this._dialogService.openProcessingDialog('Generating report');
      }),
      mergeMap((reportParams) => {
        return this._httpRestService.get(`${resourceURL}/report`, {
          params: {
            q: JSON.stringify({
              quick: null,
              adv: this._queryService.extract(qAdv),
            }),
            reportName: reportName,
            reportParams: JSON.stringify(reportParams),
            reportFormat: reportFormat,
            reportShowQueryPage: +reportShowQueryPage,
            reportCustom: reportCustom || 0,
          },
          responseType: 'blob',
          observe: 'response',
        });
      }),
      tap((response) => {
        // Get report filename. We check for null value due to CORS
        // policy which does not expose Content-Disposition header
        // when accessed from localhost (for development purpose)
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '')
          : Date.now();
        // Save report to local disk.
        saveAs(response.body, filename);
      }),
      map(() => true),
      catchError(() => of(false)),
      finalize(() => this._dialogService.closeProcessingDialog(dialogRef))
    );
  }
}
