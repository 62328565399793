<!-- field-node -->
<div *ngIf="node.f !== undefined" class="field-node">
  <!-- field -->
  <button #focusable mat-raised-button (click)="select()" (dblclick)="onDblClick()" [color]="color">
    <span class="boolean">{{ node.b }}</span> <span class="field">{{ renderFieldName(node.f) }}</span>
    <span class="operator">{{ node.o }}</span> <span class="value">{{ node.v }}</span>
  </button>

  <!-- action-buttons -->
  <button
    mat-icon-button
    *ngIf="active"
    (click)="setting()"
    color="primary"
    matTooltip="Setting"
    matTooltipShowDelay="500"
  >
    <mat-icon>settings</mat-icon>
  </button>
  <button mat-icon-button *ngIf="active" (click)="remove()" color="warn" matTooltip="Remove" matTooltipShowDelay="500">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<!-- group-node -->
<mat-card *ngIf="node.g !== undefined" class="group-node">
  <!-- header -->
  <button
    #focusable
    mat-raised-button
    (click)="select()"
    (dblclick)="onDblClick()"
    class="group-node-header"
    [color]="color"
  >
    <span fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="rootNode">
        <!-- main (this) root group node -->
        <span *ngIf="node.r === undefined">{{ node.type === 'where' ? 'WHERE' : 'ORDER BY' }} THIS</span>
        <!-- relation's root group node -->
        <span *ngIf="node.r !== undefined">
          {{ node.b }} {{ renderRelationName(node.r, node.c) }}
          <span *ngIf="node.g.length > 0">WHERE</span>
        </span>
      </span>
      <!-- logical group node -->
      <span *ngIf="!rootNode">{{ node.b }}</span>
    </span>
  </button>

  <!-- child-node -->
  <app-node
    *ngFor="let childNode of node.g"
    [resourceURL]="resourceURL"
    [node]="childNode"
    [parentNode]="node"
  ></app-node>

  <!-- action-buttons -->
  <div fxLayout="row" fxLayoutAlign="end start">
    <button
      mat-icon-button
      *ngIf="active"
      (click)="addField()"
      color="primary"
      matTooltip="Add Field"
      matTooltipShowDelay="500"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="active && whereNode"
      (click)="addGroup()"
      color="primary"
      matTooltip="Add Group"
      matTooltipShowDelay="500"
    >
      <mat-icon>library_add</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="active && (whereHasGroupNode || !rootNode)"
      (click)="setting()"
      color="primary"
      matTooltip="Setting"
      matTooltipShowDelay="500"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="active && !rootNode"
      (click)="remove()"
      color="warn"
      matTooltip="Remove"
      matTooltipShowDelay="500"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-card>
