import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';

import { SelectCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom select editor component.
 */
@Component({
  selector: 'app-select-cell-editor',
  templateUrl: './select-cell-editor.component.html',
  styleUrls: ['./select-cell-editor.component.scss'],
})
export class SelectCellEditorComponent implements AfterViewInit, AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: SelectCellEditorParams;

  /**
   * Selected option.
   */
  public selectedOption: { [key: string]: any };

  /**
   * Select form control's ElementRef.
   */
  @ViewChild('select', { read: ElementRef, static: true })
  private _selectElement: ElementRef;

  /**
   * Constructor.
   */
  public constructor() {}

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit(): void {
    setTimeout(() => this._selectElement.nativeElement.focus());
  }

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: SelectCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Set the select's width and height.
    this._selectElement.nativeElement.style.width = params.eGridCell.offsetWidth + 'px';
    this._selectElement.nativeElement.style.height = params.eGridCell.offsetHeight + 'px';

    // Initilize selected option.
    this.selectedOption = this.params.options.find(
      // We grab value using colDef.field because params.value is derived from colDef.valueGetter().
      (option) => option[this.params.valueKey] === this.params.data[this.params.colDef.field]
    );
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    return this.selectedOption ? this.selectedOption[this.params.valueKey] : null;
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    return this.params.validate ? !this.params.validate(this.selectedOption, this.params.node) : false;
  }

  /**
   * Tell editor to stop editing on option clicked.
   */
  public onClick(): void {
    // Stop editing and trigger returning of the editing result.
    this.params.stopEditing();
  }

  /**
   * Prevent grid to stop editing on left, up, right, and down key down so that the user can move the selected option.
   * @param event Browser's onKeyDown event args.
   */
  public onKeyDown(event: KeyboardEvent): void {
    if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(event.key)) {
      event.stopPropagation();
    }
  }
}
