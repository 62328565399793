import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { empty, fromEvent, interval, Observable, of, Subject, throwError } from 'rxjs';
import { concatMap, delayWhen, filter, map, mergeMap, retry, switchMap, takeUntil, tap, throttle } from 'rxjs/operators';

import { BaseDialogComponent } from '../dialog/base-dialog/base-dialog.component';
import { DraggableDirective } from '../directives/draggable.directive';
import { Navigable } from './navigable.interface';
import { ViewedImage } from './viewed-image.interface';

/**
 * Image viewer dialog component.
 */
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements AfterViewInit, OnDestroy {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Dialog wrapper's ElementRef.
   */
  @ViewChild('wrapper', { read: ElementRef })
  private _wrapper: ElementRef;

  /**
   * IMG ElementRef.
   */
  @ViewChild('img', { read: ElementRef })
  private _img: ElementRef;

  /**
   * Draggable directive instance.
   */
  @ViewChild('img', { read: DraggableDirective })
  private _imgDraggable: DraggableDirective;

  /**
   * Image data.
   */
  public imageData: string;

  /**
   * Image alt text.
   */
  public alt: String;

  /**
   * Image original width.
   */
  public width: number;

  /**
   * Image original height.
   */
  public height: number;

  /**
   * Image gallery.
   */
  public gallery: Navigable;

  /**
   * Max dialog height.
   */
  private _maxDialogHeight: number = (window.innerHeight * 90) / 100;

  /**
   * Max dialog width.
   */
  private _maxDialogWidth: number = (window.innerWidth * 95) / 100;

  /**
   * Pointer event cache to track 2 pointers when pinch zooming.
   */
  private _pointerEventCache: PointerEvent[] = [];

  /**
   * Keep track 2 pointers distance from the last occured pointer event.
   */
  private _prevPointerDiff = -1;

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ImageViewerComponent>,
    private _ngZone: NgZone,
    private _overlayContainer: OverlayContainer
  ) {
    this.gallery = data.gallery;
    this._initImage(data);
  }

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit() {
    // Setup events.
    this._ngZone.runOutsideAngular(() => {
      this._subscribePointerDown();
      this._subscribePointerUp();
      this._subscribePointerMove();

      this._subscribeMouseWheel();

      this._subscribeDragChange();
    });

    // Add custom class on the cdk-overlay-pane.
    this._dialogRef.addPanelClass('image-viewer');

    if (!this.width || !this.height) {
      // Use settimeout to wait for the IMG tag finish loading the image.
      setTimeout(() => {
        // Get the original image dimension.
        this.width = this._img.nativeElement.naturalWidth;
        this.height = this._img.nativeElement.naturalHeight;

        this._initPanel();
      });
    } else {
      this._initPanel();
    }
  }

  /**
   * Angular life cycle hook.
   */
  public ngOnDestroy() {
    // Emit destroying signal.
    this._destroySignal.next();
  }

  /**
   * Show prev image.
   */
  public prev(): Observable<ViewedImage> {
    return this.gallery
      ? this.gallery.getPrevViewedImage().pipe(
          tap((data) => {
            this._initImage(data);
            this._resizeToFit();
          })
        )
      : empty();
  }

  /**
   * Show next image.
   */
  public next(): Observable<ViewedImage> {
    return this.gallery
      ? this.gallery.getNextViewedImage().pipe(
          tap((data) => {
            this._initImage(data);
            this._resizeToFit();
          })
        )
      : empty();
  }

  /**
   * Close the dialog.
   */
  public close() {
    this._dialogRef.close();
  }

  /**
   * Subscribe to pointer down event.
   */
  private _subscribePointerDown() {
    fromEvent<PointerEvent>(this._img.nativeElement, 'pointerdown')
      .pipe(
        takeUntil(this._destroySignal),
        // Add to cache and wait until completed before perforimg another add.
        concatMap((event) => {
          if (this._pointerEventCache.length < 2) {
            // The pointerdown event signals the start of a touch interaction.
            // This event is cached to support 2-finger gestures.
            this._pointerEventCache.push(event);
          }

          // Disable native browser's touchAction.
          if (this._pointerEventCache.length > 0) {
            this._img.nativeElement.style.touchAction = 'none';
          }

          return of(event);
        }),
        retry()
      )
      .subscribe();
  }

  /**
   * Subscribe to pointer up event.
   */
  private _subscribePointerUp() {
    fromEvent<PointerEvent>(this._img.nativeElement, 'pointerup')
      .pipe(
        takeUntil(this._destroySignal),
        // Clear from cache and wait until completed before performing another clearing.
        concatMap((event) => {
          // Remove this event from the target's event cache.
          for (let i = 0; i < this._pointerEventCache.length; i++) {
            if (this._pointerEventCache[i].pointerId == event.pointerId) {
              this._pointerEventCache.splice(i, 1);
              break;
            }
          }

          // If the number of pointers down is less than two.
          if (this._pointerEventCache.length < 2) {
            // Reset diff tracker.
            this._prevPointerDiff = -1;
          }

          // Reenable native browser's touchAction.
          if (this._pointerEventCache.length <= 0) {
            this._img.nativeElement.style.touchAction = '';
          }

          return of(event);
        }),
        retry()
      )
      .subscribe();
  }

  /**
   * Subscribe to pointer move event.
   */
  private _subscribePointerMove() {
    fromEvent<PointerEvent>(this._img.nativeElement, 'pointermove')
      .pipe(
        takeUntil(this._destroySignal),
        // If excatly two pointers are down, then perform pinch zoom.
        filter((event) => this._pointerEventCache.length == 2),
        map((event) => {
          // Find this event in the cache and update its record with this event.
          for (let i = 0; i < this._pointerEventCache.length; i++) {
            if (event.pointerId == this._pointerEventCache[i].pointerId) {
              this._pointerEventCache[i] = event;
              break;
            }
          }

          // Calculate the distance between the two pointers.
          const currPointerDiffX = Math.abs(this._pointerEventCache[0].pageX - this._pointerEventCache[1].pageX);
          const currPointerDiffY = Math.abs(this._pointerEventCache[0].pageY - this._pointerEventCache[1].pageY);
          const currPointerDiff = Math.max(currPointerDiffX, currPointerDiffY);

          // Find top and left of the two pointers.
          const leftMostX = Math.min(this._pointerEventCache[0].pageX, this._pointerEventCache[1].pageX);
          const topMostY = Math.min(this._pointerEventCache[0].pageY, this._pointerEventCache[1].pageY);

          // Construct zoom params.
          const zoomParams = {
            pageX: leftMostX + currPointerDiffX / 2,
            pageY: topMostY + currPointerDiffY / 2,
            prevPointerDiff: this._prevPointerDiff,
            currPointerDiff: currPointerDiff,
            // Pinch spread of 10px will make 10% zoom in ratio.
            zoomRatio:
              ((0.1 * Math.abs(this._prevPointerDiff - currPointerDiff)) / 10) *
              // Current > Previous, then zoom in, else zoom out
              (currPointerDiff > this._prevPointerDiff ? 1 : -1),
          };

          // Update the previous pointer diff with current pointer diff.
          this._prevPointerDiff = currPointerDiff;

          return zoomParams;
        }),
        // Prevent first time zoom when previous pointer diff is still -1.
        filter((zoomParams) => zoomParams.prevPointerDiff > 0),
        // Throttle zoom until previous drag complete.
        throttle(() => this._imgDraggable.dragChange),
        // Zoom and wait until completed before performing another zoom.
        concatMap((zoomParams) => {
          return this._zoom(zoomParams.pageX, zoomParams.pageY, zoomParams.zoomRatio).pipe(
            // Wait until the align to edge finished, delay time is adjusted based
            // on zoom ratio, the larger ratio the longer the delay needed.
            delayWhen((zoomResult) => {
              const correction = this._calcAlignCorrection(zoomResult.offset);
              return correction.x == null && correction.y == null ? interval(0) : interval(25);
            })
          );
        }),
        retry()
      )
      .subscribe();
  }

  /**
   * Subscribe to mouse wheel event.
   */
  private _subscribeMouseWheel() {
    // Perform zoom on image wheel.
    fromEvent<WheelEvent>(this._img.nativeElement, 'wheel')
      .pipe(
        takeUntil(this._destroySignal),
        // Prevent navigating on image wheel due to event bubbling to the OverlayContainer.
        tap((event) => event.stopPropagation()),
        // Throttle zoom until previous drag complete.
        throttle(() => this._imgDraggable.dragChange),
        // Zoom and wait until zooming complete before performing another zoom.
        concatMap((event) => {
          const zoomIn = event.deltaY < 0;
          // Single wheel scroll will make 10% zoom in/out ratio.
          const zoomRatio = 0.1 * (zoomIn ? 1 : -1);
          // Perform zoom.
          return this._zoom(event.pageX, event.pageY, zoomRatio).pipe(
            // Wait until the align to edge finished, delay time is adjusted based
            // on zoom ratio, the larger ratio the longer the delay needed.
            delayWhen((zoomResult) => {
              const correction = this._calcAlignCorrection(zoomResult.offset);
              return correction.x == null && correction.y == null ? interval(0) : interval(25);
            })
          );
        }),
        retry()
      )
      .subscribe();

    // Perform navigating on overlay container wheel.
    this._ngZone.run(() =>
      // Run inside angular so that the image will be refreshed after navigating.
      fromEvent<WheelEvent>(this._overlayContainer.getContainerElement(), 'wheel')
        .pipe(
          takeUntil(this._destroySignal),
          throttle(() => interval(100)),
          switchMap((event) => {
            const prev = event.deltaY < 0;
            return prev ? this.prev() : this.next();
          })
        )
        .subscribe()
    );
  }

  /**
   * Subscribe to draggable's dragChange event.
   */
  private _subscribeDragChange() {
    this._imgDraggable.dragChange
      .pipe(
        takeUntil(this._destroySignal),
        // tap(() => console.log('dragChange listener called')),
        mergeMap((currentOffset) => this._alignToEdge(currentOffset))
      )
      .subscribe();
  }

  /**
   * Calculate necessary offset correction of unaligned image.
   * @param checkedOffset Current offset to be checked.
   * @returns Correction offset.
   */
  private _calcAlignCorrection(checkedOffset: { x: number; y: number }): { x: number; y: number } {
    const minX = (this._img.nativeElement.clientWidth - this._wrapper.nativeElement.clientWidth) * -1;
    const minY = (this._img.nativeElement.clientHeight - this._wrapper.nativeElement.clientHeight) * -1;

    let correction = { x: null, y: null };

    // Correct overflow translate for X and Y.
    if (Math.round(checkedOffset.x) > 0) {
      correction.x = 0;
    } else if (Math.round(checkedOffset.x) < minX) {
      correction.x = minX;
    }
    if (Math.round(checkedOffset.y) > 0) {
      correction.y = 0;
    } else if (Math.round(checkedOffset.y) < minY) {
      correction.y = minY;
    }

    return correction;
  }

  /**
   * Perform drag to align image to the edge.
   * @param currentOffset Current offset.
   * @return Observable of corrected offset.
   */
  private _alignToEdge(
    currentOffset: { x: number; y: number },
    rate: number = null
  ): Observable<{ x: number; y: number }> {
    const correction = this._calcAlignCorrection(currentOffset);

    // console.log('correcting current offset', currentOffset);
    // console.log('correcting to offset', correction);

    if (correction.x !== null || correction.y !== null) {
      return this._imgDraggable.dragTo(
        correction.x !== null ? correction.x : currentOffset.x,
        correction.y !== null ? correction.y : currentOffset.y
      );
    } else {
      return of(currentOffset);
    }
  }

  /**
   * Perform image zoom.
   * @param pageX Current pointer's X position relative to the page.
   * @param pageY Current pointer's Y position relative to the page.
   * @param zoomRatio The zoom ratio between 0 and 1.
   * @return Size and offset after being zoomed.
   */
  private _zoom(
    pageX: number,
    pageY: number,
    zoomRatio: number
  ): Observable<{ size: { width: number; height: number }; offset: { x: number; y: number } }> {
    const rect = this._img.nativeElement.getBoundingClientRect();

    const edgeOffsetX = pageX - rect.left - window.pageXOffset;
    const edgeOffsetY = pageY - rect.top - window.pageYOffset;

    const edgeRatioX = edgeOffsetX / this._img.nativeElement.clientWidth;
    const edgeRatioY = edgeOffsetY / this._img.nativeElement.clientHeight;

    const oldWidth = this._img.nativeElement.clientWidth;
    const oldHeight = this._img.nativeElement.clientHeight;
    const newWidth = Math.round(oldWidth * (1 + zoomRatio));
    const newHeight = Math.round((newWidth * oldHeight) / oldWidth);

    const oversizeWidth = newWidth >= this._maxDialogWidth ? true : false;
    const oversizeHeight = newHeight >= this._maxDialogHeight ? true : false;

    // Handle maximum zoom out size.
    if (zoomRatio < 0 && (newWidth < 128 || newHeight < 128)) {
      return throwError('Maximum zoom out reached.');
    }

    // Handle maximum zoom in size.
    if (zoomRatio > 0 && (newWidth > 12800 || newHeight > 12800)) {
      return throwError('Maximum zoom in reached.');
    }

    // Set new width and height.
    this._img.nativeElement.style.width = newWidth + 'px';
    this._img.nativeElement.style.height = newHeight + 'px';

    // Perform neccessary img drag to accomodate the zoom.
    if (oversizeWidth || oversizeHeight) {
      // The image is zoomed in/out exceeding the max dialog width or height.
      // So we drag the img to maintain pointer position on the photo.

      let delta = { x: 0, y: 0 };
      if (oversizeWidth && !oversizeHeight) {
        delta = { x: edgeOffsetX - newWidth * edgeRatioX, y: 0 };
      } else if (!oversizeWidth && oversizeHeight) {
        delta = { x: 0, y: edgeOffsetY - newHeight * edgeRatioY };
      } else {
        delta = { x: edgeOffsetX - newWidth * edgeRatioX, y: edgeOffsetY - newHeight * edgeRatioY };
      }

      // console.log('maintain zoom, drag', delta);

      return this._imgDraggable.drag(delta.x, delta.y).pipe(
        map((currentOffset) => ({ size: { width: newWidth, height: newHeight }, offset: currentOffset }))
        // finalize(() => console.log('zoom complete'))
      );
    } else {
      // The image is zoomed in/out below the max dialog width or height.
      // So we drag the img to top left (x=0 and y=0) position.

      // console.log('maintain zoom, drag to 0, 0');

      return this._imgDraggable.dragTo(0, 0).pipe(
        map((currentOffset) => ({ size: { width: newWidth, height: newHeight }, offset: currentOffset }))
        // finalize(() => console.log('zoom complete'))
      );
    }
  }

  /**
   * Init image.
   */
  private _initImage({ imageData, width, height, alt }) {
    this.imageData = imageData;
    this.width = width;
    this.height = height;
    this.alt = alt;
  }

  /**
   * Init image's main panel.
   */
  private _initPanel() {
    // Initial resize to fit screen.
    this._resizeToFit();
  }

  /**
   * Resize image to fit the screen.
   */
  private _resizeToFit() {
    let newWidth: number;
    let newHeight: number;

    // Resize width first...
    newWidth = Math.min(this.width, this._maxDialogWidth);
    newHeight = (this.height * newWidth) / this.width;
    // ...then resize height.
    newHeight = Math.min(newHeight, this._maxDialogHeight);
    newWidth = (this.width * newHeight) / this.height;

    this._img.nativeElement.style.width = newWidth + 'px';
    this._img.nativeElement.style.height = newHeight + 'px';

    // Drag to original position.
    this._imgDraggable.dragTo(0, 0).subscribe();
  }
}
