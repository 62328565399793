<app-base-dialog #dialog [showHeader]="false" [showActionBar]="false">
  <div top-toolbar></div>

  <div content>
    <span class="message">{{ content }}...</span>
    <mat-progress-spinner mode="indeterminate" diameter="20" strokeWidth="2"></mat-progress-spinner>
  </div>

  <div bottom-toolbar></div>

  <div action-bar></div>
</app-base-dialog>
