import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { BreadcrumbsService } from './breadcrumbs.service';

/**
 * Breadcrumbs HTML string as Observable.
 */
export const BREADCRUMBS_HTML: InjectionToken<Observable<string>> = new InjectionToken(
  'Breadcrumbs HTML string as Observable',
  {
    providedIn: 'root',
    factory: () => inject(BreadcrumbsService).html,
  }
);
