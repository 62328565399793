import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HelperService } from '../../shared/services/helper.service';
import { Menu1 } from '../menu/menu.interface';
import { ProfilePreference } from '../user-profile/user-profile-preference.interface';
import { UserProfile } from '../user-profile/user-profile.interface';

/**
 * Service for sharing app wide global variables.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  /**
   * Date format string (YYYY-MM-DD).
   */
  public static readonly DATE_FORMAT: string = 'YYYY-MM-DD';

  /**
   * Date time format string (YYYY-MM-DD HH:mm:ss).
   */
  public static readonly DATETIME_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

  /**
   * Small date time format string (YYYY-MM-DD HH:mm).
   */
  public static readonly SMALL_DATETIME_FORMAT: string = 'YYYY-MM-DD HH:mm';

  /**
   * Time format string (HH:mm:ss).
   */
  public static readonly TIME_FORMAT: string = 'HH:mm:ss';

  /**
   * JWT token key.
   */
  public static readonly TOKEN_KEY: string = 'token';

  /**
   * Authentication User key.
   */
  public static readonly USER_KEY: string = 'user';

  /**
   * Application name.
   */
  public appName = '';

  /**
   * Application name's acronym to be displayed on small screen.
   */
  public get appNameAcronym() {
    return this.appName.match(/\b\w/g).join('');
  }

  /**
   * Application mode.
   */
  public get appMode() {
    return environment.development ? '(development)' : environment.staging ? '(staging)' : '';
  }

  /**
   * Application id.
   */
  public appId = '';

  /**
   * Application version.
   */
  public appVersion = '';

  /**
   * Application base url.
   */
  public baseUrl = '';

  /**
   * Authentication url.
   */
  public authUrl = '';

  /**
   * Login url.
   */
  public loginUrl = '';

  /**
   * Application menus.
   */
  public menus: Menu1[] = [];

  /**
   * JWT token.
   */
  public token = '';

  /**
   * Flag indicating initial authentication has passed.
   */
  public initialAuthenticationPassed = false;

  /**
   * Profile changed event emitter.
   */
  public profileChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Dark theme flag.
   */
  private _darkTheme = false;
  public get darkTheme(): boolean {
    const stored: any = this._helperService.getLocalStorageItem('darkTheme');
    return typeof stored === 'boolean' ? stored : this._darkTheme;
  }
  public set darkTheme(v: boolean) {
    this._darkTheme = v;
    this._helperService.setLocalStorageItem('darkTheme', v);
    this._darkTheme$.next(v);
  }

  /**
   * Dark theme flag observable.
   */
  private _darkTheme$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.darkTheme);
  public get darkTheme$(): Observable<boolean> {
    return this._darkTheme$.asObservable();
  }

  /**
   * Current logged in user profile.
   */
  public userProfile: UserProfile;

  /**
   * API user profile for each app partners.
   */
  public apiProfiles: {
    [appCode: string]: UserProfile;
  };

  /**
   * Profile preference.
   */
  public profilePreference: ProfilePreference = {
    activeWorkgroupIndex: {},
    activeStorageIndex: {},
    filterWorkgroupIndexes: [],
  };

  /*
   * --------------------------------------------
   * App specific global variables.
   * --------------------------------------------
   */

  /**
   * Constructor.
   */
  constructor(private _helperService: HelperService) {}
}
