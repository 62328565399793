import { Injectable } from '@angular/core';
import { filter, forEach } from 'lodash-es';

import { Menu1Id, Menu2Id, Menu3Id } from '../menu/menu.enum';
import { Menu1 } from '../menu/menu.interface';
import { UserProfileService } from '../user-profile/user-profile.service';

/**
 * Application menu preset.
 */
@Injectable({
  providedIn: 'root',
})
export class MenuPresetService {
  /**
   * Constructor.
   */
  constructor(private _userProfileService: UserProfileService) {}

  /**
   * Get the preset values.
   * @return Array of menus.
   */
  public get(): Menu1[] {
    let allMenus: Menu1[] = [
      {
        id: Menu1Id.Master,
        title: 'Master',
        icon: 'class',
        permission: '',
        subMenus: [
          {
            id: Menu2Id.MasterMain,
            title: 'Main',
            icon: 'folder_open',
            permission: '',
            subMenus: [
              {
                id: Menu3Id.MasterActivity,
                title: 'Activity',
                icon: 'keyboard_arrow_right',
                path: '/master/activity',
                permission: 'menu.master.activity',
              },
              {
                id: Menu3Id.MasterCarrier,
                title: 'Carrier',
                icon: 'keyboard_arrow_right',
                path: '/master/carrier',
                permission: 'menu.master.carrier',
              },
              {
                id: Menu3Id.MasterPoint,
                title: 'Point',
                icon: 'keyboard_arrow_right',
                path: '/master/point',
                permission: 'menu.master.point',
              },
              {
                id: Menu3Id.MasterPort,
                title: 'Port',
                icon: 'keyboard_arrow_right',
                path: '/master/port',
                permission: 'menu.master.port',
              },
              {
                id: Menu3Id.MasterSpec,
                title: 'Spec',
                icon: 'keyboard_arrow_right',
                path: '/master/spec',
                permission: 'menu.master.spec',
              },
              {
                id: Menu3Id.MasterStakeholder,
                title: 'Stakeholder',
                icon: 'keyboard_arrow_right',
                path: '/master/stakeholder',
                permission: 'menu.master.stakeholder',
              },
              {
                id: Menu3Id.MasterTransport,
                title: 'Transport',
                icon: 'keyboard_arrow_right',
                path: '/master/transport',
                permission: 'menu.master.transport',
              },
              {
                id: Menu3Id.MasterAuthority,
                title: 'Authority',
                icon: 'keyboard_arrow_right',
                path: '/master/authority',
                permission: 'menu.master.authority',
              },
            ],
          },
          {
            id: Menu2Id.MasterMnR,
            title: 'MnR',
            icon: 'folder_open',
            permission: '',
            subMenus: [
              {
                id: Menu3Id.MasterContractor,
                title: 'Contractor',
                icon: 'keyboard_arrow_right',
                path: '/master/contractor',
                permission: 'menu.master.contractor',
              },
              {
                id: Menu3Id.MasterSurveyor,
                title: 'Surveyor',
                icon: 'keyboard_arrow_right',
                path: '/master/surveyor',
                permission: 'menu.master.surveyor',
              },
              {
                id: Menu3Id.MasterComponentCode,
                title: 'Component Code',
                icon: 'keyboard_arrow_right',
                path: '/master/component-code',
                permission: 'menu.master.component-code',
              },
              {
                id: Menu3Id.MasterDamageLocationCode,
                title: 'Damage Location Code',
                icon: 'keyboard_arrow_right',
                path: '/master/damage-location-code',
                permission: 'menu.master.damage-location-code',
              },
              {
                id: Menu3Id.MasterDamageCode,
                title: 'Damage Code',
                icon: 'keyboard_arrow_right',
                path: '/master/damage-code',
                permission: 'menu.master.damage-code',
              },
              {
                id: Menu3Id.MasterRepairCode,
                title: 'Repair Code',
                icon: 'keyboard_arrow_right',
                path: '/master/repair-code',
                permission: 'menu.master.repair-code',
              },
              {
                id: Menu3Id.MasterDamageCategoryCode,
                title: 'Damage Category Code',
                icon: 'keyboard_arrow_right',
                path: '/master/damage-category-code',
                permission: 'menu.master.damage-category-code',
              },
              {
                id: Menu3Id.MasterDivisionCode,
                title: 'Division Code',
                icon: 'keyboard_arrow_right',
                path: '/master/division-code',
                permission: 'menu.master.division-code',
              },
              {
                id: Menu3Id.MasterRepairTariff,
                title: 'Repair Tariff',
                icon: 'keyboard_arrow_right',
                path: '/master/repair-tariff',
                permission: 'menu.master.repair-tariff',
              },
            ],
          },
        ],
      },
      {
        id: Menu1Id.Agency,
        title: 'Agency',
        icon: 'directions_boat',
        permission: '',
        subMenus: [
          {
            id: Menu2Id.AgencyDashboard,
            title: 'Agency Dashboard',
            icon: 'dashboard',
            path: '/agency/agency-dashboard',
            permission: 'menu.agency.agency-dashboard',
          },
          {
            id: Menu2Id.AgencyMain,
            title: 'Main',
            icon: 'folder_open',
            permission: '',
            subMenus: [
              {
                id: Menu3Id.AgencyDeliveryOrder,
                title: 'Delivery Order',
                icon: 'keyboard_arrow_right',
                path: '/agency/delivery-order',
                permission: 'menu.agency.delivery-order',
              },
              {
                id: Menu3Id.AgencyStack,
                title: 'Stack',
                icon: 'keyboard_arrow_right',
                path: '/agency/stack',
                permission: 'menu.agency.stack',
              },
            ],
          },
        ],
      },
      {
        id: Menu1Id.Depot,
        title: 'Depot',
        icon: 'local_shipping',
        permission: '',
        subMenus: [
          {
            id: Menu2Id.DepotDashboard,
            title: 'Depot Dashboard',
            icon: 'dashboard',
            path: '/depot/depot-dashboard',
            permission: 'menu.depot.depot-dashboard',
          },
          {
            id: Menu2Id.DepotMain,
            title: 'Main',
            icon: 'folder_open',
            permission: '',
            subMenus: [
              {
                id: Menu3Id.DepotBookingIn,
                title: 'Booking In',
                icon: 'keyboard_arrow_right',
                path: '/depot/booking-in',
                permission: 'menu.depot.booking-in',
              },
              {
                id: Menu3Id.DepotBookingOut,
                title: 'Booking Out',
                icon: 'keyboard_arrow_right',
                path: '/depot/booking-out',
                permission: 'menu.depot.booking-out',
              },
              {
                id: Menu3Id.DepotDepotContainer,
                title: 'Depot Container',
                icon: 'keyboard_arrow_right',
                path: '/depot/depot-container',
                permission: 'menu.depot.depot-container',
              },
              {
                id: Menu3Id.DepotEdifactJob,
                title: 'EDIFACT Job',
                icon: 'keyboard_arrow_right',
                path: '/depot/edifact-job',
                permission: 'menu.depot.edifact-job',
              },
              {
                id: Menu3Id.DepotEdifact,
                title: 'EDIFACT',
                icon: 'keyboard_arrow_right',
                path: '/depot/edifact',
                permission: 'menu.depot.edifact',
              },
              {
                id: Menu3Id.DepotGateIn,
                title: 'Gate In',
                icon: 'keyboard_arrow_right',
                path: '/depot/gate-in',
                permission: 'menu.depot.gate-in',
              },
              {
                id: Menu3Id.DepotGateOut,
                title: 'Gate Out',
                icon: 'keyboard_arrow_right',
                path: '/depot/gate-out',
                permission: 'menu.depot.gate-out',
              },
              {
                id: Menu3Id.DepotWarning,
                title: 'Warning',
                icon: 'keyboard_arrow_right',
                path: '/depot/warning',
                permission: 'menu.depot.warning',
              },
            ],
          },
          {
            id: Menu2Id.DepotMnR,
            title: 'MnR',
            icon: 'folder_open',
            permission: '',
            subMenus: [
              {
                id: Menu3Id.DepotEor,
                title: 'EOR',
                icon: 'keyboard_arrow_right',
                path: '/depot/eor',
                permission: 'menu.depot.eor',
              },
              {
                id: Menu3Id.DepotEstimate,
                title: 'Estimate',
                icon: 'keyboard_arrow_right',
                path: '/depot/estimate',
                permission: 'menu.depot.estimate',
              },
            ],
          },
        ],
      },
      {
        id: Menu1Id.PublicAccess,
        title: 'Public Access',
        icon: 'public',
        permission: '',
        subMenus: [
          {
            id: Menu2Id.PublicAccessVgmVermas,
            title: 'VGM VERMAS',
            icon: 'keyboard_arrow_right',
            path: '/public-access/vgm-vermas',
            permission: 'menu.public-access.vgm-vermas',
          },
          {
            id: Menu2Id.PublicAccessStackCounter,
            title: 'Stack',
            icon: 'keyboard_arrow_right',
            path: '/public-access/stack-counter',
            permission: 'menu.public-access.stack-counter',
          },
        ],
      },
    ];

    // Return filtered menus.
    return this._filter(allMenus);
  }

  /**
   * Filter menu based on user's menu permission.
   * @param allMenus All menus being filtered.
   * @return Array of menus.
   */
  private _filter(allMenus: Menu1[]): Menu1[] {
    forEach(allMenus, (menu1) => {
      forEach(menu1.subMenus, (menu2) => {
        forEach(menu2.subMenus, (menu3) => {
          menu3.subMenus = filter(menu3.subMenus, (menu4) => {
            return this._userProfileService.allowAccessMenu(menu4.permission);
          });
        });
        menu2.subMenus = filter(menu2.subMenus, (menu3) => {
          return this._userProfileService.allowAccessMenu(menu3.permission) || menu3.subMenus.length > 0;
        });
      });
      menu1.subMenus = filter(menu1.subMenus, (menu2) => {
        return this._userProfileService.allowAccessMenu(menu2.permission) || menu2.subMenus.length > 0;
      });
    });
    allMenus = filter(allMenus, (menu1) => {
      return this._userProfileService.allowAccessMenu(menu1.permission) || menu1.subMenus.length > 0;
    });

    return allMenus;
  }
}
