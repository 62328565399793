import { EventEmitter, Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snakeCase, split } from 'lodash-es';
import * as moment from 'moment-mini';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, delay, filter, first, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';

import { MessageSnackbarComponent } from '../utils/message-snackbar/message-snackbar.component';

/**
 * Service providing helper functions.
 */
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Constructor.
   */
  constructor(private _matSnackBar: MatSnackBar) {}

  /**
   * Get browser's local storage item.
   * @param key Local storage key.
   */
  public getLocalStorageItem(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      // Firefox throws error if the value is not
      // valid JSON, so we just return it as is.
      return localStorage.getItem(key);
    }
  }

  /**
   * Get meta header content using its name.
   * @param name Meta header name attribute value.
   */
  public getMetaContent(name: string): string {
    const meta = document.querySelector(`meta[name='` + name + `']`);
    return meta ? meta.getAttribute('content').trim() : '';
  }

  /**
   * Scroll page to fragment.
   * @param fragment Fragment to scroll to.
   */
  public scrollToFragment(fragment: string): void {
    const offset = 80;
    const element = document.querySelector(fragment);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const target = absoluteElementTop - offset;
      try {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: target,
        });
      } catch (error) {
        window.scrollTo(0, target);
      }
    }
  }

  /**
   * Set browser's local storage item.
   * @param key Local storage key.
   * @param value Value to stored.
   */
  public setLocalStorageItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Toast a notification message using snackbar.
   * @param message Toast message.
   * @param action Toast action label.
   * @param warn Toast a warning message.
   * @param timeout Number of miliseconds before the toast is dissmissed.
   */
  public toast(message: string, action: string = 'OK', warn: boolean = true, timeout?: number): void {
    const snackBar = this._matSnackBar.openFromComponent(MessageSnackbarComponent, {
      data: { message, action, warn },
    });

    // Set default timeout.
    timeout = timeout ? timeout : warn ? 20000 : 10000;

    // Dismiss snackbar on timeout.
    setTimeout(function () {
      if (snackBar) {
        snackBar.dismiss();
      }
    }, timeout);
  }

  /**
   * Get autocomplete items.
   * @param input HTMLInputElement associated with the autocomplete.
   * @param getItems A function which returns an array of items as observable with the keyword passsed as parameter.
   * @param minLength Minimum keyword length before calling getItems. Default to 3.
   * @param optionSelected Autocomplete option selected event emitter. If provided, items will be cleared on option selected.
   * @return Array of items as observable.
   */
  public getAutocompleteItems(
    input: HTMLInputElement,
    getItems: (keyword: string) => Observable<any[]>,
    minLength: number = 3,
    optionSelected?: EventEmitter<MatAutocompleteSelectedEvent>
  ): Observable<any[]> {
    const items = fromEvent<any>(input, 'input').pipe(
      debounceTime(250),
      filter((event) => event.target.value.length >= minLength),
      switchMap((event) => getItems(event.target.value))
    );

    // If provided, merge observable of items with the option selected event
    // stream so we can clear the autocomplete items on option selected.
    const merged = optionSelected ? merge(items, optionSelected.asObservable()) : items;

    return merged.pipe(
      // If result['data'] is not a valid array, we will clear the autocomplete items by returning empty array.
      map((result) => (Array.isArray(result['data']) ? result['data'] : [])),
      // Ignores any error, so resubscribe when error occurs.
      retry()
    );
  }

  /**
   * Render relation name (e.g. relationName.anotherRelationName -> relation name > another relation name).
   * @param relationName The relation name to be beautified.
   * @return Beautified relation name.
   */
  public renderRelationName(relationName: string): string {
    // return snakeCase(last(split(relation, '.'))).split('_').join(' ');
    return split(relationName, '.')
      .map((x) => snakeCase(x).split('_').join(' '))
      .join(' > ');
  }

  /**
   * Render field name (e.g. field_name -> field name).
   * @param fieldName The field name to be beautified.
   * @return Beautified field name.
   */
  public renderFieldName(fieldName: string): string {
    return fieldName.split('_').join(' ');
  }

  /**
   * Change moment datetime's date or time portion. We use this to
   * sync single moment date and time value with two controls,
   * datepicker (MatDatePicker) and timepicker (MatInput).
   * Datetime format YYYY-MM-DD HH:mm:ss
   * @param currentValue Current moment datetime.
   * @param newValue New date or time value in string or mement format.
   * @return Combined moment datetime.
   */
  public changeDateTime(currentValue: moment.Moment, newValue: moment.Moment | string): moment.Moment {
    if (!newValue) {
      return null;
    }

    const DATE_FORMAT = 'YYYY-MM-DD';
    const TIME_FORMAT = 'HH:mm:ss';
    const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

    try {
      // We will work using string, so if newValue not a string than it must be moment object, then change to string.
      if (typeof newValue !== 'string') {
        const creationInput = newValue.creationData().input;
        newValue =
          typeof creationInput !== 'string'
            ? // Datepicker's creationInput is not a string, an object of year, month, and day instead.
              newValue.format(DATE_FORMAT)
            : creationInput;
      }

      // The date portion which has changed.
      if (newValue.includes('-')) {
        let unmasked = newValue.replace(/[-_]/g, '');
        unmasked = `${unmasked.substring(0, 4)}-${unmasked.substring(4, 6)}-${unmasked.substring(6, 8)}`;
        if (!/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(unmasked)) {
          // Skip processing and return current value until user type valid date string...
          return currentValue;
        } else {
          // Return combined moment date and time.
          return moment(`${unmasked} ${currentValue ? currentValue.format(TIME_FORMAT) : '00:00:00'}`, DATETIME_FORMAT);
        }

        // The time portion which has changed.
      } else {
        let unmasked = newValue.replace(/[:_]/g, '');
        unmasked = `${unmasked.substring(0, 2)}:${unmasked.substring(2, 4)}:${unmasked.substring(4, 6)}`;
        if (!/^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/.test(unmasked)) {
          // Skip processing and return current value until user type valid time string...
          return currentValue;
        } else {
          // Return combined moment date and time.
          return moment(`${currentValue.format(DATE_FORMAT)} ${unmasked}`, DATETIME_FORMAT);
        }
      }
    } catch (error) {
      return currentValue;
    }
  }

  /**
   * Prompt file input dialog.
   * @param accept Accepted file type.
   */
  public promptFileInput(accept: string): Observable<FileList> {
    const input: HTMLInputElement = document.createElement('input');
    input.setAttribute('style', 'display: none');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', accept);

    const inputDestroySignal = new Subject<any>();

    fromEvent(window, 'focus')
      .pipe(
        first(),
        // Add delay to wait for input change to emit an event.
        delay(500),
        tap(() => {
          document.body.removeChild(input);
          inputDestroySignal.next();
          inputDestroySignal.complete();
        })
      )
      .subscribe();

    document.body.appendChild(input);
    input.click();

    return fromEvent(input, 'change').pipe(
      takeUntil(inputDestroySignal),
      map((event) => (event.target as HTMLInputElement).files)
    );
  }
}
