import { HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './about/about.component';
import { HandlerService } from './error/handler.service';
import { UserProfileComponent } from './user-profile/user-profile.component';

/**
 * App core module.
 */
@NgModule({
  imports: [
    /**
     * Shared modules.
     */
    SharedModule,
    /**
     * 3rd party module
     */
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          breaks: true,
        },
      },
    }),
  ],
  declarations: [
    /**
     * Components.
     */
    AboutComponent,
    UserProfileComponent,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
    { provide: ErrorHandler, useClass: HandlerService },
  ],
})
export class CoreModule {}
