<div *ngIf="refreshing" @showBackdrop class="backdrop"></div>

<mat-card fxLayout="column">
  <mat-card-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-icon color="primary">show_chart</mat-icon>
    <span fxFlex>{{ title }}</span>
    <span fxLayout="row wrap-reverse" fxLayoutAlign="end center" fxLayoutGap="12px">
      <mat-checkbox
        *ngIf="timelineMode && timelinePatchModeEnable"
        [labelPosition]="'before'"
        [ngModel]="timelinePatchMode"
        (ngModelChange)="onTimelinePatchModeModelChange($event)"
        [disabled]="paramForm.invalid || timelinePatchModeGapExists"
        class="option-toggle mat-caption"
      >
        Patch Mode
      </mat-checkbox>
      <mat-checkbox
        [labelPosition]="'before'"
        [ngModel]="chart?.options.plugins.showDataLabel"
        (ngModelChange)="onShowDataLabelModelChange($event)"
        class="option-toggle mat-caption"
      >
        Data Label
      </mat-checkbox>
      <span fxLayout="row" fxLayoutAlign="end center">
        <span class="last-refresh mat-caption">{{ getLastRefresh() }}</span>
        <button mat-icon-button (click)="refresh()">
          <mat-icon color="accent" [ngClass]="{ spin: refreshing }">autorenew</mat-icon>
        </button>
      </span>
    </span>
  </mat-card-title>

  <mat-card-content fxLayout="column" fxFlex>
    <div class="overflow-params">
      <form #paramForm="ngForm">
        <ng-content></ng-content>
        <span *ngIf="timelineMode">
          <mat-form-field class="start">
            <mat-label>Start</mat-label>
            <input
              matInput
              name="start"
              [required]="true"
              [(ngModel)]="params.start"
              (dateChange)="onTimelineStartEndChange()"
              [matDatepicker]="startDatepicker"
              [matDatepickerFilter]="timelineDatepickerFilter"
              [disabled]="timelinePatchMode"
            />
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="end">
            <mat-label>End</mat-label>
            <input
              matInput
              name="end"
              [required]="true"
              [(ngModel)]="params.end"
              (dateChange)="onTimelineStartEndChange()"
              [matDatepicker]="endDatepicker"
              [matDatepickerFilter]="timelineDatepickerFilter"
              [disabled]="timelinePatchMode"
            />
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="span">
            <mat-label>Span</mat-label>
            <input
              matInput
              name="span"
              type="number"
              min="1"
              [max]="this.timelineMaxSpan"
              [required]="true"
              [ngModel]="params.span"
              (appModelChangeDebounced)="onTimelineSpanModelChange($event)"
              [disabled]="timelinePatchModeGapExists"
            />
          </mat-form-field>
          <button
            mat-icon-button
            class="pan-left"
            (click)="onTimelinePan(timelinePanDirection.Left)"
            [disabled]="timelinePatchModeGapExists"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            mat-icon-button
            class="pan-right"
            (click)="onTimelinePan(timelinePanDirection.Right)"
            [disabled]="timelinePatchModeGapExists"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </span>
      </form>
    </div>
    <div class="chart-wrapper" fxFlex><canvas #canvas></canvas></div>
  </mat-card-content>
</mat-card>
