<div
  fxLayout="column"
  [style.width]="width"
  [style.height]="height"
  [style.max-width]="maxWidth"
  [style.max-height]="maxHeight"
  mwlResizable
  [resizeEdges]="showHeader ? { top: true, right: true, bottom: true, left: true } : {}"
  (resizing)="onResizing($event)"
  (resizeEnd)="onResizeEnd()"
>
  <!-- header -->
  <mat-toolbar
    #header
    *ngIf="showHeader"
    color="primary"
    class="header"
    appDraggable
    dragTarget=".cdk-overlay-pane"
    (dragChange)="onDragChange($event)"
  >
    <mat-toolbar-row>
      <span>{{ dialogTitle }}</span> <span fxFlex></span>
      <button mat-icon-button (click)="close()" tabindex="-1">
        <mat-icon data-drag-role="BUTTON">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- top-toolbar -->
  <div class="top-toolbar"><ng-content select="[top-toolbar]"></ng-content></div>

  <!-- divider -->
  <mat-divider *ngIf="showHeader"></mat-divider>

  <!-- content -->
  <div class="content mat-typography" [class.flex]="heightDefined"><ng-content select="[content]"></ng-content></div>

  <!-- divider -->
  <div *ngIf="showHeader" class="divider"></div>

  <!-- bottom-toolbar -->
  <div class="bottom-toolbar"><ng-content select="[bottom-toolbar]"></ng-content></div>

  <!-- divider -->
  <mat-divider *ngIf="showHeader"></mat-divider>

  <!-- action-bar -->
  <div *ngIf="showActionBar" fxLayout="row" fxLayoutAlign="center start" class="action-bar">
    <ng-content select="[action-bar]"></ng-content>
    <button
      *ngIf="showHeader"
      mat-icon-button
      disableRipple="true"
      mwlResizeHandle
      [resizeEdges]="{ bottom: true, right: true }"
      class="resize-handle"
    >
      <mat-icon>unfold_more</mat-icon>
    </button>
  </div>
</div>
