import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { get } from 'lodash-es';

@Component({
  selector: 'app-state-logs-reader',
  templateUrl: './state-logs-reader.component.html',
  styleUrls: ['./state-logs-reader.component.scss'],
})
export class StateLogsReaderComponent {
  /**
   * Original unsorted state logs.
   */
  @Input()
  public stateLogs: any[];

  /**
   * Sorted state logs.
   */
  public sortedStateLogs: any[];

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Sort the stateLogs.
   * @param sort Current sort state.
   */
  public sort(sort: Sort) {
    // Set unsorted data.
    if (!sort.active || sort.direction === '') {
      this.sortedStateLogs = this.stateLogs;
      return;
    }

    // Set sorted data.
    const originalOrdered: Array<any> = this.stateLogs.slice();
    this.sortedStateLogs = originalOrdered.sort((a, b) => {
      if (sort.active) {
        return ((a: number | string, b: number | string, isAsc: boolean) =>
          (a == null ? -1 : b == null ? 1 : a < b ? -1 : 1) * (isAsc ? 1 : -1))(
          get(a, sort.active),
          get(b, sort.active),
          sort.direction === 'asc'
        );
      } else {
        return 0;
      }
    });
  }
}
