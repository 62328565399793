import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PromptDialogType } from 'src/app/shared/dialog/prompt-dialog/prompt-dialog-type.enum';

import { DialogService } from '../../shared/dialog/dialog.service';
import { ReportPreset } from '../../shared/report/report-preset.interface';

/**
 * Report preset.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportPresetService {
  /**
   * Constructor.
   */
  constructor(private _dialogService: DialogService) {}

  /**
   * Get the preset values.
   * @return Array of reports of all resources.
   */
  public get(): ReportPreset[] {
    return [
      // Master module reports.
      {
        resourceURL: '/master/activity',
        title: 'Activity',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/carrier',
        title: 'Carrier',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/component-code',
        title: 'Component Code',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/contractor',
        title: 'Contractor',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/damage-category-code',
        title: 'Damage Category Code',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/damage-code',
        title: 'Damage Code',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/damage-location-code',
        title: 'Damage Location Code',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/authority',
        title: 'Authority',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/point',
        title: 'Point',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/port',
        title: 'Port',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/repair-code',
        title: 'Repair Code',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/repair-tariff',
        title: 'Repair Tariff',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/spec',
        title: 'Spec',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/stakeholder',
        title: 'Stakeholder',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/surveyor',
        title: 'Surveyor',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/master/transport',
        title: 'Transport',
        reports: [{ name: 'List' }],
      },

      // Agency module reports.
      {
        resourceURL: '/agency/delivery-order',
        title: 'Delivery Order',
        reports: [{ name: 'List' }, { name: 'Token List' }, { name: 'Outstanding' }],
      },
      {
        resourceURL: '/agency/stack',
        title: 'Stack',
        reports: [{ name: 'List' }],
      },

      // Depot module reports.
      {
        resourceURL: '/depot/depot-container',
        title: 'Depot Container',
        reports: [
          { name: 'List' },
          { name: 'Cleaning List' },
          { name: 'Booking In EA' },
          { name: 'In Gate' },
          { name: 'Out Gate' },
          { name: 'Out Gate Order By DO' },
          { name: 'Fiat In Overdue' },
          { name: 'Fiat Out Overdue' },
          { name: 'LOLO Storage', custom: true },
          { name: 'Stock List' },
          { name: 'Stock Summary CC', formats: ['PDF', 'XLS'], custom: true },
          { name: 'Stock Summary EA', formats: ['PDF', 'XLS'], custom: true },
          { name: 'Movement', formats: ['PDF', 'XLS'], custom: true },
          { name: 'Monthly Lifting', formats: ['PDF', 'XLS'], custom: true },
          { name: 'Otoritas Pelabuhan', formats: ['PDF', 'XLS'], custom: true },
          { name: 'Properties' },
          { name: 'TMFD SRFD', formats: ['XLS'] },
          { name: 'EOR Waiting List', formats: ['CSV'] },
        ],
      },
      {
        resourceURL: '/depot/booking-in',
        title: 'Booking In',
        reports: [
          { name: 'List' },
          { name: 'Outstanding' },
          { name: 'DEM Flat & TPC', formats: ['PDF'] },
          { name: 'DEM Flat LMS & TPC', formats: ['PDF'] },
          { name: 'DEM Progressive & TPC', formats: ['PDF'] },
          { name: 'DEM Progressive LMS & TPC', formats: ['PDF'] },
        ],
      },
      {
        resourceURL: '/depot/booking-out',
        title: 'Booking Out',
        reports: [{ name: 'List' }, { name: 'Outstanding' }],
      },
      {
        resourceURL: '/depot/edifact',
        title: 'Edifact',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/depot/edifact-job',
        title: 'Edifact Job',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/depot/warning',
        title: 'Warning',
        reports: [{ name: 'List' }],
      },
      {
        resourceURL: '/depot/eor',
        title: 'EOR',
        reports: [
          { name: 'List' },
          { name: 'Daily Publish' },
          { name: 'TPC' },
          { name: 'TPC IDR' },
          // { name: 'TPC with Waiver' },
          // { name: 'TPC with Waiver IDR' },
          { name: 'Surveyor' },
          {
            name: 'Repair Bill',
            params: () => {
              return of({}).pipe(
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Material Cost Percentage #1',
                      ['Please input Material Cost Percentage #1 between 0 - 100.'],
                      null,
                      null,
                      100,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, m_p1: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Material Cost Percentage #2',
                      ['Please input Material Cost Percentage #2 between 0 - 100.'],
                      null,
                      null,
                      85,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, m_p2: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Labor Cost Percentage #1',
                      ['Please input Labor Cost Percentage #1 between 0 - 100.'],
                      null,
                      null,
                      100,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, l_p1: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Labor Cost Percentage #2',
                      ['Please input Labor Cost Percentage #2 between 0 - 100.'],
                      null,
                      null,
                      80,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, l_p2: +(result || 0) })))
                )
              );
            },
          },
          {
            name: 'Repair Bill IDR',
            params: () => {
              return of({}).pipe(
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Material Cost Percentage #1',
                      ['Please input Material Cost Percentage #1 between 0 - 100.'],
                      null,
                      null,
                      100,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, m_p1: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Material Cost Percentage #2',
                      ['Please input Material Cost Percentage #2 between 0 - 100.'],
                      null,
                      null,
                      85,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, m_p2: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Labor Cost Percentage #1',
                      ['Please input Labor Cost Percentage #1 between 0 - 100.'],
                      null,
                      null,
                      100,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, l_p1: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Labor Cost Percentage #2',
                      ['Please input Labor Cost Percentage #2 between 0 - 100.'],
                      null,
                      null,
                      80,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input && input <= 100,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, l_p2: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      'Exchange Rate IDR',
                      ['Please input the exchange rate.'],
                      null,
                      null,
                      null,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, rate: +(result || 0) })))
                )
              );
            },
          },
          { name: 'PTI Bill' },
          {
            name: 'Cleaning Bill',
            params: () => {
              return of({}).pipe(
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      '20ft Commission',
                      ['Please input cleaning commission (USD|IDR) for 20ft container.'],
                      null,
                      null,
                      1,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, commission20: +(result || 0) })))
                ),
                switchMap((prev) =>
                  this._dialogService
                    .openPromptDialog(
                      '40ft Commission',
                      ['Please input cleaning commission (USD|IDR) for 40ft container.'],
                      null,
                      null,
                      2,
                      PromptDialogType.Number,
                      null,
                      (input) => 0 <= input,
                      true,
                      'OK',
                      'SET TO ZERO'
                    )
                    .afterClosed()
                    .pipe(map((result) => ({ ...prev, commission40: +(result || 0) })))
                )
              );
            },
          },
          { name: 'Costs' },
          {
            name: 'On Site Repair',
            formats: ['PDF'],
          },
        ],
      },
    ];
  }
}
