import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DialogService } from '../../dialog/dialog.service';
import { Option } from '../../interfaces/option.interface';
import { HelperService } from '../../services/helper.service';
import { HttpRestService } from '../../services/http-rest.service';
import { DateRangeComponent } from '../date-range/date-range.component';
import { Searchable } from '../searchable.interface';

@Component({
  selector: 'app-fav-node',
  templateUrl: './fav-node.component.html',
  styleUrls: ['./fav-node.component.scss'],
})
export class FavNodeComponent implements AfterViewInit {
  /**
   * Searchable object bound to this FavNode component.
   */
  @Input()
  public searchable: Searchable;

  /**
   * Disable the component.
   */
  @Input()
  public disabled: boolean;

  /**
   * Event emmitted when trigger checkbox is toggled by user interaction or programmaticaly.
   */
  @Output()
  public toggleChange: EventEmitter<MatCheckboxChange> = new EventEmitter();

  /**
   * The trigger checkbox for activating this Fav Node.
   */
  @ViewChild('trigger', { read: MatCheckbox })
  public trigger: MatCheckbox;

  /**
   * The input control for inputing field value.
   */
  @ViewChild('control', { read: MatInput })
  public input: MatInput;

  /**
   * The select control for inputing field value.
   */
  @ViewChild('control', { read: MatSelect })
  public select: MatSelect;

  /**
   * Operators.
   */
  public operators: Option[] = [
    { display: '=', value: '=' },
    { display: '!=', value: '!=' },
    { display: '<', value: '<' },
    { display: '<=', value: '<=' },
    { display: '>', value: '>' },
    { display: '>=', value: '>=' },
  ];

  /**
   * More Operators.
   */
  public moreOperators: Option[] = [
    { display: 'C', value: 'contains' },
    { display: 'n C', value: 'not contains' },
    { display: 'L', value: 'like' },
    { display: 'n L', value: 'not like' },
    { display: 'I', value: 'in' },
    { display: 'n I', value: 'not in' },
    { display: 'B', value: 'between' },
    { display: 'n B', value: 'not between' },
    { display: 'N', value: 'is null' },
    { display: 'n N', value: 'is not null' },
  ];

  /**
   * Keep track the previous value which is then used to construct the
   * piped values during guided value option selected. We're doing like
   * this because ngModelChange will be called before optionSelected.
   */
  private _previousValue: string;

  /**
   * Constructor
   */
  constructor(
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService
  ) {}

  /**
   * Angular lifcycle hook.
   */
  public ngAfterViewInit() {
    this.searchable.favNode = this;
  }

  /**
   * Handle trigger checkbox checked change event.
   * @param event Checkbox change event args.
   */
  public onTriggerCheckboxChange(event: MatCheckboxChange) {
    this.toggleChange.next(event);
    if (this.trigger.checked) {
      setTimeout(() => {
        this.focus();
      });
    }
  }

  /**
   * Handle ngModelChange event for input with guided options.
   * @param value New ngModel value.
   * @param node The field node object.
   */
  public onGuidedValueModelChange(value: string, node: any) {
    this._previousValue = node.v;
    node.v = value;
  }

  /**
   * Handle if user select an option from the guided values list.
   * We will append the selected value from the dropdown list
   * to the end of the string using "|" as the separator.
   * @param event MatAutocompleteSelectedEvent.
   * @param node The field node object.
   * @param input Input element.
   */
  public onGuidedValueOptionSelected(event: MatAutocompleteSelectedEvent, node: any, input: HTMLInputElement) {
    const split = this._previousValue.split('|').map((item) => item.trim());
    split.pop();
    split.push(event.option.value);
    node.v = split.join(' | ');
    // Here we manualy assign the input element value to overcome "caching"
    // effect due to selecting the same option from the dropdown list.
    input.value = split.join(' | ');
  }

  /**
   * Toggle trigger checkbox programmaticaly and emit toggle event.
   */
  public toggle() {
    this.trigger.toggle();

    const event = new MatCheckboxChange();
    event.source = this.trigger;
    event.checked = this.trigger.checked;

    // Manually call the event handler because (change) event won't be fired if not from user interaction.
    this.onTriggerCheckboxChange(event);
  }

  /**
   * Focus to the input.
   */
  public focus() {
    (this.input || this.select).focus();
  }

  /**
   * Show date time prompt dialog.
   * @param searchable Being edited Searchable.
   */
  public showDateTimePrompt(searchable: Searchable) {
    this._dialogService.openImmediateDialog(
      DateRangeComponent,
      null,
      (result) => {
        // Skip if unchanged.
        if (!result) {
          return;
        }
        // Set operator based on user selected datetime.
        if (result.includes('|')) {
          searchable.node.o = 'between';
        } else {
          if (searchable.node.o === 'between') {
            searchable.node.o = '=';
          }
        }
        // Set node value.
        searchable.node.v = result;
      },
      { data: { current: searchable.node.v } }
    );
  }

  /**
   * Get observable of value items for Searchable with guided value.
   * @param input HTMLElement of the input control associated with the autocomplete.
   * @param searchable Searchable object.
   */
  public getValueItems(input: HTMLInputElement, searchable: Searchable) {
    if (!searchable.guidedValueItems) {
      searchable.guidedValueItems = this._helperService.getAutocompleteItems(
        input,
        (keyword) => this._getValueItems(keyword, searchable),
        1
      );
    }
    return searchable.guidedValueItems;
  }

  /**
   * Value's autocomplete display renderer.
   * @param item Field to be rendered.
   * @return Rendered field.
   */
  public valueDisplayWith(item: any): string {
    return item ? item : '';
  }

  /**
   * Get the initial string of the selected operator.
   * @param operatorValue Operator value
   */
  public getOperatorInitial(operatorValue: string) {
    return operatorValue
      ? (
          this.operators.find((operator) => operator.value === operatorValue) ||
          this.moreOperators.find((operator) => operator.value === operatorValue)
        ).display
      : '';
  }

  /**
   * Check whether a Searchable is using NULL operator.
   * @param searchable The Searchable being checked.
   */
  public isNULLOperator(searchable: Searchable) {
    const isNULLOperator: boolean = searchable.node.o.includes('null');
    if (isNULLOperator) {
      searchable.node.v = '';
    }
    return isNULLOperator;
  }

  /**
   * Check whether a Searchable is using IN operator.
   * @param searchable The Searchable being checked.
   */
  public isINOperator(searchable: Searchable) {
    return searchable.node.o ? searchable.node.o.includes('in') : false;
  }

  /**
   * Get the model object of the "IN select" field.
   * @param searchable The Searchable object.
   */
  public getINSelectValue(searchable: Searchable) {
    if (searchable.node.o.includes('in') && !searchable.selectedValues) {
      searchable.selectedValues = searchable.node.v ? searchable.node.v.split(' | ') : [];
    }
    return searchable.selectedValues;
  }

  /**
   * The "IN select" field's ngModelChange handler.
   * @param selectedItems Array of selected items.
   * @param searchable The Searchable object.
   */
  public onINSelectModelChange(selectedItems: any[], searchable: Searchable) {
    searchable.selectedValues = selectedItems;
    searchable.node.v = selectedItems.join(' | ');
  }

  /**
   * Get value's items based on provided keyword.
   * @param keyword User input keyword.
   * @param searchable The Searchable object.
   * @return Observable of value's items.
   */
  private _getValueItems(keyword: string, searchable: Searchable): Observable<any[]> {
    const value = '%' + keyword.split('|').pop().trim() + '%';
    const fieldName = searchable.field.guidedValueFieldName || searchable.field.name;
    const qAdv = {
      s: [{ f: fieldName }],
      w: [{ b: 'and', f: fieldName, o: 'like', v: value }],
      o: [{ f: fieldName, o: 'asc' }],
    };

    return this._httpRestService
      .get(searchable.field.guidedValueResourceURL || searchable.resourceURL, {
        params: { q: JSON.stringify({ quick: null, adv: qAdv }), i: '1', skip: '0', take: '50' },
      })
      .pipe(
        map((result) => <any>{ data: result.data.map((item) => item[fieldName]) }),
        catchError((error) => of([]))
      );
  }
}
