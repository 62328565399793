import { Injectable } from '@angular/core';
import { isNumber, mapValues } from 'lodash-es';
import * as moment from 'moment-mini';

/**
 * Sanitize sent or received data.
 */
@Injectable({
  providedIn: 'root',
})
export class SanitizerService {
  /**
   * Server date format.
   */
  private _serverDateFormat = 'YYYY-MM-DD HH:mm:ss.SSS';

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Sanitize data before being sent.
   * @param data Data to be sanitized.
   * @return Sanitized data.
   */
  public sanitizeSent(data: any): any {
    if (data instanceof FormData || data instanceof Blob) {
      return data;
    } else if (data instanceof Array) {
      return data.map((value) => {
        return this.sanitizeSent(value);
      });
    } else if (data instanceof Object && !(data instanceof Date || data instanceof moment)) {
      return mapValues(data, (value) => {
        return this.sanitizeSent(value);
      });
    } else {
      let sanitized = data;
      sanitized = this._dateToString(sanitized);
      sanitized = this._undefinedOrEmptyStringToNull(sanitized);
      sanitized = this._booleanToNumber(sanitized);
      sanitized = this._numberToString(sanitized);
      sanitized = this._removeThousand(sanitized);
      return sanitized;
    }
  }

  /**
   * Sanitize data before being used.
   * @param data Data to be sanitized.
   * @return Sanitized data.
   */
  public sanitizeReceived(data: any): any {
    if (data instanceof Array) {
      return data.map((value) => {
        return this.sanitizeReceived(value);
      });
    } else if (data instanceof Object && !(data instanceof Date || data instanceof moment)) {
      return mapValues(data, (value) => {
        return this.sanitizeReceived(value);
      });
    } else {
      let sanitized = data;
      sanitized = this._stringToDate(sanitized);
      return sanitized;
    }
  }

  /**
   * Convert Date to string.
   * @param value Any value.
   * @return Date as string or original value.
   */
  private _dateToString(value: any): string {
    if (value instanceof Date) {
      value = moment(value);
    }
    if (moment.isMoment(value)) {
      return value.format(this._serverDateFormat);
    }
    return value;
  }

  /**
   * Convert string to Moment date.
   * @param value Any value.
   * @return Moment date or original value.
   */
  private _stringToDate(value: any): moment.Moment {
    const momentDate = moment(value, this._serverDateFormat, true);
    if (momentDate.isValid()) {
      return momentDate;
    }
    return value;
  }

  /**
   * Convert undefined or '' to null.
   * @param value Any value.
   * @return null or original value.
   */
  private _undefinedOrEmptyStringToNull(value: any): any {
    return value === undefined || value === '' ? null : value;
  }

  /**
   * Convert boolean to number.
   * @param value Any value.
   * @return Boolean as number or original value.
   */
  private _booleanToNumber(value: any): number {
    if (value === true) {
      return 1;
    } else if (value === false) {
      return 0;
    } else {
      return value;
    }
  }

  /**
   * Convert number to string.
   * @param value Any value.
   * @return Number as string or original value.
   */
  private _numberToString(value: any): string {
    return isNumber(value) ? value.toString() : value;
  }

  /**
   * Remove thousand separator `,` from string of number.
   * @param value Any string value.
   */
  private _removeThousand(value: any): string {
    const removed: any = typeof value === 'string' ? value.replace(/,(?=\d{3})/g, '') : value;
    return !isNaN(removed) ? removed : value;
  }
}
