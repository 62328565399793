import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-mini';

import { GlobalService } from '../../../core/services/global.service';
import { BaseDialogComponent } from '../../dialog/base-dialog/base-dialog.component';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * From date.
   */
  public from: moment.Moment;

  /**
   * Until date.
   */
  public until: moment.Moment;

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DateRangeComponent>,
    private _helperService: HelperService
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    if (this.data.current) {
      try {
        const current: string[] = this.data.current.split('|', 2);
        if (current.length === 1) {
          const datetime = moment(current[0], GlobalService.DATETIME_FORMAT);
          if (datetime.format(GlobalService.TIME_FORMAT) === '00:00:00') {
            this.from = datetime;
          } else if (datetime.format(GlobalService.TIME_FORMAT) === '23:59:59') {
            this.until = datetime;
          } else {
            this.from = datetime;
          }
        } else {
          this.from = moment(current[0], GlobalService.DATETIME_FORMAT);
          this.until = moment(current[1], GlobalService.DATETIME_FORMAT);
        }
      } catch (error) {
        // Ignore any error and set from and until to null
        this.from = null;
        this.until = null;
      }
    }
  }

  /**
   * On 'until' model change handler.
   * @param event New value.
   */
  public onUntilModelChange(event) {
    if (this.until && this.until.format(GlobalService.TIME_FORMAT) === '00:00:00') {
      this.until = this._helperService.changeDateTime(this.until, '23:59:59');
    }
  }

  /**
   * Close the dialog.
   */
  public close(): void {
    const combined = [];

    if (this.from && this.from.isValid()) {
      combined.push(this.from.format(GlobalService.DATETIME_FORMAT));
    }
    if (this.until && this.until.isValid()) {
      combined.push(this.until.format(GlobalService.DATETIME_FORMAT));
    }

    this._dialogRef.close(combined.join(' | '));
  }
}
