/**
 * Available Menu 1.
 */
export enum Menu1Id {
  Master,
  Agency,
  Depot,
  PublicAccess,
}

/**
 * Available Menu 2.
 */
export enum Menu2Id {
  MasterDashboard,
  MasterMain,
  MasterMnR,

  AgencyDashboard,
  AgencyMain,

  DepotDashboard,
  DepotMain,
  DepotMnR,

  PublicAccessVgmVermas,
  PublicAccessStackCounter,
}

/**
 * Available Menu 3.
 */
export enum Menu3Id {
  MasterActivity,
  MasterCarrier,
  MasterPoint,
  MasterPort,
  MasterSpec,
  MasterStakeholder,
  MasterTransport,
  MasterAuthority,
  MasterContractor,
  MasterSurveyor,
  MasterComponentCode,
  MasterDamageLocationCode,
  MasterDamageCode,
  MasterDamageCategoryCode,
  MasterRepairCode,
  MasterDivisionCode,
  MasterRepairTariff,

  AgencyDeliveryOrder,
  AgencyStack,

  DepotBookingIn,
  DepotBookingOut,
  DepotDepotContainer,
  DepotEdifact,
  DepotEdifactJob,
  DepotEor,
  DepotEstimate,
  DepotGateIn,
  DepotGateOut,
  DepotWarning,
}

/**
 * Available Menu 4.
 */
export enum Menu4Id {}
