<div fxLayout="column">
  <div class="greeting">{{ greeting }}</div>

  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="center stretch"
    *ngIf="!guest"
  >
    <mat-card
      fxFlex
      class="menu-button"
      (click)="openDashboard('agency')"
      *ngIf="allowAccessMenu('menu.agency.agency-dashboard')"
    >
      <mat-card-title>
        <span class="menu-label">Agency Dashboard</span>
      </mat-card-title>
    </mat-card>

    <mat-card
      fxFlex
      class="menu-button"
      (click)="openDashboard('depot')"
      *ngIf="allowAccessMenu('menu.depot.depot-dashboard')"
    >
      <mat-card-title>
        <span class="menu-label">Depot Dashboard</span>
      </mat-card-title>
    </mat-card>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="center stretch"
    *ngIf="guest"
  >
    <mat-card
      fxFlex
      class="menu-button"
      (click)="openVgmVermas()"
      *ngIf="allowAccessMenu('menu.public-access.vgm-vermas')"
    >
      <mat-card-title>
        <span class="menu-label">VGM VERMAS</span>
      </mat-card-title>
    </mat-card>

    <mat-card
      fxFlex
      class="menu-button"
      (click)="openStackCounter()"
      *ngIf="allowAccessMenu('menu.public-access.stack-counter')"
    >
      <mat-card-title>
        <span class="menu-label">Stack</span>
      </mat-card-title>
    </mat-card>
  </div>

  <div class="quote" *ngIf="quote" fxLayout="column" fxLayoutAlign="start end">
    <div class="quote-text">{{ quote?.quote }}</div>
    <div class="quote-source">
      <em>{{ quote?.source }}</em>
    </div>
  </div>
</div>
