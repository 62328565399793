import { Directive, DoCheck, HostBinding, Input, Optional, Self } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: '[appHighlightError]',
  exportAs: 'appHighlightError',
})
export class HighlightErrorDirective implements DoCheck {
  /**
   * Array of errors for a single field.
   */
  @HostBinding('class.highlight-error')
  @Input('appHighlightError')
  public errors: string[];

  /**
   * Constructor.
   */
  constructor(@Optional() @Self() private _matFormField: null | MatFormField) {}

  /**
   * Angular lifecycle hook.
   */
  public ngDoCheck() {
    if (!this._matFormField) return;

    if (this.errors && this.errors.length > 0) {
      // Only show one (first) error at a time.
      this._matFormField.hintLabel = this.errors[0];
    } else {
      this._matFormField.hintLabel = null;
    }
  }
}
