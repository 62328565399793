import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { zipWith } from 'lodash-es';
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '../../dialog/dialog.service';
import { ResourceFieldService } from '../../resource-field/resource-field.service';
import { HelperService } from '../../services/helper.service';
import { GroupNode } from '../group-node.interface';
import { JoinNode } from '../join-node.interface';
import { NodeSettingComponent } from '../node-setting/node-setting.component';
import { OrderFieldNode } from '../order-field-node.interface';
import { SelectFieldNode } from '../select-field-node.interface';
import { WhereFieldNode } from '../where-field-node.interface';
import { WhereHasGroupNode } from '../where-has-group-node.interface';

/**
 * Advanced search node component either a field or group node.
 */
@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss'],
})
export class NodeComponent implements OnInit {
  /**
   * Current active node either where or order node.
   */
  private static _activeNode:
    | SelectFieldNode
    | WhereFieldNode
    | OrderFieldNode
    | JoinNode
    | GroupNode<SelectFieldNode | WhereFieldNode | OrderFieldNode | JoinNode>
    | WhereHasGroupNode<WhereFieldNode>;

  /**
   * ResourceURL of this node.
   */
  @Input() public resourceURL: string;

  /**
   * This node component's object reference.
   *   SelectFieldNode |
   *   WhereFieldNode |
   *   OrderFieldNode |
   *   GroupNode<SelectFieldNode | WhereFieldNode | OrderFieldNode> |
   *   WhereHasGroupNode<WhereFieldNode>
   */
  @Input() public node: any;

  /**
   * This parent node component's object reference.
   *   GroupNode<SelectFieldNode | WhereFieldNode | OrderFieldNode> |
   *   WhereHasGroupNode<WhereFieldNode>
   */
  @Input() public parentNode: any;

  /**
   * Node's focusable button element.
   */
  @ViewChild('focusable', { read: MatButton })
  private _focusable: MatButton;

  /**
   * Create group node.
   * @param type Group node type.
   * @param b Group node boolean logic.
   * @param g Array of nodes contained by this group node.
   * @param root Flag indicating if this group node is a root group node.
   * @return A GroupNode of type 'where', 'order', or 'select'.
   */
  public static createGroupNode(
    type: 'where' | 'order' | 'select' | 'join',
    b: 'and' | 'or' = 'and',
    g: any[] = [],
    root: boolean = false
  ): GroupNode<any> {
    return { b: b, g: g, type: type, root: root, i: NodeComponent._getI() };
  }

  /**
   * Create where has group node.
   * @param relation Main resource's relation name for this where has group node.
   * @param resourceURL Resource URL of the related resource.
   * @param b WhereHas group node boolean logic.
   * @param a Allow to execute this wherehas relation without any where filter (a.k.a grab anything).
   * @param g Array of nodes contained by this where has group node.
   * @return A WhereHasGroupNode of type 'where'.
   */
  public static createWhereHasGroupNode(
    relation: string,
    resourceURL: string,
    b: 'and' | 'or' = 'and',
    a: boolean = false,
    g: any[] = []
  ): WhereHasGroupNode<WhereFieldNode> {
    return {
      b: b,
      r: relation,
      c: relation.split('.').map<any>(() => ({ o: '>=', n: 1 })),
      resourceURL: resourceURL,
      a: a,
      g: g,
      type: 'where',
      root: true,
      i: NodeComponent._getI(),
    };
  }

  /**
   * Create select field node.
   * @param f Field name.
   * @return A SelectFieldNode.
   */
  public static createSelectFieldNode(f: string = 'id'): SelectFieldNode {
    return { f: f, type: 'select', i: NodeComponent._getI() };
  }

  /**
   * Create where field node.
   * @param b Where field node boolean logic.
   * @param f Field name.
   * @param o Comparation operator.
   * @param v Value to compare.
   * @param at Flag whether it's an @ where field or not.
   * @return A WhereFieldNode.
   */
  public static createWhereFieldNode(
    b: 'and' | 'or' = 'and',
    f: string = 'id',
    o: string = '=',
    v: any = '',
    at: boolean = false
  ): WhereFieldNode {
    return { b: b, f: f, o: o, v: v, type: at ? '@where' : 'where', i: NodeComponent._getI() };
  }

  /**
   * Create join node.
   * @param t Table name.
   * @param s First column.
   * @param o Join operator.
   * @param d Second column.
   * @param y Join type.
   * @return A JoinNode.
   */
  public static createJoinNode(t: string, s: string, o: string, d: string, y: string = 'inner'): JoinNode {
    return { t: t, s: s, o: o, d: d, y: y, type: 'join', i: NodeComponent._getI() };
  }

  /**
   * Create order field node.
   * @param f Field name.
   * @param o Sorting operator.
   * @return A OrderFieldNode.
   */
  public static createOrderFieldNode(f: string = 'id', o: 'asc' | 'desc' = 'asc'): OrderFieldNode {
    return { f: f, o: o, type: 'order', i: NodeComponent._getI() };
  }

  /**
   * Get random i value based on Date.now().
   * @return A random number.
   */
  private static _getI(): number {
    return Date.now() + Math.floor(Math.random() * 1000);
  }

  /**
   * ThemePalette indicating the color mode, either 'primary' or undefeind (default).
   */
  public get color(): ThemePalette {
    return this.active ? 'primary' : undefined;
  }

  /**
   * Flag indicating if this node is active.
   */
  public get active(): boolean {
    return NodeComponent._activeNode ? (this.node.i === NodeComponent._activeNode.i ? true : false) : false;
  }

  /**
   * Flag indicating if this node is root node.
   */
  public get rootNode(): boolean {
    return this.node.root;
  }

  /**
   * Flag indicating if this node is field node.
   */
  public get fieldNode(): boolean {
    return this.node.f !== undefined;
  }

  /**
   * Flag indicating if this node is group node.
   */
  public get groupNode(): boolean {
    return this.node.g !== undefined;
  }

  /**
   * Flag indicating if this node is whereHas group node.
   */
  public get whereHasGroupNode(): boolean {
    return this.node.r !== undefined;
  }

  /**
   * Flag indicating if this node is where type node.
   */
  public get whereNode(): boolean {
    return this.node.type === 'where';
  }

  /**
   * Flag indicating if this node is order type node.
   */
  public get orderNode(): boolean {
    return this.node.type === 'order';
  }

  /**
   * Flag indicating if this node is select type node.
   */
  public get selectNode(): boolean {
    return this.node.type === 'select';
  }

  /**
   * Constructor.
   */
  constructor(
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _mediaObserver: MediaObserver,
    private _resourceFieldService: ResourceFieldService
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this.node.comp = this;
  }

  /**
   * Select this node as current active node.
   */
  public select(): void {
    NodeComponent._activeNode = this.node;
    this._focusable.focus();
  }

  /**
   * Add new field node.
   */
  public addField(): void {
    // Create new node.
    const newNode = this.whereNode ? NodeComponent.createWhereFieldNode() : NodeComponent.createOrderFieldNode();
    this.node.g.push(newNode);

    // Clear grab any flag.
    if (this.node.root) {
      this.node.a = false;
    }

    // Open node setting.
    setTimeout(() => {
      newNode.comp.setting();
    }, 0);
  }

  /**
   * Add new group node.
   */
  public addGroup(): void {
    this._dialogService.openImmediateDialog(
      ConfirmDialogComponent,
      null,
      (result) => {
        // Create new node.
        const newNode = NodeComponent.createGroupNode(this.node.type);
        newNode.b = result ? 'and' : 'or';
        this.node.g.push(newNode);

        // CLear grab any flag.
        if (this.node.root) {
          this.node.a = false;
        }

        // Set the new node as active.
        NodeComponent._activeNode = newNode;
      },
      {
        data: {
          title: 'Add new group node',
          contents: ['What type of group node do you want to add?'],
          okLabel: 'AND Group',
          cancelLabel: 'OR Group',
        },
        disableClose: true,
      }
    );
  }

  /**
   * Set the selected node.
   */
  public setting(): void {
    this._dialogService
      .openImmediateDialog(NodeSettingComponent, null, (result) => {}, {
        data: {
          node: this.node,
          fields: this.fieldNode ? this._resourceFieldService.get(this.resourceURL) : undefined,
        },
        width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '80vw' : '25vw',
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(tap(() => this.select()))
      .subscribe();
  }

  /**
   * Remove this node from parent node.
   */
  public remove(): void {
    const index = this.parentNode.g.findIndex((childNode) => childNode.i === this.node.i);
    this.parentNode.g.splice(index, 1);
  }

  /**
   * On Node double click handler.
   */
  public onDblClick(): void {
    if (!this.active) {
      return;
    }

    if (this.fieldNode || (this.groupNode && (this.whereHasGroupNode || !this.rootNode))) {
      this.setting();
    }
  }

  /**
   * Render relation name.
   * @param relationName The relation name to be beautified.
   * @param occurrences The relation occurence settings.
   * @return Beautified relation name.
   */
  public renderRelationName(relationName: string, occurrences: [{ o: 'string'; n: number }]): string {
    return zipWith(
      this._helperService.renderRelationName(relationName).split(' > '),
      occurrences,
      (value1, value2) => ({ r: value1, c: value2 })
    )
      .map((item) => `WHERE HAS ${item.c.o} ${item.c.n} ${item.r}`)
      .join(' ');
  }

  /**
   * Render field name.
   * @param fieldName The field name to be beautified.
   * @return Beautified field name.
   */
  public renderFieldName(fieldName: string): string {
    return this._helperService.renderFieldName(fieldName);
  }
}
