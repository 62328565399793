import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { isFunction } from 'lodash-es';

import { NumberCellEditorParams } from '../cell-editor-params.interface';

/**
 * AG custom number editor component.
 */
@Component({
  selector: 'app-number-cell-editor',
  templateUrl: './number-cell-editor.component.html',
  styleUrls: ['./number-cell-editor.component.scss'],
})
export class NumberCellEditorComponent implements AfterViewInit, AgEditorComponent {
  /**
   * Editor parameters.
   */
  public params: NumberCellEditorParams;

  /**
   * Minimum value allowed.
   */
  public min: number | (() => number);

  /**
   * Maximum value allowed.
   */
  public max: number | (() => number);

  /**
   * Input value step.
   */
  public step: number | ((params: NumberCellEditorParams) => number);

  /**
   * Cancel editing before start flag.
   */
  private _cancelBeforeStart: boolean = false;

  /**
   * Select form control's ElementRef.
   */
  @ViewChild('number', { read: ElementRef, static: true })
  private _numberElement: ElementRef;

  /**
   * Constructor.
   */
  public constructor() {}

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit(): void {
    setTimeout(() => this._numberElement.nativeElement.focus());
  }

  /**
   * AgEditorComponent implementation. Gets called once after the editor is created.
   * @param params Editor parameter.
   */
  public agInit(params: NumberCellEditorParams): void {
    // Set the editor params.
    this.params = params;

    // Set the number's width and height to fit it's parent cell.
    this._numberElement.nativeElement.style.width = params.eGridCell.offsetWidth + 'px';
    this._numberElement.nativeElement.style.height = params.eGridCell.offsetHeight + 'px';

    // Set min. max, step properties.
    this.min = this.params.min || 0;
    this.max = this.params.max || 9999999999;
    this.step = (isFunction(this.params.step) ? this.params.step(params) : this.params.step) || 1;

    // Only start edit if key pressed is a number, not a letter
    this._cancelBeforeStart = params.charPress && '1234567890.-'.indexOf(params.charPress) < 0;

    /**
     * Set pressed character as value, so it feels like editing right
     * into the cell, except decimal and negative symbol because
     * on their own they are not valid number value.
     */
    if (!this._cancelBeforeStart && params.charPress) {
      if ('.-'.indexOf(params.charPress) < 0) {
        this._numberElement.nativeElement.value = params.charPress;
      } else {
        this._numberElement.nativeElement.value = params.value;
      }
    } else {
      this._numberElement.nativeElement.value = params.value;
    }
  }

  /**
   * AgEditorComponent implementation. Returns the result of the editing.
   * @return Result of the editing.
   */
  public getValue(): any {
    return +this._numberElement.nativeElement.value;
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will appear in a popup.
   * @return True if this is a popup editor.
   */
  public isPopup(): boolean {
    return false;
  }

  /**
   * AgEditorComponent implementation. If return true, the editor will not shown.
   * @return True if editor is canceled before start.
   */
  public isCancelBeforeStart(): boolean {
    return this._cancelBeforeStart;
  }

  /**
   * AgEditorComponent implementation. If return true, the result of the edit will be ignored.
   * @return True if editor is canceled after end.
   */
  public isCancelAfterEnd(): boolean {
    const value: number = +this._numberElement.nativeElement.value;
    const outOfBound =
      (this.min ? value < (isFunction(this.min) ? this.min() : this.min) : false) ||
      (this.max ? value > (isFunction(this.max) ? this.max() : this.max) : false);
    const invalid = this.params.validate && !this.params.validate(value, this.params.node) ? true : false;
    return outOfBound || invalid ? true : false;
  }

  /**
   * Prevent grid to stop editing on left, up, right, and down key down so that the user can move the cursor inside the input element.
   * @param event Browser's onKeyDown event args.
   */
  public onKeyDown(event: any): void {
    if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(event.key)) {
      event.stopPropagation();
    }
  }
}
