import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AgRendererComponent } from 'ag-grid-angular';

import { CheckboxCellRendererParams } from '../cell-renderer-params.interface';

/**
 * AG custom checkbox renderer component.
 *
 * This component is a cell renderer which can also be used as
 * an editor to allow user to directly perform edit without
 * requiring the user to enter into edit mode first.
 */
@Component({
  selector: 'app-checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  styleUrls: ['./checkbox-cell-renderer.component.scss'],
})
export class CheckboxCellRendererComponent implements AgRendererComponent {
  /**
   * Renderer parameters.
   */
  params: CheckboxCellRendererParams;

  /**
   * AgRendererComponent implementation. Gets called every cell refresh.
   * @param params Renderer parameter.
   */
  refresh(params: CheckboxCellRendererParams): boolean {
    return false;
  }

  /**
   * AgRendererComponent implementation. Gets called once after the renderer is created.
   * @param params Renderer parameter.
   */
  public agInit(params: CheckboxCellRendererParams): void {
    this.params = params;
  }

  /**
   * Checkbox change event handler.
   * @param event MatCheckboxChange event.
   */
  public checkedHandler(event: MatCheckboxChange) {
    this.params.node.setDataValue(this.params.column.getColId(), event.checked);
  }
}
