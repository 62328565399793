<app-base-dialog
  #dialog
  [dialogTitle]="
    'Advanced Search ' +
    (resourceURL.startsWith('/') ? resourceURL.substring(1) : resourceURL).split('-').join(' ').split('/').join(' - ')
  "
>
  <div top-toolbar></div>

  <div content>
    <!-- reset and preset -->
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="preset-bar">
      <button mat-icon-button color="primary" (click)="reset()" matTooltip="Reset" matTooltipShowDelay="500">
        <mat-icon>replay</mat-icon>
      </button>

      <mat-checkbox [(ngModel)]="showSelectedOnly" class="show-selected-only">Show Selected Only</mat-checkbox>

      <span fxFlex></span>

      <mat-form-field fxFlex.gt-sm="25" [fxShow]="selectedModeTabIndex === 0">
        <mat-label>Search Favourite Fields</mat-label>
        <input
          #filterFavSearchableInput
          matInput
          [matAutocomplete]="filterFavSearchableAutocomplete"
          [(ngModel)]="favSearchableFilterKeyword"
          placeholder="Type field you want to use"
        />
        <mat-autocomplete
          #filterFavSearchableAutocomplete="matAutocomplete"
          [displayWith]="searchableDisplayFn"
          (optionSelected)="favSearchableOptionSelected($event)"
        >
          <mat-option
            *ngFor="let searchable of filteredFavSearchables | async"
            [value]="searchable"
            [disabled]="customMode && searchable.node.i === undefined"
            matTooltip="{{ searchable.display }}"
            matTooltipPosition="left"
          >
            {{ searchable.display }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex.gt-sm="25" [fxShow]="selectedModeTabIndex === 1">
        <mat-label>Search All Fields</mat-label>
        <input
          #filterSearchableInput
          matInput
          [matAutocomplete]="filterSearchableAutocomplete"
          [(ngModel)]="searchableFilterKeyword"
          placeholder="Type field you want to use"
        />
        <mat-autocomplete
          #filterSearchableAutocomplete="matAutocomplete"
          [displayWith]="searchableDisplayFn"
          (optionSelected)="searchableOptionSelected($event)"
        >
          <mat-option
            *ngFor="let searchable of filteredSearchables | async"
            [value]="searchable"
            matTooltip="{{ searchable.display }}"
            matTooltipPosition="left"
          >
            {{ searchable.display }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex.gt-sm="15">
        <mat-label>Selected Preset</mat-label>
        <mat-select #selectedPresetSelect [(ngModel)]="selectedQAdvPreset" (ngModelChange)="setQAdvWorkspace($event)">
          <mat-option
            *ngFor="let qAdvPreset of qAdvPresets"
            [value]="qAdvPreset"
            [disabled]="qAdvPreset.mode === 1 && this.selectedModeTabIndex !== 1"
          >
            {{ qAdvPreset.name }} {{ qAdvPreset.report ? '(report)' : '' }}
            {{ qAdvPreset.mode === 2 ? '(custom)' : '' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button [matMenuTriggerFor]="moreMenu"><mat-icon>more_vert</mat-icon></button>
      <mat-menu x-position="before" #moreMenu="matMenu">
        <button mat-menu-item (click)="save()">
          <mat-icon>save</mat-icon>
          <span>Save Current As</span>
        </button>
        <button mat-menu-item (click)="delete()">
          <mat-icon>delete</mat-icon>
          <span>Delete Selected</span>
        </button>
        <button mat-menu-item (click)="export()">
          <mat-icon>file_upload</mat-icon>
          <span>Export All</span>
        </button>
        <button mat-menu-item (click)="import()">
          <mat-icon>file_download</mat-icon>
          <span>Import All</span>
        </button>
      </mat-menu>
    </div>

    <!-- where -->
    <mat-tab-group [selectedIndex]="selectedModeTabIndex" (selectedIndexChange)="onSelectedModeTabChange($event)">
      <!-- favourite mode -->
      <mat-tab label="Favourite">
        <mat-card fxLayout="row wrap" class="favourite">
          <app-fav-node
            *ngFor="let favSearchable of favSearchables"
            [searchable]="favSearchable"
            [disabled]="customMode && favSearchable.node.i === undefined"
            (toggleChange)="toggleFavSearchable($event, favSearchable)"
            [fxShow]="isFavSearchableShown(favSearchable)"
            fxFlex="100"
          >
          </app-fav-node>
        </mat-card>
      </mat-tab>

      <!-- builder mode -->
      <mat-tab label="Builder" *ngIf="!customMode">
        <mat-card class="where">
          <mat-button-toggle-group
            fxLayout="row wrap"
            [(ngModel)]="currentWhereHasTab"
            (change)="onRelationButtonToggleChange()"
          >
            <mat-button-toggle
              [value]="'this'"
              [fxShow]="isWhareHasShown(qAdvWorkspace.w)"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <span>{{ 'This' }}</span>
              <mat-icon *ngIf="qAdvWorkspace.w.g.length > 0" class="star">star</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              [value]="h.r"
              *ngFor="let h of qAdvWorkspace.h"
              [fxShow]="isWhareHasShown(h)"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <span>{{ renderRelationName(h.r) }}</span>
              <mat-icon *ngIf="h.g.length > 0 || h.a === true" class="star">star</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div>
            <app-node
              #rootNode
              *ngIf="currentWhereHasTab === 'this' && isWhareHasShown(qAdvWorkspace.w)"
              [resourceURL]="resourceURL"
              [node]="qAdvWorkspace.w"
            ></app-node>
          </div>
          <div *ngFor="let h of qAdvWorkspace.h">
            <app-node
              #rootNode
              *ngIf="currentWhereHasTab === h.r && isWhareHasShown(h)"
              [resourceURL]="h.resourceURL"
              [node]="h"
            ></app-node>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

    <!-- order -->
    <mat-card class="order" *ngIf="!customMode">
      <app-node [resourceURL]="resourceURL" [node]="qAdvWorkspace.o"></app-node>
    </mat-card>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="search()">Search</button></div>
</app-base-dialog>
