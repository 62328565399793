import { ErrorHandler, Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { mergeMap, throttleTime } from 'rxjs/operators';
import { HttpRestService } from 'src/app/shared/services/http-rest.service';

import { ErrorInfo } from './error-info.interface';

@Injectable({
  providedIn: 'root',
})
export class HandlerService extends ErrorHandler {
  private _errorSubject: Subject<ErrorInfo> = new Subject<ErrorInfo>();

  /**
   * Constructor.
   */
  public constructor(private _httpRestService: HttpRestService, private _deviceDetector: DeviceDetectorService) {
    super();

    this._errorSubject
      .pipe(
        throttleTime(2000),
        mergeMap((data) => this._httpRestService.post('/report-error', { data }, {}, false))
      )
      // Ignore any error during reporting.
      .subscribe({ error: () => {} });
  }

  /**
   * @inheritdoc
   */
  public handleError(error: any): void {
    super.handleError(error);

    if (
      // General error.
      error.zoneAwareStack ||
      // HttpRestService's normalized non-Http error.
      (error.code && error.code === 0) ||
      // Thrown string error message.
      typeof error === 'string'
    ) {
      const device: DeviceInfo = this._deviceDetector.getDeviceInfo();
      this._errorSubject.next({ error: error.zoneAwareStack || error.message || error, device });
    }
  }
}
