<app-base-dialog #dialog [showHeader]="false" [showActionBar]="false">
  <div #wrapper content>
    <img #img [src]="imageData" [alt]="alt" appDraggable [attr.draggable]="'false'" />

    <button
      *ngIf="this.gallery"
      mat-mini-fab
      color="primary"
      (click)="prev().subscribe()"
      matTooltip="Prev"
      matTooltipShowDelay="500"
      class="prev"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      *ngIf="this.gallery"
      mat-mini-fab
      color="primary"
      (click)="next().subscribe()"
      matTooltip="Next"
      matTooltipShowDelay="500"
      class="next"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>

    <button
      mat-icon-button
      color="primary"
      (click)="close()"
      matTooltip="Close"
      matTooltipShowDelay="500"
      class="close"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</app-base-dialog>
