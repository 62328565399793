export const environment = {
  production: false,
  staging: true,
  development: false,
  pwaEnabled: true,
  baseUrl: 'https://cms-staging.jangkarpacific.com',
  authUrl: 'https://uam-staging.jangkarpacific.com',
  loginUrl: 'https://apps-staging.jangkarpacific.com',
  xDebugSessionKey: '',
};
