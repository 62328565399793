<app-base-dialog
  #dialog
  [dialogTitle]="'Generate ' + resourceURL.substring(1).split('-').join(' ').split('/').join(' - ') + ' Report'"
>
  <div top-toolbar></div>

  <div content>
    <mat-card>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayout.gt-xs="row">
          <mat-form-field class="name">
            <mat-label>Name</mat-label>
            <mat-select [(ngModel)]="selectedReport">
              <mat-option *ngFor="let report of reports" [value]="report">
                {{ report.name + (report.custom ? ' (custom)' : '') }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="format">
            <mat-label>Format</mat-label>
            <mat-select [(ngModel)]="selectedFormat">
              <mat-option *ngFor="let format of selectedReport.formats" [value]="format">{{ format }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-checkbox [(ngModel)]="showQueryPage" fxFlexAlign="start" style="margin: 4px 8px 12px">
          Show Query Page
        </mat-checkbox>
        <button mat-raised-button (click)="buildQuery()" color="primary" class="build">Build Query</button>
      </div>
    </mat-card>
  </div>

  <div bottom-toolbar></div>

  <div action-bar>
    <button mat-raised-button color="primary" (click)="close()">Close</button>
  </div>
</app-base-dialog>
