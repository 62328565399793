import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

/**
 * Alert dialog component.
 */
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Alert title.
   */
  public title: string;

  /**
   * Alert contents.
   */
  public contents: string[];

  /**
   * OK button label.
   */
  public okLabel: string;

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<AlertDialogComponent>) {
    this.title = data.title;
    this.contents = data.contents;
    this.okLabel = data.okLabel ? data.okLabel : 'OK';
  }

  /**
   * OK action.
   */
  public ok(): void {
    this._dialogRef.close(true);
  }
}
