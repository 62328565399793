<div fxLayout="row">
  <mat-form-field [appHighlightError]="validationMessages" fxFlex>
    <mat-label>{{ label }} (Date)</mat-label>
    <input
      matInput
      [ngModel]="value"
      (ngModelChange)="onModelChange($event)"
      [matDatepicker]="datepicker"
      [appTxtMask]="{ mask: dateMask, pipe: datePipe }"
      [disabled]="disabled"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field [appHighlightError]="validationMessages">
    <mat-label>{{ label }} (Time)</mat-label>
    <input
      matInput
      [ngModel]="value ? value.format('HH:mm:ss') : ''"
      (ngModelChange)="onModelChange($event)"
      [appTxtMask]="{ mask: timeMask, pipe: timePipe }"
      [disabled]="disabled"
    />
  </mat-form-field>
</div>
