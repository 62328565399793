import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../../dialog/base-dialog/base-dialog.component';
import { Report } from '../report.interface';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-multi-resource-report',
  templateUrl: './multi-resource-report.component.html',
  styleUrls: ['./multi-resource-report.component.scss'],
})
export class MultiResourceReportComponent implements OnInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Report Dashboard dialog title.
   */
  public title: string;

  /**
   * All availabile reports.
   */
  public resourceReports: { resourceURL: string; title: string; reports: Report[] }[] = [];

  /**
   * Constructor.
   */
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<MultiResourceReportComponent>,
    private _reportService: ReportService
  ) {
    this.title = this.data.title;
    this.resourceReports = this.data.resourceReports;
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    // Initialize all report's format if not specified.
    this.resourceReports.forEach((dashboardReport) => {
      dashboardReport.reports.forEach((report) => (report.formats = report.formats || ['PDF', 'XLS', 'CSV']));
    });
  }

  /**
   * Build report.
   * @param resourceURL Resource URL
   * @param report Report
   */
  public buildReport(resourceURL: string, report: Report): void {
    this._reportService.build(resourceURL, report).subscribe();
  }

  /**
   * Close the dialog.
   */
  public close(): void {
    this._dialogRef.close();
  }
}
