import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, first, map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '../../shared/dialog/dialog.service';
import { HelperService } from '../../shared/services/helper.service';
import { HttpRestService } from '../../shared/services/http-rest.service';
import { GlobalService } from '../services/global.service';

/**
 * Authentication service using Jason Web Token.
 */
@Injectable({
  providedIn: 'root',
})
export class JwtService {
  /**
   * Refresh JWT token API URI.
   */
  public readonly REFRESH_API_URI: string = '/refresh';

  /**
   * Logout user API URI.
   */
  public readonly LOGOUT_API_URI: string = '/logout';

  /**
   * Authenticated event emitter.
   */
  public authenticated: EventEmitter<boolean> = new EventEmitter();

  /**
   * Authenticated flag.
   */
  public isAuthenticated = false;

  /**
   * Constuructor.
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService,
    private _router: Router
  ) {
    /**
     * Refresh token when HttpRestService refresh threshold is reached.
     */
    this._httpRestService.refreshedTokenRequired.subscribe(() => this._refreshToken().subscribe());
  }

  /**
   * Authenticate user using either existing or URL embedded token.
   * @param params URL's query params.
   * @return True if autheticated, otherwise false.
   */
  public authenticate(params: Params): Observable<boolean> {
    // Only perform this authentication once after full page reload.
    if (this.isAuthenticated) {
      return of(true);
    }

    // Show authenticating dialog.
    const authenticating = this._dialogService.openProcessingDialog('Authenticating');

    // Get payload from query params.
    const payload = params['payload'] ? JSON.parse(params['payload']) : {};

    if (payload[GlobalService.TOKEN_KEY]) {
      // Token exists in payload, proceed using payload's token.

      if (this._helperService.getLocalStorageItem(GlobalService.TOKEN_KEY)) {
        // There is existing token in the browser.

        // Notify user about existing token in the browser, then refresh existing token.
        return of(false).pipe(
          // Delay the alert dialog opening so that it is shown above the authenticating dialog.
          delay(100),
          switchMap(() =>
            this._dialogService
              .openAlertDialog(
                'Previous user found',
                [
                  'Please logout first before logging in as another user. For now, login as the already logged in user.',
                ],
                null,
                true
              )
              .afterClosed()
              .pipe(
                switchMap(() => {
                  return this._refreshToken().pipe(
                    tap((refreshed) => {
                      if (refreshed) {
                        // Set authenticated.
                        this._setAuthenticated();
                      }

                      // Clear payload query params in the url by navigating to home route using empty queryParams.
                      this._router.navigate(['home'], { queryParams: {} });

                      // Close authenticating dialog.
                      this._dialogService.closeProcessingDialog(authenticating);
                    })
                  );
                })
              )
          )
        );
      } else {
        // There is no existing token in the browser, load token from payload.

        // Load token.
        this._loadToken(payload);

        // Set authenticated.
        this._setAuthenticated();

        // Clear the token from query params in the url by navigating to current activated
        // route passing empty queryParams. We wait until first navigating end to
        // avoid double initial user authentication by CanActivate guard.
        this._router.events
          .pipe(
            first((event) => event instanceof NavigationEnd),
            tap(() => this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: {} }))
          )
          .subscribe();

        // Close authenticating dialog.
        this._dialogService.closeProcessingDialog(authenticating);

        return of(true);
      }
    } else {
      // Token does not exist in payload, trying to refresh existing token.

      // Refresh existing token.
      return this._refreshToken().pipe(
        tap((refreshed) => {
          if (refreshed) {
            // Set authenticated.
            this._setAuthenticated();
          }

          // Close authenticating dialog.
          this._dialogService.closeProcessingDialog(authenticating);
        })
      );
    }
  }

  /**
   * Logout a user.
   * @return Observable of boolean indicating logout is success or not.
   */
  public logout(): Observable<boolean> {
    return this._httpRestService.post(this.LOGOUT_API_URI, {}, {}, true, true).pipe(
      tap(() => {
        this.redirectToLogin();
      })
    );
  }

  /**
   * Redirect user to login page.
   */
  public redirectToLogin(): void {
    this._helperService.setLocalStorageItem(GlobalService.TOKEN_KEY, '');
    window.location.href =
      this._globalService.loginUrl + (this._globalService.appId ? `/booth/auth?app=${this._globalService.appId}` : '');
  }

  /**
   * Refresh existing token.
   * @return Observable of boolean indicating refresh is success or not.
   */
  private _refreshToken(): Observable<boolean> {
    return this._httpRestService.get(this.REFRESH_API_URI, {}, true, true).pipe(
      tap((payload) => this._loadToken(payload)),
      map(() => true),
      catchError((error) => of(false))
    );
  }

  /**
   * Set authenticated and emit it.
   */
  private _setAuthenticated(): void {
    this.isAuthenticated = true;
    this.authenticated.emit(this.isAuthenticated);
    // Immediately complete this event to avoid leak, because it only emits once on initialization.
    this.authenticated.complete();
  }

  /**
   * Load token from paylaod.
   * @param payload UAM payload.
   */
  private _loadToken(payload: any): void {
    // Load retrieved token.
    this._globalService.token = payload[GlobalService.TOKEN_KEY];
    // Persist retrieved token.
    this._helperService.setLocalStorageItem(GlobalService.TOKEN_KEY, payload[GlobalService.TOKEN_KEY]);
  }
}
