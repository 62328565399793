<div class="table-responsive">
  <table matSort (matSortChange)="sort($event)" class="table table-condensed table-hover table-striped">
    <tr>
      <th mat-sort-header="_no">No</th>
      <th mat-sort-header="from">From</th>
      <th mat-sort-header="to">To</th>
      <th mat-sort-header="remark" class="text">Remark</th>
      <th mat-sort-header="tag">Tag</th>
      <th mat-sort-header="event_at" class="date-time">Event At</th>
      <th mat-sort-header="actor">Actor</th>
      <th mat-sort-header="valid">Valid</th>
      <th mat-sort-header="current">Current</th>
      <th mat-sort-header="reverted_by">Reverted By</th>
      <th mat-sort-header="id">Id</th>
      <th mat-sort-header="created_by">Created By</th>
      <th mat-sort-header="created_at" class="date-time">Created At</th>
    </tr>
    <tr *ngFor="let log of sortedStateLogs || stateLogs | rowNumber" [ngClass]="{ dimmed: log.valid === 'FALSE' }">
      <td>{{ log._no }}</td>
      <td>{{ log.from }}</td>
      <td>{{ log.to }}</td>
      <td>{{ log.remark }}</td>
      <td>{{ log.tag }}</td>
      <td>{{ log.event_at }}</td>
      <td>
        <a [routerLink]="[log | actorPath]">
          {{ log.actor?.document_number || log.actor?.doc_no }}
        </a>
      </td>
      <td>{{ log.valid }}</td>
      <td>{{ log.current }}</td>
      <td>{{ log.reverted_by }}</td>
      <td>{{ log.id }}</td>
      <td>{{ log.created_by }}</td>
      <td>{{ log.created_at }}</td>
    </tr>
    <tr *ngIf="stateLogs.length === 0">
      <td colspan="13">There're no records.</td>
    </tr>
  </table>
</div>
