<app-base-dialog #dialog [showHeader]="false">
  <div top-toolbar></div>

  <div content>
    <h2 [innerHtml]="title"></h2>
    <p *ngFor="let content of contents" [innerHtml]="content | safe: 'html'"></p>
  </div>

  <div bottom-toolbar></div>

  <div action-bar>
    <button mat-raised-button color="primary" (click)="ok()">{{ okLabel }}</button>
  </div>
</app-base-dialog>
