import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpStatusCode,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, finalize, first, last, map, mergeMap, retryWhen, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GlobalService } from '../../core/services/global.service';
import { ProfilePreference } from '../../core/user-profile/user-profile-preference.interface';
import { UserProfile } from '../../core/user-profile/user-profile.interface';
import { AlertDialogComponent } from '../dialog/alert-dialog/alert-dialog.component';
import { CommonError } from '../interfaces/common-error.interface';
import { HelperService } from './helper.service';
import { SanitizerService } from './sanitizer.service';

/**
 * Wrapper service for HttpClient module. This wrapper adds some default behaviors
 * for communicating with REST backend such as error handling and message handling.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpRestService {
  /**
   * HttpRestService's url segment for accessing API backend.
   */
  public readonly API_URI: string = '/api';

  /**
   * HttpRestService will request a new refreshed token if this threshold reached.
   */
  public readonly REFRESH_TOKEN_THRESHOLD: number = 50;

  /**
   * HttpRestService will attach the permission payload if set to true.
   */
  public readonly ATTACH_PERMISSION_PAYLOAD: boolean = true;

  /**
   * HttpRestService will attach the authorization http header and emit request token refresh event if set to true.
   */
  public readonly USE_AUTHORIZATION: boolean = true;

  /**
   * HttpRestService will show redirect to authenticate alert dialog on authentication error if set to true.
   */
  public readonly ON_ERROR_REQUIRE_AUTHENTICATION: boolean = true;

  /**
   * Emit event every time _refreshTokenThreshold is reached.
   */
  public refreshedTokenRequired: EventEmitter<number> = new EventEmitter();

  /**
   * Emit event every time we get 400 or 401 authentication error.
   */
  public authenticateRequired: EventEmitter<CommonError> = new EventEmitter();

  /**
   * Emit event every time we get 503 service uunavailable error.
   */
  public serviceUnavailable: EventEmitter<CommonError> = new EventEmitter();

  /**
   * Emit event every time new version is known to be available.
   */
  public newVersionAvailable: EventEmitter<CommonError> = new EventEmitter();

  /**
   * Emit event if there's an error during permission payload build which requires profile preference re-init.
   */
  public profilePreferenceReinitRequired: EventEmitter<any> = new EventEmitter();

  /**
   * Current total number of request. It will be reset to 0 every time _refreshTokenThreshold is reached.
   */
  private _numberOfRequest = 0;

  /**
   * Constructor.
   */
  constructor(
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _httpClient: HttpClient,
    private _matDialog: MatDialog,
    private _sanitizerService: SanitizerService
  ) {}

  /**
   * Performs a request with `get` http method.
   * @param url URL string prefixed with slash.
   * @param options Http request options.
   * @param toast Set to true to toast the response message.
   * @param auth Flag indicating whether to use auth or base url.
   * @param progressHandler Callback which is called during request progress.
   * @return Observable Response or data object.
   */
  public get(
    url: string,
    options: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      context?: HttpContext;
      observe?: 'body' | 'events' | 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
    toast: boolean = true,
    auth: boolean = false,
    progressHandler?: (event: HttpEvent<any>) => void
  ): Observable<any> {
    return this._send(
      () => {
        return this._httpClient
          .get<any>(
            `${auth ? this._globalService.authUrl : this._globalService.baseUrl}${this.API_URI}${url}`,
            options as any
          )
          .pipe(
            tap((event) => {
              if (options.reportProgress) {
                progressHandler(event);
              }
            }),
            last()
          );
      },
      options,
      toast,
      auth
    );
  }

  /**
   * Performs a request with `post` http method.
   * @param url URL string prefixed with slash.
   * @param body Http request body.
   * @param options Http request options.
   * @param toast Set to true to toast the response message.
   * @param auth Flag indicating whether to use auth or base url.
   * @param progressHandler Callback which is called during request progress.
   * @return Observable Response or data object.
   */
  public post(
    url: string,
    body: any,
    options: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      context?: HttpContext;
      observe?: 'body' | 'events' | 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
    toast: boolean = true,
    auth: boolean = false,
    progressHandler?: (event: HttpEvent<any>) => void
  ): Observable<any> {
    return this._send(
      () => {
        return this._httpClient
          .post<any>(
            `${auth ? this._globalService.authUrl : this._globalService.baseUrl}${this.API_URI}${url}`,
            this._sanitizeSent(body),
            options as any
          )
          .pipe(
            tap((event) => {
              if (options.reportProgress) {
                progressHandler(event);
              }
            }),
            last()
          );
      },
      options,
      toast,
      auth
    );
  }

  /**
   * Performs a request with `put` http method.
   * @param url URL string prefixed with slash.
   * @param body Http request body.
   * @param options Http request options.
   * @param toast Set to true to toast the response message.
   * @param auth Flag indicating whether to use auth or base url.
   * @param progressHandler Callback which is called during request progress.
   * @return Observable Response or data object.
   */
  public put(
    url: string,
    body: any,
    options: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      context?: HttpContext;
      observe?: 'body' | 'events' | 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
    toast: boolean = true,
    auth: boolean = false,
    progressHandler?: (event: HttpEvent<any>) => void
  ): Observable<any> {
    return this._send(
      () => {
        return this._httpClient
          .put<any>(
            `${auth ? this._globalService.authUrl : this._globalService.baseUrl}${this.API_URI}${url}`,
            this._sanitizeSent(body),
            options as any
          )
          .pipe(
            tap((event) => {
              if (options.reportProgress) {
                progressHandler(event);
              }
            }),
            last()
          );
      },
      options,
      toast,
      auth
    );
  }

  /**
   * Performs a request with `delete` http method.
   * @param url URL string prefixed with slash.
   * @param options Http request options.
   * @param toast Set to true to toast the response message.
   * @param auth Flag indicating whether to use auth or base url.
   * @param progressHandler Callback which is called during request progress.
   * @return Observable Response or data object.
   */
  public delete(
    url: string,
    options: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      context?: HttpContext;
      observe?: 'body' | 'events' | 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
    toast: boolean = true,
    auth: boolean = false,
    progressHandler?: (event: HttpEvent<any>) => void
  ): Observable<any> {
    return this._send(
      () => {
        return this._httpClient
          .delete<any>(
            `${auth ? this._globalService.authUrl : this._globalService.baseUrl}${this.API_URI}${url}`,
            options as any
          )
          .pipe(
            tap((event) => {
              if (options.reportProgress) {
                progressHandler(event);
              }
            }),
            last()
          );
      },
      options,
      toast,
      auth
    );
  }

  /**
   * Send the http call with several preset behaviors.
   * @param httpCall Http call function to be sent.
   * @param options Http request options.
   * @param toast Flag indicating whether to toast info and error message.
   * @param auth Flag indicating whether to use auth or base url.
   * @return Observable Response or data object.
   */
  private _send(
    httpCall: () => Observable<any>,
    options: any,
    toast: boolean = true,
    auth: boolean = false
  ): Observable<any> {
    // Return http call observable which
    return of({}).pipe(
      // ...first, attaches default http headers (X-Requested-With and Authorization)
      tap(() => this._attachDefaultHeaders(options)),

      // ...then, normalize options' url search params
      tap(() => this._normalizeParams(options)),

      // ...then, attaches permission payload to url's params
      tap(() => this._attachPermissionParams(options)),

      // ...then, attaches XDebug session trigger to url's params
      tap(() => this._attachXDebugParam(options)),

      // ...then, performs actual http call
      mergeMap(() =>
        httpCall().pipe(
          retryWhen((errors) => {
            // We retry twice (with some dalay) before throwing the error.
            // Unknown error (0)
            // Gateway Timeout error (504).
            return errors.pipe(
              concatMap((error) => iif(() => [0, 504].includes(error.status), of(error), throwError(error))),
              concatMap((error, index) => {
                return iif(() => index < 2, of({}).pipe(delay(1000)), throwError(error));
              })
            );
          })
        )
      ),

      // ...then, sanitize received data
      map((result) => this._sanitizeReceived(result)),

      // ...then toasts a message if exists in the reponse
      tap((result) => this._handleMessage(result, toast)),

      // ...if error happens, catches the error (Error or HttpError response) then toasts the message and rethrows the normalized error
      catchError((error) => this._handleError(error, toast)),

      // ...finally, requests token refresh if the threshold is reached.
      finalize(() => this._requestTokenRefresh(auth))
    );
  }

  /**
   * Attach default headers.
   * @param options Http request options.
   */
  private _attachDefaultHeaders(options: any): void {
    // Define default headers.
    let headers = new HttpHeaders().append('X-Requested-With', 'XMLHttpRequest');

    // Append authorization header.
    if (this.USE_AUTHORIZATION) {
      headers = headers.append(
        'Authorization',
        `Bearer ${this._helperService.getLocalStorageItem(GlobalService.TOKEN_KEY)}`
      );
    }

    // Merging with default headers.
    if (options.headers) {
      options.headers.forEach((name, value) => {
        headers = headers.append(name, value.join(','));
      });
    }

    // Attach to request options.
    options.headers = headers;
  }

  /**
   * Normalize options' url search params to an instance of HttpParams.
   * @param options Http request options.
   */
  private _normalizeParams(options: any): void {
    options.params = options.params
      ? options.params instanceof HttpParams
        ? options.params
        : typeof options.params === 'string'
        ? new HttpParams({ fromString: options.params, encoder: new CustomHttpUrlEncodingCodec() })
        : new HttpParams({ fromObject: options.params, encoder: new CustomHttpUrlEncodingCodec() })
      : new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  }

  /**
   * Attach permission query string
   * @param options Http request options.
   */
  private _attachPermissionParams(options: any): void {
    // Skip if _attachPermissionPayload is set to false.
    if (!this.ATTACH_PERMISSION_PAYLOAD) {
      return;
    }

    // Set local variables.
    const userProfile: UserProfile = this._globalService.userProfile;
    const apiProfiles: { [appCode: string]: UserProfile } = this._globalService.apiProfiles;
    const profilePreference = this._globalService.profilePreference;

    // Main app permission payload
    if (userProfile) {
      options.params = options.params.append(
        'payload',
        JSON.stringify(this._getPermissionPayload(this._globalService.appId, userProfile, profilePreference))
      );
    }

    // Partner apps permission payload
    if (apiProfiles) {
      const payload = {};

      for (const appCode of Object.keys(apiProfiles)) {
        const profile = apiProfiles[appCode];
        payload[appCode] = this._getPermissionPayload(
          profile.applications[0].id.toString(),
          profile,
          profilePreference
        );
      }

      options.params = options.params.append('APIPartnersPayload', JSON.stringify(payload));
    }
  }

  /**
   * Attach XDebug trigger query parameter.
   * @param options Http request options.
   */
  private _attachXDebugParam(options: any): void {
    if (environment.xDebugSessionKey && environment.xDebugSessionKey !== '') {
      options.params = (<HttpParams>options.params).append('XDEBUG_SESSION_START', environment.xDebugSessionKey);
    }
  }

  /**
   * Sanitize going to be sent data.
   * @param data Data to be sent as http request.
   * @return Sanitized data.
   */
  private _sanitizeSent(data: any): any {
    return this._sanitizerService.sanitizeSent(data);
  }

  /**
   * Sanitized received data.
   * @param data Data received as Object or HttpResponse.
   * @return HttpResponse or sanitized data.
   */
  private _sanitizeReceived(data: any): any {
    // Skip sanitizing extracted data if the client expext the result as Response object.
    return data instanceof HttpResponse ? data : this._sanitizerService.sanitizeReceived(data);
  }

  /**
   * Performs default message handling.
   * @param data Data received as decoded from JSON data object.
   * @param toast Whether toast message or not.
   */
  private _handleMessage(data: any, toast: boolean): void {
    if (data.message && toast) {
      if (data.prompt) {
        // Prompt info message.
        this._openAlertDialog('Message', [data.message]);
      } else {
        // Toast info message.
        this._helperService.toast(data.message, 'OK', false);
      }
    }
  }

  /**
   * Performs default http error handling and rethrow normalized error.
   * @param response Error response.
   * @param toast Whether toast message or not.
   * @return Observable of CommonError.
   */
  private _handleError(response: HttpErrorResponse | CommonError | any, toast: boolean): Observable<CommonError> {
    const commonError: Observable<CommonError> =
      !(response instanceof HttpErrorResponse) && response.status && response.statusText && response.message
        ? // Already normalized error.
          of(response as CommonError)
        : // Any error.
          this._normalizeError(response, toast);

    return commonError.pipe(
      // Toast error message.
      tap((error) => this._toastErrorMessage(error, toast)),
      // Rethrow the normalized error
      map((error) => {
        throw error;
      })
    );
  }

  /**
   * Toast error message.
   * @param error Error
   * @param toast Whether toast message or not.
   */
  private _toastErrorMessage(error: CommonError, toast: boolean) {
    if (
      !this._requireFetchNewVersion(error) &&
      !this._requireToAuthenticate(error) &&
      !this._serviceUnavailable(error) &&
      toast
    ) {
      if (error.prompt) {
        // Prompt error message.
        this._openAlertDialog(error.status, [error.message]);
      } else {
        // Toast error message.
        this._helperService.toast(error.message ? error.message : error.status);
      }
    }
  }

  /**
   * Request a new refreshed token if needed.
   * @param auth Flag indicating whether to use auth or base url.
   */
  private _requestTokenRefresh(auth: boolean): void {
    // Skip request token if it a request to auth URL.
    if (auth) {
      return;
    }

    if (this._numberOfRequest >= this.REFRESH_TOKEN_THRESHOLD) {
      // Emit event if USE_AUTHORIZATION is set to true.
      if (this.USE_AUTHORIZATION) {
        this.refreshedTokenRequired.emit(this._numberOfRequest);
      }
      // Reset counter back to 0.
      this._numberOfRequest = 0;
    } else {
      // Increment counter by 1.
      this._numberOfRequest++;
    }
  }

  /**
   * Get permission payload object.
   * @param appId App id to retrieve permission payload.
   * @param profile Currently logged in user profile.
   * @param preference User profile preference.
   * @return Permission paylaod
   */
  private _getPermissionPayload(appId: string, profile: UserProfile, preference: ProfilePreference): any {
    const build = () => {
      return {
        userId: profile.id,
        appId: profile.applications[0].id,
        versionId: profile.applications[0].versions[0].id,
        roleIds: profile.roles.map((role) => role.id),
        storageId:
          profile.workgroups[preference.activeWorkgroupIndex[appId]].storages[preference.activeStorageIndex[appId]].id,
        workgroupId: profile.workgroups[preference.activeWorkgroupIndex[appId]].id,
      };
    };
    let payload;

    // There are chances of undefined index on building permission payload based on saved preference. This is
    // caused by the difference of workgroups and storages between saved and latest values from UAM.
    // To handle this we wrap it with try catch, reinitialize it and ask user to try again.
    try {
      payload = build();
    } catch (error) {
      this.profilePreferenceReinitRequired.emit();
      throw 'Invalid user profile has been reinitialized.<br/>Please check the user profile and try to load again.';
    }

    // Only attach filterWorkgroupIds on main app payload.
    if (appId === this._globalService.appId) {
      payload['filterWorkgroupIds'] = profile.workgroups.reduce((prev, curr, index) => {
        if (
          preference.filterWorkgroupIndexes.find((filterWorkgroupIndex) => filterWorkgroupIndex === index) !== undefined
        ) {
          prev.push(curr.id);
        }
        return prev;
      }, []);
    }

    return payload;
  }

  /**
   * Normalize error response or exception.
   * @param response Error response.
   * @param toast Whether toast message or not.
   * @return Observable of normalized error as CommonError.
   */
  private _normalizeError(response: HttpErrorResponse | any, toast: boolean): Observable<CommonError> {
    // Set default Error.
    const error: CommonError = {};

    // Normalize error.
    if (response instanceof HttpErrorResponse) {
      // Http error response.
      const httpErrorDefaultMessage =
        'An error occured while processing your request.<br/>Please make sure you have an active internet connection.';

      error.code = response.status;
      error.status = response.statusText;
      error.prompt = false;

      try {
        // Read error using FileReader if response data is blob, otherwise using Response.json() as json.
        if (response.error instanceof Blob) {
          const reader = new FileReader();
          reader.readAsText(response.error);
          return fromEvent(reader, 'loadend').pipe(
            first(),
            map((event) => {
              try {
                const result = (<FileReader>event.target).result;
                const errorBag = JSON.parse(<string>result);
                error.errorBag = errorBag;
                error.message =
                  (errorBag && errorBag.message) ||
                  (response.status > 0 ? response.statusText : httpErrorDefaultMessage);
                error.prompt = errorBag && errorBag.prompt;
              } catch (e) {
                error.message = response.statusText;
              }
              return error;
            })
          );
        } else {
          const errorBag = response.error;
          error.errorBag = errorBag;
          error.message =
            (errorBag && errorBag.message) || (response.status > 0 ? response.statusText : httpErrorDefaultMessage);
          error.prompt = errorBag && errorBag.prompt;
        }
      } catch (e) {
        error.message = response.statusText;
      }
    } else {
      // Other non-Http error.
      error.code = 0;
      error.status = 'General Failure';
      error.message = response.toString() !== '' ? response.toString() : 'General Failure';
    }

    // Return normalized error.
    return of(error);
  }

  /**
   * If the error is due to new version is available.
   * @param error CommonError to be handled.
   */
  private _requireFetchNewVersion(error: CommonError): boolean {
    if (error.code === HttpStatusCode.Unauthorized && error.message.includes('New version')) {
      this.newVersionAvailable.emit(error);
      return true;
    } else {
      return false;
    }
  }

  /**
   * If the error requires user to authenticate.
   * @param error CommonError to be handled.
   */
  private _requireToAuthenticate(error: CommonError): boolean {
    if (
      this.ON_ERROR_REQUIRE_AUTHENTICATION &&
      //
      // List of required authentication http codes.
      //
      (error.code === HttpStatusCode.BadRequest || error.code === HttpStatusCode.Unauthorized)
    ) {
      this.authenticateRequired.emit(error);
      return true;
    } else {
      return false;
    }
  }

  /**
   * If the error indicates the service is unavailable.
   * @param error CommonError to be handled.
   */
  private _serviceUnavailable(error: CommonError): boolean {
    if (error.code === HttpStatusCode.ServiceUnavailable) {
      this.serviceUnavailable.emit(error);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Open simple alert dialog.
   * @param title Title text.
   * @param contents Array of content paragraphs.
   * @param okLabel OK button label.
   */
  private _openAlertDialog(title: string, contents: string[], okLabel?: string): MatDialogRef<AlertDialogComponent> {
    const component = AlertDialogComponent;
    const config = {
      data: {
        title: title,
        contents: contents,
        okLabel: okLabel,
      },
      disableClose: true,
    };
    return this._matDialog.open(component, config);
  }
}

/**
 * Custom implementation of HttpUrlEncodingCodec which "re-encodes" the + sign.
 *
 * HttpUrlEncodingCodec encodes keys and values of parameters using encodeURIComponent,
 * and then un-encodes certain characters that are allowed to be part of the
 * query according to IETF RFC 3986: https://tools.ietf.org/html/rfc3986.
 */
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  /**
   * @inheritdoc
   */
  encodeKey(value: string): string {
    return super.encodeKey(value).replace(/\+/g, '%2B');
  }

  /**
   * @inheritdoc
   */
  encodeValue(value: string): string {
    return super.encodeValue(value).replace(/\+/g, '%2B');
  }
}
