import { NgModel } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AsyncValidatorArray, getErrorMessage, validate, ValidationResult, ValidatorArray } from './validation';
import { ValueAccessor } from './value-accessor';

/**
 * Form control base which implements the Control Value Accessor pattern from Angular providing data validation using ngModel.
 */
export abstract class FormControlBase<T> extends ValueAccessor<T> {
  /**
   * This control's underlying ngModel used for validation.
   */
  protected model: NgModel;

  /**
   * Flag which will be true when any of the validators attached to the control through the dependency injector are failing.
   */
  protected get invalid(): Observable<boolean> {
    return this.validate().pipe(map((v) => Object.keys(v || {}).length > 0));
  }

  /**
   * Validator's error messages.
   */
  protected get errors(): Observable<Array<string>> {
    return this.validate().pipe(map((v) => Object.keys(v).map((k) => getErrorMessage(v, k))));
  }

  /**
   * Constructor.
   */
  constructor(private validators: ValidatorArray, private asyncValidators: AsyncValidatorArray) {
    super();
  }

  /**
   * Validate underlying model value. Returns an Observable object containing keys for any failed validators.
   * @return Observable of ValidationResult.
   */
  protected validate(): Observable<ValidationResult> {
    return this.model ? validate(this.model.control, this.validators, this.asyncValidators) : of(null);
  }
}
