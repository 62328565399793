<mat-toolbar id="navbar" color="primary" class="mat-elevation-z6">
  <mat-progress-bar mode="buffer" *ngIf="navigating" style="position: absolute"></mat-progress-bar>

  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>

    <a id="app-name" routerLink="/">
      <span fxShow="false" fxShow.gt-xs>{{ global.appName }} {{ global.appMode }}</span>
      <span fxHide="false" fxHide.gt-xs>{{ global.appNameAcronym }} {{ global.appMode }}</span>
    </a>

    <span fxFlex></span>

    <button mat-button fxShow="false" fxShow.gt-sm (click)="openProfile()" class="profile">{{ profile }}</button>

    <button mat-icon-button [matMenuTriggerFor]="moreMenu"><mat-icon>more_vert</mat-icon></button>

    <mat-menu x-position="before" #moreMenu="matMenu">
      <button mat-menu-item (click)="toggleDarkTheme()">
        <mat-icon>lightbulb_outline</mat-icon>
        <span>{{ themeToggle }} Theme</span>
      </button>
      <button mat-menu-item (click)="openHelp()">
        <mat-icon>help_outline</mat-icon>
        <span>Help</span>
      </button>
      <button mat-menu-item (click)="openAbout()">
        <mat-icon>info_outline</mat-icon>
        <span>About</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container id="main-container">
  <mat-sidenav #sidenav id="sidenav" mode="push" align="start">
    <mat-card id="profile">
      <mat-card-header>
        <mat-card-title> {{ global.userProfile?.name }} </mat-card-title>
        <mat-card-subtitle fxLayout="column">
          <span> {{ roles }} </span>
          <span>
            {{ (global.userProfile?.workgroups)[global.profilePreference.activeWorkgroupIndex[global.appId]]?.name }}
          </span>
          <span>
            {{
              ((global.userProfile?.workgroups)[global.profilePreference.activeWorkgroupIndex[global.appId]]?.storages)[
                global.profilePreference.activeStorageIndex[global.appId]
              ]?.name
            }}
          </span>
        </mat-card-subtitle>
        <div fxFlex></div>
        <button mat-icon-button color="accent" (click)="openProfile()"><mat-icon>settings</mat-icon></button>
      </mat-card-header>
    </mat-card>

    <mat-accordion multi="true" id="menu">
      <ng-container *ngFor="let menu1 of menus">
        <mat-nav-list *ngIf="menu1.path">
          <a mat-list-item [routerLink]="menu1.path" routerLinkActive="active" class="menu-1">
            <mat-icon mat-list-icon>{{ menu1.icon }}</mat-icon>
            <h3 matLine>{{ menu1.title }}</h3>
          </a>
        </mat-nav-list>
        <mat-expansion-panel *ngIf="!menu1.path" class="menu-1">
          <mat-expansion-panel-header>
            <mat-icon>{{ menu1.icon }}</mat-icon>
            <span>{{ menu1.title }}</span>
          </mat-expansion-panel-header>
          <mat-accordion multi="true">
            <ng-container *ngFor="let menu2 of menu1.subMenus">
              <mat-nav-list *ngIf="menu2.path">
                <a mat-list-item [routerLink]="menu2.path" routerLinkActive="active" class="menu-2">
                  <mat-icon mat-list-icon>{{ menu2.icon }}</mat-icon>
                  <h3 matLine>{{ menu2.title }}</h3>
                </a>
              </mat-nav-list>
              <mat-expansion-panel *ngIf="!menu2.path" class="menu-2">
                <mat-expansion-panel-header>
                  <mat-icon>{{ menu2.icon }}</mat-icon>
                  <span>{{ menu2.title }}</span>
                </mat-expansion-panel-header>
                <mat-accordion multi="true">
                  <ng-container *ngFor="let menu3 of menu2.subMenus">
                    <mat-nav-list *ngIf="menu3.path">
                      <a mat-list-item [routerLink]="menu3.path" routerLinkActive="active" class="menu-3">
                        <mat-icon mat-list-icon>{{ menu3.icon }}</mat-icon>
                        <h3 matLine>{{ menu3.title }}</h3>
                      </a>
                    </mat-nav-list>
                    <mat-expansion-panel *ngIf="!menu3.path" class="menu-3">
                      <mat-expansion-panel-header>
                        <mat-icon>{{ menu3.icon }}</mat-icon>
                        <span>{{ menu3.title }}</span>
                      </mat-expansion-panel-header>
                      <mat-nav-list>
                        <a
                          mat-list-item
                          *ngFor="let menu4 of menu3.subMenus"
                          [routerLink]="menu4.path"
                          routerLinkActive="active"
                          class="menu-4"
                        >
                          <mat-icon mat-list-icon>{{ menu4.icon }}</mat-icon>
                          <h3 matLine>{{ menu4.title }}</h3>
                        </a>
                      </mat-nav-list>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-sidenav>

  <mat-toolbar #breadcrumbs id="breadcrumbs" class="mat-elevation-z2">
    <span [innerHTML]="breadcrumbsHtml | async"></span>
  </mat-toolbar>

  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <mat-toolbar id="footer">
    <mat-toolbar-row id="notes">
      <span
        >Make sure you are using latest version of
        <a href="https://www.google.com/intl/en/chrome" target="blamk">Chrome</a> or
        <a href="https://www.mozilla.org/en-US/firefox/new" target="blamk">Firefox</a> to access the website.</span
      >
    </mat-toolbar-row>

    <mat-toolbar-row id="copyright" fxLayout="column" fxLayoutAlign="center center">
      <span>Copyright © 2023 PT Jangkar Pacific</span> <span>All rights reserved</span>
    </mat-toolbar-row>
  </mat-toolbar>
</mat-sidenav-container>

<button id="back-to-top" mat-mini-fab color="accent" (click)="backToTop()"><mat-icon>arrow_upward</mat-icon></button>
