<app-base-dialog
  #dialog
  [dialogTitle]="
    'Data List ' +
    (resourceURL.startsWith('/') ? resourceURL.substring(1) : resourceURL).split('-').join(' ').split('/').join(' - ')
  "
>
  <div top-toolbar></div>

  <div content>
    <app-data-list
      #dataList
      [gridOptions]="gridOptions"
      [resourceURL]="resourceURL"
      [searchMode]="true"
      [defaultTake]="25"
      [height]="dataListHeight"
    ></app-data-list>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
