<div fxLayout="row" class="topNav" fxLayoutAlign="end center" [style.width]="width">
  <!-- Dummy button so that when enter is pressed on ag grid's editable cell, click event binding is not get called. -->
  <button type="button" [disabled]="disabled" style="display: none"></button>
  <div
    *ngIf="!hideTopNav && searchMode"
    class="searchPanel"
    fxLayout="row"
    fxLayoutAlign="start end"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="end stretch"
    fxFlex
  >
    <mat-form-field appearance="standard" fxFlex>
      <mat-label>Quick Search</mat-label>
      <input
        #quickSearchInput
        matInput
        [matAutocomplete]="quickSearchAutocomplete"
        [disabled]="busy || disabled"
        [(ngModel)]="q"
        placeholder="Type search keyword (* column only) or use @ syntax keyword"
      />
      <mat-autocomplete
        #quickSearchAutocomplete="matAutocomplete"
        [displayWith]="searchableDisplayFn"
        (optionSelected)="onFieldOptionSelected($event)"
      >
        <mat-option *ngFor="let searchable of filteredSearchables | async" [value]="searchable">
          {{ searchable.display }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-around stretch">
      <button
        type="button"
        mat-icon-button
        [disabled]="busy || disabled"
        *ngIf="selectedSearchable"
        (click)="openAtNodeSetting().subscribe()"
        matTooltip="@ Node Setting"
        matTooltipShowDelay="500"
        color="primary"
      >
        <mat-icon>alternate_email</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        [disabled]="busy || disabled"
        (click)="openAdvancedSearch().subscribe()"
        [color]="qAdv === undefined ? 'primary' : 'accent'"
        matTooltip="Adv. Search"
        matTooltipShowDelay="500"
      >
        <mat-icon *ngIf="qAdv === undefined">search</mat-icon>
        <mat-icon *ngIf="qAdv !== undefined">zoom_in</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        [disabled]="busy || disabled"
        (click)="refresh().subscribe()"
        color="primary"
        matTooltip="Refresh"
        matTooltipShowDelay="500"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        [disabled]="busy || disabled"
        (click)="scheduleRefresh()"
        [color]="scheduledRefreshSubscription && !scheduledRefreshSubscription.closed ? 'accent' : 'primary'"
        [matTooltip]="
          scheduledRefreshInterval
            ? 'Refresh scheduled every ' + scheduledRefreshInterval + ' minutes'
            : 'Scheduled Refresh'
        "
        matTooltipShowDelay="500"
      >
        <mat-icon>update</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!hideTopNav && !searchMode" class="controlPanel">
    <ng-container *ngFor="let action of actions">
      <ng-container *ngTemplateOutlet="action.icon ? iconButton : strokedButton"></ng-container>
      <ng-template #iconButton>
        <button
          type="button"
          [disabled]="busy || disabled || (action.disabled && action.disabled(this.gridOptions.api?.getSelectedRows()))"
          [fxHide]="action.hidden && action.hidden(this.gridOptions.api?.getSelectedRows())"
          mat-icon-button
          (click)="action.action(this.gridOptions.api?.getSelectedRows())"
          color="primary"
          [matTooltip]="action.display"
          matTooltipShowDelay="500"
        >
          <mat-icon>{{ action.icon }}</mat-icon>
        </button>
      </ng-template>

      <ng-template #strokedButton>
        <button
          type="button"
          [disabled]="busy || disabled || (action.disabled && action.disabled(this.gridOptions.api?.getSelectedRows()))"
          [fxHide]="action.hidden && action.hidden(this.gridOptions.api?.getSelectedRows())"
          mat-stroked-button
          (click)="action.action(this.gridOptions.api?.getSelectedRows())"
          color="primary"
        >
          {{ action.display }}
        </button>
      </ng-template>
    </ng-container>

    <button
      type="button"
      [disabled]="busy || disabled || addDisabled"
      mat-icon-button
      (click)="add()"
      color="primary"
      matTooltip="Add"
      matTooltipShowDelay="500"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      type="button"
      [disabled]="busy || disabled || duplicateDisabled || !rowsSelected"
      mat-icon-button
      (click)="duplicate()"
      color="primary"
      matTooltip="Duplicate"
      matTooltipShowDelay="500"
    >
      <mat-icon>file_copy</mat-icon>
    </button>
    <button
      type="button"
      [disabled]="busy || disabled || removeDisabled || !rowsSelected"
      mat-icon-button
      (click)="remove()"
      color="warn"
      matTooltip="Remove"
      matTooltipShowDelay="500"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>

<ag-grid-angular
  [class.ag-theme-dark]="darkTheme"
  [class.ag-theme-fresh]="!darkTheme"
  [gridOptions]="gridOptions"
  [style.width]="width"
  [style.height]="gridHeight"
  [style.min-height]="'170px'"
  (keyup)="onKeyUp($event)"
  (keydown)="onKeyDown($event)"
></ag-grid-angular>

<div
  fxLayout="row"
  fxLayoutAlign="space-between start"
  fxLayout.lt-sm="column"
  fxLayoutAlign.lt-sm="start center"
  class="paginationNav"
  [style.width]="width"
>
  <div>
    <button
      type="button"
      [disabled]="busy || (!localData && disabled)"
      mat-icon-button
      (click)="first().subscribe()"
      color="primary"
      matTooltip="First"
      matTooltipShowDelay="500"
    >
      <mat-icon>first_page</mat-icon>
    </button>
    <button
      type="button"
      [disabled]="busy || (!localData && disabled)"
      mat-icon-button
      (click)="previous().subscribe()"
      color="primary"
      matTooltip="Previous"
      matTooltipShowDelay="500"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    &nbsp;
    <mat-form-field appearance="standard">
      <input
        #pageInput
        matInput
        [disabled]="busy || (!localData && disabled)"
        type="number"
        min="1"
        [(ngModel)]="page"
      />
    </mat-form-field>
    <span>/ {{ totalPages }}</span> &nbsp;
    <button
      type="button"
      [disabled]="busy || (!localData && disabled)"
      mat-icon-button
      (click)="next().subscribe()"
      color="primary"
      matTooltip="Next"
      matTooltipShowDelay="500"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button
      type="button"
      [disabled]="busy || (!localData && disabled)"
      mat-icon-button
      (click)="last().subscribe()"
      color="primary"
      matTooltip="Last"
      matTooltipShowDelay="500"
    >
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
  <div>
    <span>Showing</span>
    <mat-form-field appearance="standard">
      <input
        #takeInput
        matInput
        [disabled]="busy || (!localData && disabled)"
        type="number"
        min="1"
        [max]="MAX_PER_PAGE"
        [(ngModel)]="take"
      />
    </mat-form-field>
    <span>of {{ totalRows }} rows</span>
  </div>
</div>
