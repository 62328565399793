import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { CellPosition, GridOptions, SelectionChangedEvent } from 'ag-grid-community/main';
import { saveAs } from 'file-saver';
import { assign, findLast, get, mapKeys, omit, set } from 'lodash-es';
import { concat, EMPTY, from, iif, merge, Observable, of, Subject, throwError } from 'rxjs';
import {
  bufferCount,
  catchError,
  debounceTime,
  delay,
  finalize,
  first,
  last,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs/breadcrumbs.service';

import { GlobalService } from '../../core/services/global.service';
import { ColumnDefService } from '../data-list/column-def/column-def.service';
import { DataListComponent } from '../data-list/data-list.component';
import { DialogJob } from '../dialog/dialog-job.interface';
import { DialogService } from '../dialog/dialog.service';
import { CommonError } from '../interfaces/common-error.interface';
import { Report } from '../report/report.interface';
import { SingleResourceReportComponent } from '../report/single-resource-report/single-resource-report.component';
import { HelperService } from '../services/helper.service';
import { HttpRestService } from '../services/http-rest.service';
import { CrudActionDef } from './crud-action-def.interface';
import { PopupDataListComponent } from './popup-data-list/popup-data-list.component';

/**
 * CRUD's common action.
 */
export enum CrudAction {
  List = <any>'List',
  Report = <any>'Report',
  Create = <any>'Create',
  Store = <any>'Store',
  Read = <any>'Read',
  Edit = <any>'Edit',
  Update = <any>'Update',
  Destroy = <any>'Destroy',
  Print = <any>'Print',
}

/**
 * CRUD's tab.
 */
export enum CrudTab {
  List,
  Editor,
  Reader,
}

/**
 * CRUD component.
 */
@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss'],
})
export class CrudComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Reference to all MatFormField.
   */
  @ContentChildren(MatFormField, { read: MatFormField, descendants: true })
  private _matFormFields: QueryList<MatFormField>;

  /**
   * Reference to all MatSort.
   */
  @ContentChildren(MatSort, { read: MatSort, descendants: true })
  private _matSorts: QueryList<MatSort>;

  /**
   * Action buttons' wrapper divs.
   */
  @ViewChild('actionButtons', { read: ElementRef })
  private _actionButtons: ElementRef;

  /**
   * Nav (up/down) action buttons' wrapper divs.
   */
  @ViewChild('navActionButtons', { read: ElementRef })
  private _navActionButtons: ElementRef;

  /**
   * CRUD component's data list Component.
   */
  @ViewChild('dataList')
  private _dataList: DataListComponent;

  /**
   * Resource URL to load the data. Prefix it with '/'.
   */
  @Input() public resourceURL: string = '';

  /**
   * Resource actions.
   */
  @Input() public actions: CrudActionDef[];

  /**
   * Before doCreate hook.
   */
  @Input() public beforeCreate: (actionTarget: any) => Observable<any>;

  /**
   * Before doRead hook.
   */
  @Input() public beforeRead: (actionTarget: any) => Observable<any>;

  /**
   * Before doUpdate hook.
   */
  @Input() public beforeUpdate: (actionTarget: any) => Observable<any>;

  /**
   * Before doDestroy hook.
   */
  @Input() public beforeDestroy: (actionTargets: any[]) => Observable<any>;

  /**
   * Before doPrint hook.
   */
  @Input() public beforePrint: (actionTargets: any[], printOut: { name: string; params: any }) => Observable<any>;

  /**
   * Before doPost hook.
   */
  @Input() public beforePost: (actionTarget: any) => Observable<any>;

  /**
   * Before doPut hook.
   */
  @Input() public beforePut: (actionTarget: any) => Observable<any>;

  /**
   * After doCreat hooke
   */
  @Input() public afterCreate: (actionResult: any, actionTarget: any) => Observable<any>;

  /**
   * After doRead hook.
   */
  @Input() public afterRead: (actionResult: any, actionTarget: any) => Observable<any>;

  /**
   * After doUpdate hook.
   */
  @Input() public afterUpdate: (actionResult: any, actionTarget: any) => Observable<any>;

  /**
   * After doDestroy hook.
   */
  @Input() public afterDestroy: (actionResult: any, actionTargets: any[]) => Observable<any>;

  /**
   * After doPrint hook.
   */
  @Input() public afterPrint: (actionResult: any, actionTargets: any[]) => Observable<any>;

  /**
   * After doPost hook.
   */
  @Input() public afterPost: (actionResult: any, actionTarget: any) => Observable<any>;

  /**
   * After doPut hook.
   */
  @Input() public afterPut: (actionResult: any, actionTarget: any) => Observable<any>;

  /**
   * CRUD data list's preloaded query.
   */
  @Input() public preloadedQuery: string;

  /**
   * Reports for this resource.
   */
  @Input() public reports: Report[] = [];

  /**
   * Print outs for this resource.
   */
  @Input() public printOuts: Report[] = [];

  /**
   * Editor controls initializer.
   */
  @Input() public initializeEditor: Observable<boolean>;

  /**
   * New editor entity getter function for retrieving new data entry.
   */
  @Input() public getNewEditorEntity: () => any;

  /**
   * Flag indicating whether create is disabled.
   */
  @Input() public createDisabled: boolean = false;

  /**
   * Flag indicating whether read is disabled.
   */
  @Input() public readDisabled: boolean = false;

  /**
   * Flag indicating whether update is disabled.
   */
  @Input() public updateDisabled: boolean = false;

  /**
   * Flag indicating whether destroy is disabled.
   */
  @Input() public destroyDisabled: boolean = false;

  /**
   * Flag indicating whether print is disabled.
   */
  @Input() public printDisabled: boolean = false;

  /**
   * Leaf breadcrumbs generator for editor and reader.
   */
  @Input() public leafBreadcrumbs: (() => string) | string;

  /**
   * Action succeeded event emitter.
   */
  @Output() public actionSucceeded: EventEmitter<{ id: any; result: any }> = new EventEmitter();

  /**
   * Action failed event emitter.
   */
  @Output() public actionFailed: EventEmitter<{ id: any; error: any }> = new EventEmitter();

  /**
   * Data list's grid options.
   */
  public gridOptions: GridOptions;

  /**
   * Data list's selected rows.
   */
  public selectedRows: any[] = [];

  /**
   * Selected tab index.
   * 0 -> Data List
   * 1 -> Editor
   * 2 -> Reader
   */
  public selectedTab: CrudTab = CrudTab.List;

  /**
   * Entity for the editor.
   */
  public editorEntity: any;

  /**
   * Entity for the reader.
   */
  public readerEntity: any;

  /**
   * Editor validation error messages.
   */
  public validationMessages: any = {};

  /**
   * Indicating that the CRUD is currently processing resource.
   * If the value is empty string, it means it is not busy,
   * Otherwise it is busy doing the specified value.
   */
  private _busy = '';
  private _busy$: Subject<{ last: string; new: string }> = new Subject<{ last: string; new: string }>();
  public get busy(): string {
    return this._busy;
  }
  public set busy(v: string) {
    if (this._busy === v) {
      return;
    }
    const last = this._busy;
    this._busy = v;
    this._busy$.next({ last: last, new: v });
  }

  /**
   * CRUD busy observable.
   */
  public get busy$(): Observable<{ last: string; new: string }> {
    return this._busy$.asObservable();
  }

  /**
   * Flag indicating no rows are selected.
   */
  public get noRowSelected(): boolean {
    return this.selectedRows.length === 0;
  }

  /**
   * Flag indicating the editor is in create mode.
   */
  public get listMode(): boolean {
    return this._activatedRoute.firstChild.routeConfig.path === '';
  }

  /**
   * Flag indicating the editor is in create mode.
   */
  public get createMode(): boolean {
    return this._activatedRoute.firstChild.routeConfig.path === 'create';
  }

  /**
   * Flag indicating the editor is in update mode.
   */
  public get updateMode(): boolean {
    return this._activatedRoute.firstChild.routeConfig.path === ':id/update';
  }

  /**
   * Flag indicating the editor is in reader mode.
   */
  public get readMode(): boolean {
    return this._activatedRoute.firstChild.routeConfig.path === ':id';
  }

  /**
   * Flag indicating the editor is in reader mode.
   */
  public get pivotMode(): boolean {
    return this.gridOptions.columnApi.isPivotMode();
  }

  /**
   * Flag indicating whether data list is popped up.
   */
  public get dataListPoppedUp(): boolean {
    return this._popUpDataList ? true : false;
  }

  /**
   * Resource Name based on resourceURL.
   */
  public get resourceName(): string {
    return findLast(this.resourceURL.split('/')).replace('-', ' ');
  }

  /**
   * Busy dialog reference.
   */
  private _busyDialog: DialogJob;

  /**
   * Flag indicating embedded data list should be immediately refreshed after entering list mode.
   */
  private _needToRefreshEmbeddedDataList: boolean;

  /**
   * Flag indicating editor has been initialized.
   */
  private _editorInitialized: boolean;

  /**
   * PopupDataListComponent dialog ref.
   */
  private _popUpDataListDialogRef: MatDialogRef<PopupDataListComponent, any[]>;

  /**
   * Popped up DataListComponent.
   */
  private _popUpDataList: DataListComponent;

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal: Subject<any> = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _columnDefService: ColumnDefService,
    private _dialogService: DialogService,
    private _globalService: GlobalService,
    private _helperService: HelperService,
    private _httpRestService: HttpRestService,
    private _mediaObserver: MediaObserver,
    private _router: Router
  ) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    // Initialize data list.
    this.gridOptions = <GridOptions>{
      columnDefs: this._columnDefService.get(this.resourceURL),
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        defaultToolPanel: '',
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'left',
          },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' },
        ],
      },
      onSelectionChanged: (event) => {
        const selectedRows = event.api.getSelectedRows();
        this.selectedRows = selectedRows ? selectedRows : [];
      },
      onCellDoubleClicked: () => {
        if (this.selectedRows.length > 0) {
          if (!this.updateDisabled) {
            this.update(this.selectedRows[0].id);
          } else {
            this.read(this.selectedRows[0].id);
          }
        }
      },
    };

    // Subscribe to router events to detect child route change.
    this._router.events.pipe(takeUntil(this._destroySignal)).subscribe((event) => {
      if (event instanceof ResolveEnd) {
        this._onRouteResolveEnd(event);
      } else if (event instanceof NavigationEnd) {
        this._onRouteNavigationEnd(event);
      }
    });

    // Immediately calls _onRouteNavigationEnd() to set CRUD component mode
    // according to its child routes. We 'manualy' call here because router
    // events observable is not emitting any event after full page load.
    setTimeout(() => this._onRouteNavigationEnd(null));

    /**
     * Subscribe to profileChanged event so that we will tell
     * the editor to re-initialize itself on next opening and
     * navigate to list when the event is emitted.
     */
    this._globalService.profileChanged.pipe(takeUntil(this._destroySignal)).subscribe(() => {
      this._editorInitialized = false;
      this.list().subscribe();
    });

    /**
     * Subsribe to busy observable to open blocking processing dialog when busy.
     */
    this.busy$.subscribe((busy) => {
      if (busy.new !== '') {
        // Close last busy dialog before opening new one.
        if (busy.last !== '') {
          this._dialogService.closeProcessingDialog(this._busyDialog);
        }
        this._busyDialog = this._dialogService.openProcessingDialog(busy.new);
      } else {
        this._dialogService.closeProcessingDialog(this._busyDialog);
      }
    });

    // Initialize editor entity.
    this._initEditorEntity();
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit() {
    // Subsribe to data list's busy observable and mirror its
    // value to CRUD's busy property so that user will also
    // be blocked when data list is loading its data.
    this._dataList.busy$.subscribe((dataListBusy) => {
      this.busy = dataListBusy ? 'Loading list' : '';
    });
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy(): void {
    // Emit destroying signal.
    this._destroySignal.next();
    // Complete this component's subjects.
    this._busy$.complete();
    this.actionSucceeded.complete();
    this.actionFailed.complete();
  }

  /**
   * Call report action, we don't navigate to report child route because we don't have one.
   * @return Observable of SingleResourceReportComponent dialog close result.
   */
  public report(): Observable<any> {
    return this.doReport();
  }

  /**
   * Navigate to data list (default) child route.
   * @param skipLocationChange Go to list route without location change.
   * @return Observable of boolean from navigation promise.
   */
  public list(skipLocationChange = false): Observable<boolean> {
    if (this.dataListPoppedUp) {
      this._popUpDataListDialogRef.componentInstance.close();
    }

    return from(
      this._router.navigate(['./'], { relativeTo: this._activatedRoute, skipLocationChange: skipLocationChange })
    );
  }

  /**
   * Pop up the data list into modal dialog.
   * @return Observable of void.
   */
  public popUpList(): Observable<void> {
    if (this.dataListPoppedUp) {
      return of();
    }

    // Keep current focused cell for refocusing after update/read on selected row changed.
    let currentFocusedCell: CellPosition;

    // Wrap row selection changed in a subject allowing use to debounce events before performing update/read.
    const selectionChanged = new Subject<SelectionChangedEvent>();

    this._popUpDataListDialogRef = this._dialogService.openImmediateDialog(PopupDataListComponent, null, null, {
      data: {
        resourceURL: this.resourceURL,
        gridOptions: set(
          omit(this.gridOptions, ['api', 'columnApi']),
          'onSelectionChanged',
          (event: SelectionChangedEvent) => selectionChanged.next(event)
        ),
        destroySignal: this._destroySignal.asObservable(),
      },
      hasBackdrop: false,
      disableClose: true,
      width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? '90vw' : '50vw',
    });

    this._popUpDataListDialogRef
      .beforeClosed()
      .pipe(
        tap(() => {
          if (this._popUpDataList.refreshScheduled) {
            this._popUpDataList.scheduledRefreshSubscription.unsubscribe();
            this._dataList.scheduledRefreshInterval = this._popUpDataList.scheduledRefreshInterval;
            this._dataList.scheduleRefresh();
          }
        })
      )
      .subscribe();

    this._popUpDataListDialogRef
      .afterClosed()
      .pipe(
        tap((selectedRows) => {
          // Mirror back data list's current state from the popup to embedded data list.
          this._dataList.page = this._popUpDataList.page;
          this._dataList.take = this._popUpDataList.take;
          this._dataList.q = this._popUpDataList.q;
          this._dataList.selectedSearchable = this._popUpDataList.selectedSearchable;
          this._dataList.qAdv = this._popUpDataList.qAdv;

          // Select current data row.
          this._dataList.focusAfterLoad = null;
          this._dataList.dataLoadEnd
            .pipe(
              first(),
              mergeMap((data) => {
                if (data) {
                  return this._alignSelectedRow(
                    this.updateMode
                      ? this.editorEntity
                      : this.readMode
                      ? this.readerEntity
                      : this.listMode
                      ? selectedRows[0]
                      : null
                  );
                } else {
                  return of({});
                }
              }),
              finalize(() => (this._dataList.focusAfterLoad = true))
            )
            .subscribe();

          // Refresh data list.
          this._dataList.refresh().subscribe();

          // Clear the reference to popup data list and its dialog ref.
          this._popUpDataList = undefined;
          this._popUpDataListDialogRef = undefined;
        })
      )
      .subscribe();

    return this._popUpDataListDialogRef.afterOpened().pipe(
      tap(() => {
        // Get reference to pop up data list component.
        this._popUpDataList = this._popUpDataListDialogRef.componentInstance.dataList;
        // Mirror data list's busy state to CRUD's busy state.
        this._popUpDataList.busy$.subscribe((dataListBusy) => {
          this.busy = dataListBusy ? 'Loading list' : '';
        });
        // Mirror the embedded data list's current state to popup data list.
        this._popUpDataList.page = this._dataList.page;
        this._popUpDataList.take = this._dataList.take;
        this._popUpDataList.q = this._dataList.q;
        this._popUpDataList.selectedSearchable = this._dataList.selectedSearchable;
        this._popUpDataList.qAdv = this._dataList.qAdv;
        if (this._dataList.refreshScheduled) {
          this._dataList.scheduledRefreshSubscription.unsubscribe();
          this._popUpDataList.scheduledRefreshInterval = this._dataList.scheduledRefreshInterval;
          this._popUpDataList.scheduleRefresh();
        }

        // Initialy read first data.
        this._popUpDataList.focusAfterLoad = null;
        this._popUpDataList.dataLoadEnd
          .pipe(
            first(),
            mergeMap((data) => {
              if (data) {
                return this._popUpDataList.selectById(this.selectedRows.length > 0 ? this.selectedRows[0].id : 0).pipe(
                  tap(() => {
                    this._popUpDataList.focusToCell();
                    !this.updateMode && !this.readMode
                      ? this.read(this._popUpDataList.gridOptions.api.getSelectedRows()[0].id).subscribe()
                      : null;
                  })
                );
              } else {
                return of(null);
              }
            }),
            finalize(() => (this._popUpDataList.focusAfterLoad = true))
          )
          .subscribe();

        // Clear embedded data list's range selection.
        this._dataList.gridOptions.api.clearRangeSelection();

        // Perform update/read on selected row changed.
        selectionChanged
          .asObservable()
          .pipe(debounceTime(500), takeUntil(this._popUpDataListDialogRef.afterClosed()))
          .subscribe((event) => {
            currentFocusedCell = event.api.getFocusedCell();

            // If there're multiple selectedRows, use top most row as single selected row.
            const lastSelectedRow = this.selectedRows[0];
            const selectedRows = event.api.getSelectedRows();
            this.selectedRows = selectedRows ? selectedRows : [];

            if (
              // Perform update/read if there's only one selected row.
              this.selectedRows.length === 1 &&
              // Determine the selected row equality by using id field, so it must be always present.
              // We don't use row as a whole because sometimes during refreshAfterAction we actually
              // still select the same row (id is the same) but its other fields may have changed.
              this.selectedRows[0].id !== (lastSelectedRow && lastSelectedRow.id)
            ) {
              if (this.updateMode) {
                this.update();
              } else {
                this.read();
              }
            }
          });

        // Refresh popup data list.
        this._popUpDataList.refresh().subscribe();
      })
    );
  }

  /**
   * Navigate to create child route.
   * @return Observable of boolean from navigation promise.
   */
  public create(): Observable<boolean> {
    return from(this._router.navigate(['./create'], { relativeTo: this._activatedRoute }));
  }

  /**
   * Navigate to read child route.
   * @param id Specific id to be processed.
   * @return Observable of boolean from navigation promise.
   */
  public read(id?: number | string): Observable<boolean> {
    return from(
      this._router.navigate(
        [
          './',
          id
            ? id
            : this.selectedRows.length > 0
            ? this.selectedRows[0].id
            : this._activatedRoute.firstChild.snapshot.params.id,
        ],
        { relativeTo: this._activatedRoute }
      )
    );
  }

  /**
   * Navigate to update child route.
   * @param id Specific id to be processed.
   * @return Observable of boolean from navigation promise.
   */
  public update(id?: number | string): Observable<boolean> {
    return from(
      this._router.navigate(
        [
          './',
          id
            ? id
            : this.selectedRows.length > 0
            ? this.selectedRows[0].id
            : this._activatedRoute.firstChild.snapshot.params.id,
          'update',
        ],
        { relativeTo: this._activatedRoute }
      )
    );
  }

  /**
   * Call destroy action, we don't navigate to destroy child route because we don't have one.
   * @param id Specific id to be processed.
   * @return Observable of destroy action result.
   */
  public destroy(id?: number | string): Observable<any> {
    return this._dialogService
      .openConfirmDialog('Are you sure want to destroy selected items?', [
        `${
          this.selectedRows.length > 1 ? this.selectedRows.length : 1
        } item(s) will be destroyed. All destroyed items will be permanently destroyed and can not be restored.`,
      ])
      .afterClosed()
      .pipe(switchMap((confirmed) => (confirmed ? this.doDestroy(id) : of(null))));
  }

  /**
   * Call print action, we don't navigate to print child route because we don't have one.
   * @param ids Specific ids to be processed.
   * @return Observable of print action result.
   */
  public print(ids?: number[]): Observable<any> {
    return this._dialogService
      .openConfirmDialog('Are you sure want to print selected items?', [
        `${this.selectedRows.length > 1 ? this.selectedRows.length : 1} item(s) will be downloaded as PDF.`,
      ])
      .afterClosed()
      .pipe(switchMap((confirmed) => (confirmed ? this.doPrint(ids) : of(null))));
  }

  /**
   * Perform action on selected item.
   * @param action CRUD action definition.
   * @param specificActionTargets Array of specific action targets.
   * @param disableConfirmation Overload action's disableConfirmation property.
   * @param disableRefreshAfterAction Overload action's disableRefreshAfterAction property.
   * @returns Observable of action result.
   */
  public performAction(
    action: CrudActionDef,
    specificActionTargets: any[] = null,
    disableConfirmation: boolean = null,
    disableRefreshAfterAction: boolean = null
  ): Observable<any> {
    // If single resource mode, then deselect row other than row at index 0.
    if (action.singleResource && this.selectedRows.length > 1) {
      (this._popUpDataList || this._dataList).gridOptions.api.getSelectedNodes().forEach((node, index) => {
        if (index > 0) {
          node.setSelected(false);
        }
      });
    }

    // If no resource mode, then deselect all rows.
    if (action.noResource) {
      (this._popUpDataList || this._dataList).gridOptions.api.deselectAll();
    }

    // Confirm then perform action.
    return of({}).pipe(
      switchMap(() =>
        (disableConfirmation == null ? action.disableConfirmation : disableConfirmation)
          ? of(true)
          : this._dialogService
              .openConfirmDialog(
                action.noResource
                  ? `Are you sure want to perform ${action.name.toLowerCase()}?`
                  : `Are you sure want to perform ${action.name.toLowerCase()} on selected items?`,
                action.noResource
                  ? [`You are about to perform an action that does not require any selected items.`]
                  : [
                      `${
                        this.selectedRows.length > 1 && !action.singleResource ? this.selectedRows.length : 1
                      } item(s) will be affected by this action. Please make sure you have selected correctly.`,
                    ]
              )
              .afterClosed()
      ),
      switchMap((confirmed) => {
        if (confirmed) {
          // Modify action observable to refresh data list after complete.
          const modifiedAction = !(disableRefreshAfterAction == null
            ? action.disableRefreshAfterAction
            : disableRefreshAfterAction)
            ? (actionTargets, beforeActionResult) => {
                return action
                  .action(actionTargets, beforeActionResult)
                  .pipe(switchMap(() => this.refreshAfterAction(actionTargets)));
              }
            : action.action;

          // Do action.
          return this.doAction(
            { ...action, name: 'Performing ' + action.name.toLowerCase(), action: modifiedAction },
            specificActionTargets
          );
        } else {
          // User aborted an ongoing action, there should not be any further handling.
          // Let the observable chain completes with emtpy value silently.
          return EMPTY;
        }
      })
    );
  }

  /**
   * Refresh the datalist after action has successfully performed.
   * @param actionTargets Array of action targets.
   * @param keepOptionalRouteParams Keep current update or read's optional route params.
   * @return Observable of datalist has been refreshed flag.
   */
  public refreshAfterAction(actionTargets: any[], keepOptionalRouteParams: boolean = true): Observable<boolean> {
    if (this.dataListPoppedUp) {
      // Pop up data list mode.

      // Immediate Refresh Data List -> Select First Action Target -> Reload Editor/Reader.
      return of({}).pipe(
        switchMap(() => this.refreshDataList(null)),
        switchMap(() => this._popUpDataList.selectById(actionTargets[0].id)),
        switchMap(() => {
          if (keepOptionalRouteParams) {
            return this.updateMode ? this.doUpdate() : this.doRead();
          } else {
            return this.updateMode ? this.update() : this.read();
          }
        })
      );
    } else {
      // Embedded data list mode.

      if (this.updateMode || this.readMode) {
        // Reload Editor/Reader -> Schedule Refresh.
        if (keepOptionalRouteParams) {
          return (this.updateMode ? this.doUpdate() : this.doRead()).pipe(
            tap(() => this.markNeedToRefreshEmbeddedDataList())
          );
        } else {
          return (this.updateMode ? this.update() : this.read()).pipe(
            tap(() => this.markNeedToRefreshEmbeddedDataList())
          );
        }
      } else {
        // Refresh Embedded Data List -> Select First Action Target.
        return this.refreshDataList(null).pipe(switchMap(() => this._dataList.selectById(actionTargets[0].id)));
      }
    }
  }

  /**
   * Navigate to resource one row to the top of the current row.
   * @return Observable of boolean from navigation promise.
   */
  public up(): Observable<boolean> {
    return this.down(-1);
  }

  /**
   * Navigate to resource one row to the bottom of the current row.
   * @return Observable of boolean indicating whether move operation is success or not.
   */
  public down(step: number = 1): Observable<boolean> {
    return this.noRowSelected
      ? of(false)
      : (this._popUpDataList || this._dataList).move(step).pipe(
          tap((loaded) => {
            // If datalist is popped up, the datalist grid's onSelectionChanged that will trigger update/read.
            if (loaded && !this.dataListPoppedUp) {
              // Update or read selected row.
              return this.updateMode ? this.update() : this.read();
            }
          })
        );
  }

  /**
   * Show report generator.
   * @return Observable of SingleResourceReportComponent dialog close result.
   */
  public doReport(): Observable<any> {
    return this._dialogService
      .openImmediateDialog(SingleResourceReportComponent, null, null, {
        data: {
          resourceURL: this.resourceURL,
          reports: this.reports,
        },
      })
      .afterClosed();
  }

  /**
   * Show data list.
   * @return Observable of boolean if immediate refresh (if needed) succeeds or not.
   */
  public doList(): Observable<boolean> {
    this.selectedTab = CrudTab.List;

    this._breadcrumbsService.setLeaf(null);

    // Immediate refresh if required, e.g. after creating and updating resource.
    if (this._needToRefreshEmbeddedDataList) {
      this._needToRefreshEmbeddedDataList = false;
      return this.refreshDataList();
    } else {
      return of(true).pipe(tap(() => setTimeout(() => this._dataList.focusToCell(), 800)));
    }
  }

  /**
   * Prepare clean editor for new data entry.
   * @returns Observable of action result.
   */
  public doCreate(): Observable<any> {
    return this.doAction({
      id: CrudAction.Create,
      name: 'Preparing create',
      beforeAction: (actionTargets) => {
        return this.beforeCreate ? this.beforeCreate(actionTargets[0]) : of({});
      },
      action: (actionTargets, beforeActionResult) => {
        // Open the editor tab.
        this.selectedTab = CrudTab.Editor;

        // Reset grid's horizontal scroll posision.
        this._scrollToFirstUnpinnedColumn();

        return this._initializeEditor().pipe(
          tap(() => {
            // Reset previous editorEntity.
            this._initEditorEntity();

            // Clear data list selection.
            this.gridOptions.api.deselectAll();
          }),
          // Go to list if error occurs.
          catchError((error) => {
            this.list();
            this._initEditorEntity();
            return throwError(error);
          })
        );
      },
      afterAction: (actionResult, actionTargets) => {
        this._breadcrumbsService.setLeaf(null);
        return this.afterCreate ? this.afterCreate(actionResult, actionTargets[0]) : of({});
      },
    });
  }

  /**
   * Perform GET request of read resource to backend API's read endpoint. (url => {resourceURL}/{id})
   * @param id Specific id to be processed.
   * @returns Observable of action result.
   */
  public doRead(id?: number | string): Observable<any> {
    return this.doAction(
      {
        id: CrudAction.Read,
        name: 'Reading',
        beforeAction: (actionTargets) => {
          return this.beforeRead ? this.beforeRead(actionTargets[0]) : of({});
        },
        action: (actionTargets, beforeActionResult) => {
          // Open the reader tab.
          this.selectedTab = CrudTab.Reader;

          // Reset grid's horizontal scroll position.
          this._scrollToFirstUnpinnedColumn();

          // Perform GET request to backend API endpoint, then set the result to readerEntity.
          return this._httpRestService.get(`${this.resourceURL}/${actionTargets[0].id}`, { params: { d: '1' } }).pipe(
            tap((result) =>
              this._matSorts.forEach((matSort) => {
                if (matSort.active) {
                  matSort.sort({ id: '', start: 'asc', disableClear: false });
                }
              })
            ),
            tap((result) => (this.readerEntity = result.data)),
            // Go to list if error occurs.
            catchError((error) => {
              this.list();
              this.readerEntity = undefined;
              return throwError(error);
            })
          );
        },
        afterAction: (actionResult, actionTargets) => {
          this._breadcrumbsService.setLeaf(
            (this.leafBreadcrumbs &&
              (typeof this.leafBreadcrumbs === 'function'
                ? this.leafBreadcrumbs()
                : this.readerEntity[this.leafBreadcrumbs])) ||
              this.readerEntity.document_number ||
              this.readerEntity.doc_no ||
              this.readerEntity.id
          );

          return concat(
            this._alignSelectedRow(actionTargets[0]),
            this.afterRead ? this.afterRead(actionResult, actionTargets[0]) : of({})
          );
        },
      },
      id ? [{ id }] : null
    );
  }

  /**
   * Perform GET request of updated resource to backend API's edit endpoint. (url => {resourceURL}/{id}/edit)
   * @param id Specific id to be processed.
   * @returns Observable of action result.
   */
  public doUpdate(id?: number | string): Observable<any> {
    return this.doAction(
      {
        id: CrudAction.Edit,
        name: 'Preparing update',
        beforeAction: (actionTargets) => {
          return this.beforeUpdate ? this.beforeUpdate(actionTargets[0]) : of({});
        },
        action: (actionTargets, beforeActionResult) => {
          // Open the editor tab.
          this.selectedTab = CrudTab.Editor;

          // Reset grid's horizontal scroll position.
          this._scrollToFirstUnpinnedColumn();

          return this._initializeEditor().pipe(
            // Perform GET request to backend API endpoint, then set the result to editorEntity.
            mergeMap(() => this._httpRestService.get(`${this.resourceURL}/${actionTargets[0].id}/edit`)),
            tap((result) => {
              // Set to be updated entity.
              this.editorEntity = result.data;
            }),
            // Go to list if error occurs.
            catchError((error) => {
              this.list();
              this._initEditorEntity();
              return throwError(error);
            })
          );
        },
        afterAction: (actionResult, actionTargets) => {
          this._breadcrumbsService.setLeaf(
            (this.leafBreadcrumbs &&
              (typeof this.leafBreadcrumbs === 'function'
                ? this.leafBreadcrumbs()
                : this.editorEntity[this.leafBreadcrumbs])) ||
              this.editorEntity.document_number ||
              this.editorEntity.doc_no ||
              this.editorEntity.id
          );

          return concat(
            this._alignSelectedRow(actionTargets[0]),
            this.afterUpdate ? this.afterUpdate(actionResult, actionTargets[0]) : of({})
          );
        },
      },
      id ? [{ id }] : null
    );
  }

  /**
   * Align datalist's selected row to match current read or updated entity.
   * @param actionTarget Current read or updated entity.
   * @returns Observable of boolean indicating selection is success or not.
   */
  private _alignSelectedRow(actionTarget: any): Observable<boolean> {
    const datalist = this._popUpDataList || this._dataList;
    return datalist.selectById(actionTarget?.id, false).pipe(
      tap((success) => {
        if (success) {
          datalist.focusToCell();
        } else {
          // On row selection failed (e.g. row not found), we prefer to clear
          // selection so that crud component will use current editorEntity
          // or readerEntity when performing next action.
          datalist.gridOptions.api.deselectAll();
        }
      })
    );
  }

  /**
   * Perform DELETE request of deleted resource to backend API's destroy endpoint.
   *  - single resource    : url => {resourceURL}/{id}
   *  - multiple resources : url => {resourceURL}/0?q=[{id},{id},...]
   * @param id Specific id to be processed.
   * @returns Observable of action result.
   */
  public doDestroy(id?: number | string): Observable<any> {
    return this.doAction(
      {
        id: CrudAction.Destroy,
        name: 'Destroying',
        beforeAction: (actionTargets) => {
          return this.beforeDestroy ? this.beforeDestroy(actionTargets) : of({});
        },
        action: (actionTargets) => {
          const params: any = {};
          let id: number;

          // Determine whether deleting single or multiple resources.
          if (actionTargets.length > 1) {
            id = 0;
            params.q = JSON.stringify(actionTargets.map((value) => value.id));
          } else {
            id = actionTargets[0].id;
          }

          // Perform DELETE request to backend API endpoint.
          return this._httpRestService.delete(`${this.resourceURL}/${id}`, { params: params });
        },
        afterAction: (actionResult, actionTargets) => {
          return this.afterDestroy
            ? this.afterDestroy(actionResult, actionTargets)
            : of({}).pipe(
                switchMap(() => {
                  if (this.dataListPoppedUp) {
                    // Pop up data list mode.
                    // Immediate Refresh Data List.
                    return this.refreshDataList();
                  } else {
                    // Embedded data list mode.
                    // Go To List -> Refresh Embedded Data List.
                    return concat(this.list(), this.refreshDataList());
                  }
                })
              );
        },
      },
      id ? [{ id }] : null
    );
  }

  /**
   * Perform GET request of print resource to backend API's print endpoint. (url => {resourceURL}/{id}/print)
   * @param ids Specifics ids to be processed.
   * @param printOutName Specific printout name to be generated.
   * @returns Observable of action result.
   */
  public doPrint(ids?: number[], printOutName?: string): Observable<any> {
    return this.doAction(
      {
        id: CrudAction.Print,
        name: 'Printing',
        beforeAction: (actionTargets) => {
          // Observable of selected printout name. Possible value are...
          // 1. Specific as per method parameter
          // 2. User's selected printout name from dropdown list (if more than one printout are available)
          // 3. NULL (will print the only one available printout name)
          const selectedPrintOutName: Observable<string> = printOutName
            ? of(printOutName)
            : this.printOuts
            ? this._dialogService
                .openPromptDialog(
                  'Print Out Name',
                  ['Please choose print out name to be generated.'],
                  null,
                  this.printOuts.map((item) => ({ display: item.name, value: item.name })),
                  this.printOuts.length > 0 ? this.printOuts[0].name : undefined
                )
                .afterClosed()
            : of(null);

          // Map selected printout name to an instance of Report.
          const selectedPrintOut: Observable<Report> = selectedPrintOutName.pipe(
            map((selectedPrintOutName) => {
              if (selectedPrintOutName === undefined) {
                // This will immediately complete the action chain with empty value.
                throw undefined;
              }
              return selectedPrintOutName ? this.printOuts.find((item) => item.name == selectedPrintOutName) : null;
            })
          );

          // Get the report params (if available) and map it to simple object of report name and params.
          return selectedPrintOut.pipe(
            mergeMap((selectedPrintOut) =>
              ((selectedPrintOut && selectedPrintOut.params && selectedPrintOut.params()) || of({})).pipe(
                map((params) => ({
                  name: selectedPrintOut ? selectedPrintOut.name : null,
                  params: params,
                }))
              )
            ),
            mergeMap((printOut) =>
              this.beforePrint ? this.beforePrint(actionTargets, printOut).pipe(map(() => printOut)) : of(printOut)
            )
          );
        },
        action: (actionTargets, beforeActionResult) => {
          // Build http call observables.
          const printHttpCalls: Observable<any>[] = actionTargets.map((actionTarget) => {
            return this._httpRestService
              .get(`${this.resourceURL}/${actionTarget.id}/print`, {
                params: {
                  q: JSON.stringify({ quick: null, adv: null }),
                  printOutName: beforeActionResult.name,
                  printOutParams: JSON.stringify(beforeActionResult.params),
                  printOutFormat: 'PDF',
                  user: this._globalService.userProfile.id.toString(),
                  workgroup: this._globalService.userProfile.workgroups[
                    this._globalService.profilePreference.activeWorkgroupIndex[this._globalService.appId]
                  ].id.toString(),
                },
                responseType: 'blob',
                observe: 'response',
              })
              .pipe(
                tap((response) => {
                  // Get report filename. We check for null value due to CORS
                  // policy which does not expose Content-Disposition header
                  // when accessed from localhost (for development purpose)
                  const contentDisposition = response.headers.get('Content-Disposition');
                  const filename = contentDisposition
                    ? contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '')
                    : Date.now();
                  // Save report to local disk.
                  saveAs(response.body, filename);
                })
              );
          });

          // Return merged observables which is buffered to emit print results as single output.
          return merge(...printHttpCalls).pipe(bufferCount(actionTargets.length));
        },
        afterAction: (actionResult, actionTargets) => {
          return this.afterPrint ? this.afterPrint(actionResult, actionTargets) : of({});
        },
      },
      ids ? ids.map((id) => ({ id })) : null
    );
  }

  /**
   * Do POST/PUT to create or update a resource.
   * @return Observable of POST or PUT result.
   */
  public doSave(): Observable<any> {
    return this.updateMode ? (this.editorEntity._updatable ? this._doPUT() : of(null)) : this._doPOST();
  }

  /**
   * Do POST/PUT to create or update a child resource.
   * @param resourceURL Resource URL.
   * @param entity Entity object being saved.
   * @param datalist DataListComponent to be refreshed after saving.
   * @param validationMessageKeyPrefix Validation message key to be prepended because we are dealing directly with a child resource.
   * @returns Observable of action result.
   */
  public doSaveChild(
    resourceURL: string,
    entity: any,
    datalist: DataListComponent,
    validationMessageKeyPrefix: string
  ): Observable<any> {
    return iif(
      () => entity.id,
      this._httpRestService.put(`${resourceURL}/${entity.id}`, entity),
      this._httpRestService.post(`${resourceURL}`, entity)
    ).pipe(
      tap(() => (this.validationMessages = {})),
      switchMap((result) => this._httpRestService.get(`${resourceURL}/${result.data.id}/edit`)),
      tap((result) => {
        assign(entity, result.data);
        datalist.gridOptions.api.refreshCells({ rowNodes: datalist.gridOptions.api.getSelectedNodes() });
      }),
      catchError((error) => {
        this.handleValidationMessages(error);
        this.validationMessages = mapKeys(
          this.validationMessages,
          (msg, key) => `${validationMessageKeyPrefix}.${key}`
        );
        return throwError(error);
      })
    );
  }

  /**
   * Do DELETE to destroy a child resource.
   * @param resourceURL Resource URL.
   * @param entity Entity object being destroyed.
   * @param datalist DataListComponent to be refreshed after destroy.
   * @returns Observable of action result.
   */
  public doDestroyChild(resourceURL: string, entity: any, datalist: DataListComponent): Observable<any> {
    return of({}).pipe(
      // Give some dalay to allow below confirmation dialog to be
      // on top of the performing action dialog as doDestroyChild()
      // will likely be used as part of a crud action chain.
      delay(100),
      switchMap(() =>
        this._dialogService
          .openConfirmDialog('Are you sure want to destroy selected item?', [
            `1 item will be destroyed. All destroyed items will be permanently destroyed and can not be restored.`,
          ])
          .afterClosed()
      ),
      switchMap((confirmed) =>
        confirmed
          ? this._httpRestService.delete(`${resourceURL}/${entity.id}`).pipe(
              tap(() => {
                datalist.value.splice(
                  datalist.value.findIndex((item) => item === entity),
                  1
                );
                datalist.refresh().subscribe();
              })
            )
          : of({})
      )
    );
  }

  /**
   * Check if the save child resource button should be disabled.
   * @param entity The entity being saved.
   * @param datalist Datalist the entity belongs to.
   * @returns boolean.
   */
  public saveChildDisabled(entity: any, datalist: DataListComponent) {
    const entityIsEmpty = Object.keys(entity).length === 0 && entity.constructor === Object;
    const multipleRowsSelected = datalist.gridOptions.api?.getSelectedRows().length > 1;
    return entityIsEmpty || multipleRowsSelected;
  }

  /**
   * Check if the destroy child resource button should be disabled.
   * @param entity The entity being destroyed.
   * @param datalist Datalist the entity belongs to.
   * @returns boolean.
   */
  public destroyChildDisabled(entity: any, datalist: DataListComponent) {
    const hasNoId = !entity.id;
    const multipleRowsSelected = datalist.gridOptions.api?.getSelectedRows().length > 1;
    return hasNoId || multipleRowsSelected;
  }

  /**
   * Mark embedded datalist to be refreshed on next view.
   */
  public markNeedToRefreshEmbeddedDataList() {
    this._needToRefreshEmbeddedDataList = true;
  }

  /**
   * Refresh the datalist.
   */
  public refreshDataList(focusAfterLoad = true): Observable<boolean> {
    const datalist = this._popUpDataList || this._dataList;
    datalist.focusAfterLoad = focusAfterLoad;
    return datalist.refresh().pipe(
      // Give some delay to allow loading list dialog to be completely closed before showing any next processing dialog.
      delay(100),
      tap(() => {
        const selectedRows = datalist.gridOptions.api.getSelectedRows();
        this.selectedRows = selectedRows ? selectedRows : [];
      }),
      finalize(() => ((this._popUpDataList || this._dataList).focusAfterLoad = true))
    );
  }

  /**
   * Select datalist row by id.
   * @param id The id of underlying data row. Set to 0 to select first row.
   */
  public selectDataListById(id: number): Observable<boolean> {
    return (this._popUpDataList || this._dataList).selectById(id);
  }

  /**
   * Call specified action providing selected rows as parameter and set busy state.
   * @param action CRUD action definition.
   * @param specificActionTargets Array of specific action targets.
   * @returns Observable of action result.
   */
  public doAction(action: CrudActionDef, specificActionTargets: any[] = null): Observable<any> {
    const actionTargets = action.noResource
      ? []
      : specificActionTargets
      ? specificActionTargets
      : this.createMode
      ? [this.editorEntity]
      : (this.readMode && !this.readerEntity?.id) || (this.updateMode && !this.editorEntity?.id)
      ? this.selectedRows.length > 0
        ? this.selectedRows
        : [{ id: this._activatedRoute.firstChild.snapshot.params.id }]
      : this.readMode && !this.dataListPoppedUp
      ? [this.readerEntity]
      : this.updateMode && !this.dataListPoppedUp
      ? [this.editorEntity]
      : this.selectedRows.length > 0
      ? this.selectedRows
      : null;

    if (actionTargets === null) {
      const error = 'Unable to do action without target.';
      this._helperService.toast(error);
      return throwError(error);
    }

    // Execute the action observables chain.
    return of({}).pipe(
      // Perform before-action and emit only the last value.
      mergeMap(() => (action.beforeAction ? action.beforeAction(actionTargets) : of({})).pipe(last())),
      // Set busy dialog.
      tap(() => (this.busy = action.name)),
      // Perform action and emit only the last value.
      mergeMap((beforeActionResult) => action.action(actionTargets, beforeActionResult).pipe(last())),
      // Clear busy dialog on success.
      tap(() => (this.busy = '')),
      // Perform after-action and emit only the last value.
      mergeMap((actionResult) =>
        (action.afterAction
          ? action.afterAction(actionResult, actionTargets).pipe(
              // Discard any value emitted by afterAction, we need to emit actionResult down the chain.
              map(() => actionResult)
            )
          : of(actionResult)
        ).pipe(last())
      ),
      // Emit action success event.
      tap((result) => this.actionSucceeded.emit({ id: action.id, result: result })),
      // Give some delay to wait for this action's busy dialog to be completely closed.
      // This allows next action's busy dialog on the observable chain to be properly
      // shown and is not accidentaly closed by this completed action's "clearing".
      delay(100),
      // Handle error.
      catchError((error) => {
        // Clear busy dialog on error.
        this.busy = '';

        // Ignore emtpy result error from last() operator above so that
        // it allows the action to be aborted silently without result.
        if (error && error.name == 'EmptyError') {
          error = undefined;
        }

        // Emit action failed event.
        this.actionFailed.emit({ id: action.id, error: error });

        // Toast error when the error is not produced during the process
        // of http call. When the error occurs during the http call,
        // the HttpRestService itself that will toast the error.
        if (error && !error.errorBag) {
          this._helperService.toast(error);
        }

        // Error catched with value of "undefined" is considered a user aborting
        // an ongoing action, so there should not be any further handling.
        // Let the observable chain completes with emtpy value silently.
        if (error === undefined) {
          return EMPTY;
        }

        // Rethrow the error up the chain.
        return throwError(error);
      })
    );
  }

  /**
   * Sort reader's tabular data.
   * @param sort Current sort state.
   * @param path Path to original array to be sorted relative to `this.readerEntity`.
   */
  public sortReaderData(sort: Sort, path: string = null) {
    const originalData: Array<any> = get(this.readerEntity, path).slice();

    // Set unsorted data.
    if (!sort.active || sort.direction === '') {
      set(this.readerEntity, path + '_sorted', originalData);
      return;
    }

    // Set sorted data.
    set(
      this.readerEntity,
      path + '_sorted',
      originalData.sort((a, b) => {
        if (sort.active) {
          return ((a: number | string, b: number | string, isAsc: boolean) =>
            (a == null ? -1 : b == null ? 1 : a < b ? -1 : 1) * (isAsc ? 1 : -1))(
            get(a, sort.active),
            get(b, sort.active),
            sort.direction === 'asc'
          );
        } else {
          return 0;
        }
      })
    );
  }

  /**
   * Handle shortcut keydown event using document as the event target.
   * @param event Keyboard event.
   */
  @HostListener('document:keydown', ['$event'])
  public onShortcutKeydown(event: KeyboardEvent) {
    const datalist = this._popUpDataList || this._dataList;

    if (event.shiftKey) {
      switch (event.key) {
        case 'ArrowDown':
          if (!this.listMode && !this.dataListPoppedUp) {
            this.down().subscribe();
          }
          return;

        case 'ArrowUp':
          if (!this.listMode && !this.dataListPoppedUp) {
            this.up().subscribe();
          }
          return;
      }
    }

    switch (event.key) {
      case 'F4':
        concat(this.dataListPoppedUp ? EMPTY : this.doList(), datalist.openAdvancedSearch()).subscribe();
        return;

      case 'F8':
        this._actionButtons.nativeElement.focus();
        return;

      case 'F9':
        datalist.focusToCell();
        return;

      default:
        break;
    }
  }

  /**
   * Scroll page to fragment.
   * @param fragment Fragment to scroll to.
   */
  public scrollTo(fragment: string): void {
    this._helperService.scrollToFragment(fragment);
  }

  /**
   * Retrieve validation message
   * @param error CommonError
   */
  public handleValidationMessages(error: CommonError) {
    this.validationMessages =
      error.errorBag.data && error.errorBag.data.validation_messages ? error.errorBag.data.validation_messages : {};
  }

  /**
   * Perform POST request of new resource to backend API's create endpoint. (url => {resourceURL})
   * @returns Observable of POST result.
   */
  private _doPOST(): Observable<any> {
    return this.doAction({
      id: CrudAction.Store,
      name: 'Saving new',
      beforeAction: (actionTargets) => {
        return this.beforePost ? this.beforePost(actionTargets[0]) : of({});
      },
      action: (actionTargets, beforeActionResult) => {
        // Perform POST request to backend API endpoint.
        return this._httpRestService
          .post(`${this.resourceURL}`, { ...this.editorEntity, _beforePost: beforeActionResult })
          .pipe(
            // Handle validation error messages.
            catchError((error: CommonError) => {
              this.handleValidationMessages(error);
              return throwError(error);
            })
          );
      },
      afterAction: (actionResult, actionTargets) => {
        return (this.afterPost ? this.afterPost(actionResult, actionTargets[0]) : of({})).pipe(
          switchMap(() => {
            if (this.dataListPoppedUp) {
              // Pop up data list mode.
              // Immediate Refresh Data List -> re-Do Create.
              return concat(this.refreshDataList(null), this.doCreate());
            } else {
              // Embedded data list mode.
              // Defered Refresh Data List -> re-Do Create.
              this.markNeedToRefreshEmbeddedDataList();
              return this.doCreate();
            }
          })
        );
      },
    });
  }

  /**
   * Perform PUT request of updated resource to backend API's update endpoint. (url => {resourceURL}/{id})
   * @returns Observable of PUT result.
   */
  private _doPUT(): Observable<any> {
    return this.doAction({
      id: CrudAction.Update,
      name: 'Saving changes',
      beforeAction: (selectedRows) => {
        return this.beforePut ? this.beforePut(this.editorEntity) : of({});
      },
      action: (selectedRows, beforeActionResult) => {
        // Perform PUT request to backend API endpoint.
        return this._httpRestService
          .put(`${this.resourceURL}/${selectedRows[0].id}`, {
            ...this.editorEntity,
            _beforePut: beforeActionResult,
          })
          .pipe(
            // Handle validation error messages.
            catchError((error: CommonError) => {
              this.handleValidationMessages(error);
              return throwError(error);
            })
          );
      },
      afterAction: (actionResult, actionTargets) => {
        return (this.afterPut ? this.afterPut(actionResult, actionTargets[0]) : of({})).pipe(
          switchMap(() => {
            if (this.dataListPoppedUp) {
              // Pop up data list mode.
              // Immediate Refresh Data List -> Select Updated Row -> re-Do Update.
              return concat(
                this.refreshDataList(null),
                this._popUpDataList.selectById(actionTargets[0].id),
                this.doUpdate()
              );
            } else {
              // Embedded data list mode.
              // Defer Refresh Data List -> re-Do Update.
              this.markNeedToRefreshEmbeddedDataList();
              return this.doUpdate();
            }
          })
        );
      },
    });
  }

  /**
   * Initialize (set new) the editor entity.
   */
  private _initEditorEntity() {
    this.editorEntity = this.getNewEditorEntity();
  }

  /**
   * On router route resolve end, do reader or editor entity "before leaving" cleaning.
   * @param event Router's ResolveEnd event args.
   */
  private _onRouteResolveEnd(event: ResolveEnd): void {
    const currentPath = this._activatedRoute.snapshot.firstChild.routeConfig.path;
    const firstChildTargetRoute = event.state.root.firstChild;
    const secondChildTargetRoute = firstChildTargetRoute && firstChildTargetRoute.firstChild;
    const thirdChildTargetRoute = secondChildTargetRoute && secondChildTargetRoute.firstChild;
    const targetPath = thirdChildTargetRoute && thirdChildTargetRoute.routeConfig.path;

    if (currentPath !== targetPath) {
      if (currentPath === ':id/update') {
        this._initEditorEntity();
      } else if (currentPath === ':id') {
        this.readerEntity = undefined;
      }
    }
  }

  /**
   * On router navigation end, do certain action based on CRUD component's child route path.
   * @param event Router's NavigationEnd event args.
   */
  private _onRouteNavigationEnd(event: NavigationEnd): void {
    switch (this._activatedRoute.firstChild.routeConfig.path) {
      case '':
        this.doList().subscribe();
        break;

      case 'create':
        if (this.createDisabled) {
          // Create is disabled, go to list.
          this.list().subscribe();
        } else {
          this.doCreate().subscribe();
        }
        break;

      case ':id':
        if (this.readDisabled) {
          // Read is disabled, go to list.
          this.list().subscribe();
        } else {
          const id: any = this._activatedRoute.firstChild.snapshot.params.id;
          this.doRead(isNaN(id) ? id : parseInt(id)).subscribe();
        }
        break;

      case ':id/update':
        if (this.updateDisabled) {
          // Update is disabled, go to list.
          this.list().subscribe();
        } else {
          const id: any = this._activatedRoute.firstChild.snapshot.params.id;
          this.doUpdate(isNaN(id) ? id : parseInt(id)).subscribe();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Initialize editor's controls.
   * @return Observable of boolean indicating editor is initialized or not.
   */
  private _initializeEditor(): Observable<boolean> {
    return of(this._editorInitialized).pipe(
      mergeMap((editorInitialized) => (editorInitialized ? of(true) : this.initializeEditor)),
      tap(() => {
        this._editorInitialized = true;
        this.validationMessages = {};
      }),
      catchError((error) => {
        return throwError(`Editor failed to be initialized due to the following error:<br/>${error.message}`);
      })
    );
  }

  /**
   * Horizontally scroll the grid to the first unpinned column.
   * This function is used to "reset" the horizontal scroll position to zero.
   * Therefore when the grid is re-rendered into the DOM (matTab is active again),
   * it's header rows position won't be stucked to the previous scrolled position.
   */
  private _scrollToFirstUnpinnedColumn() {
    // Only if the grid api is initialized.
    if (this.gridOptions.api) {
      this.gridOptions.api.ensureColumnVisible(
        this.gridOptions.columnApi.getAllColumns().find((col) => !col.isPinned())
      );
    }
  }
}
