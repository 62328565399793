import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

/**
 * Confirm dialog component.
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Confirm title.
   */
  public title: string;

  /**
   * Confirm content.
   */
  public contents: string[];

  /**
   * OK button label.
   */
  public okLabel: string;

  /**
   * Cancel button label.
   */
  public cancelLabel: string;

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    this.title = data.title;
    this.contents = data.contents;
    this.okLabel = data.okLabel ? data.okLabel : 'OK';
    this.cancelLabel = data.cancelLabel ? data.cancelLabel : 'Cancel';
  }

  /**
   * OK action.
   */
  public ok(): void {
    this._dialogRef.close(true);
  }

  /**
   * Cancel action.
   */
  public cancel(): void {
    this._dialogRef.close(false);
  }
}
