import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../../shared/dialog/base-dialog/base-dialog.component';
import { GlobalService } from '../services/global.service';
import { UserProfileService } from '../user-profile/user-profile.service';

/**
 * About dialog component.
 */
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Version.
   */
  public version: string;

  /**
   * Currently logged in user is a guest.
   */
  public guest: boolean;

  /**
   * Contructor.
   */
  constructor(
    private _dialogRef: MatDialogRef<AboutComponent>,
    private _globalService: GlobalService,
    private _userProfileService: UserProfileService
  ) {}

  /**
   * Angular life cycle hook.
   */
  public ngOnInit(): void {
    // Set app version.
    this.version = this._globalService.appVersion;

    // Set guest flag.
    this.guest = this._userProfileService.guest;
  }

  /**
   * Close the dialog.
   */
  public close(): void {
    this._dialogRef.close();
  }
}
