import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './core/auth/auth-guard.service';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  /**
   * App main routes.
   */
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },

  /**
   * Lazy loaded modules routes.
   */
  {
    path: 'master',
    loadChildren: () => import('./master/master.module').then((m) => m.MasterModule),
  },
  {
    path: 'agency',
    loadChildren: () => import('./agency/agency.module').then((m) => m.AgencyModule),
  },
  {
    path: 'depot',
    loadChildren: () => import('./depot/depot.module').then((m) => m.DepotModule),
  },
  {
    path: 'public-access',
    loadChildren: () => import('./public-access/public-access.module').then((m) => m.PublicAccessModule),
  },

  /**
   * Root and default routes.
   */
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

/**
 * App routing module.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
