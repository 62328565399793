import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment-mini';

import { ColDefExt } from '../../shared/data-list/column-def/col-def-ext.interface';
import { ColumnDefPreset } from '../../shared/data-list/column-def/column-def-preset.interface';
import { ResourceFieldService } from '../../shared/resource-field/resource-field.service';
import { GlobalService } from '../services/global.service';

/**
 * AG Grid column definition preset.
 */
@Injectable({
  providedIn: 'root',
})
export class ColumnDefPresetService {
  /**
   * Common column definitions for all resources. All resources must have these common columns.
   */
  private _common: ColDefExt[] = [
    {
      headerName: '',
      width: 37,
      pinned: 'left',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      enablePivot: false,
      enableRowGroup: false,
      enableValue: false,
      suppressMenu: true,
      lockPosition: true,
      lockVisible: true,
    },
    { headerName: '* Id *', field: 'id', width: 85, filter: 'agNumberColumnFilter' },
    { headerName: 'Workgroup', field: 'workgroup', width: 150 },
    { headerName: 'Created By', field: 'created_by', width: 150 },
    { headerName: 'Updated By', field: 'updated_by', width: 150 },
    {
      headerName: 'Created At',
      field: 'created_at',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null),
      datetimeFormat: GlobalService.DATETIME_FORMAT,
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null),
      datetimeFormat: GlobalService.DATETIME_FORMAT,
    },
  ];

  /**
   * Constructor.
   */
  constructor(private _resourceFieldService: ResourceFieldService) {}

  /**
   * Get the preset values.
   * @return Array of columnDefs of all resources.
   */
  public get(): ColumnDefPreset[] {
    return [
      // #region Agency Module
      {
        resourceURL: '/agency/delivery-order',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 180 },
            {
              headerName: '* Status *',
              field: 'status',
              width: 160,
              valueGetter: (params) =>
                params.data && params.data.status
                  ? this._resourceFieldService
                      .get('/agency/delivery-order')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.status).display
                  : null,
            },
            {
              headerName: 'Status At',
              field: 'current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            { headerName: 'Booking Out - Document Number', field: 'booking_out.document_number', width: 180 },
            { headerName: 'Depot - Name', field: 'depot.name', width: 220 },
            { headerName: 'CA - Code', field: 'container_authority.code', width: 100 },
            { headerName: 'Gate Out To - Name', field: 'gate_out_to.name', width: 220 },
            { headerName: '* Shipper *', field: 'shipper', width: 220 },
            { headerName: 'Transport - Number', field: 'transport.number', width: 160 },
            { headerName: 'Transport - Carrier - Name', field: 'transport.carrier.name', width: 180 },
            {
              headerName: 'Transport - ETD',
              field: 'transport.etd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Transport - ATD',
              field: 'transport.atd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Activity - Code', field: 'activity.code', width: 130 },
            { headerName: 'Activity - Name', field: 'activity.name', width: 170 },
            { headerName: '* Shipping BC *', field: 'shipping_bc', width: 200 },
            { headerName: '* Shipping Instruction *', field: 'shipping_instruction', width: 200 },
            { headerName: '* Kwitansi *', field: 'kwitansi', width: 180 },
            { headerName: '* TTD *', field: 'ttd', width: 180 },
            { headerName: '* TTS *', field: 'tts', width: 180 },
            { headerName: 'POL - Name', field: 'pol.name', width: 200 },
            { headerName: 'POD - Name', field: 'pod.name', width: 200 },
            { headerName: 'FD - Name', field: 'fd.name', width: 200 },
            { headerName: '* Commodity *', field: 'commodity', width: 300 },
            { headerName: '* Amount 20 *', field: 'amount_20', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 40 *', field: 'amount_40', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 45 *', field: 'amount_45', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount All *', field: 'amount_all', width: 140, filter: 'agNumberColumnFilter' },
            {
              headerName: '* Expiry At *',
              field: 'expiry_at',
              width: 130,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Renewed *',
              field: 'renewed',
              width: 120,
              valueGetter: (params) =>
                params.data
                  ? params.data.renewed === true
                    ? 'TRUE'
                    : params.data.renewed === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: '* Remark *', field: 'remark', width: 250 },
            { headerName: '* Seal Numbers *', field: 'seal_numbers', width: 300 },
            { headerName: '* Token *', field: 'token', width: 110 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/agency/stack',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 180 },
            {
              headerName: '* Status *',
              field: 'status',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.status
                  ? this._resourceFieldService
                      .get('/agency/stack')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.status).display
                  : null,
            },
            {
              headerName: 'Status At',
              field: 'current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            { headerName: 'Gate Out To - Name', field: 'gate_out_to.name', width: 220 },
            { headerName: '* Shipper *', field: 'shipper', width: 220 },
            { headerName: '* Shipping BL *', field: 'shipping_bl', width: 200 },
            { headerName: 'Transport - Number', field: 'transport.number', width: 160 },
            { headerName: 'Transport - Carrier - Name', field: 'transport.carrier.name', width: 180 },
            {
              headerName: 'Transport - ETD',
              field: 'transport.etd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Total Gross *',
              field: 'total_gross',
              width: 130,
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* DG *',
              field: 'dg',
              width: 100,
              valueGetter: (params) =>
                params.data ? (params.data.dg === true ? 'TRUE' : params.data.dg === false ? 'FALSE' : null) : null,
            },
            {
              headerName: '* RF *',
              field: 'rf',
              width: 100,
              valueGetter: (params) =>
                params.data ? (params.data.rf === true ? 'TRUE' : params.data.rf === false ? 'FALSE' : null) : null,
            },
            {
              headerName: '* OOG *',
              field: 'oog',
              width: 100,
              valueGetter: (params) =>
                params.data ? (params.data.oog === true ? 'TRUE' : params.data.oog === false ? 'FALSE' : null) : null,
            },
            {
              headerName: '* MISC *',
              field: 'misc',
              width: 100,
              valueGetter: (params) =>
                params.data ? (params.data.misc === true ? 'TRUE' : params.data.misc === false ? 'FALSE' : null) : null,
            },
            { headerName: '* Customer Email *', field: 'customer_email', width: 200 },
            {
              headerName: '* Email Sent At *',
              field: 'email_sent_at',
              width: 200,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            { headerName: '* Email Sent To *', field: 'email_sent_to', width: 350 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 }
          );
          return defs;
        })(),
      },
      // #endregion

      // #region Depot Module
      {
        resourceURL: '/depot/booking-in',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 200 },
            { headerName: 'CA - Code', field: 'container_authority.code', width: 100 },
            {
              headerName: '* Status *',
              field: 'status',
              width: 160,
              valueGetter: (params) =>
                params.data && params.data.status
                  ? this._resourceFieldService
                      .get('/depot/booking-in')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.status).display
                  : null,
            },
            {
              headerName: 'Status At',
              field: 'current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            { headerName: '* Reference Document *', field: 'reference_document', width: 200 },
            { headerName: 'Gate In From - Name', field: 'gate_in_from.name', width: 220 },
            { headerName: '* Consignee *', field: 'consignee', width: 220 },
            { headerName: 'Transport - Number', field: 'transport.number', width: 160 },
            { headerName: 'Transport - Carrier - Name', field: 'transport.carrier.name', width: 180 },
            {
              headerName: 'Transport - ETA',
              field: 'transport.eta',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Transport - ATA',
              field: 'transport.ata',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Activity - Code', field: 'activity.code', width: 120 },
            { headerName: 'Activity - Name', field: 'activity.name', width: 170 },
            { headerName: '* Shipping BL *', field: 'shipping_bl', width: 200 },
            { headerName: '* DEM FT *', field: 'dem_ft', width: 110 },
            {
              headerName: '* DEM FT End At *',
              field: 'dem_ft_end_at',
              width: 160,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: '* TTS JC *', field: 'tts_jc', width: 180 },
            { headerName: '* Fiat Day *', field: 'fiat_day', width: 110 },
            {
              headerName: '* Fiat Day End At *',
              field: 'fiat_day_end_at',
              width: 160,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: '* TTS DM *', field: 'tts_dm', width: 180 },
            { headerName: '* LMS DEM FT *', field: 'lms_dem_ft', width: 110 },
            { headerName: '* LMS DEM APVL *', field: 'lms_dem_apvl', width: 160 },
            { headerName: 'POO - Name', field: 'poo.name', width: 200 },
            { headerName: 'POE - Name', field: 'poe.name', width: 200 },
            { headerName: '* Commodity *', field: 'commodity', width: 300 },
            { headerName: '* Amount 20 *', field: 'amount_20', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 40 *', field: 'amount_40', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 45 *', field: 'amount_45', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount All *', field: 'amount_all', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Remark *', field: 'remark', width: 250 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 },
            { headerName: 'Depot - Name', field: 'depot.name', width: 220 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/booking-out',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 200 },
            { headerName: 'CA - Code', field: 'container_authority.code', width: 100 },
            {
              headerName: '* Status *',
              field: 'status',
              width: 160,
              valueGetter: (params) =>
                params.data && params.data.status
                  ? this._resourceFieldService
                      .get('/depot/booking-out')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.status).display
                  : null,
            },
            {
              headerName: 'Status At',
              field: 'current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            { headerName: '* Reference Document *', field: 'reference_document', width: 200 },
            { headerName: 'Delivery Order - Document Number', field: 'delivery_order.document_number', width: 200 },
            { headerName: 'Gate Out To - Name', field: 'gate_out_to.name', width: 220 },
            { headerName: '* Shipper *', field: 'shipper', width: 220 },
            { headerName: 'Transport - Number', field: 'transport.number', width: 160 },
            { headerName: 'Transport - Carrier - Name', field: 'transport.carrier.name', width: 180 },
            {
              headerName: 'Transport - ETD',
              field: 'transport.etd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Transport - ATD',
              field: 'transport.atd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Transport Confirm - Carrier - Name', field: 'transport_confirm.carrier.name', width: 180 },
            { headerName: 'Transport Confirm - Number', field: 'transport_confirm.number', width: 180 },
            {
              headerName: 'Transport Confirm - ETD',
              field: 'transport_confirm.etd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Transport Confirm - ATD',
              field: 'transport_confirm.atd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Activity - Code', field: 'activity.code', width: 130 },
            { headerName: 'Activity - Name', field: 'activity.name', width: 170 },
            { headerName: '* Shipping BC *', field: 'shipping_bc', width: 200 },
            { headerName: '* Shipping Instruction *', field: 'shipping_instruction', width: 200 },
            {
              headerName: '* Fiat Day End At *',
              field: 'fiat_day_end_at',
              width: 130,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: '* TTD *', field: 'ttd', width: 180 },
            { headerName: '* TTS *', field: 'tts', width: 180 },
            { headerName: 'POL - Name', field: 'pol.name', width: 200 },
            { headerName: 'POD - Name', field: 'pod.name', width: 200 },
            { headerName: 'FD - Name', field: 'fd.name', width: 200 },
            { headerName: '* Commodity *', field: 'commodity', width: 300 },
            { headerName: '* Amount 20 *', field: 'amount_20', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 40 *', field: 'amount_40', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount 45 *', field: 'amount_45', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Amount All *', field: 'amount_all', width: 140, filter: 'agNumberColumnFilter' },
            { headerName: '* Customer Email *', field: 'customer_email', width: 200 },
            { headerName: '* Remark *', field: 'remark', width: 250 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 },
            { headerName: 'Depot - Name', field: 'depot.name', width: 220 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/depot-container',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 190 },
            { headerName: 'CA - Code', field: 'container_authority.code', width: 100 },
            { headerName: '* Number *', field: 'number', width: 140 },
            { headerName: 'Spec - Type', field: 'spec.type', width: 110 },
            { headerName: 'Spec - Size', field: 'spec.size', width: 110 },
            { headerName: 'Spec - ISO', field: 'spec.iso_code', width: 110 },
            {
              headerName: '* Availability *',
              field: 'availability',
              width: 140,
              valueGetter: (params) =>
                params.data && params.data.availability
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'availability')
                      .options.find((option) => option.value === params.data.availability).display
                  : null,
            },
            {
              headerName: 'Availability At',
              field: 'current_availability_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Condition *',
              field: 'condition',
              width: 130,
              valueGetter: (params) =>
                params.data && params.data.condition
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'condition')
                      .options.find((option) => option.value === params.data.condition).display
                  : null,
            },
            {
              headerName: 'Condition At',
              field: 'current_condition_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* CGI *',
              field: 'cgi',
              width: 130,
              valueGetter: (params) =>
                params.data && params.data.cgi
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'cgi')
                      .options.find((option) => option.value === params.data.cgi).display
                  : null,
            },
            {
              headerName: '* Grade *',
              field: 'grade',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.grade
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'grade')
                      .options.find((option) => option.value === params.data.grade).display
                  : null,
            },
            {
              headerName: '* Cleaning *',
              field: 'cleaning',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.cleaning
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'cleaning')
                      .options.find((option) => option.value === params.data.cleaning).display
                  : null,
            },
            {
              headerName: '* PTI Result *',
              field: 'pti_result',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.pti_result
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'pti_result')
                      .options.find((option) => option.value === params.data.pti_result).display
                  : null,
            },
            {
              headerName: 'PTI Result At',
              field: 'current_pti_result_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Ex Repair *',
              field: 'ex_repair',
              width: 110,
              valueGetter: (params) =>
                params.data
                  ? params.data.ex_repair === true
                    ? 'TRUE'
                    : params.data.ex_repair === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: 'EOR (latest) - Document Number', field: 'latest_eor.document_number', width: 200 },
            {
              headerName: 'EOR (latest) - Status',
              field: 'latest_eor.status',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.latest_eor && params.data.latest_eor.status
                  ? this._resourceFieldService
                      .get('/depot/eor')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.latest_eor.status).display
                  : null,
            },
            {
              headerName: 'EOR (latest) - Status At',
              field: 'latest_eor.current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
            },
            { headerName: 'Booking In - Document Number', field: 'booking_in.document_number', width: 200 },
            { headerName: 'Booking In - Shipping BL', field: 'booking_in.shipping_bl', width: 200 },
            {
              headerName: 'Booking In - Gate In From',
              field: 'booking_in.gate_in_from.name',
              width: 240,
            },
            { headerName: 'Booking In - Consignee', field: 'booking_in.consignee', width: 240 },
            { headerName: 'Booking In - Commodity', field: 'booking_in.commodity', width: 240 },
            { headerName: 'Booking In - Transport', field: 'booking_in.transport.number', width: 160 },
            { headerName: 'Booking In - Transport - Carrier', field: 'booking_in.transport.carrier.name', width: 180 },
            {
              headerName: 'Booking In - Transport - ETA',
              field: 'booking_in.transport.eta',
              width: 140,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Booking In - Activity', field: 'booking_in.activity.code', width: 120 },
            {
              headerName: '* Gate In At *',
              field: 'gate_in_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Load In *',
              field: 'load_in',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.load_in
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'load_in')
                      .options.find((option) => option.value === params.data.load_in).display
                  : null,
            },
            { headerName: '* Truck Provider In *', field: 'truck_provider_in', width: 140 },
            { headerName: '* Truck Number In *', field: 'truck_number_in', width: 140 },
            { headerName: '* Simple Pre Survey In *', field: 'simple_pre_survey_in', width: 250 },
            { headerName: '* Pre Survey In *', field: 'pre_survey_in', width: 250 },
            { headerName: '* PIC In *', field: 'pic_in', width: 150 },
            { headerName: '* Recipient In *', field: 'recipient_in', width: 150 },
            {
              headerName: '* Recieved Date In *',
              field: 'received_date_in',
              width: 160,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Booking Out - Document Number',
              field: 'booking_out_item.booking_out.document_number',
              width: 200,
            },
            { headerName: 'Booking Out - Shipping BC', field: 'booking_out_item.booking_out.shipping_bc', width: 200 },
            {
              headerName: 'Booking Out - Gate Out To',
              field: 'booking_out_item.booking_out.gate_out_to.name',
              width: 240,
            },
            { headerName: 'Booking Out - Shipper', field: 'booking_out_item.booking_out.shipper', width: 240 },
            { headerName: 'Booking Out - Commodity', field: 'booking_out_item.booking_out.commodity', width: 240 },
            {
              headerName: 'Booking Out - Transport',
              field: 'booking_out_item.booking_out.transport.number',
              width: 160,
            },
            {
              headerName: 'Booking Out - Transport - Carrier',
              field: 'booking_out_item.booking_out.transport.carrier.name',
              width: 180,
            },
            {
              headerName: 'Booking Out - Transport - ETD',
              field: 'booking_out_item.booking_out.transport.etd',
              width: 140,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Booking Out - Transport Confirm',
              field: 'booking_out_item.booking_out.transport_confirm.number',
              width: 160,
            },
            {
              headerName: 'Booking Out - Transport Confirm - Carrier',
              field: 'booking_out_item.booking_out.transport_confirm.carrier.name',
              width: 180,
            },
            {
              headerName: 'Booking Out - Transport Confirm - ETD',
              field: 'booking_out_item.booking_out.transport_confirm.etd',
              width: 140,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: 'Booking Out - Activity',
              field: 'booking_out_item.booking_out.activity.code',
              width: 120,
            },
            {
              headerName: '* Gate Out At *',
              field: 'gate_out_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Load Out *',
              field: 'load_out',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.load_out
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'load_out')
                      .options.find((option) => option.value === params.data.load_out).display
                  : null,
            },
            { headerName: '* Truck Provider Out *', field: 'truck_provider_out', width: 140 },
            { headerName: '* Truck Number Out *', field: 'truck_number_out', width: 140 },
            { headerName: '* Simple Pre Survey Out *', field: 'simple_pre_survey_out', width: 250 },
            { headerName: '* Pre Survey Out *', field: 'pre_survey_out', width: 250 },
            { headerName: '* PIC Out *', field: 'pic_out', width: 150 },
            { headerName: '* Tare *', field: 'tare', width: 120, filter: 'agNumberColumnFilter' },
            { headerName: '* Max Gross *', field: 'max_gross', width: 120, filter: 'agNumberColumnFilter' },
            { headerName: '* Net *', field: 'net', width: 120, filter: 'agNumberColumnFilter' },
            { headerName: '* Cu Cap *', field: 'cu_cap', width: 100, filter: 'agNumberColumnFilter' },
            { headerName: '* Vent *', field: 'vent', width: 100, filter: 'agNumberColumnFilter' },
            {
              headerName: '* Mnf Date *',
              field: 'mnf_date',
              width: 120,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) => (params.value ? params.value.format('YYYY-MM') : null),
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* ACEP *',
              field: 'acep',
              width: 100,
              valueGetter: (params) =>
                params.data ? (params.data.acep === true ? 'TRUE' : params.data.acep === false ? 'FALSE' : null) : null,
            },
            { headerName: '* Location *', field: 'location', width: 120 },
            {
              headerName: 'Warning - Subject',
              field: 'warning.subject',
              width: 160,
              valueGetter: (params) =>
                params.data && params.data.warning && params.data.warning.subject
                  ? this._resourceFieldService
                      .get('/depot/warning')
                      .find((field) => field.name === 'subject')
                      .options.find((option) => option.value === params.data.warning.subject).display
                  : null,
            },
            { headerName: 'Warning - Id', field: 'warning.id', width: 120 },
            { headerName: 'Warning - Remark', field: 'warning.remark', width: 200 },
            { headerName: '* VGM Gross *', field: 'vgm_gross', width: 140 },
            {
              headerName: '* VGM Method *',
              field: 'vgm_method',
              width: 140,
              valueGetter: (params) =>
                params.data && params.data.vgm_method
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'vgm_method')
                      .options.find((option) => option.value === params.data.vgm_method).display
                  : null,
            },
            { headerName: '* VGM Responsible Party *', field: 'vgm_responsible_party', width: 220 },
            { headerName: '* VGM Authorized Official *', field: 'vgm_authorized_official', width: 220 },
            { headerName: '* Seal Number *', field: 'seal_number', width: 150 },
            { headerName: '* Remark *', field: 'remark', width: 250 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 },
            { headerName: 'Depot - Name', field: 'depot.name', width: 220 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/edifact',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            {
              headerName: 'Job - Type',
              field: 'edifact_job.type',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.edifact_job && params.data.edifact_job.type
                  ? this._resourceFieldService
                      .get('/depot/edifact-job')
                      .find((field) => field.name === 'type')
                      .options.find((option) => option.value === params.data.edifact_job.type).display
                  : null,
            },
            { headerName: 'Job - Id', field: 'edifact_job.id', width: 60, filter: 'agNumberColumnFilter' },
            {
              headerName: 'Job - Depot',
              valueGetter: (params) =>
                params.data
                  ? params.data.edifact_job.depot.port.code +
                    params.data.edifact_job.depot.type +
                    params.data.edifact_job.depot.number
                  : null,
              width: 100,
            },
            { headerName: 'Job - CA', field: 'edifact_job.container_authority.code', width: 70 },
            { headerName: 'Job - Subject', field: 'edifact_job.subject', width: 70 },
            { headerName: 'Job - Activity', field: 'edifact_job.activity.code', width: 70 },
            { headerName: 'Job - Terminal', field: 'edifact_job.terminal.name', width: 100 },
            { headerName: 'Job - Sender', field: 'edifact_job.sender', width: 100 },
            { headerName: 'Job - Recipient', field: 'edifact_job.recipient', width: 100 },
            { headerName: '* EQD Count *', field: 'eqd_count', width: 70, filter: 'agNumberColumnFilter' },
            {
              headerName: '* Generated At *',
              field: 'generated_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Sent At *',
              field: 'sent_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: 'Sent Duration',
              width: 70,
              filter: 'agNumberColumnFilter',
              valueGetter: (params) =>
                params.data
                  ? params.data.sent_at
                    ? moment(params.data.sent_at).diff(moment(params.data.created_at), 'seconds')
                    : null
                  : null,
            },
            {
              headerName: '* Success *',
              field: 'success',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.success === true
                    ? 'TRUE'
                    : params.data.success === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Scheduled *',
              field: 'scheduled',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.scheduled === true
                    ? 'TRUE'
                    : params.data.scheduled === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Resending *',
              field: 'resending',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.resending === true
                    ? 'TRUE'
                    : params.data.resending === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: '* Next Attempt Id *', field: 'next_attempt_id', width: 100, filter: 'agNumberColumnFilter' },
            {
              headerName: '* Error At *',
              field: 'error_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: 'Error Duration',
              width: 80,
              filter: 'agNumberColumnFilter',
              valueGetter: (params) =>
                params.data
                  ? params.data.error_at
                    ? moment(params.data.error_at).diff(moment(params.data.created_at), 'seconds')
                    : null
                  : null,
            },
            { headerName: '* Error Message *', field: 'error_message', width: 300 },
            { headerName: 'Job - Target Host', field: 'edifact_job.target_host', width: 160 },
            { headerName: '* Filepath *', field: 'filepath', width: 400 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/edifact-job',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            {
              headerName: '* Type *',
              field: 'type',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.type
                  ? this._resourceFieldService
                      .get('/depot/edifact-job')
                      .find((field) => field.name === 'type')
                      .options.find((option) => option.value === params.data.type).display
                  : null,
            },
            {
              headerName: '* Subject *',
              field: 'subject',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.subject
                  ? this._resourceFieldService
                      .get('/depot/edifact-job')
                      .find((field) => field.name === 'subject')
                      .options.find((option) => option.value === params.data.subject).display
                  : null,
            },
            {
              headerName: '* Format *',
              field: 'format',
              width: 110,
              valueGetter: (params) =>
                params.data && params.data.format
                  ? this._resourceFieldService
                      .get('/depot/edifact-job')
                      .find((field) => field.name === 'format')
                      .options.find((option) => option.value === params.data.format).display
                  : null,
            },
            { headerName: '* Sender *', field: 'sender', width: 110 },
            { headerName: '* Recipient *', field: 'recipient', width: 110 },
            { headerName: '* Filepath Prefix *', field: 'filepath_prefix', width: 330 },
            {
              headerName: '* Target Protocol *',
              field: 'target_protocol',
              width: 100,
              valueGetter: (params) =>
                params.data && params.data.target_protocol
                  ? this._resourceFieldService
                      .get('/depot/edifact-job')
                      .find((field) => field.name === 'target_protocol')
                      .options.find((option) => option.value === params.data.target_protocol).display
                  : null,
            },
            {
              headerName: '* Target FTP SSL *',
              field: 'target_ftp_ssl',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.taret_ftp_ssl === true
                    ? 'TRUE'
                    : params.data.taret_ftp_ssl === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: '* Target Port *', field: 'target_port', width: 100 },
            { headerName: '* Target Host *', field: 'target_host', width: 130 },
            {
              headerName: 'Depot',
              valueGetter: (params) =>
                params.data ? params.data.depot.port.code + params.data.depot.type + params.data.depot.number : null,
              width: 110,
            },
            { headerName: 'Container Authority - Code', field: 'container_authority.code', width: 100 },
            { headerName: 'Activity - Code', field: 'activity.code', width: 100 },
            { headerName: 'Terminal - Name', field: 'terminal.name', width: 200 },
            { headerName: '* Schedule *', field: 'schedule', width: 170 },
            {
              headerName: '* Effective At *',
              field: 'effective_at',
              width: 130,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Active *',
              field: 'active',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/eor',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Document Number *', field: 'document_number', width: 200 },
            {
              headerName: '* Status *',
              field: 'status',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.status
                  ? this._resourceFieldService
                      .get('/depot/eor')
                      .find((field) => field.name === 'status')
                      .options.find((option) => option.value === params.data.status).display
                  : null,
            },
            {
              headerName: 'Status At',
              field: 'current_status_log.event_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Service Time *',
              field: 'service_time',
              width: 140,
              valueGetter: (params) =>
                params.data && params.data.service_time
                  ? this._resourceFieldService
                      .get('/depot/eor')
                      .find((field) => field.name === 'service_time')
                      .options.find((option) => option.value === params.data.service_time).display
                  : null,
            },
            {
              headerName: '* Initial *',
              field: 'initial',
              width: 90,
              valueGetter: (params) =>
                params.data
                  ? params.data.initial === true
                    ? 'TRUE'
                    : params.data.initial === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: 'Document Number', field: 'depot_container.document_number', width: 190 },
            { headerName: 'CA - Code', field: 'depot_container.container_authority.code', width: 100 },
            { headerName: 'Number', field: 'depot_container.number', width: 140 },
            { headerName: 'Type', field: 'depot_container.spec.type', width: 80 },
            { headerName: 'Size', field: 'depot_container.spec.size', width: 80 },
            {
              headerName: 'Mnf Date',
              field: 'depot_container.mnf_date',
              width: 120,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) => (params.value ? params.value.format('YYYY-MM') : null),
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Max Gross', field: 'depot_container.max_gross', width: 120, filter: 'agNumberColumnFilter' },
            {
              headerName: 'Condition',
              field: 'depot_container.condition',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.depot_container.condition
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'condition')
                      .options.find((option) => option.value === params.data.depot_container.condition).display
                  : null,
            },
            {
              headerName: 'CGI',
              field: 'depot_container.cgi',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.depot_container.cgi
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'cgi')
                      .options.find((option) => option.value === params.data.depot_container.cgi).display
                  : null,
            },
            {
              headerName: 'Gate In At',
              field: 'depot_container.gate_in_at',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: 'Availability',
              field: 'depot_container.availability',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.depot_container.cgi
                  ? this._resourceFieldService
                      .get('/depot/depot-container')
                      .find((field) => field.name === 'availability')
                      .options.find((option) => option.value === params.data.depot_container.availability).display
                  : null,
            },
            { headerName: 'Surveyor', field: 'surveyor.code', width: 100 },
            {
              headerName: '* Currency *',
              field: 'currency',
              width: 100,
              valueGetter: (params) =>
                params.data && params.data.currency
                  ? this._resourceFieldService
                      .get('/depot/eor')
                      .find((field) => field.name === 'currency')
                      .options.find((option) => option.value === params.data.currency).display
                  : null,
            },
            {
              headerName: '* Ori CC *',
              field: 'original_cleaning_code',
              width: 105,
            },
            {
              headerName: '* Ori M *',
              field: 'original_material_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori M IDR *',
              field: 'original_material_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori L *',
              field: 'original_labor_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori L IDR *',
              field: 'original_labor_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori O *',
              field: 'original_owner_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori O IDR *',
              field: 'original_owner_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori U *',
              field: 'original_user_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori U IDR *',
              field: 'original_user_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori C *',
              field: 'original_cleaning_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori C IDR *',
              field: 'original_cleaning_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori P *',
              field: 'original_pti_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori P IDR *',
              field: 'original_pti_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori Tot *',
              field: 'original_total_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori Tot IDR *',
              field: 'original_total_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Ori TPC *',
              field: 'original_tpc_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Ori TPC IDR *',
              field: 'original_tpc_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy CC *',
              field: 'surveyed_cleaning_code',
              width: 105,
            },
            {
              headerName: '* Svy M *',
              field: 'surveyed_material_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy M IDR *',
              field: 'surveyed_material_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy L *',
              field: 'surveyed_labor_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy L IDR *',
              field: 'surveyed_labor_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy O *',
              field: 'surveyed_owner_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy O IDR *',
              field: 'surveyed_owner_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy U *',
              field: 'surveyed_user_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy U IDR *',
              field: 'surveyed_user_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy C *',
              field: 'surveyed_cleaning_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy C IDR *',
              field: 'surveyed_cleaning_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy P *',
              field: 'surveyed_pti_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy P IDR *',
              field: 'surveyed_pti_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy Tot *',
              field: 'surveyed_total_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy Tot IDR *',
              field: 'surveyed_total_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy TPC *',
              field: 'surveyed_tpc_cost',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value != null
                  ? parseFloat(params.value)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                  : null,
            },
            {
              headerName: '* Svy TPC IDR *',
              field: 'surveyed_tpc_cost_idr',
              width: 105,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            // { headerName: '* Waiver Reference *', field: 'waiver_reference', width: 150 },
            // {
            //   headerName: '* Waiver Value *',
            //   field: 'waiver_value',
            //   width: 105,
            //   cellStyle: { textAlign: 'right' },
            //   filter: 'agNumberColumnFilter',
            //   valueFormatter: (params) =>
            //     params.value != null
            //       ? parseFloat(params.value)
            //           .toFixed(2)
            //           .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            //       : null,
            // },
            // {
            //   headerName: '* Svy TPC Nett *',
            //   field: 'surveyed_tpc_nett',
            //   width: 105,
            //   cellStyle: { textAlign: 'right' },
            //   filter: 'agNumberColumnFilter',
            //   valueFormatter: (params) =>
            //     params.value != null
            //       ? parseFloat(params.value)
            //           .toFixed(2)
            //           .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            //       : null,
            // },
            {
              headerName: '* Exch Date IDR *',
              field: 'exchange_date_idr',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Exch Rate IDR *',
              field: 'exchange_rate_idr',
              width: 150,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Exch Rounded IDR *',
              field: 'exchange_rounded_idr',
              width: 120,
              valueGetter: (params) =>
                params.data
                  ? params.data.exchange_rounded_idr === true
                    ? 'TRUE'
                    : params.data.exchange_rounded_idr === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Ori Total TPC IDR *',
              field: 'original_total_tpc_cost_idr',
              width: 150,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            {
              headerName: '* Svy Total TPC IDR *',
              field: 'surveyed_total_tpc_cost_idr',
              width: 150,
              cellStyle: { textAlign: 'right' },
              filter: 'agNumberColumnFilter',
              valueFormatter: (params) =>
                params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            },
            // {
            //   headerName: '* Waiver Value IDR *',
            //   field: 'waiver_value_idr',
            //   width: 150,
            //   cellStyle: { textAlign: 'right' },
            //   filter: 'agNumberColumnFilter',
            //   valueFormatter: (params) =>
            //     params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            // },
            // {
            //   headerName: '* Svy TPC Nett IDR *',
            //   field: 'surveyed_tpc_nett_idr',
            //   width: 150,
            //   cellStyle: { textAlign: 'right' },
            //   filter: 'agNumberColumnFilter',
            //   valueFormatter: (params) =>
            //     params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
            // },
            { headerName: '* Remark *', field: 'remark', width: 300 },
            {
              headerName: 'Booking In - Document Number',
              field: 'depot_container.booking_in.document_number',
              width: 200,
            },
            { headerName: 'Booking In - Shipping BL', field: 'depot_container.booking_in.shipping_bl', width: 200 },
            { headerName: 'Booking In - Activity', field: 'depot_container.booking_in.activity.code', width: 110 },
            {
              headerName: 'Booking In - Gate In From',
              field: 'depot_container.booking_in.gate_in_from.name',
              width: 240,
            },
            { headerName: 'Booking In - Consignee', field: 'depot_container.booking_in.consignee', width: 240 },
            { headerName: 'Booking In - Commodity', field: 'depot_container.booking_in.commodity', width: 240 },
            {
              headerName: 'Booking In - Transport - Number',
              field: 'depot_container.booking_in.transport.number',
              width: 160,
            },
            {
              headerName: 'Booking In - Transport - Carrier',
              field: 'depot_container.booking_in.transport.carrier.name',
              width: 180,
            },
            { headerName: '* BL No *', field: 'bl_no', width: 200 },
            { headerName: '* Act *', field: 'act', width: 200 },
            { headerName: '* Cnee *', field: 'cnee', width: 200 },
            { headerName: '* Comm *', field: 'comm', width: 200 },
            { headerName: '* Ves *', field: 'ves', width: 180 },
            { headerName: '* Voy *', field: 'voy', width: 180 },
            {
              headerName: 'Warning - Subject',
              field: 'depot_container.warning.subject',
              width: 160,
              valueGetter: (params) =>
                params.data &&
                params.data.depot_container &&
                params.data.depot_container.warning &&
                params.data.depot_container.warning.subject
                  ? this._resourceFieldService
                      .get('/depot/warning')
                      .find((field) => field.name === 'subject')
                      .options.find((option) => option.value === params.data.depot_container.warning.subject).display
                  : null,
            },
            { headerName: 'Warning - Id', field: 'depot_container.warning.id', width: 120 },
            { headerName: 'Warning - Remark', field: 'depot_container.warning.remark', width: 200 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 220 },
            { headerName: 'Depot - Name', field: 'depot.name', width: 220 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/depot/warning',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            {
              headerName: '* Subject *',
              field: 'subject',
              width: 170,
              valueGetter: (params) =>
                params.data && params.data.subject
                  ? this._resourceFieldService
                      .get('/depot/warning')
                      .find((field) => field.name === 'subject')
                      .options.find((option) => option.value === params.data.subject).display
                  : null,
            },
            { headerName: '* Reference *', field: 'reference', width: 200 },
            {
              headerName: '* Reference Date *',
              field: 'reference_date',
              width: 160,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: 'Spec - Type', field: 'spec.type', width: 130 },
            { headerName: '* Number Start *', field: 'number_start', width: 150 },
            { headerName: '* Number End *', field: 'number_end', width: 150 },
            { headerName: '* Range Size *', field: 'range_size', width: 130, filter: 'agNumberColumnFilter' },
            { headerName: '* Affected *', field: 'affected', width: 130, filter: 'agNumberColumnFilter' },
            {
              headerName: '* Expired At *',
              field: 'expired_at',
              width: 130,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Completed At *',
              field: 'completed_at',
              width: 140,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            { headerName: '* Remark *', field: 'remark', width: 300 }
          );
          return defs;
        })(),
      },
      // #endregion

      // #region Master Module
      // Main
      {
        resourceURL: '/master/activity',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Name *', field: 'name', width: 200 },
            { headerName: '* Code *', field: 'code', width: 100 },
            {
              headerName: '* Direction *',
              field: 'direction',
              width: 170,
              valueGetter: (params) =>
                params.data && params.data.direction
                  ? this._resourceFieldService
                      .get('/master/activity')
                      .find((field) => field.name === 'direction')
                      .options.find((option) => option.value === params.data.direction).display
                  : null,
            },
            {
              headerName: '* Required Transport *',
              field: 'required_transport',
              width: 230,
              valueGetter: (params) =>
                params.data
                  ? params.data.required_transport === true
                    ? 'TRUE'
                    : params.data.required_transport === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            { headerName: '* Required Container Availability *', field: 'required_container_availability', width: 350 },
            { headerName: '* Required Container Condition *', field: 'required_container_condition', width: 350 },
            {
              headerName: '* Active *',
              field: 'active',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/carrier',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 200 },
            { headerName: '* Name *', field: 'name', width: 400 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/point',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: 'Port - Code', field: 'port.code', width: 120 },
            {
              headerName: '* Type *',
              field: 'type',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.type
                  ? this._resourceFieldService
                      .get('/master/point')
                      .find((field) => field.name === 'type')
                      .options.find((option) => option.value === params.data.type).display
                  : null,
            },
            { headerName: '* Number *', field: 'number', width: 120 },
            { headerName: '* Name *', field: 'name', width: 350 },
            { headerName: '* Address *', field: 'address', width: 350 },
            { headerName: 'Authority - Name', field: 'authority.name', width: 200 },
            {
              headerName: '* Ediable *',
              field: 'ediable',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.ediable === true
                    ? 'TRUE'
                    : params.data.ediable === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Active *',
              field: 'active',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/port',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 200 },
            { headerName: '* Name *', field: 'name', width: 300 },
            { headerName: '* Country *', field: 'country', width: 300 },
            {
              headerName: '* Active *',
              field: 'active',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/spec',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Type *', field: 'type', width: 100 },
            { headerName: '* Size *', field: 'size', width: 100 },
            { headerName: '* ISO Code *', field: 'iso_code', width: 130 },
            { headerName: '* ISO Length *', field: 'iso_length', width: 130 },
            { headerName: '* ISO Height *', field: 'iso_height', width: 130 },
            { headerName: '* ISO Width *', field: 'iso_width', width: 130 },
            { headerName: '* ISO Type Description *', field: 'iso_type_description', width: 400 },
            {
              headerName: '* Active *',
              field: 'active',
              width: 100,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/stakeholder',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 100 },
            { headerName: '* Name *', field: 'name', width: 350 },
            { headerName: '* Address *', field: 'address', width: 350 },
            { headerName: '* Contacts *', field: 'contacts', width: 350 },
            {
              headerName: '* Type *',
              field: 'type',
              width: 200,
              valueGetter: (params) =>
                params.data && params.data.type
                  ? this._resourceFieldService
                      .get('/master/stakeholder')
                      .find((field) => field.name === 'type')
                      .options.find((option) => option.value === params.data.type).display
                  : null,
            },
            {
              headerName: '* Container Authority *',
              field: 'container_authority',
              width: 200,
              valueGetter: (params) =>
                params.data
                  ? params.data.container_authority === true
                    ? 'TRUE'
                    : params.data.container_authority === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Container Principal *',
              field: 'container_principal',
              width: 200,
              valueGetter: (params) =>
                params.data
                  ? params.data.container_principal === true
                    ? 'TRUE'
                    : params.data.container_principal === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Active *',
              field: 'active',
              width: 120,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/transport',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            {
              headerName: '* Mode *',
              field: 'mode',
              width: 100,
              valueGetter: (params) =>
                params.data && params.data.mode
                  ? this._resourceFieldService
                      .get('/master/transport')
                      .find((field) => field.name === 'mode')
                      .options.find((option) => option.value === params.data.mode).display
                  : null,
            },
            { headerName: 'Carrier - Code', field: 'carrier.code', width: 150 },
            { headerName: 'Carrier - Name', field: 'carrier.name', width: 200 },
            { headerName: '* Number *', field: 'number', width: 150 },
            { headerName: '* Number EDI *', field: 'number_edi', width: 150 },
            { headerName: 'Departure Point - Name', field: 'departure_point.name', width: 200 },
            { headerName: 'Arrival Point - Name', field: 'arrival_point.name', width: 200 },
            {
              headerName: '* ETD *',
              field: 'etd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* ETA *',
              field: 'eta',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* ATD *',
              field: 'atd',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* ATA *',
              field: 'ata',
              width: 150,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATE_FORMAT) : null,
              datetimeFormat: GlobalService.DATE_FORMAT,
            },
            {
              headerName: '* Closing Cargo *',
              field: 'closing_cargo',
              width: 180,
              filter: 'agDateColumnFilter',
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(GlobalService.DATETIME_FORMAT) : null,
              datetimeFormat: GlobalService.DATETIME_FORMAT,
            },
            {
              headerName: '* Direction *',
              field: 'direction',
              width: 120,
              valueGetter: (params) =>
                params.data && params.data.direction
                  ? this._resourceFieldService
                      .get('/master/transport')
                      .find((field) => field.name === 'direction')
                      .options.find((option) => option.value === params.data.direction).display
                  : null,
            },
            { headerName: 'Terminal - Name', field: 'terminal.name', width: 200 },
            {
              headerName: '* Completed *',
              field: 'completed',
              width: 120,
              valueGetter: (params) =>
                params.data
                  ? params.data.completed === true
                    ? 'TRUE'
                    : params.data.completed === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      // Depot
      {
        resourceURL: '/master/authority',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Name *', field: 'name', width: 200 },
            { headerName: '* Address *', field: 'address', width: 350 },
            {
              headerName: '* Type *',
              field: 'type',
              width: 100,
              cellRenderer: (params) =>
                params.value
                  ? this._resourceFieldService
                      .get('/master/authority')
                      .find((field) => field.name === 'type')
                      .options.find((option) => option.value === params.value).display
                  : '',
            },
            { headerName: 'Port - Code', field: 'port.code', width: 120 }
          );
          return defs;
        })(),
      },
      // EOR
      {
        resourceURL: '/master/component-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/contractor',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Name *', field: 'name', width: 300 },
            { headerName: '* Address *', field: 'address', width: 375 },
            { headerName: '* Contacts *', field: 'contacts', width: 375 }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/damage-category-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/damage-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/damage-location-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/repair-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/division-code',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Description *', field: 'description', width: 400 },
            {
              headerName: '* Custom *',
              field: 'custom',
              width: 150,
              valueGetter: (params) =>
                params.data
                  ? params.data.custom === true
                    ? 'TRUE'
                    : params.data.custom === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/repair-tariff',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: 'Container Authority - Code', field: 'container_authority.code', width: 90 },
            { headerName: 'Contractor - Code', field: 'contractor.code', width: 90 },
            { headerName: 'Activity - Code', field: 'activity.code', width: 90 },
            {
              headerName: '* Standard *',
              field: 'standard',
              width: 90,
              cellRenderer: (params) =>
                params.value
                  ? this._resourceFieldService
                      .get('/master/repair-tariff')
                      .find((field) => field.name === 'standard')
                      .options.find((option) => option.value === params.value).display
                  : '',
            },
            {
              headerName: '* Group *',
              field: 'group',
              width: 90,
              cellRenderer: (params) =>
                params.value
                  ? this._resourceFieldService
                      .get('/master/repair-tariff')
                      .find((field) => field.name === 'group')
                      .options.find((option) => option.value === params.value).display
                  : '',
            },
            { headerName: 'Component Code - Code', field: 'component_code.code', width: 90 },
            { headerName: 'Repair Code - Code', field: 'repair_code.code', width: 90 },
            { headerName: 'Division Code - Code', field: 'division_code.code', width: 90 },
            { headerName: '* Description *', field: 'description', width: 350 },
            {
              headerName: '* Material Rate In IDR *',
              field: 'material_rate_in_idr',
              width: 90,
              valueGetter: (params) =>
                params.data
                  ? params.data.material_rate_in_idr === true
                    ? 'TRUE'
                    : params.data.material_rate_in_idr === false
                    ? 'FALSE'
                    : null
                  : null,
            },
            {
              headerName: '* Volume Type *',
              field: 'volume_type',
              width: 90,
              cellRenderer: (params) =>
                params.value
                  ? this._resourceFieldService
                      .get('/master/repair-tariff')
                      .find((field) => field.name === 'volume_type')
                      .options.find((option) => option.value === params.value).display
                  : '',
            },
            { headerName: '* Volume Min *', field: 'volume_min', width: 90, cellStyle: { textAlign: 'right' } },
            { headerName: '* Volume Max *', field: 'volume_max', width: 90, cellStyle: { textAlign: 'right' } },
            { headerName: '* Volume Range *', field: 'volume_range', width: 90, cellStyle: { textAlign: 'right' } },
            { headerName: '* Material Rate *', field: 'material_rate', width: 90, cellStyle: { textAlign: 'right' } },
            { headerName: '* Man Hour *', field: 'man_hour', width: 90, cellStyle: { textAlign: 'right' } },
            {
              headerName: '* Volume Range Additional *',
              field: 'volume_range_additional',
              width: 90,
              cellStyle: { textAlign: 'right' },
            },
            {
              headerName: '* Material Rate Additional *',
              field: 'material_rate_additional',
              width: 90,
              cellStyle: { textAlign: 'right' },
            },
            {
              headerName: '* Man Hour Additional *',
              field: 'man_hour_additional',
              width: 90,
              cellStyle: { textAlign: 'right' },
            },
            {
              headerName: '* Active *',
              field: 'active',
              width: 90,
              valueGetter: (params) =>
                params.data
                  ? params.data.active === true
                    ? 'TRUE'
                    : params.data.active === false
                    ? 'FALSE'
                    : null
                  : null,
            }
          );
          return defs;
        })(),
      },
      {
        resourceURL: '/master/surveyor',
        columnDefs: (() => {
          const defs = cloneDeep(this._common);
          defs.splice(
            2,
            0,
            { headerName: '* Code *', field: 'code', width: 150 },
            { headerName: '* Name *', field: 'name', width: 300 },
            { headerName: '* Address *', field: 'address', width: 375 },
            { headerName: '* Contacts *', field: 'contacts', width: 375 }
          );
          return defs;
        })(),
      },
      // #endregion
    ];
  }

  // Column definitions examples....
  //
  // columnDefs: (() => {
  //   const defs = this._commonColumnDefs.slice(0);
  //   defs.splice(2, 0,
  //     {
  //       headerName: "Text",
  //       field: "text",
  //       width: 100,
  //       editable: true,
  //       cellEditor: 'text'
  //     },
  //     {
  //       headerName: "Number",
  //       field: "number",
  //       width: 100,
  //       cellRenderer: params => params.value ? (params.value * params.value).toString() : ''
  //       editable: true,
  //       cellEditorFramework: NumberEditorComponent
  //     },
  //     {
  //       headerName: "Large Text",
  //       field: "largeText",
  //       width: 175,
  //       cellRenderer: params => params.value ? params.value.toString() : ''
  //       editable: true,
  //       cellEditor: 'largeText'
  //     },
  //     {
  //       headerName: "Select",
  //       field: "select_id",
  //       width: 100,
  //       cellRenderer: params => {
  //         const selectedOption = columnDefService.getSelectedOption(params);
  //         return selectedOption ? `${columnDefService.getSelectedOption(params).name}` : '';
  //       },
  //       editable: true,
  //       cellEditorFramework: SelectEditorComponent,
  //       cellEditorParams: {
  //         valueKey: 'id',
  //         selectEditorRenderer: option => option.id ? `${option.code}| ${option.name}` : ''
  //         options: [
  //           { id: 1, name: 'Ireland', code: 'IE' },
  //           { id: 2, name: 'United Kingdoms', code: 'UK' },
  //           { id: 3, name: 'France', code: 'FR' },
  //           { id: null, name: '' code: '' },
  //         ]
  //       }
  //     },
  //     {
  //       headerName: "Multi Select",
  //       field: "multi_select_id",
  //       width: 150,
  //       cellRenderer: params => columnDefService.getSelectedOptions(params).map(option => option.name).join(', '),
  //       editable: true,
  //       cellEditorFramework: MultiSelectEditorComponent,
  //       cellEditorParams: {
  //         valueKey: 'id',
  //         selectEditorRenderer: option => option.id ? `${option.code}| ${option.name}` : ''
  //         options: [
  //           { id: 1, name: 'Ireland', code: 'IE' },
  //           { id: 2, name: 'United Kingdoms', code: 'UK' },
  //           { id: 3, name: 'France', code: 'FR' },
  //           { id: null, name: '' code: '' },
  //         ]
  //       }
  //     },
  //     {
  //       headerName: "Date",
  //       field: "date",
  //       width: 150,
  //       cellRenderer: params => params.value ? params.value.format(this._dateFormat) : ''
  //       editable: true,
  //       cellEditorFramework: DateEditorComponent
  //     },
  //     {
  //       headerName: "Datetime",
  //       field: "datetime",
  //       width: 200,
  //       cellRenderer: params => params.value ? params.value.format(this._dateTimeFormat) : ''
  //       editable: true,
  //       cellEditorFramework: DatetimeEditorComponent
  //     },
  //     {
  //       headerName: "Lookup",
  //       field: "user_id",
  //       width: 150,
  //       cellRenderer: params => params.data.user ? params.data.user.name : ''
  //       editable: true,
  //       cellEditorFramework: LookupEditorComponent,
  //       cellEditorParams: {
  //         valueKey: 'id',
  //         resourceURL: '/user',
  //         resourceFields: ['id', 'name', 'email'],
  //         getColumnDefs: () => columnDefService.get('/user')
  //       }
  //     },
  //     {
  //       headerName: "Lookup Adjacent",
  //       field: "user.email",
  //       width: 250,
  //       editable: false
  //     },
  //     {
  //       headerName: 'Popup With Additionals', field: 'dg', width: 65,
  //       cellRenderer: params => params.value === true ? 'TRUE' : params.value === false ? 'FALSE', : ''
  //       editable: true, cellEditorFramework: PopupEditorComponent,
  //       cellEditorParams: {
  //         component: DgEditorComponent,
  //         triggerField: 'dg',
  //         additionalFields: ['dg_imo_class', 'dg_un_number'],
  //       }
  //     },
  //     {
  //       headerName: 'Popup With Array Dialog', field: 'gate_out_containers', width: 200,
  //       editable: () => true || (this._crud.editorEntity.depot.authority ? false : true),
  //       cellEditorFramework: PopupEditorComponent,
  //       cellEditorParams: {
  //         component: ArrayDialogComponent,
  //         triggerField: 'gate_out_containers',
  //         dialogConfig: { width: '70vw' },
  //         sanitize: (rowData, cellValue) => {
  //           // Convert container number to uppercase
  //           rowData['gate_out_containers'] = cellValue.map(value => value.toUpperCase());
  //         },
  //         validate: (rowData, cellValue) => {
  //           // Check quantity.
  //           if (cellValue.length > rowData.quantity) return false;
  //           // Check duplicate.
  //           const allContainers = flattenDeep(this._crud.editorEntity.delivery_order_items.map(value => value.gate_out_containers));
  //           if (uniq(allContainers).length !== allContainers.length) return false;
  //           // Validation passed.
  //           return true;
  //         }
  //       }
  //     },
  //   )
  //   return defs;
  // })(),
}
