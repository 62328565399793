<div fxLayout="row wrap-reverse" fxLayoutAlign="end start" class="action-bar">
  <div #actionButtons fxLayout="row wrap" class="action-buttons" tabindex="0" fxFlex="100">
    <div fxLayout="row wrap">
      <button mat-raised-button (click)="report().subscribe()" color="primary">Report</button>
      <button mat-raised-button (click)="list().subscribe()" color="primary">List</button>
      <button mat-raised-button (click)="popUpList().subscribe()" [disabled]="dataListPoppedUp" color="primary">
        Pop Up List
      </button>
      <button mat-raised-button (click)="create().subscribe()" [disabled]="createDisabled" color="primary">
        Create
      </button>
      <button
        mat-raised-button
        (click)="read().subscribe()"
        [disabled]="readDisabled || (listMode && (noRowSelected || pivotMode)) || createMode"
        color="primary"
      >
        Read
      </button>
      <button
        mat-raised-button
        (click)="update().subscribe()"
        [disabled]="updateDisabled || (listMode && (noRowSelected || pivotMode)) || createMode"
        color="primary"
      >
        Update
      </button>
      <button
        mat-raised-button
        (click)="destroy().subscribe()"
        [disabled]="destroyDisabled || (listMode && (noRowSelected || pivotMode)) || createMode"
        color="warn"
      >
        Destroy
      </button>
      <button
        mat-raised-button
        (click)="print().subscribe()"
        [disabled]="printDisabled || (listMode && (noRowSelected || pivotMode)) || createMode"
        color="primary"
      >
        Print
      </button>
    </div>
    <div fxLayout="row wrap">
      <ng-container *ngFor="let action of actions">
        <button
          mat-raised-button
          *ngIf="!action.hide"
          (click)="performAction(action).subscribe()"
          [disabled]="!action.noResource && ((listMode && (noRowSelected || pivotMode)) || createMode)"
        >
          {{ action.name }}
        </button>
      </ng-container>
    </div>
  </div>
  <div #navActionButtons fxLayout="row" class="nav-action-buttons">
    <button
      mat-raised-button
      (click)="up().subscribe()"
      [disabled]="noRowSelected"
      color="accent"
      matTooltip="Up"
      matTooltipShowDelay="500"
      class="mat-elevation-z6"
    >
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button
      mat-raised-button
      (click)="down().subscribe()"
      [disabled]="noRowSelected"
      color="accent"
      matTooltip="Down"
      matTooltipShowDelay="500"
      class="mat-elevation-z6"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>

<mat-tab-group [selectedIndex]="selectedTab" headerPosition="below" animationDuration="0ms">
  <mat-tab label="Data List" [disabled]="true">
    <app-data-list
      #dataList
      [gridOptions]="gridOptions"
      [resourceURL]="resourceURL"
      [searchMode]="true"
      [defaultTake]="25"
      [preloadedQuery]="preloadedQuery"
      height="(100vh - 350px)"
    ></app-data-list>
  </mat-tab>

  <mat-tab label="Editor" [disabled]="true">
    <form>
      <ng-content select="[editor]"></ng-content>
      <button
        mat-raised-button
        type="button"
        (click)="doSave().subscribe()"
        [disabled]="updateMode && !editorEntity._updatable"
        color="primary"
        class="save"
      >
        {{ updateMode ? 'Save ' + resourceName + ' Changes' : 'Save New ' + resourceName }}
      </button>
    </form>
  </mat-tab>

  <mat-tab label="Reader" [disabled]="true"> <ng-content select="[reader]"></ng-content> </mat-tab>
</mat-tab-group>
