import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '../../dialog/base-dialog/base-dialog.component';
import { Report } from '../report.interface';
import { ReportService } from '../report.service';

/**
 * Dialog component to show report component.
 */
@Component({
  selector: 'app-single-resource-report',
  templateUrl: './single-resource-report.component.html',
  styleUrls: ['./single-resource-report.component.scss'],
})
export class SingleResourceReportComponent {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Resource URL.
   */
  public resourceURL: string;

  /**
   * Report names.
   */
  public reports: Report[] = [];

  /**
   * Selected report.
   */
  public selectedReport: Report;

  /**
   * Selected report format.
   */
  public selectedFormat: string;

  /**
   * Show or hide query page.
   */
  public showQueryPage: boolean = true;

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SingleResourceReportComponent>,
    private _reportService: ReportService
  ) {
    // Retrieve resourceURL.
    this.resourceURL = data.resourceURL;
    // Retrieve any available reports.
    this.reports = data.reports ? data.reports : [];

    // Initialize all report's format if not specified.
    this.reports.forEach((report) => (report.formats = report.formats || ['PDF', 'XLS', 'CSV']));
    // Initialize selected report.
    this.selectedReport = this.reports.length > 0 ? this.reports[0] : undefined;
    // Initialize selected report format.
    this.selectedFormat = this.selectedReport.formats[0];
  }

  /**
   * Show advanced search to build report query.
   */
  public buildQuery() {
    this._reportService
      .build(this.resourceURL, this.selectedReport, this.selectedFormat, this.showQueryPage)
      .subscribe();
  }

  /**
   * Close this dialog.
   */
  public close() {
    this._dialogRef.close();
  }
}
