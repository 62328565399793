import { Injectable } from '@angular/core';

import { ColDefExt } from './col-def-ext.interface';
import { ColumnDefPreset } from './column-def-preset.interface';

/**
 * AG Grid column definitions service.
 */
@Injectable({
  providedIn: 'root',
})
export class ColumnDefService {
  /**
   * Column definitions for all resources.
   */
  public presets: ColumnDefPreset[] = [];

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Get column definitions of specified resource URL.
   * @param resourceURL Resource URL to get column definitions.
   */
  public get(resourceURL: string): ColDefExt[] {
    const preset = this.presets.find((item) => item.resourceURL === resourceURL);
    return preset ? preset.columnDefs : [];
  }

  /**
   * Get the selected option from editor params' options.
   * @param params AG Grid editor parameters.
   */
  public getSelectedOption(params: any): any {
    if (!params.data) {
      return null;
    }
    const valueKey = params.colDef.cellEditorParams.valueKey;
    return params.colDef.cellEditorParams.options.find(
      (option) => option[valueKey] === params.data[params.column.colId]
    );
  }

  /**
   * Get the selected options from editor params' options.
   * @param params AG Grid editor parameters.
   */
  public getSelectedOptions(params: any): any[] {
    if (!params.data) {
      return null;
    }
    const valueKey = params.colDef.cellEditorParams.valueKey;
    return params.colDef.cellEditorParams.options.filter((option) => {
      return params.data[params.column.colId].find((value) => value === option[valueKey]) !== undefined;
    });
  }
}
