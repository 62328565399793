<app-base-dialog #dialog [dialogTitle]="title">
  <div top-toolbar></div>

  <div content>
    <div fxLayout="row wrap">
      <mat-card *ngFor="let resourceReport of resourceReports">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="primary" class="report-icon">description</mat-icon>
          {{ resourceReport.title }} Reports
        </mat-card-title>
        <div fxLayout="row wrap">
          <button
            mat-raised-button
            *ngFor="let report of resourceReport.reports"
            (click)="buildReport(resourceReport.resourceURL, report)"
            class="report-name"
          >
            {{ report.name + (report.custom ? ' (custom)' : '') }}
          </button>
        </div>
      </mat-card>
    </div>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
