<app-base-dialog #dialog [showHeader]="false">
  <div top-toolbar></div>

  <div content fxLayout="column">
    <h2 [innerHtml]="title"></h2>
    <p *ngFor="let content of contents" [innerHtml]="content | safe: 'html'"></p>

    <ng-container
      *ngTemplateOutlet="
        promptDialogType === PromptDialogType.Select
          ? selectControl
          : promptDialogType === PromptDialogType.MultiSelect
          ? multiSelectControl
          : promptDialogType === PromptDialogType.Date
          ? dateControl
          : promptDialogType === PromptDialogType.Datetime
          ? dateTimeControl
          : promptDialogType === PromptDialogType.Array
          ? arrayControl
          : promptDialogType.startsWith(PromptDialogType.Autocomplete)
          ? autocompleteControl
          : inputControl
      "
    >
    </ng-container>

    <ng-template #selectControl>
      <mat-form-field>
        <mat-select [(ngModel)]="inputValue" class="input">
          <mat-option *ngFor="let option of options" [value]="option.value">
            <span [innerHTML]="option.display"></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <ng-template #multiSelectControl>
      <mat-form-field>
        <mat-select multiple [(ngModel)]="inputValue" class="input">
          <mat-option *ngFor="let option of options" [value]="option.value">
            <span [innerHTML]="option.display"></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <ng-template #dateControl>
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="inputValue"
          [matDatepicker]="inputDatepicker"
          [appTxtMask]="{ mask: dateMask, pipe: datePipe }"
        />
        <mat-datepicker-toggle matSuffix [for]="inputDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #inputDatepicker></mat-datepicker>
      </mat-form-field>
    </ng-template>

    <ng-template #dateTimeControl>
      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field fxFlex="65">
          <input
            matInput
            [ngModel]="inputValue"
            (ngModelChange)="onTypeDateTimeInputModelChange($event)"
            [matDatepicker]="inputDatepicker"
            [appTxtMask]="{ mask: dateMask, pipe: datePipe }"
          />
          <mat-datepicker-toggle matSuffix [for]="inputDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #inputDatepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="35">
          <input
            matInput
            [ngModel]="inputValue ? inputValue.format('HH:mm:ss') : ''"
            (ngModelChange)="onTypeDateTimeInputModelChange($event)"
            [appTxtMask]="{ mask: timeMask, pipe: timePipe }"
          />
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #arrayControl>
      <mat-form-field>
        <mat-chip-list #chipList="matChipList" selectable="true" multiple="false">
          <ng-container *ngFor="let item of inputValue; let index = index">
            <mat-chip
              #chip="matChip"
              [value]="inputValue[index]"
              (removed)="chipRemove($event)"
              (keydown.enter)="chipStartEdit(chip, index)"
              fxLayout="row"
            >
              <ng-container *ngTemplateOutlet="$any(chip).edit ? editor : reader"></ng-container>

              <ng-template #editor>
                <input
                  #chipEditor
                  [value]="inputValue[index]"
                  (keydown)="onChipEditorKeydown(chip, index, $event)"
                  fxFlex
                />
              </ng-template>

              <ng-template #reader>
                <span (dblclick)="chipStartEdit(chip, index)" fxFlex>
                  {{ inputValue[index] }}
                </span>
              </ng-template>

              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>

          <input
            #chipListInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="chipSeparatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="chipAdd($event)"
            placeholder="Type new item here..."
          />
        </mat-chip-list>

        <mat-hint align="end">
          {{ chipSelectedIndex === null ? '' : chipSelectedIndex + 1 + ' of ' }}
          {{ inputValue.length + ' items' }}
        </mat-hint>
      </mat-form-field>
    </ng-template>

    <ng-template #autocompleteControl>
      <mat-form-field>
        <input #autocompleteInput matInput [matAutocomplete]="guidedValueAutocomplete" [(ngModel)]="inputValue" />
        <mat-autocomplete
          #guidedValueAutocomplete="matAutocomplete"
          [displayWith]="autocompleteValueDisplayWith.bind(this)"
        >
          <mat-option *ngFor="let item of autocompleteValueItems | async" [value]="item">{{
            autocompleteValueDisplayWith(item)
          }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>

    <ng-template #inputControl>
      <mat-form-field>
        <input matInput [type]="promptDialogType" [(ngModel)]="inputValue" />
      </mat-form-field>
    </ng-template>
  </div>

  <div bottom-toolbar></div>

  <div action-bar>
    <button mat-raised-button color="primary" (click)="cancel()">{{ cancelLabel }}</button>
    <button mat-raised-button color="primary" (click)="ok()">{{ okLabel }}</button>
  </div>
</app-base-dialog>
