<app-base-dialog #dialog [showHeader]="false">
  <div top-toolbar></div>

  <div content>
    <h2>{{ isAtWhereNode ? '@' : '' }} Node Setting</h2>

    <!-- where-field-setting -->
    <div
      *ngIf="isAtWhereNode || (node.comp && node.comp.fieldNode && node.comp.whereNode)"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <mat-form-field *ngIf="!isAtWhereNode">
        <mat-label>Boolean</mat-label>
        <mat-select [(ngModel)]="node.b">
          <mat-option value="and">AND</mat-option>
          <mat-option value="or">OR</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Relation</mat-label>
        <input matInput [value]="getRelationName()" [disabled]="true" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Field</mat-label>
        <mat-select #fieldSelect [(ngModel)]="node.f" (selectionChange)="onFieldChange()">
          <mat-option *ngFor="let field of fields" [value]="field.name" [disabled]="!field.searchable">
            {{ renderFieldName(field.name) }} {{ field.custom ? '(custom)' : '' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Operator</mat-label>
        <mat-select [(ngModel)]="node.o" (selectionChange)="onOperatorChange()">
          <mat-option *ngFor="let operator of operators" [value]="operator.value">{{ operator.display }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="suggestionOptionsAvailable && !isNULLOperator && !isDateTimeField">
        <mat-label>Suggestion (optional)</mat-label>
        <mat-select [(ngModel)]="node.v" (ngModelChange)="node.temp = undefined" *ngIf="!isINOperator">
          <mat-option *ngFor="let option of suggestionOptions" [value]="option.value">{{ option.display }}</mat-option>
        </mat-select>
        <mat-select
          [ngModel]="getINSelectValue()"
          (ngModelChange)="onINSelectModelChange($event)"
          *ngIf="isINOperator"
          multiple
        >
          <mat-option *ngFor="let option of suggestionOptions" [value]="option.value">{{ option.display }}</mat-option>
          <mat-select-trigger>
            <span>{{ node.v }}</span>
          </mat-select-trigger>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="useGuidedValue && !isNULLOperator">
        <mat-label>Value (guided)</mat-label>
        <input
          matInput
          #valueInput
          [matAutocomplete]="guidedValueAutocomplete"
          [ngModel]="node.v"
          (ngModelChange)="onGuidedValueModelChange($event, node)"
          placeholder="Type search keyword to get options"
        />
        <mat-autocomplete
          #guidedValueAutocomplete="matAutocomplete"
          (optionSelected)="onGuidedValueOptionSelected($event, node, valueInput)"
          [displayWith]="valueDisplayWith"
        >
          <mat-option *ngFor="let item of valueItems$ | async" [value]="item">{{ valueDisplayWith(item) }}</mat-option>
        </mat-autocomplete>
        <mat-hint align="start" *ngIf="node.o === 'like' || node.o === 'not like'">
          % : 0, 1, or more characters <br />
          _ &nbsp;: 1 character
        </mat-hint>
        <mat-hint align="start" *ngIf="node.o === 'in' || node.o === 'not in'"> | : value separator </mat-hint>
        <mat-hint align="start" *ngIf="node.o === 'between' || node.o === 'not between'">
          | : value separator with maximum of 2 values
        </mat-hint>
      </mat-form-field>

      <div *ngIf="!useGuidedValue" fxlayout="row" fxLayoutAlign="start center">
        <mat-form-field *ngIf="!isNULLOperator" fxFlex>
          <mat-label>Value</mat-label>
          <input matInput *ngIf="!isDateTimeField" [(ngModel)]="node.v" placeholder="Type search value" />
          <input matInput *ngIf="isDateTimeField" [(ngModel)]="node.v" placeholder="Select a date or date range" />
          <mat-hint align="start" *ngIf="node.o === 'like' || node.o === 'not like'">
            % : 0, 1, or more characters <br />
            _ &nbsp;: 1 character
          </mat-hint>
          <mat-hint align="start" *ngIf="node.o === 'in' || node.o === 'not in'"> | : value separator </mat-hint>
          <mat-hint align="start" *ngIf="node.o === 'between' || node.o === 'not between'">
            | : value separator with maximum of 2 values
          </mat-hint>
        </mat-form-field>

        <button
          mat-icon-button
          *ngIf="!isNULLOperator && isDateTimeField"
          (click)="showDateTimePrompt()"
          color="primary"
        >
          <mat-icon>today</mat-icon>
        </button>
      </div>
    </div>

    <!-- order-field-setting -->
    <div
      *ngIf="node.comp && node.comp.fieldNode && node.comp.orderNode"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <mat-form-field>
        <mat-label>Field</mat-label>
        <mat-select [(ngModel)]="node.f">
          <mat-option *ngFor="let field of fields" [value]="field.name" [disabled]="!field.searchable">{{
            renderFieldName(field.name)
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Order</mat-label>
        <mat-select [(ngModel)]="node.o">
          <mat-option value="asc">ASC</mat-option>
          <mat-option value="desc">DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- where-group-setting -->
    <div
      *ngIf="node.comp && node.comp.groupNode && !node.comp.whereHasGroupNode"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <mat-form-field>
        <mat-label>Boolean</mat-label>
        <mat-select [(ngModel)]="node.b">
          <mat-option value="and">AND</mat-option>
          <mat-option value="or">OR</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- where-has-group-setting -->
    <div *ngIf="node.comp && node.comp.whereHasGroupNode" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
        <mat-label>Boolean</mat-label>
        <mat-select [(ngModel)]="node.b">
          <mat-option value="and">AND</mat-option>
          <mat-option value="or">OR</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngFor="let occurrence of hasOccurences" fxLayout="row">
        <mat-form-field fxFlex="17">
          <mat-label>Operator</mat-label>
          <mat-select [(ngModel)]="occurrence.c.o">
            <mat-option *ngFor="let operator of hasOperators" [value]="operator.value">{{
              operator.display
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="17">
          <mat-label>Number</mat-label>
          <input matInput [(ngModel)]="occurrence.c.n" type="number" min="0" />
        </mat-form-field>
        <mat-form-field fxFlex="66">
          <mat-label>Relation Name</mat-label>
          <input matInput [(ngModel)]="occurrence.r" [disabled]="true" />
        </mat-form-field>
      </div>

      <mat-checkbox [(ngModel)]="node.a" (change)="onGrabAnyChange($event)">
        GRAB ANY {{ hasOccurences.slice(-1)[0].r.toUpperCase() }}
      </mat-checkbox>
    </div>
  </div>

  <div bottom-toolbar></div>

  <div action-bar><button mat-raised-button color="primary" (click)="close()">Close</button></div>
</app-base-dialog>
