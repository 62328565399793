import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  cloneDeep,
  cloneDeepWith,
  differenceBy,
  escapeRegExp,
  flatten,
  isEmpty,
  mergeWith,
  pullAllBy,
  sortBy,
} from 'lodash-es';
import { merge, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { QueryPresetService } from '../../core/presets/query-preset.service';
import { BaseDialogComponent } from '../dialog/base-dialog/base-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { ResourceFieldService } from '../resource-field/resource-field.service';
import { HelperService } from '../services/helper.service';
import { DateRangeComponent } from './date-range/date-range.component';
import { GroupNode } from './group-node.interface';
import { Mode } from './mode.enum';
import { NodeSettingComponent } from './node-setting/node-setting.component';
import { NodeComponent } from './node/node.component';
import { QAdv } from './q-adv.interface';
import { QueryService } from './query.service';
import { Searchable } from './searchable.interface';
import { WhereFieldNode } from './where-field-node.interface';

/**
 * Advanced search component.
 */
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit, AfterViewInit {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: ElementRef, static: true })
  public dialogEl: ElementRef;

  /**
   * Root nodes.
   */
  @ViewChildren('rootNode', { read: NodeComponent })
  private _rootNodes: QueryList<NodeComponent>;

  /**
   * Filter Favourite Searchable trigger.
   */
  @ViewChild('filterFavSearchableInput', { read: MatAutocompleteTrigger })
  private _filterFavSearchableTrigger: MatAutocompleteTrigger;

  /**
   * Filter Searchable autocomplete.
   */
  @ViewChild('filterSearchableInput', { read: MatAutocompleteTrigger })
  private _filterSearchableTrigger: MatAutocompleteTrigger;

  /**
   * Filter Favourite Searchable input.
   */
  @ViewChild('filterFavSearchableInput', { read: MatInput })
  private _filterFavSearchableInput: MatInput;

  /**
   * Filter Searchable input.
   */
  @ViewChild('filterSearchableInput', { read: MatInput })
  private _filterSearchableInput: MatInput;

  /**
   * Selected Preset select.
   */
  @ViewChild('selectedPresetSelect', { read: MatSelect })
  private _selectedPresetSelect: MatSelect;

  /**
   * Resource URL.
   */
  public resourceURL: string;

  /**
   * Advanced query.
   */
  public qAdv: QAdv;

  /**
   * Cloned advanced query used on workspace.
   */
  public qAdvWorkspace: QAdv;

  /**
   * Default qAdv preset name used when user reset the qAdvWorkspace.
   */
  public defaultQAdvPresetName: string;

  /**
   * Array of qAdv presets
   */
  public qAdvPresets: QAdv[];

  /**
   * Selected preset qAdv.
   */
  public selectedQAdvPreset: QAdv;

  /**
   * Current selected where has tab.
   */
  public currentWhereHasTab: string;

  /**
   * All available searchables.
   */
  public searchables: Searchable[];

  /**
   * Favourite searchables.
   */
  public favSearchables: Searchable[];

  /**
   * Observable of filtered favourite searchables.
   */
  public filteredFavSearchables: Observable<Searchable[]>;

  /**
   * Observable of filtered searchables.
   */
  public filteredSearchables: Observable<Searchable[]>;

  /**
   * Keyword used to filter favourite searchables.
   */
  public favSearchableFilterKeyword: string | Searchable = '';

  /**
   * Keyword used to filter searchables.
   */
  public searchableFilterKeyword: string | Searchable = '';

  /**
   * Selected mode tab index. (0: Favourite, 1: Builder).
   */
  public selectedModeTabIndex = 0;

  /**
   * Show selected fields (favourite mode) or selected whereHases (builder mode)
   */
  public showSelectedOnly = false;

  /**
   * Current qAdv mode is favourite mode.
   */
  public get favouriteMode() {
    return this.qAdvWorkspace.mode === Mode.Favourite;
  }

  /**
   * Current qAdv mode is builder mode.
   */
  public get builderMode() {
    return this.qAdvWorkspace.mode === Mode.Builder;
  }

  /**
   * Current qAdv mode is custom mode.
   */
  public get customMode() {
    return this.qAdvWorkspace.mode === Mode.Custom;
  }

  /**
   * Get a proxied searchable option rendering function allowing 'this' to reference AdvancedSearchComponent.
   */
  public get searchableDisplayFn(): (option: Searchable) => string {
    return (option) => option?.display;
  }

  /**
   * Constructor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AdvancedSearchComponent>,
    private _dialogService: DialogService,
    private _helperService: HelperService,
    private _matDialog: MatDialog,
    private _queryPresetService: QueryPresetService,
    private _queryService: QueryService,
    private _resourceFieldService: ResourceFieldService
  ) {
    this.resourceURL = data.resourceURL;
    this.qAdv = data.qAdv;
    this.defaultQAdvPresetName = data.defaultQAdvPresetName || this._queryService.DEFAULT_QADV_PRESET_NAME;
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit() {
    // Load qAdv presets.
    this._loadPresets();

    // Build all available searchables.
    this.searchables = flatten(
      [{ resourceURL: this.resourceURL, relation: 'this' }]
        .concat(this._queryPresetService.getWhereHases(this.resourceURL))
        .map((whereHas) => {
          return this._resourceFieldService
            .get(whereHas.resourceURL)
            .filter((field) => field.searchable)
            .map((field) => {
              return {
                relation: whereHas.relation,
                resourceURL: whereHas.resourceURL,
                field: field,
                display: null,
                guidedValueItems: undefined,
                node: {} as WhereFieldNode,
                parentNode: {} as GroupNode<WhereFieldNode>,
              };
            })
            .map((searchable) => {
              searchable.display = this.renderRelationAndFieldName(searchable).toLowerCase();
              return searchable;
            });
        })
    );

    // Build favourite searchables.
    this.favSearchables = this.searchables.filter((searchable) => searchable.field.fav);

    // Initialize to default state.
    this.reset(true);
  }

  /**
   * Angular lifecycle hook.
   */
  public ngAfterViewInit() {
    const filtered = (input: MatInput, searchables: Searchable[]) => {
      return merge(input.ngControl.valueChanges, this._selectedPresetSelect.selectionChange.pipe(map(() => ''))).pipe(
        // Allow autocomplete to show options at first time focus.
        startWith(''),
        debounceTime(100),
        map((val) => {
          if (typeof val !== 'string') {
            return searchables.slice();
          }

          const keyword = val.toLowerCase();
          const re = new RegExp(escapeRegExp('¦' + keyword.split('').join('¦') + '¦').replace(/¦/g, '.*'));

          return searchables.filter((searchable) => {
            try {
              return (
                re.test(searchable.display) && (this.customMode ? searchable.field.custom : !searchable.field.custom)
              );
            } catch (error) {
              return false;
            }
          });
        })
      );
    };

    // Build filtered fav searchables.
    this.filteredFavSearchables = filtered(this._filterFavSearchableInput, this.favSearchables);

    // Build filtered searchables.
    this.filteredSearchables = filtered(this._filterSearchableInput, this.searchables);
  }

  /**
   * Close the dialog.
   */
  public close() {
    this._dialogRef.close(false);
  }

  /**
   * Close the dialog and return qAdvWorkspace.
   */
  public search() {
    // Close any opened NodeSettingComponent or DateRangeComponent dialog to avoid dangling dialog on search shortcut pressed.
    this._matDialog.openDialogs
      .filter(
        (dialogRef) =>
          dialogRef.componentInstance instanceof NodeSettingComponent ||
          dialogRef.componentInstance instanceof DateRangeComponent
      )
      .map((dialogRef) => dialogRef.componentInstance)
      .forEach((component) => component.close());

    // Add delay to let the opened dialog to be properly closed and the node's value is properly set.
    setTimeout(() => {
      const clonedQAdvWorkspace: QAdv = cloneDeepWith(
        this.qAdvWorkspace,
        // Remove NodeComponent and Searchable references from qAdv because
        // NodeComponent and Searchable have node property which references
        // to a qAdv node and thus will cause circular reference error.
        (value, key) => (['comp', 'searchable'].includes(key as string) ? null : undefined)
      );

      // Save qAdv workspace as last used preset.
      this._queryService.saveAsQAdvPreset(
        this.resourceURL,
        clonedQAdvWorkspace.name.startsWith(this._queryService.LAST_USED_QADV_PRESET_NAME)
          ? clonedQAdvWorkspace.name
          : this._queryService.LAST_USED_QADV_PRESET_NAME + ` — ${clonedQAdvWorkspace.name}`,
        clonedQAdvWorkspace
      );

      // Close the dialog and return qAdvWorkspace.
      this._dialogRef.close(
        // We clone qAdv workspace again so that qAdv stored as last
        // used preset and as data list's qAdv are separate
        // objects and can be modified indepenently.
        cloneDeep(clonedQAdvWorkspace)
      );
    }, 250);
  }

  /**
   * Save qAdv as preset.
   */
  public save() {
    this._dialogService.openPromptDialog(
      'Save As Preset',
      ['Please give this preset a name. Already saved preset with same name will be overwritten.'],
      (result) => {
        // Return if canceled.
        if (!result) {
          return;
        }

        // Save qAdv workspace as preset.
        this._queryService.saveAsQAdvPreset(
          this.resourceURL,
          <string>result,
          cloneDeepWith(
            this.qAdvWorkspace,
            // Remove all NodeComponent reference variable from qAdv because
            // NodeComponent has node property which references to a qAdv
            // node and thus will cause circular reference error.
            (value) => (value instanceof NodeComponent ? null : undefined)
          )
        );

        // Reload presets.
        this._loadPresets();

        // Select just saved preset.
        this._selectPreset(<string>result);
      },
      null,
      this._queryService.DEFAULT_CUSTOM_QADV_PRESET_NAME
    );
  }

  /**
   * Delete qAdv preset.
   */
  public delete() {
    this._dialogService.openConfirmDialog(
      'Are you sure want to delete this preset?',
      ['Preset will be permanently deleted and can not be restored. Default preset can not be deleted.'],
      (result) => {
        // Return if canceled.
        if (!result) {
          return;
        }

        // Delete selected preset.
        this._queryService.deleteQAdvPreset(
          this.resourceURL,
          this.qAdvPresets.find((item) => item === this.selectedQAdvPreset).name
        );

        // Reload presets.
        this._loadPresets();

        // Reset to default state.
        this.reset();
      }
    );
  }

  /**
   * Export all saved qAdv presets from local storage.
   */
  public export() {
    this._queryService.export();
  }

  /**
   * Import provided qAdv presets into local storage.
   */
  public import() {
    this._queryService.import(() => this.ngOnInit());
  }

  /**
   * Favourite Searchable option selected handler.
   * @param event Autocomplete option selected event.
   */
  public favSearchableOptionSelected(event: MatAutocompleteSelectedEvent) {
    const searchable: Searchable = event.option.value;

    if (!searchable.favNode.trigger.checked) {
      searchable.favNode.toggle();
    } else {
      searchable.favNode.focus();
    }

    setTimeout(() => {
      // Clear the input for next filtering.
      this.favSearchableFilterKeyword = '';
    });
  }

  /**
   * Searchable option selected handler.
   * @param event Autocomplete option selected event.
   */
  public searchableOptionSelected(event: MatAutocompleteSelectedEvent) {
    const relation = event.option.value.relation;
    const field = event.option.value.field.name;

    // Change selected whereHas tab.
    this.currentWhereHasTab = relation;

    // Create where field node.
    const whereFieldNode = NodeComponent.createWhereFieldNode('and', field, '=');

    // Push the created node.
    if (relation === 'this') {
      this.qAdvWorkspace.w.g.push(whereFieldNode);
    } else {
      this.qAdvWorkspace.h.find((h) => h.r === this.currentWhereHasTab).g.push(whereFieldNode);
    }

    setTimeout(() => {
      // Clear the input for next filtering.
      this.searchableFilterKeyword = '';
      // Open node setting.
      whereFieldNode.comp.setting();
    });
  }

  /**
   * Check whether the favSearchable is shown or not.
   * @param favSearchable favSearchable being checked.
   */
  public isFavSearchableShown(favSearchable: Searchable): boolean {
    return (
      (favSearchable.node.i !== undefined || !this.showSelectedOnly) &&
      (this.customMode ? favSearchable.field.custom : !favSearchable.field.custom)
    );
  }

  /**
   * Check whether the whereHas is shown or not.
   * @param whereHas whereHas being checked.
   */
  public isWhareHasShown(whereHas: any): boolean {
    if (this.favSearchableFilterKeyword == '' && !this.showSelectedOnly) {
      return true;
    } else {
      return whereHas.g.length > 0 || whereHas.a === true;
    }
  }

  /**
   * Search field's relation name and field name renderer.
   * @param searchable Searchable to be rendered.
   */
  public renderRelationAndFieldName(searchable: Searchable): string {
    return searchable
      ? this._helperService.renderRelationName(searchable.relation) +
          ' - ' +
          this._helperService.renderFieldName(searchable.field.name) +
          (searchable.field.custom ? ' (custom) ' : '')
      : '';
  }

  /**
   * Handle shortcut keydown event using document as the event target.
   * @param event Keyboard event.
   */
  @HostListener('document:keydown', ['$event'])
  public onShortcutKeydown(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey) {
      switch (event.key) {
        case 'K':
          this.selectedModeTabIndex = 0;
          return;

        case 'L':
          this.selectedModeTabIndex = 1;
          return;

        case 'S':
          this.showSelectedOnly = !this.showSelectedOnly;
          return;

        default:
          break;
      }
    }

    if (event.ctrlKey) {
      switch (event.key) {
        case 'Enter':
          this.search();
          return;

        case 'Delete':
          this.reset();
          return;

        default:
          break;
      }
    }

    switch (event.key) {
      case 'F2':
        if (this.builderMode) {
          this._filterSearchableInput.focus();
        } else {
          this._filterFavSearchableInput.focus();
        }
        return;

      default:
        break;
    }
  }

  /**
   * On relations button toggle change handler.
   */
  public onRelationButtonToggleChange() {
    // Select selected tab's root group node.
    setTimeout(() => {
      this._rootNodes.toArray()[0].select();
    });
  }

  /**
   * On Favourite and Builder tab change.
   */
  public onSelectedModeTabChange(tabIndex: number) {
    this.favSearchableFilterKeyword = '';
    this.searchableFilterKeyword = '';
    this._filterSearchableTrigger.closePanel();
    this._filterFavSearchableTrigger.closePanel();

    setTimeout(() => {
      // Store selected mode.
      this.selectedModeTabIndex = tabIndex;
      this.qAdvWorkspace.mode = tabIndex === 0 ? Mode.Favourite : Mode.Builder;

      // Builder mode always be able to display favourite mode query,
      // but not the other way around, hence we need to reset
      // to default state when we are going to favourite mode.
      if (tabIndex === 0) {
        this.reset();
      } else {
        this._selectDefaultWhereTab();
        this._setQAdvWorkspaceMode(this.qAdvWorkspace);
      }

      // Set focus back to main wrapper (Base Dialog) to enable proper keyboard shorcut handling.
      this.dialogEl.nativeElement.focus();
    });
  }

  /**
   * Render relation name.
   * @param relation The relation name to be beautified.
   * @return Beautified relation name.
   */
  public renderRelationName(relationName: string): string {
    return this._helperService.renderRelationName(relationName);
  }

  /**
   * Activate or deactivate favSearchable.
   * @param change Checked state change event.
   * @param favSearchable favSearchable object.
   * @param control Control related to favSearchable.
   */
  public toggleFavSearchable(change: MatCheckboxChange, favSearchable: Searchable) {
    if (change.checked) {
      // Create where field node.
      const whereFieldNode = NodeComponent.createWhereFieldNode(
        // For custom mode, use fixed boolean value which is set with value as defined in query preset.
        favSearchable.fixedBoolean || 'and',
        favSearchable.field.name,
        '='
      );

      // Reference each other between node and searchable.
      favSearchable.node = whereFieldNode;
      whereFieldNode.searchable = favSearchable;

      // Push the created node.
      if (isEmpty(favSearchable.parentNode)) {
        // Push the node as root node's child. (without nested group)
        if (favSearchable.relation === 'this') {
          this.qAdvWorkspace.w.g.push(whereFieldNode);
        } else {
          this.qAdvWorkspace.h.find((h) => h.r === favSearchable.relation).g.push(whereFieldNode);
        }
      } else {
        // Push the node directly to its parent node as defined in query preset. (allowing nested group)
        favSearchable.parentNode.g.push(whereFieldNode);
      }
    } else {
      // Remove the node from qAdv.
      if (isEmpty(favSearchable.parentNode)) {
        // Remove the node only from the root node. (without nested group)
        if (favSearchable.relation === 'this') {
          const index = this.qAdvWorkspace.w.g.findIndex((node) => node.i === favSearchable.node.i);
          this.qAdvWorkspace.w.g.splice(index, 1);
        } else {
          const h = this.qAdvWorkspace.h.find((item) => item.r === favSearchable.relation);
          const index = h.g.findIndex((node) => node.i === favSearchable.node.i);
          h.g.splice(index, 1);
        }
      } else {
        // Remove the node directly from its parent node as defined in query preset. (allowing nested group)
        const index = favSearchable.parentNode.g.findIndex((node) => node.i === favSearchable.node.i);
        favSearchable.parentNode.g.splice(index, 1);
      }

      // Clear favSearchable's node reference.
      favSearchable.node = {} as WhereFieldNode;
    }
  }

  /**
   * Reset to default state.
   */
  public reset(init: boolean = false) {
    if (init && this.qAdv) {
      this.selectedModeTabIndex = this.qAdv.mode === Mode.Builder ? 1 : 0;
      this.selectedQAdvPreset =
        this.qAdv.name === undefined ? null : this.qAdvPresets.find((qAdvPreset) => qAdvPreset.name === this.qAdv.name);
      this.setQAdvWorkspace(this.qAdv);
    } else {
      this._selectPreset(this.defaultQAdvPresetName);
    }

    // Apply provided Custom mode qAdv to qAdvWorkspace.
    if (init && this.qAdv && this.qAdv.mode === Mode.Custom) {
      this._mergeCustomQAdv(this.qAdvWorkspace, this.qAdv);
    }
  }

  /**
   * Merge custom qAdv into qAdvWorkspace.
   * @param object The destination object for merging
   * @param source The source object.
   */
  private _mergeCustomQAdv(object, source) {
    mergeWith(object, source, (objValue, srcValue, key, object, source, stack) => {
      if (key == 'searchable') {
        // Skip merging already hydrated FavSearchable object by returning the destination object.
        return objValue;
      }

      if (key == 'g') {
        // Sort before merging 'g' because merging an array is done using array
        // index as the key during item matching. We also sort by 'i' to
        // accomodate group node which doesn't have 'f' field.
        objValue = sortBy(objValue, ['f', 'i']);
        srcValue = sortBy(srcValue, ['f', 'i']);

        // Programatically unchecking FavSearchables according to provided qAdv.
        // It happens when there're different amount of 'g' items from the
        // default qAdv preset, meaning there're missing WhereFieldNodes.
        if (objValue.length != srcValue.length) {
          const missings: WhereFieldNode[] = differenceBy<WhereFieldNode, WhereFieldNode>(objValue, srcValue, 'f');

          // Remove missing nodes from destination object.
          pullAllBy(objValue, missings, 'f');

          // Clear corresponding unchecked FavSearchables' node reference.
          for (const missing of missings) {
            missing.searchable.node = {} as WhereFieldNode;
          }
        }

        // We need to perform another recursive merge here because parent mergeWith will
        // not perform recursive merge on object returned from this callback and we
        // could still have many nested fields to merge wrapped inside another 'g'.
        this._mergeCustomQAdv(objValue, srcValue);

        return objValue;
      }
    });
  }

  /**
   * Set qAdvWorkspace using provided QAdv.
   * @param selectedQAdvPreset Selected qAdv preset.
   */
  public setQAdvWorkspace(selectedQAdvPreset: QAdv) {
    if (this.selectedModeTabIndex === 0) {
      // Favourite tab...

      // Clear all favSearchables' node reference.
      this._clearFavSearchables();

      if (selectedQAdvPreset.mode == undefined || selectedQAdvPreset.mode === Mode.Favourite) {
        // Favourite mode...

        // Deep clone the default qAdv preset to workspace. We use default preset
        // as new slate and clone nodes from selected preset into it because
        // we can only use nodes which are set as favourite.
        this.qAdvWorkspace = <QAdv>(
          cloneDeep(this.qAdvPresets.find((item) => item.name === this._queryService.DEFAULT_QADV_PRESET_NAME))
        );

        this.qAdvWorkspace.name = selectedQAdvPreset.name;

        // Clone nodes from the selected qAdv preset into the new
        // slate and reference it from the coresponding favSearchables.
        for (const node of selectedQAdvPreset.w.g as WhereFieldNode[]) {
          for (const favSearchable of this.favSearchables) {
            if (
              favSearchable.relation === 'this' &&
              !favSearchable.field.custom &&
              favSearchable.field.name === node.f
            ) {
              favSearchable.node = cloneDeep(node);
              this.qAdvWorkspace.w.g.push(favSearchable.node);
            }
          }
        }
        for (const h of selectedQAdvPreset.h) {
          for (const node of h.g as WhereFieldNode[]) {
            for (const favSearchable of this.favSearchables) {
              if (favSearchable.relation === h.r && favSearchable.field.name === node.f) {
                favSearchable.node = cloneDeep(node);
                this.qAdvWorkspace.h.find((wsh) => wsh.r === favSearchable.relation).g.push(favSearchable.node);
              }
            }
          }
        }
        this.qAdvWorkspace.o = cloneDeep(selectedQAdvPreset.o);
      } else if (selectedQAdvPreset.mode === Mode.Custom) {
        // Custom mode...

        // Deep clone the selected qAdv preset to workspace.
        this.qAdvWorkspace = <QAdv>cloneDeep(selectedQAdvPreset);

        // Hydrate favSearchables based on custom qAdvWorkspace.
        this._hydrateFavSearchables();
      } else {
        // Builder mode is not supported on Favourite tab.
      }
    } else {
      // Builder tab...

      // Deep clone the selected qAdv preset to workspace.
      this.qAdvWorkspace = <QAdv>cloneDeep(selectedQAdvPreset);
    }

    // Set current mode in workspace.
    this._setQAdvWorkspaceMode(selectedQAdvPreset);

    // Show only selected fields when non-default query preset is selected or qAdv is provided as preloaded.
    if (selectedQAdvPreset?.name !== this._queryService.DEFAULT_QADV_PRESET_NAME || this.data.qAdv) {
      this.showSelectedOnly = true;
    }

    // Select default where tab if it's on builder mode.
    if (this.selectedModeTabIndex === 1) {
      this._selectDefaultWhereTab();
    }
  }

  /**
   * Set current mode in workspace.
   * @param selectedQAdvPreset Selected qAdv preset.
   */
  private _setQAdvWorkspaceMode(selectedQAdvPreset: QAdv) {
    if (this.selectedModeTabIndex === 0) {
      // Favourite tab...
      if (selectedQAdvPreset?.mode == undefined || selectedQAdvPreset.mode === Mode.Favourite) {
        this.qAdvWorkspace.mode = Mode.Favourite;
      } else if (selectedQAdvPreset.mode === Mode.Custom) {
        this.qAdvWorkspace.mode = Mode.Custom;
      } else {
        // Builder mode is not supported on Favourite tab.
        this.qAdvWorkspace.mode = undefined;
      }
    } else {
      // Builder tab...
      if (selectedQAdvPreset?.mode == undefined || selectedQAdvPreset.mode === Mode.Builder) {
        this.qAdvWorkspace.mode = Mode.Builder;
      } else if (selectedQAdvPreset.mode === Mode.Custom) {
        this.qAdvWorkspace.mode = Mode.Custom;
      } else {
        // Favourite mode is not supported on Builder tab.
        this.qAdvWorkspace.mode = undefined;
      }
    }
  }

  /**
   * Populate favourite searchables' control based on qAdv.
   */
  private _hydrateFavSearchables() {
    this.favSearchables.forEach((favSearchable) => {
      if (favSearchable.relation === 'this') {
        this._linkWhereFieldNodeToFavSearchable(this.qAdvWorkspace.w, this.qAdvWorkspace.w.g, favSearchable);
      } else {
        const h = this.qAdvWorkspace.h.find((item) => item.r === favSearchable.relation);
        this._linkWhereFieldNodeToFavSearchable(h, h.g, favSearchable);
      }
    });
  }

  /**
   * Link WhereFieldNode and its parent node to FavSearchable.
   * By also linking the parent node, we are allowed to directly insert
   * or remove WhereFieldNode to or from nested group via FavSearchable.
   * @param parentNode Parent node of the target WhereFieldNode to be linked.
   * @param childNodes Array of target WhereFieldNodes one match of which is going to be linked.
   * @param favSearchable FavSearchable to be linked to.
   */
  private _linkWhereFieldNodeToFavSearchable(
    parentNode: GroupNode<WhereFieldNode>,
    childNodes: (WhereFieldNode | GroupNode<WhereFieldNode>)[],
    favSearchable: Searchable
  ) {
    childNodes.forEach((node) => {
      const groupNode = (node as any).g ? (node as GroupNode<WhereFieldNode>) : null;
      const whereFieldNode = (node as any).f ? (node as WhereFieldNode) : null;

      if (whereFieldNode) {
        if (favSearchable.field.name == whereFieldNode.f) {
          whereFieldNode.searchable = favSearchable;
          favSearchable.node = whereFieldNode;
          favSearchable.parentNode = parentNode;
          if (this.customMode) {
            // Remember field's 'b' as defined in the preset and we'll use it when toggling checkbox.
            favSearchable.fixedBoolean = whereFieldNode.b;
          }
        }
      } else {
        // Recursively link nested group node.
        this._linkWhereFieldNodeToFavSearchable(groupNode, groupNode.g, favSearchable);
      }
    });
  }

  /**
   * Select qAdv preset by name.
   * @param name qAdv preset name.
   */
  private _selectPreset(name: string = this._queryService.DEFAULT_QADV_PRESET_NAME) {
    const found =
      this.qAdvPresets.find((item) => item.name === name) ||
      this.qAdvPresets.find((item) => item.name === this._queryService.DEFAULT_QADV_PRESET_NAME);
    this.selectedQAdvPreset = found;
    this.setQAdvWorkspace(found);
  }

  /**
   * Clear all favSearchables (node reference).
   */
  private _clearFavSearchables() {
    if (this.favSearchables && this.favSearchables.length > 0) {
      this.favSearchables.forEach((favSearchable) => {
        favSearchable.node = {} as WhereFieldNode;
      });
    }
  }

  /**
   * Select the first selectable where tab and it's root group node.
   */
  private _selectDefaultWhereTab() {
    // Set selected whereHas tab.
    if (this.isWhareHasShown(this.qAdvWorkspace.w)) {
      this.currentWhereHasTab = 'this';
    } else {
      const firstSelectableWhereHas = this.qAdvWorkspace.h.find((h) => this.isWhareHasShown(h));
      if (!firstSelectableWhereHas) {
        return;
      }
      this.currentWhereHasTab = firstSelectableWhereHas.r;
    }

    // Select the selected where tab's root group node.
    setTimeout(() => {
      if (this._rootNodes.first) {
        this._rootNodes.first.select();
      }
    });
  }

  /**
   * Load qAdv presets.
   */
  private _loadPresets() {
    this.qAdvPresets = this._queryService.getQAdvPresets(this.resourceURL);
  }
}
