<app-base-dialog
  #dialog
  (keydown)="onKeydown($event)"
  [dialogTitle]="'Lookup ' + resourceURL.substring(1).split('?')[0].split('-').join(' ').split('/').join(' - ')"
>
  <div top-toolbar></div>

  <div content>
    <mat-card>
      <app-data-list
        #datalist
        [rowSelection]="rowSelection"
        [gridOptions]="gridOptions"
        [resourceURL]="resourceURL"
        [searchMode]="true"
        [preloadedQuery]="preloadedQuery"
        [height]="dataListHeight"
      ></app-data-list>
    </mat-card>
  </div>

  <div bottom-toolbar></div>

  <div action-bar>
    <button mat-raised-button color="primary" (click)="clear()">Clear</button>
    <button mat-raised-button color="primary" (click)="select()">Select</button>
  </div>
</app-base-dialog>
