import { HttpEventType } from '@angular/common/http';
import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, mergeMap, tap } from 'rxjs/operators';

import { HelperService } from '../services/helper.service';
import { HttpRestService } from '../services/http-rest.service';

@Component({
  selector: 'app-card-pdf',
  templateUrl: './card-pdf.component.html',
  styleUrls: ['./card-pdf.component.scss'],
  exportAs: 'cardPdf',
})
export class CardPdfComponent implements OnInit, OnDestroy {
  /**
   * Custom template for card header.
   */
  @ContentChild('header', { read: TemplateRef })
  public header: TemplateRef<any>;

  /**
   * PdfJsViewerComponent.
   */
  @ViewChild('pdfViewer', { read: PdfJsViewerComponent })
  public pdfViewer: PdfJsViewerComponent;

  /**
   * Fixed target width in pixel.
   */
  @Input()
  public width: number;

  /**
   * Fixed target height in pixel.
   */
  @Input()
  public height: number;

  /**
   * Title.
   */
  @Input()
  public title: string;

  /**
   * Filename without extension.
   */
  @Input()
  public filename: string;

  /**
   * GET URL.
   */
  @Input()
  public getURL: string;

  /**
   * PUT URL.
   */
  @Input()
  public putURL: string;

  /**
   * PUT form key.
   */
  @Input()
  public putKey: string;

  /**
   * Disabled flag.
   */
  @Input()
  public disabled: boolean;

  /**
   * Modification disabled flag.
   */
  @Input()
  public modificationDisabled: boolean;

  /**
   * Loading state of the PdfViewer.
   */
  public pdfViewerState: { loading: boolean; percentage: number; changed: boolean };

  /**
   * Component destroying signal to unsubscribe.
   */
  private _destroySignal = new Subject<any>();

  /**
   * Constructor.
   */
  constructor(private _helperService: HelperService, private _httpRestService: HttpRestService) {}

  /**
   * Angular lifecycle hook.
   */
  public ngOnInit(): void {
    this.pdfViewerState = { loading: false, percentage: 0, changed: false };
  }

  /**
   * Angular lifecycle hook.
   */
  public ngOnDestroy() {
    this._destroySignal.next();
  }

  /**
   * Check if add action is disabled.
   */
  public isAddDisabled(): boolean {
    return this.disabled || this.modificationDisabled || !this.pdfViewer || this.pdfViewerState.loading;
  }

  /**
   * Check if download action is disabled.
   */
  public isDownloadDisabled(): boolean {
    return this.disabled || (this.pdfViewer && this.pdfViewer.pdfSrc == null) || this.pdfViewerState.loading;
  }

  /**
   * Check if clear action is disabled.
   */
  public isClearDisabled(): boolean {
    return (
      this.disabled ||
      this.modificationDisabled ||
      (this.pdfViewer && this.pdfViewer.pdfSrc == null) ||
      this.pdfViewerState.loading
    );
  }

  /**
   * Check if card is empty.
   */
  public isEmpty(): boolean {
    return this.pdfViewer && this.pdfViewer.pdfSrc == null;
  }

  /**
   * Add new pdf into pdfViewer.
   */
  public add(): Observable<Blob> {
    if (this.isAddDisabled()) {
      return of(null);
    }

    return this._helperService.promptFileInput('application/pdf').pipe(
      map((files) => files[0]),
      tap((blob) => {
        this.pdfViewer.pdfSrc = blob;
        this.pdfViewer.zoom = 'page-height';
        this.pdfViewer.downloadFileName = this.filename;
        this.pdfViewer.refresh();
        this.pdfViewerState.changed = true;
      })
    );
  }

  /**
   * Save pdf to local disk.
   */
  public download(): Observable<Blob> {
    if (this.isDownloadDisabled()) {
      return of(null);
    }

    return of(this.pdfViewer).pipe(
      tap((pdfViewer) => {
        saveAs(pdfViewer.pdfSrc as Blob, pdfViewer.downloadFileName);
      }),
      map((pdfViewer) => pdfViewer.pdfSrc as Blob)
    );
  }

  /**
   * Clear the pdf from pdfViewer.
   */
  public clear(): Observable<PdfJsViewerComponent> {
    if (this.isClearDisabled()) {
      return of(null);
    }

    this.pdfViewer.pdfSrc = null;
    this.pdfViewer.refresh();
    this.pdfViewerState.changed = true;

    return of(this.pdfViewer);
  }

  /**
   * GET the pdf then load into pdfViewer.
   * @return Observable of response body.
   */
  public get(): Observable<any> {
    return of({}).pipe(
      mergeMap(() => this.clear()),
      mergeMap(() => {
        return this._httpRestService
          .get(
            this.getURL,
            {
              responseType: 'blob',
              observe: 'events',
              reportProgress: true,
            },
            false,
            false,
            (event) => {
              if (event.type == HttpEventType.Sent) {
                this.pdfViewerState.loading = true;
                this.pdfViewerState.percentage = 0;
              } else if (event.type == HttpEventType.DownloadProgress) {
                if (event.total) {
                  this.pdfViewerState.percentage = Math.round((100 * event.loaded) / event.total);
                }
              }
            }
          )
          .pipe(
            finalize(() => {
              this.pdfViewerState.loading = false;
              this.pdfViewerState.percentage = 0;
            })
          );
      }),
      map((res) => res.body),
      tap((blob) => {
        this.pdfViewer.pdfSrc = blob;
        this.pdfViewer.zoom = 'page-height';
        this.pdfViewer.downloadFileName = this.filename;
        this.pdfViewer.refresh();
        this.pdfViewerState.changed = false;
      })
    );
  }

  /**
   * PUT the pdf.
   * @return Observable of response body.
   */
  public put(): Observable<any> {
    return of(this.pdfViewer.pdfSrc).pipe(
      filter(() => this.pdfViewerState.changed),
      mergeMap((blob) => {
        const formData = new FormData();
        formData.append('_method', 'put');
        if (blob) {
          formData.append(this.putKey, <Blob>blob, `${this.filename}.pdf`);
        }

        return this._httpRestService
          .post(this.putURL, formData, { observe: 'events', reportProgress: true }, true, false, (event) => {
            if (event.type == HttpEventType.Sent) {
              this.pdfViewerState.loading = true;
              this.pdfViewerState.percentage = 0;
            } else if (event.type == HttpEventType.UploadProgress) {
              if (event.total) {
                this.pdfViewerState.percentage = Math.round((100 * event.loaded) / event.total);
              }
            }
          })
          .pipe(
            map((res) => res.body),
            finalize(() => {
              this.pdfViewerState.loading = false;
              this.pdfViewerState.percentage = 0;
            })
          );
      })
    );
  }

  /**
   * Initialize pdfViewer.
   * @return Observable of fabric.Canvas.
   */
  public initialize(): Observable<PdfJsViewerComponent> {
    this.pdfViewer.pdfSrc = null;
    this.pdfViewer.refresh();

    return of(this.pdfViewer);
  }
}
