import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Cropper from 'cropperjs';

import { BaseDialogComponent } from '../dialog/base-dialog/base-dialog.component';

/**
 * Image viewer dialog component.
 */
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Dialog component.
   */
  @ViewChild('dialog', { read: BaseDialogComponent, static: true })
  public dialog: BaseDialogComponent;

  /**
   * IMG ElementRef.
   */
  @ViewChild('img', { read: ElementRef })
  private _img: ElementRef;

  /**
   * Image data.
   */
  public imageData: string;

  /**
   * Image alt text.
   */
  public alt: String;

  /**
   * Image original width.
   */
  public width: number;

  /**
   * Image original height.
   */
  public height: number;

  /**
   * Cropper.
   */
  public cropper: any;

  /**
   * Cropper config.
   */
  public cropperConfig: any;

  /**
   * Forced to crop.
   */
  public forced: boolean;

  /**
   * Max dialog height.
   */
  private _maxDialogHeight: number = (window.innerHeight * 90) / 100;

  /**
   * Max dialog width.
   */
  private _maxDialogWidth: number = (window.innerWidth * 95) / 100;

  /**
   * Constructor.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<ImageEditorComponent>) {
    this.cropperConfig = data.cropperConfig;
    this._initImage(data);
  }

  /**
   * Angular life cycle hook.
   */
  public ngOnInit() {
    this.forced = this._dialogRef.disableClose;
  }

  /**
   * Angular life cycle hook.
   */
  public ngOnDestroy() {
    this.cropper.destroy();
  }

  /**
   * Angular life cycle hook.
   */
  public ngAfterViewInit() {
    // Add custom class on the cdk-overlay-pane.
    this._dialogRef.addPanelClass('image-editor');

    if (!this.width || !this.height) {
      // Use settimeout to wait for the IMG tag finish loading the image.
      setTimeout(() => {
        // Get the original image dimension.
        this.width = this._img.nativeElement.naturalWidth;
        this.height = this._img.nativeElement.naturalHeight;

        this._initPanel();
      });
    } else {
      this._initPanel();
    }
  }

  /**
   * Reset.
   */
  public reset() {
    this.cropper.reset();
  }

  /**
   * Rotate left.
   */
  public rotateLeft() {
    this.cropper.rotate(-30);
  }

  /**
   * Rotate right.
   */
  public rotateRight() {
    this.cropper.rotate(30);
  }

  /**
   * Rotate crop.
   */
  public rotateCrop() {
    this.cropperConfig.aspectRatio = 1 / this.cropperConfig.aspectRatio;
    this.cropper.setAspectRatio(this.cropperConfig.aspectRatio);
  }

  /**
   * Confirm.
   */
  public confirm() {
    this._dialogRef.close(this.cropper.getCroppedCanvas({}).toDataURL('image/jpeg'));
  }

  /**
   * Close the dialog.
   */
  public close() {
    this._dialogRef.close();
  }

  /**
   * Init image.
   */
  private _initImage({ imageData, width, height, alt }) {
    this.imageData = imageData;
    this.width = width;
    this.height = height;
    this.alt = alt;
  }

  /**
   * Init image's main panel.
   */
  private _initPanel() {
    // Initial resize to fit screen.
    this._resizeToFit();

    // Initialize Cropper.js.
    this.cropper = new Cropper(this._img.nativeElement, this.cropperConfig);
  }

  /**
   * Resize image to fit the screen.
   */
  private _resizeToFit() {
    let newWidth: number;
    let newHeight: number;

    // Resize width first...
    newWidth = Math.min(this.width, this._maxDialogWidth);
    newHeight = (this.height * newWidth) / this.width;
    // ...then resize height.
    newHeight = Math.min(newHeight, this._maxDialogHeight);
    newWidth = (this.width * newHeight) / this.height;

    this._img.nativeElement.style.width = newWidth + 'px';
    this._img.nativeElement.style.height = newHeight + 'px';
  }
}
