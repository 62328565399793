import { ControlValueAccessor } from '@angular/forms';

/**
 * Base implemention of the Control Value Accessor pattern from Angular.
 */
export class ValueAccessor<T> implements ControlValueAccessor {
  /**
   * OnChanged listeners.
   */
  protected _onChanged = new Array<(value: T) => void>();

  /**
   * OnTouched listeners.
   */
  protected _onTouched = new Array<() => void>();

  /**
   * The inner value.
   */
  protected _value: T;
  get value(): T {
    return this._value;
  }
  set value(value: T) {
    if (this._value !== value) {
      this._value = value;

      // Call all onChanged listeners.
      this._onChanged.forEach((fn) => fn(value));
    }
  }

  /**
   * Constructor
   */
  constructor() {}

  /**
   * Simulate touch event.
   */
  public touch(): void {
    // Call all onTouched listeners.
    this._onTouched.forEach((f) => f());
  }

  /**
   * ControlValueAccessor implementation. Called by angular to write changed value to the form control.
   * @param value Value to be written to the form control.
   */
  public writeValue(value: T): void {
    this.value = value;
  }

  /**
   * ControlValueAccessor implementation. Used to register onChanged handler.
   * @param fn onChange handler function.
   */
  public registerOnChange(fn: (value: T) => void): void {
    this._onChanged.push(fn);
  }

  /**
   * ControlValueAccessor implementation. Used to register onTouched handler.
   * @param fn onTouched handler function.
   */
  public registerOnTouched(fn: () => void): void {
    this._onTouched.push(fn);
  }
}
