import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message-snackbar',
  templateUrl: './message-snackbar.component.html',
  styleUrls: ['./message-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSnackbarComponent extends SimpleSnackBar {
  /**
   * Flag indicating it is a warning message.
   */
  @HostBinding('class.warn')
  public warn: boolean;

  /**
   * Constructor.
   */
  constructor(public snackBarRef: MatSnackBarRef<SimpleSnackBar>, @Inject(MAT_SNACK_BAR_DATA) data: any) {
    super(snackBarRef, data);
    this.warn = data.warn;
  }
}
